import { SessionService } from 'src/app/core/services/session.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { GroupType, Permission } from 'src/app/core/enums/enums';
import { Content, IGroup, IRole, IUser } from 'src/app/core/models/models';
import { UseCaseRoleService } from 'src/app/core/rest-services/rest-services';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from 'src/app/core/services/services.service';
import {
  DEFAULT_IMAGE_BENEFIT,
  DEFAULT_IMAGE_CORPORATE,
  DEFAULT_IMAGE_HCP,
  DEFAULT_IMAGE_KIMBO,
  INPUT_CLASSES,
} from 'src/app/core/utils/constant';
import { OptionObject, PaginationElement } from 'src/app/core/utils/type';
import { CreateEditRolesComponent } from 'src/app/pages/containers/components/create-edit-roles/create-edit-roles.component';
import { GroupMembersComponent } from 'src/app/pages/containers/components/group-members/group-members.component';

@Component({
  selector: 'app-role-selection-modal',
  templateUrl: './role-selection-modal.component.html',
  styleUrls: ['./role-selection-modal.component.scss'],
})
export class RoleSelectionModalComponent implements OnInit, OnDestroy {
  input_cls = INPUT_CLASSES;
  roleList!: OptionObject[];
  roleGroups: { [key: string]: IGroup | undefined } = {};
  optionCreateGroup!: OptionObject;
  groupType = GroupType;
  currentRole!: IRole;
  currentRoleOption!: OptionObject | undefined;
  isOwner = false;
  isLoading = false;
  page = 1;
  pagination: PaginationElement = { page: 1, totalPages: 1 };
  roles!: IRole[];
  roleOptions: OptionObject[] = [];
  translations = {
    companyRole: '',
    AddGroup: '',
  };
  translationKeys: string[] = [
    'common.company-role',
    'admin-fields.create-group-label',
  ];
  searchValue = '';
  $languageChange!: Subscription;
  isAddingNewData = false;
  currentUser!: IUser;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedRole: OptionObject },
    public dialogRef: MatDialogRef<RoleSelectionModalComponent>,
    private services: ServicesService,
    public usecaseRoleService: UseCaseRoleService,
    public languageService: LanguageService,
    public sessionService: SessionService
  ) {
    this.currentRole = this.services.sessionService.getCurrentRole();
    this.isOwner = this.currentRole.permissions.includes(Permission.Owner);
    this.currentUser = this.sessionService.getUser();
    if (this.currentRole.id === -1) {
      this.currentRoleOption = {
        label: this.currentRole.group.type,
        value: GroupType.Admin,
        src: 'assets/images/settings.png',
      };
    } else {
      this.currentRoleOption = {
        label: this.currentRole.group.name,
        value: this.currentRole.id.toString(),
        src:
          this.currentRole.group.image_profil &&
          this.currentRole.group.image_profil.path !==
            'public/general/unknow.png' &&
          this.currentRole.group.image_profil.path !==
            'storage/general/group.png'
            ? this.services.utilsService.getServerUrl(
                this.currentRole.group.image_profil.path
              )
            : this.currentRole.group.type === GroupType.Hcp
            ? DEFAULT_IMAGE_HCP
            : this.currentRole.group.type === GroupType.Companie
            ? DEFAULT_IMAGE_CORPORATE
            : this.currentRole.group.type === GroupType.Benefit
            ? DEFAULT_IMAGE_BENEFIT
            : DEFAULT_IMAGE_KIMBO,
      };
    }
  }
  ngOnDestroy(): void {
    this.$languageChange?.unsubscribe();
  }

  ngOnInit(): void {
    this.getRoles();
    this.getTranslations();
  }

  getRoles(isSetPage = false) {
    if (!isSetPage) this.isLoading = true;
    if (!isSetPage) this.roles = [];
    this.isAddingNewData = isSetPage;
    this.usecaseRoleService
      .listRoles(this.page, this.searchValue, 5)
      .subscribe({
        next: (res: Content<IRole>) => {
          this.roles = [...this.roles, ...res.data];
          this.getFilter();
          this.pagination = {
            page: this.page,
            totalPages: res.last_page,
            total: res.total,
            perPage: res.per_page,
            form: res.from,
            to: res.to,
          };
        },
        error: (error: any) => {
          this.isLoading = false;
          this.isAddingNewData = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isAddingNewData = false;
        },
      });
  }

  search(value: string): void {
    this.page = 1;
    this.searchValue = value.toLocaleLowerCase();
    this.getRoles();
  }

  selectedOption(option: OptionObject) {
    this.dialogRef.close(option);
  }

  editCurrentRole(action: 'EDIT_GROUP' | 'EDIT_MEMBERS'): void {
    if (this.isOwner && this.currentRole.id !== -1) {
      if (action === 'EDIT_GROUP') {
        this.services.modalService.openModal(CreateEditRolesComponent, {
          height: 'auto',
          width: '800px',
          data: {
            state: this.data.selectedRole,
            action: '_EDIT_GROUP',
            caller: '_OTHER',
          },
        });
      } else {
        this.services.modalService.openModal(GroupMembersComponent, {
          width: '400px',
          height: '500px',
          data: {
            selectedRole: this.data.selectedRole,
          },
        });
      }
    }
  }

  onScroll(): void {
    this.page++;
    if (this.page <= this.pagination.totalPages) {
      this.getRoles(true);
    }
  }

  getFilter(): void {
    this.roleOptions = [];
    const user = this.services.sessionService.getUser();
    this.currentRole = this.services.sessionService.getCurrentRole();
    if (this.currentRole) {
      if (
        this.currentRole.group.type === GroupType.Admin &&
        user?.adminroles &&
        user?.adminroles?.length > 0
      ) {
        this.roleOptions.push({
          label: this.currentRole.group.type,
          value: GroupType.Admin,
          src: 'assets/images/settings.png',
        });
      } else {
        this.roleOptions.push({
          label: this.currentRole.group.name,
          value: this.currentRole.id.toString(),
          src:
            this.currentRole.group.image_profil &&
            this.currentRole.group.image_profil.path !==
              'public/general/unknow.png' &&
            this.currentRole.group.image_profil.path !==
              'storage/general/group.png'
              ? this.services.utilsService.getServerUrl(
                  this.currentRole.group.image_profil.path
                )
              : this.currentRole.group.type === GroupType.Hcp
              ? DEFAULT_IMAGE_HCP
              : this.currentRole.group.type === GroupType.Companie
              ? DEFAULT_IMAGE_CORPORATE
              : this.currentRole.group.type === GroupType.Benefit
              ? DEFAULT_IMAGE_BENEFIT
              : DEFAULT_IMAGE_KIMBO,
        });
      }
    }
    if (
      (user.admin_type != null || user.admin_type != undefined) &&
      this.currentRole &&
      this.currentRole.group.type != GroupType.Admin &&
      user?.adminroles &&
      user?.adminroles?.length > 0
    ) {
      this.roleOptions.push({
        label: user.admin_type,
        value: GroupType.Admin,
        src: 'assets/images/settings.png',
      });
    }
    if (this.roles) {
      this.roles.forEach(role => {
        if (this.currentRole && this.currentRole.group.id !== role.group.id) {
          this.roleOptions.push({
            label: role.group.name,
            value: role.id.toString(),
            src:
              role.group.image_profil &&
              role.group.image_profil.path !== 'public/general/unknow.png' &&
              role.group.image_profil.path !== 'storage/general/group.png'
                ? this.services.utilsService.getServerUrl(
                    role.group.image_profil.path
                  )
                : role.group.type === GroupType.Hcp
                ? DEFAULT_IMAGE_HCP
                : role.group.type === GroupType.Companie
                ? DEFAULT_IMAGE_CORPORATE
                : role.group.type === GroupType.Benefit
                ? DEFAULT_IMAGE_BENEFIT
                : DEFAULT_IMAGE_KIMBO,
          });
        } else {
          this.currentRole = role;
        }
      });
    }

    this.roleList = this.roleOptions.filter(
      role =>
        role.value !== '_ADD_GROUP' &&
        Number(role.value) !== this.currentRole.id &&
        !(this.currentRole.id == -1 && role.value == 'admin')
    );

    this.optionCreateGroup = {
      label: this.translations.AddGroup,
      value: '_ADD_GROUP',
      src: 'assets/images/human.png',
    };

    if (this.roleOptions.length > 0) {
      this.services.eventService.publish('role:picked', this.roleOptions[0]);
    }

    this.prepareRoleGroups();
  }

  prepareRoleGroups(): void {
    this.roleGroups = {};
    this.roleList.forEach(role => {
      const roleId = Number(role.value);
      const correspondingRole = this.roles.find(r => r.id === roleId);
      this.roleGroups[role.value] = correspondingRole
        ? correspondingRole.group
        : undefined;
    });
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.companyRole = translations['common.company-role'];
      this.translations.AddGroup =
        translations['admin-fields.create-group-label'];
    });
  }

  public listenForLanguageEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }
}
