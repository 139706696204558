import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ICountryModel,
  ICountryModelStore,
  ICountryModelUpdate,
} from '../../models/Country';
import { Content } from '../../models/models';

@Injectable()
export class CountryService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * List all Currencies
   * @param page
   */
  public index(
    page?: number,
    search?: string,
    lang?: string
  ): Observable<Content<ICountryModel>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page ? page : 1,
        ...(search != null && { search: search }),
        ...(lang != null && { lang: lang }),
      },
    });
    return this.http.get<Content<ICountryModel>>(
      `${this.basePath}/v1/resources/countrie`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param currencieId the currencie identifier
   */
  public view(currencieId: number): Observable<ICountryModel> {
    return this.http.get<ICountryModel>(
      `${this.basePath}/v1/resources/countrie/${currencieId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: any): Observable<ICountryModel> {
    return this.http.post<ICountryModel>(
      `${this.basePath}/v1/resources/countrie`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param countryId
   */
  public update(
    countryId: number,
    body: ICountryModelUpdate
  ): Observable<ICountryModel> {
    return this.http.put<ICountryModel>(
      `${this.basePath}/v1/resources/countrie/${countryId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param countryId
   */
  public delete(countryId: number): Observable<ICountryModel> {
    return this.http.delete<ICountryModel>(
      `${this.basePath}/v1/resources/countrie/${countryId}`,
      this.httpOptions
    );
  }
}
