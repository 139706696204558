<label class="relative flex items-center" for="search-input">
  <div
    class="absolute left-3 w-6 h-6 pointer-events-none text-blue"
    aria-label="search icon"
    [inlineSVG]="'assets/svg/search.svg'"></div>
  <input
    (input)="search($event)"
    type="search"
    [value]="searchValue"
    class="w-full px-4 rounded-lg pl-14 h-12 bg-kimbo-blue bg-opacity-5 placeholder-dark-gray focus:outline-none focus:shadow-inner-blue"
    id="search-input"
    [placeholder]="'kimbo-layout.search-placeholder' | translate"
    [disabled]="disabled"
    tabindex="-1" />
</label>
