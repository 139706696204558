<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="flex flex-col rounded-4xl h-full bg-white  {{
    variant === 'backdrop' ? 'shadow-backdrop' : ''
  }} {{ variant === 'border' ? 'shadow-inner-blue-sm' : '' }} {{
    caller === '_KIMBO' ? 'overflow-hidden' : ''
  }}">
  <div *ngIf="isLoading" class="flex items-center justify-center w-full h-24">
    <app-spinner class="inline-block w-8 h-8 ml-4 filter-blue"></app-spinner>
  </div>
  <div
    *ngIf="!isLoading"
    class="relative flex-grow p-6 md:px-8 md:pt-8 md:pb-6">
    <div *ngIf="caller === '_HCP'" class="absolute top-16 -right-2">
      <div
        class="px-2 h-5 rounded-b-sm rounded-tl-sm  {{
          recommendation.status === recommendationStatus.WAITING
            ? 'bg-yellow'
            : recommendation.status === recommendationStatus.ACCEPTED
            ? 'bg-green'
            : 'bg-red'
        }} mt-1 w-fit text-xs items-center justify-center flex text-white font-semibold relative shadow-dark-gray">
        <div
          class="r_triangle_t {{
            recommendation.status === recommendationStatus.WAITING
              ? 'r_triangle_t_yellow'
              : recommendation.status === recommendationStatus.ACCEPTED
              ? 'r_triangle_t_green'
              : 'r_triangle_t_red'
          }}"></div>
        <span>{{ 'common.' + recommendation.status | translate }}</span>
      </div>
    </div>
    <div *ngIf="healthcarepackage" class="flex items-start justify-between">
      <img
        appImg
        class="mb-3 fill-current h-14 w-14"
        [src]="
          healthcarepackage
            ? (healthcarepackage!.image_profil | imageToUrl)
            : ''
        "
        alt="healthcare provider image" />
      <button
        *ngIf="
          caller === '_HCP' &&
          recommendation.status === recommendationStatus.WAITING
        "
        mat-icon-button
        color="warn"
        (click)="delete()">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
    <div class="text-xl font-bold text-center text-navy">
      {{
        healthcarepackage
          ? (healthcarepackage.title.languages
            | textLanguages : currentLanguage)
          : ''
      }}
    </div>
    <div class="mt-2 font-semibold text-center text-dark-gray">
      {{ 'common.hc' | translate : { amount: recommendation.amount } }} /
      {{
        'common.money-s'
          | translate
            : {
                currency: currency.symbol,
                amount: _toMoney(recommendation.amount)
              }
      }}
    </div>
    <div
      *ngIf="recommendation && recommendation.healthcareprovider"
      class="mt-2 text-lg font-bold text-center text-navy">
      {{ recommendation.healthcareprovider.title.languages | textLanguages }}
    </div>
  </div>
  <div
    *ngIf="!isLoading"
    (click)="toggleDropdownVisibility()"
    class="flex w-full px-8  items-center justify-between bg-blue bg-opacity-5 cursor-pointer {{
      caller === '_HCP' && !dropdownVisibility
        ? 'rounded-br-4xl rounded-bl-4xl'
        : ''
    }} py-3">
    <span class="text-xs font-semibold text-blue">{{
      'hcp-common.note-label' | translate
    }}</span>
    <img
      appImg
      class="ml-4 mt-2 w-6 {{ dropdownVisibility ? 'rotate-180' : '' }}"
      aria-label="plus icon"
      alt="plus icon"
      src="assets/svg/arrow-bottom.svg" />
  </div>
  <div
    *ngIf="dropdownVisibility"
    class="px-8 pt-2 pb-8 text-sm break-words text-navy">
    {{ recommendation.note }}
  </div>
  <div
    *ngIf="caller === '_KIMBO' && isOwner"
    class="flex w-full items-center justify-center border-t-[1px] border-light-gray h-16">
    <div
      class="w-4/12 xs:w-1/2 border-r-[1px] border-light-gray flex items-center justify-center h-full">
      <button
        (click)="reject()"
        mat-button
        color="warn"
        class="w-full h-full"
        [disabled]="isLoading">
        <div class="flex items-center justify-center gap-1">
          <mat-icon>delete_forever</mat-icon>
          <span>{{ 'common.refused-button' | translate }}</span>
        </div>
      </button>
    </div>
    <div class="flex items-center justify-center w-8/12 h-full xs:w-1/2">
      <button
        mat-button
        color="primary"
        (click)="accept()"
        type="submit"
        class="w-full h-full"
        [disabled]="isLoading">
        <div class="flex items-center justify-center gap-1">
          <mat-icon>done_all</mat-icon>
          <span>{{ 'common.accepted-button' | translate }}</span>
        </div>
      </button>
    </div>
  </div>
</div>
