import { Injectable } from '@angular/core';
import { GroupType } from '../enums/enums';
import { SessionService } from './session.service';
import { CustomNavigationService } from './custom-navigation.service';
import { IRole } from '../models/User';
import { ServicesService } from './services.service';
import { Currency } from '../models/Common';
import { CurrencyService } from '../rest-services/rest-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private currentRole!: IRole;
  constructor(
    private sessionService: SessionService,
    private services: ServicesService,
    private navigationService: CustomNavigationService,
    private currencyService: CurrencyService,
    public translateService: TranslateService
  ) {
    this.currentRole = this.sessionService.getCurrentRole();
  }

  public changeRoleWithGroupType(group_type: GroupType): void {
    const role = this.services.sessionService.getRole(group_type);
    if (role == null) {
      this.services.customNavigationService.goTo('/404');
      return;
    }
    this.changeRole(role);
  }

  public changeRole(role: IRole, activeLocation?: string): void {
    const actLocation = !activeLocation?.includes('login')
      ? activeLocation
      : undefined;
    if (role) {
      activeLocation?.includes('login') &&
        this.sessionService.deleteCurrentRole();
      const storedRole: IRole = this.sessionService.getCurrentRole();
      if (!storedRole || storedRole.id !== role.id) {
        this.currentRole = role;
        this.sessionService.deleteCurrentRole();
        this.sessionService.storeCurrentRole(role);
        if (!actLocation) {
          if (
            role.group.type === GroupType.Admin ||
            role.group.type === GroupType.Companie ||
            role.group.type === GroupType.Kimbo
          ) {
            this.sessionService.deleteCurrencie();
            this.sessionService.storeCurrencie(
              this.sessionService.getUserCurrencie()
            );
          }
          if (role.group.type === GroupType.Admin) {
            if (
              this.services.sessionService.getUser()?.adminroles?.length! > 0
            ) {
              //const adminInitialRoute = this.sessionService.initialRoute();
              this.navigationService.goToWithState('/admin/monitoring', {
                state: { dashBoardRedirection: true },
              });
              // if (adminInitialRoute) {

              // }
            } else {
              this.navigationService.goTo('/kimbo');
            }
          } else if (role.group.type === GroupType.Companie) {
            this.navigationService.goTo('/corporate');
            this.services.eventService.publish('set:browser-compatibility');
          }
          if (role.group.type === GroupType.Benefit) {
            this.navigationService.goTo('/benefit');
            this.services.eventService.publish('contact-count:refresh');
            this.services.eventService.publish('set:browser-compatibility');
          } else if (role.group.type === GroupType.Hcp) {
            this.fetchCurrencie(role.group.healthcareprovider?.currencie_id!);
          } else if (role.group.type === GroupType.Kimbo) {
            this.navigationService.goTo('/kimbo');
            this.services.eventService.publish('contact-count:refresh');
          }
        } else {
          if (role.group.type === GroupType.Hcp) {
            this.fetchCurrencie(
              role.group.healthcareprovider?.currencie_id!,
              actLocation
            );
          } else {
            this.navigationService.goTo(actLocation);
          }
        }
        if (role.group.type !== GroupType.Hcp) {
          this.services.eventService.publish('change:role', this.currentRole);
        }
      }
    }
  }

  fetchCurrencie(currencieId: number, activeLocation?: string): void {
    const currencyhcp = this.sessionService.getHcpCurrencie();
    if (currencyhcp === null || currencyhcp.id !== currencieId) {
      this.services.eventService.publish('show:loading');
      this.currencyService.view(currencieId).subscribe({
        next: (res: Currency) => {
          this.sessionService.deleteHcpCurrencie();
          this.sessionService.storeHcpCurrencie(res);
          this.sessionService.deleteCurrencie();
          this.sessionService.storeCurrencie(res);
          this.navigationService.goTo(activeLocation ? activeLocation : '/hcp');
          this.services.eventService.publish('change:role', this.currentRole);
        },
        complete: () => {
          this.services.eventService.publish('hide:loading');
        },
      });
    } else {
      this.sessionService.deleteCurrencie();
      this.sessionService.storeCurrencie(currencyhcp);
      this.navigationService.goTo('/hcp');
      this.services.eventService.publish('change:role', this.currentRole);
    }
  }

  public getCurrentRole(): IRole {
    return this.currentRole;
  }

  public getCurrentTypeRole(): GroupType {
    return this.currentRole.group.type;
  }

  public getCurrentGroupId(): number {
    return this.currentRole.group.id;
  }

  public getCurrentHcpId(): number | null {
    if (this.currentRole.group.healthcareprovider != null) {
      return this.currentRole.group.healthcareprovider.id;
    }
    return null;
  }

  public storeCurrentRole(role: IRole): void {
    this.sessionService.storeCurrentRole(role);
    this.currentRole = role;
  }
}
