import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileResponse } from '../../models/File';

@Injectable()
export class FileService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Store
   * Store a file
   * @param file
   * @param url
   * @param filable_id
   * @param filable_type
   * @param type
   * @param data
   */
  public store(
    file?: Blob,
    url?: string,
    filable_id?: number,
    filable_type?: string,
    type?: string,
    data?: any
  ): Observable<any> {
    const formParams = new FormData();
    if (file) {
      formParams.append('file', file);
    }
    if (url) {
      formParams.append('url', url);
    }
    if (filable_id) {
      formParams.append('filable_id', String(filable_id));
    }
    if (filable_type) {
      formParams.append('filable_type', filable_type);
    }
    if (type) {
      formParams.append('type', type);
    }
    if (data) {
      formParams.append('data', data);
    }
    return this.http.post<any>(
      `${this.basePath}/v1/resources/file`,
      formParams,
      this.httpOptionsWithFromData
    );
  }

  /**
   * Delete
   * Delete a file
   * @param fileID
   */
  public delete(fileID: number): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/v1/resources/file/${fileID}`,
      this.httpOptions
    );
  }

  /**
   * Update
   * Update a file
   * @param file
   * @param url
   */
  public update(
    fileID: number,
    file?: File,
    url?: string
  ): Observable<FileResponse> {
    const formParams = new FormData();
    if (file) {
      formParams.append('file', file, file.name);
    }
    if (url) {
      formParams.append('url', url);
    }
    return this.http.post<FileResponse>(
      `${this.basePath}/v1/resources/file-up/${fileID}`,
      formParams,
      this.httpOptionsWithFromData
    );
  }
}
