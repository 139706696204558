import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BenefitDashboard, IBenefitDashboardV2 } from '../../models/Benefit';

@Injectable()
export class BenefitService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}
  /**
   * Benefit dashboard v1
   * @deprecated
   * @param benefitId the group benefit id
   * @param period the period of the dashboard
   * @param start_date the start date of the dashboard when selected custom period
   * @param end_date the end date of the dashboard when selected custom period
   */
  public homeDashoard(
    benefitId: number,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<BenefitDashboard> {
    const queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date && { start_date: start_date }),
        ...(end_date && { end_date: end_date }),
      },
    });
    return this.http.get<BenefitDashboard>(
      `${this.basePath}/v1/benefit/${benefitId}`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * Benefit dashboard v2
   * @param benefitId the group benefit id
   * @param period the period of the dashboard
   * @param start_date the start date of the dashboard when selected custom period
   * @param end_date the end date of the dashboard when selected custom period
   */
  public dashoardV2(
    benefitId: number,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<IBenefitDashboardV2> {
    const queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date && { start_date: start_date }),
        ...(end_date && { end_date: end_date }),
      },
    });
    return this.http.get<IBenefitDashboardV2>(
      `${this.basePath}/v1/benefit/${benefitId}/v2`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
