<ng-container
  *ngIf="shape === '_BLUE'"
  [ngTemplateOutlet]="classicPangination"></ng-container>

<div *ngIf="shape === '_WHITE'" class="flex">
  <span
    (click)="back()"
    class="flex items-center justify-center  px-4 py-2 mx-1 text-gray-500 capitalize bg-white rounded-md {{
      this.canBack ? 'cursor-pointer' : 'cursor-not-allowed'
    }}  rtl:-scale-x-100 dark:bg-gray-800 dark:text-gray-600">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clip-rule="evenodd" />
    </svg>
  </span>
  <div
    *ngFor="let element of paginationElements; let i = index"
    class="flex items-center">
    <span
      *ngIf="
        element !== params.pagination.page &&
        kind === 'button' &&
        !isSeparator(element)
      "
      (click)="onPageChange(element)"
      title="Go to page number {{ element }}"
      class="hidden px-4 py-2 mx-1 text-gray-700 cursor-pointer transition-colors duration-300 transform bg-white rounded-md sm:inline hover:bg-kimbo-blue hover:text-white">
      {{ element }}
    </span>

    <span
      *ngIf="element === params.pagination.page && !isSeparator(element)"
      class="hidden px-4 py-2 mx-1 text-gray-700 cursor-pointer transition-colors duration-300 transform rounded-md sm:inline bg-kimbo-blue text-white">
      {{ element }}
    </span>

    <span
      *ngIf="isSeparator(element)"
      class="hidden px-4 py-2 mx-1 text-gray-700 cursor-pointer transition-colors duration-300 transform bg-white rounded-md sm:inline hover:bg-kimbo-blue hover:text-white">
      ...
    </span>
  </div>
  <span
    (click)="next()"
    class="flex items-center justify-center px-4 py-2  {{
      this.canNext ? 'cursor-pointer' : 'cursor-not-allowed'
    }} mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md rtl:-scale-x-100 hover:bg-blue-500 hover:text-white">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clip-rule="evenodd" />
    </svg>
  </span>
</div>

<div
  *ngIf="shape === '_COMPOSED'"
  class="w-full bg-white mt-2 dark:bg-gray-800">
  <div
    class="container flex flex-col items-center px-1 mx-auto sm:flex-row sm:justify-between">
    <ng-container [ngTemplateOutlet]="classicPangination"></ng-container>

    <div class="text-gray-500 dark:text-gray-400">
      <span class="font-medium text-gray-700 dark:text-gray-100"
        >{{ params.pagination.form }} - {{ params.pagination.to }}</span
      >
      {{ 'common.of-label' | translate }}
      {{ params.pagination.total }}
      {{ 'common.records-label' | translate }}
    </div>
  </div>
</div>

<ng-template #classicPangination>
  <ul
    class="{{
      params.className
    }} flex flex-wrap items-stretch rounded-lg bg-transparent text-sm">
    <li *ngFor="let element of paginationElements; let i = index">
      <div
        *ngIf="isSeparator(element)"
        class="inline-flex items-center justify-center h-10 w-10 {{
          i === 0
            ? 'rounded-l-lg'
            : i === paginationElements.length - 1
            ? 'rounded-r-lg'
            : ''
        }} text-lighter-blue ">
        ...
      </div>
      <div
        *ngIf="element === params.pagination.page && !isSeparator(element)"
        tabIndex="0"
        class="inline-flex items-center justify-center h-10 w-10 {{
          i === 0
            ? 'rounded-l-lg'
            : i === paginationElements.length - 1
            ? 'rounded-r-lg'
            : ''
        }} font-bold bg-blue text-white focus:outline-none focus:shadow-inner-lighter-blue">
        {{ element }}
      </div>
      <button
        *ngIf="
          element !== params.pagination.page &&
          kind === 'button' &&
          !isSeparator(element)
        "
        class="inline-flex items-center justify-center h-10 w-10 {{
          i === 0
            ? 'rounded-l-lg'
            : i === paginationElements.length - 1
            ? 'rounded-r-lg'
            : ''
        }} font-bold text-blue hover:opacity-75  bg-kimbo-blue bg-opacity-5 focus:outline-none focus:shadow-inner-lighter-blue"
        (click)="onPageChange(element)"
        title="Go to page number {{ element }}"
        type="button">
        {{ element }}
      </button>
    </li>
  </ul>
</ng-template>
