import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CampaignCommonResponse,
  CampaignStore,
  Content,
  ICampaign,
  Insight,
  InsightStore,
} from '../../models/models';

@Injectable()
export class CampaignService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * @param page
   * @param name
   * @param status
   * @param without_status
   * @param orderby_column
   * @param orderby_direction
   */
  public index(
    page: number,
    country_id?: number,
    name?: string,
    status?: string,
    without_status?: string,
    orderby_column = 'id',
    orderby_direction: 'DESC' | 'ASC' = 'DESC'
  ): Observable<Content<ICampaign>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(country_id != null && { country_id: country_id }),
        ...(name != null && { name: name }),
        ...(status != null && { status: status }),
        ...(without_status != null && { without_status: without_status }),
        ...(orderby_column != null && { orderby_column: orderby_column }),
        ...(orderby_direction != null && {
          orderby_direction: orderby_direction,
        }),
      },
    });

    return this.http.get<Content<ICampaign>>(
      `${this.basePath}/v1/resources/campaign`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param campaignId the campaign identifier
   */
  public view(campaignId: number): Observable<CampaignCommonResponse> {
    return this.http.get<CampaignCommonResponse>(
      `${this.basePath}/v1/resources/campaign/${campaignId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: CampaignStore): Observable<ICampaign> {
    return this.http.post<ICampaign>(
      `${this.basePath}/v1/resources/campaign`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param campaignId the campaign identifier
   */
  public update(campaignId: number, body?: any): Observable<ICampaign> {
    return this.http.put<ICampaign>(
      `${this.basePath}/v1/resources/campaign/${campaignId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param campaignId the campaign identifier
   */
  public delete(campaignId: number): Observable<CampaignCommonResponse> {
    return this.http.delete<CampaignCommonResponse>(
      `${this.basePath}/v1/resources/campaign/${campaignId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public insightstore(body?: InsightStore): Observable<Insight> {
    return this.http.post<Insight>(
      `${this.basePath}/v1/resources/insight`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param insightId
   */
  public insightUpdate(
    insightId: number,
    body?: InsightStore
  ): Observable<Insight> {
    return this.http.put<Insight>(
      `${this.basePath}/v1/resources/insight/${insightId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * View
   * @param insightId
   */
  public viewInsight(insightId: number): Observable<Insight> {
    return this.http.get<Insight>(
      `${this.basePath}/v1/resources/insight/${insightId}`,
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param insightId
   */
  public deleteInsight(insightId: number): Observable<Insight> {
    return this.http.delete<Insight>(
      `${this.basePath}/v1/resources/insight/${insightId}`,
      this.httpOptions
    );
  }
}
