import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Currency } from '../../models/models';

@Injectable({ providedIn: 'root' })
export class PublicApiService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * list_currencies
   * List all Currencies
   */
  public list_currencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(
      `${this.basePath}/v1/usecases/currencies`,
      this.httpOptions
    );
  }
}
