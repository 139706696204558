<div
  class="px-6 h-[68px] bg-white flex items-center shadow-md shadow-black/5 sticky top-0 left-0 z-30">
  <button
    (click)="toggleSideMenuOpen()"
    type="button"
    class="text-lg text-gray-900 font-semibold sidebar-toggle block lg:hidden py-2">
    <i class="ri-menu-line"></i>
  </button>

  <ul class="ml-auto flex items-center h-full">
    <li
      *ngIf="notifications.length > 0"
      appClickOutside
      (clickOutside)="closeNotificationsDropdown()"
      class="dropdown cursor-pointer px-1">
      <div
        matRipple
        matRippleColor="rgba(30,104,194,.1)"
        class="rounded-full p-1">
        <button
          mat-icon-button
          type="button"
          class="relative inline-block dropdown-toggle items-center h-full px-1"
          (click)="toggleSideNotificationsOpen()">
          <span class="flex text-2xl pl-2">
            <i
              class="animate-ping text-gray absolute ri-notification-3-line opacity-75"></i>
            <i
              class="relative text-dark-gray inline-flex ri-notification-3-line"></i>
          </span>
          <span class="absolute flex top-2 right-2 h-3 w-3">
            <span
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-kimbo-ruby opacity-75"></span>
            <span
              class="relative inline-flex rounded-full h-3 w-3 bg-kimbo-ruby"></span>
          </span>
        </button>
      </div>
      <div
        *ngIf="isNotificationsOpen"
        class="absolute right-2 z-20 w-75 mt-3.5 h-[420px] origin-top-right bg-white flex flex-col rounded-md shadow-xl dark:bg-gray-800 transition ease-out duration-100">
        <div
          class="text-center p-2 border-b-1 sticky border-b-gray text-kimbo-blue font-medium">
          {{ 'admin-layout.alert-heading' | translate }}
        </div>
        <div class="overflow-y-auto">
          <button
            *ngFor="let item of notifications"
            class="p-4 gap-2 border-b-1 flex flex-row border-b-gray items-center hover:bg-gray-100 text-sm text-gray-600 w-full truncate cursor-pointer"
            (click)="showNotification(item)">
            <img
              src="assets/images/notification/notification-icon.png"
              class="w-5"
              alt="" />
            <span>{{
              shortenText(
                'admin-fields.' + item.data.message
                  | translate : getTranslateParams(item)
              )
            }}</span>
          </button>
        </div>
      </div>
    </li>

    <li
      appClickOutside
      (clickOutside)="closeProfileDropdown()"
      class="dropdown ml-1 cursor-pointer h-full">
      <button
        (click)="toggleSideProfileOpen()"
        type="button"
        class="dropdown-toggle flex items-center gap-1 h-full px-1"
        matRipple
        matRippleColor="rgba(30,104,194,.1)">
        <div class="flex-shrink-0 w-10 h-10 relative">
          <div class="p-1 bg-white rounded-full focus:outline-none focus:ring">
            <app-avatar
              [classNames]="'w-8 h-8'"
              [withFrame]="false"
              [withPreview]="false"
              caller="_PROFILE"
              [src]="user.image_profil | imageToUrl"></app-avatar>
          </div>
        </div>
        <div class="p-2 md:block text-left">
          <h2 class="text-sm font-semibold text-gray-800">
            {{
              user.data && user!.data.lastName && user!.data.firstName
                ? user!.data.firstName + ' ' + user!.data.lastName
                : ''
            }}
          </h2>
          <p class="text-xs text-gray-500">Admin</p>
        </div>
        <i
          class="ri-arrow-down-s-line text-dark-gray text-xl {{
            isProfilOpen ? 'rotate-180' : ''
          }}"></i>
      </button>
      <div
        *ngIf="isProfilOpen"
        class="absolute right-2 z-20 w-56 pt-2 mt-1 overflow-hidden origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800 transition ease-out duration-100">
        <app-menu-role-select variant="_MODAL"></app-menu-role-select>
        <hr class="border-gray-200 dark:border-gray-700" />

        <span
          (click)="openProfilDialog()"
          class="block gap-2 px-4 py-3 text-sm cursor-pointer text-gray-600 transition-colors duration-300 transform hover:bg-gray-100">
          <i class="ri-user-line mr-1 text-lg"></i>
          {{ 'common.view-profile' | translate }}
        </span>

        <hr class="border-gray-200 dark:border-gray-700" />

        <span
          (click)="logout()"
          class="block px-4 py-3 cursor-pointer text-sm text-gray-600 transition-colors duration-300 transform hover:bg-gray-100">
          <i class="ri-logout-circle-r-line mr-1 text-lg"></i>
          {{ 'common.logout-button' | translate }}
        </span>
      </div>
    </li>
  </ul>
</div>
