import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { PhoneNumberService } from './phoneNumber.service';

declare let sendPaymentInfos: any;

@Injectable({
  providedIn: 'root',
})
export class PaymentMobileService {
  constructor(
    private sessionService: SessionService,
    private phoneNumberService: PhoneNumberService
  ) {}
  origin = window.location.origin;

  public callTouchpay(
    order: number,
    agency: string,
    key: string,
    site: string,
    first_name: string,
    last_name: string,
    email: string,
    amount: number,
    city: string
  ) {
    this.sessionService.storePaymentInfoToast();
    sendPaymentInfos(
      order,
      agency,
      key,
      site,
      origin,
      origin,
      amount / 100,
      city,
      email,
      first_name,
      last_name,
      this.phoneNumberService.removeCoutryCodeInPhoneNumber(
        this.sessionService.getUser().tel!
      )
    );
  }
}
