import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content, IUser } from '../../models/models';
import { ICampaignMessage } from '../../models/campaign-message';

@Injectable({ providedIn: 'root' })
export class UseCaseCampaignService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  public listPatient(
    campaign_id: number,
    page: number,
    search_value: string
  ): Observable<Content<IUser>> {
    let queryParameters = new HttpParams({});
    if (search_value) {
      queryParameters = new HttpParams({
        fromObject: { search_value: search_value, page: page },
      });
    }
    return this.http.get<Content<IUser>>(
      `${this.basePath}/v1/campaign/${campaign_id}/list_patient`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  sendMessage(body: any, campaign_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/campaign/${campaign_id}/create_message`,
      body,
      this.httpOptions
    );
  }

  public listSendMessage(
    campaign_id: number,
    page: number,
    search_value: string
  ): Observable<Content<ICampaignMessage>> {
    let queryParameters = new HttpParams({});
    if (search_value) {
      queryParameters = new HttpParams({
        fromObject: { search_value: search_value, page: page },
      });
    }
    return this.http.get<Content<ICampaignMessage>>(
      `${this.basePath}/v1/campaign/${campaign_id}/message`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
