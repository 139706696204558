<h2 class="text-navy" mat-dialog-title>
  <span *ngIf="data.action === '_CREATE'">{{
    'forms.add-note-heading' | translate
  }}</span>
  <span *ngIf="data.action === '_EDIT'">{{
    'forms.edit-note-heading' | translate
  }}</span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="Example icon button with a delete icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <form
    [formGroup]="form"
    (ngSubmit)="save()"
    class="flex flex-wrap items-start justify-between mt-8">
    <app-input-dropdown
      class="w-full"
      (triggerEvent)="handleNoteTypeDropdownEvent($event)"
      [options]="noteTypeOptions"
      [pickValue]="selectedOption"
      [name]="'forms.note-type-label' | translate"></app-input-dropdown>
    <app-form-field [label]="'Note' | translate" class="w-full mt-6">
      <textarea
        class="w-full rounded-lg px-4 bg-light-gray placeholder-dark-gray focus:outline-none focus:shadow-inner-blue resize-none py-4"
        formControlName="textNote"
        id="textNote"
        [placeholder]="'forms.note-text-placeholder' | translate"></textarea>
    </app-form-field>
    <div
      class="w-full flex-col sm:items-end flex justify-center border-t-1 flex-shrink-0 border-gray mt-6 h-24">
      <div
        class="flex items-center justify-between w-full mt-6 py-4 md:py-0 flex-col xs:flex-row xs:justify-between gap-2">
        <button
          class="w-full sm:w-fit h-12"
          mat-stroked-button
          color="primary"
          [disabled]="isLoading"
          type="button"
          (click)="close()">
          {{ 'forms.cancel-button' | translate }}
        </button>
        <button
          class="w-full sm:w-fit h-12"
          mat-raised-button
          color="primary"
          [disabled]="isLoading || form.invalid"
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block h-6 w-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
