<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<button
  *ngIf="!readOnly"
  matTooltipPosition="after"
  type="button"
  (click)="closeConversationPannel()"
  class="absolute z-header flex items-center justify-center w-10 h-10 text-white bg-white rounded-full hover:bg-opacity-75 ring-4 focus:ring-blue-300 focus:outline-none top-5 right-5">
  <img
    appImg
    src="assets/svg/close.svg"
    alt="close icon"
    class="w-6 h-6 text-white" />
  <span class="sr-only">Open actions menu</span>
</button>
<div
  *ngIf="caller === 'HCP' && !readOnly"
  class="flex justify-start items-center border-light-gray border-b-1 gap-2 px-4 py-5 absolute top-0 left-0 right-0 z-dropdown glass-morphism">
  <app-avatar
    *ngIf="buyedPackage"
    class="w-10 h-10 flex-shrink cursor-pointer"
    classNames="w-10 h-10"
    [withFrame]="false"
    [src]="buyedPackage.patient.image_profil | imageToUrl">
  </app-avatar>
  <h1
    *ngIf="buyedPackage"
    class="text-navy text-base font-semibold z-base flex items-start gap-2">
    {{
      'common.hcp-conversation-heading'
        | translate
          : {
              name: buyedPackage.patient.adresse
                ? buyedPackage.patient.adresse!.first_name +
                  ' ' +
                  buyedPackage.patient.adresse!.last_name
                : ''
            }
    }}
  </h1>
</div>
<div
  *ngIf="caller === 'KIMBO' && !readOnly"
  class="flex justify-start items-center border-light-gray border-b-1 gap-2 px-4 py-5 absolute top-0 left-0 right-0 z-dropdown glass-morphism">
  <app-avatar
    *ngIf="buyedPackage"
    class="w-10 h-10 flex-shrink cursor-pointer"
    classNames="w-10 h-10"
    [withFrame]="false"
    [src]="buyedPackage.healthcareprovider?.image_profil! | imageToUrl">
  </app-avatar>
  <h1
    *ngIf="buyedPackage"
    class="text-navy text-base font-semibold z-base flex items-start pr-11 gap-2">
    {{ buyedPackage.healthcareprovider?.title?.languages! | textLanguages }}
  </h1>
</div>

<div
  #chatdiv
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="1.5"
  [infiniteScrollThrottle]="150"
  [scrollWindow]="false"
  (scrolledUp)="onScroll()"
  class="flex flex-col flex-autos chat-pattern {{
    !readOnly &&
      'absolute z-base top-0 left-0 right-0 bottom-[100px] overflow-y-scroll pt-20'
  }} ">
  <div class="flex flex-col flex-auto flex-shrink-0 h-full py-4">
    <div
      class="flex items-center justify-center text-sm p-6 {{
        !readOnly ? 'mx-4' : ''
      }}  rounded-sm gap-3 bg-kimbo-blue bg-opacity-10 text-kimbo-blue">
      <i class="ri-information-2-line text-xl"></i>
      {{ 'common.chat-disclamer-label' | translate }}
    </div>
    <app-skeleton-loader
      *ngIf="isLoading || isAddingNewData"
      skeletonType="MESSAGE_LIST"
      class="block w-full mt-10" />

    <div
      *ngIf="
        (buyedPackage &&
          !isLoading &&
          (buyedPackage.conversations?.length === 0 ||
            this.messages.length === 0)) ||
        readOnly
      "
      class="w-full flex items-center justify-center flex-col gap-2">
      <app-no-content
        imgPath="assets/gif/messages-animate.svg"
        [message]="
          (buyedPackage.conversations.length === 0
            ? 'common.no-chat-found'
            : buyedPackage.conversations.length > 0 &&
              this.messages.length === 0
            ? 'common.conversation-open-successfully'
            : readOnly
            ? 'common.conversation-open-successfully'
            : ''
          ) | translate
        "
        textWidth="text-base"
        iconWidth="w-52"
        customHeight="h-fit"
        class="block w-full sm:w-9/12 my-4 mx-auto" />
      <button
        *ngIf="buyedPackage.conversations.length === 0"
        mat-raised-button
        color="primary"
        type="button"
        class="w-full sm:w-fit uppercase h-12"
        (click)="createConversation()">
        <span *ngIf="!isCreatingConversation">{{
          'common.create-conversation' | translate
        }}</span>
        <mat-icon *ngIf="!isCreatingConversation" class="ml-1"
          >arrow_forward</mat-icon
        >
        <app-spinner
          *ngIf="isCreatingConversation"
          class="inline-block w-6 h-6 filter-white"></app-spinner>
      </button>
      <button
        *ngIf="buyedPackage.conversations.length > 0 && readOnly"
        mat-raised-button
        color="primary"
        type="button"
        class="w-full sm:w-fit uppercase h-12"
        (click)="openConverstionPanel()">
        <span>{{ 'common.open-channel-label' | translate }}</span>
        <mat-icon class="ml-1">arrow_forward</mat-icon>
        <div
          *ngIf="buyedPackage?.new_message"
          [matTooltip]="'common.new-message-label' | translate"
          matTooltipClass="custom-material-tooltip"
          (click)="openConversationPanel()"
          class="absolute -top-2 -right-2 !overflow-visible {{
            isOpenConversation ? 'pointer-events-nones' : ''
          }}">
          <div
            class="animate-ping rounded-full inset-0 w-6 h-6 absolute bg-kimbo-ruby bg-opacity-10"></div>
          <button
            class="w-6 h-6 bg-red-pink bg-opacity-90 text-sm rounded-full">
            <i
              *ngIf="!isOpenConversation"
              class="ri-chat-unread-line text-white"></i>
            <app-spinner
              *ngIf="isOpenConversation"
              class="inline-block w-4 h-4 filter-white"></app-spinner>
          </button>
        </div>
      </button>
    </div>
    <div
      *ngIf="
        !readOnly && !isLoading && this.messages.length > 0 && groupedDatas
      ">
      <div *ngFor="let group of getGroupedKeys()">
        <h2
          *ngIf="group.length > 0"
          class="w-full my-6 pb-[17px] flex items-center justify-center text-navy font-medium text-[12px]">
          {{ group }}
        </h2>
        <div
          *ngFor="let conversation of groupedDatas[group]; trackBy: identify"
          class="grid grid-cols-12 gap-y-2">
          <div
            *ngIf="conversation.group_id !== this.currentRole.group.id"
            class="col-start-1 col-end-12 p-3 rounded-lg mb-4 min-w-36">
            <div class="flex flex-row items-center">
              <div
                class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                <app-avatar
                  class="w-10 h-10 flex-shrink cursor-pointer"
                  classNames="w-10 h-10"
                  [withFrame]="false"
                  [src]="conversation.group.image_profil! | imageToUrl">
                </app-avatar>
              </div>
              <div
                class="relative ml-3 text-sm bg-white shadow px-4 pt-4 pb-8 rounded-b-xl rounded-tr-xl min-w-28">
                <div>
                  {{ conversation.content.languages | textLanguages }}
                </div>
                <div class="absolute text-xs bottom-1 right-4 text-gray-500">
                  {{
                    conversation.created_at | dateTimeToTime : { toTime: true }
                  }}
                </div>
                <div
                  class="absolute text-xs bottom-0 left-0 -mb-5 mr-2 text-gray-500 text-start w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">
                  {{
                    (conversation.user.data?.firstName ?? '') +
                      ' ' +
                      (conversation.user.data?.lastName ?? ' ')
                  }}
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="conversation.group_id === this.currentRole.group.id"
            class="col-start-3 col-end-13 p-3 rounded-lg mb-4 min-w-36">
            <div class="flex items-center justify-start flex-row-reverse">
              <div
                class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                <app-avatar
                  class="w-10 h-10 flex-shrink cursor-pointer"
                  classNames="w-10 h-10"
                  [withFrame]="false"
                  [src]="conversation.group.image_profil! | imageToUrl">
                </app-avatar>
              </div>
              <div
                class="relative mr-3 text-sm text-white bg-kimbo-blue px-4 pt-4 pb-8 shadow rounded-b-xl rounded-tl-xl min-w-28">
                <div>
                  {{ conversation.content.languages | textLanguages }}
                </div>
                <div class="absolute text-xs bottom-1 left-4 text-white">
                  {{
                    conversation.created_at | dateTimeToTime : { toTime: true }
                  }}
                </div>
                <div
                  class="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500 text-end w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">
                  {{
                    (conversation.user.data?.firstName ?? '') +
                      ' ' +
                      (conversation.user.data?.lastName ?? ' ')
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="!readOnly && buyedPackage && buyedPackage.conversations.length > 0"
  class="flex flex-row items-center h-fit rounded-t-xl glass-morphism w-full px-4 py-4 absolute z-dropdown bottom-0 right-0 left-0 {{
    readOnly && 'cursor-pointer'
  }}">
  <app-audio-recorder
    (triggerEvent)="handleSpeechTotextEvent($event)"
    [streamAudioWhithDeepgram]="true"></app-audio-recorder>

  <div class="flex-grow ml-4">
    <div class="relative w-full">
      <textarea
        rows="2"
        [(ngModel)]="message.text"
        class="flex w-full border p-2 bg-light-gray rounded-xl outline-none focus:ring-4 focus:ring-kimbo-blue focus:ring-opacity-20 border-light-gray focus:border-kimbo-blue pl-4"></textarea>
    </div>
  </div>
  <div class="ml-4">
    <button
      [disabled]="message.text.trim().length === 0 || isSendingMessage"
      type="submit"
      (click)="sendMessage()"
      class="flex items-center gap-2 justify-center bg-kimbo-blue disabled:bg-light-gray bg-opacity-90 hover:bg-opacity-100 w-12 sm:w-fit h-12 rounded-full sm:rounded-xl focus:ring-4 focus:ring-kimbo-blue outline-none focus:ring-opacity-20 text-white px-4 py-1 flex-shrink-0">
      <span *ngIf="!isSendingMessage" class="hidden sm:inline-block">{{
        'common.send-label' | translate
      }}</span>
      <i
        *ngIf="!isSendingMessage"
        class="ri-send-plane-2-line text-xl sm:text-lg"></i>
      <app-spinner
        *ngIf="isSendingMessage"
        class="inline-block w-6 h-6 filter-blue"></app-spinner>
    </button>
  </div>
</div>
