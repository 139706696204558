import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {
  allReadyLoaded: string[] = [];
  constructor() {}

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (
      route.data &&
      !this.allReadyLoaded.includes(route.data['title']) &&
      route.data['preload'] === true
    ) {
      this.allReadyLoaded.push(route.data['title']);
      return fn();
    }
    return of(false);
  }
}
