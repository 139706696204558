import {
  ChangeDetectorRef,
  inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Language } from '../../models/Common';
import { ServicesService } from '../../services/services.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'textLanguages',
})
export class TextLanguagesPipe implements PipeTransform, OnDestroy {
  $languageChange!: Subscription;
  services = inject(ServicesService);
  constructor(private changeDetectorRef?: ChangeDetectorRef) {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.changeDetectorRef?.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {
    this.$languageChange && this.$languageChange.unsubscribe();
  }

  transform(value: Language[], ...args: any[]): string {
    return this.services.languagesService.getTextOnLanguages(value);
  }
}
