import {
  Component,
  ElementRef,
  Renderer2,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from 'src/app/core/services/navservice';
import { environment } from 'src/environments/environment';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { BreakpointState } from '@angular/cdk/layout';
import { AdminRoleService } from 'src/app/core/services/admin-role.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy, OnInit {
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  // Addding sticky-pin
  scrolled = false;
  screenWidth: number;
  eventTriggered = false;
  public localdata = localStorage;

  public windowSubscribe$!: Subscription;
  options = { autoHide: false, scrollbarMinSize: 100 };
  icon!: SafeHtml;

  public menuItems!: Menu[];
  public menuitemsSubscribe$!: Subscription;
  public navServices!: NavService;
  badge = false;
  enviromentname = '';
  appVersion = '';
  breackpointMatche = false;

  constructor(
    private sanitizer: DomSanitizer,
    public router: Router,
    public renderer: Renderer2,
    private elementRef: ElementRef,
    public adminrole: AdminRoleService,
    public breakpointMatcherService: BreakpointMatcherService
  ) {
    this.badge = environment.badge;
    this.enviromentname = environment.name;
    this.appVersion = environment.appVersion;
    this.screenWidth = window.innerWidth;
    this.breakpointMatcherService
      .initObserver('lg')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
        } else {
          this.breackpointMatche = false;
        }
      });
    this.navServices = new NavService();
  }

  ngOnDestroy(): void {
    this.menuitemsSubscribe$.unsubscribe();
  }

  ngOnInit() {
    this.menuitemsSubscribe$ = this.navServices.items.subscribe(
      (items: Menu[]) => {
        this.menuItems = items;
      }
    );
    this.parentActive();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.parentActive();
      }
    });
  }

  //Active Nav State
  setNavActive(item: any) {
    if (item.badgeValue && item.badgeValue === 'coming-soon') {
      return;
    }
    this.menuItems?.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
        menuItem.selected = true;
      }
      if (menuItem.children) {
        menuItem.children?.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            menuItem.selected = true;
            submenuItems.selected = true;
          }
          if (submenuItems.children) {
            submenuItems.children?.forEach(subsubmenuItems => {
              if (
                subsubmenuItems.children &&
                subsubmenuItems.children.includes(item)
              ) {
                menuItem.active = true;
                submenuItems.active = true;
                subsubmenuItems.active = true;
                menuItem.selected = true;
                submenuItems.selected = true;
                subsubmenuItems.selected = true;
              }
            });
          }
        });
      }
    });
    if (!this.breackpointMatche) this.closeSideBar();
  }

  // Toggle menu
  toggleNavActive(item: any) {
    if (!item.active) {
      this.menuItems?.forEach((a: any) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        a?.children?.forEach((b: any) => {
          if (a.children.includes(item)) {
            b.active = false;
          } else {
            b.active = false;
          }
          b?.children?.forEach((c: any) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
  }
  // Close Nav menu
  closeNavActive() {
    this.menuItems?.forEach((a: any) => {
      if (this.menuItems) {
        a.active = false;
      }
      a?.children?.forEach((b: any) => {
        if (a.children) {
          b.active = false;
        }
      });
    });
  }

  parentActive() {
    this.menuItems.map((element: any) => {
      if (element.children) {
        element.active = false;
        element.selected = false;
        element.children.map((ele: any) => {
          if (ele.path == this.router.url) {
            element.active = true;
            element.selected = true;
          }
          if (ele.children) {
            ele.active = false;
            ele.children.map((child1: any) => {
              if (child1.path == this.router.url) {
                element.active = true;
                element.selected = true;
                ele.active = true;
                ele.selected = true;
              }
            });
          }
        });
      }
    });
  }

  closeSideBar(): void {
    this.triggerEvent.emit('closed');
  }
}
