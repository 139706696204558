import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { NotificationService } from './notification.service';
import { IContactInfo, IContactProperty } from '../models/ContactPicker';

@Injectable({
  providedIn: 'root',
})
export class ContactPickerService {
  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  public isContactPickerSupported(): boolean {
    return 'contacts' in navigator && 'ContactsManager' in window;
  }

  public openContactsSelectModal(
    props: IContactProperty[] = ['name', 'email', 'tel', 'address', 'icon']
  ): Observable<IContactInfo[]> {
    if (this.isContactPickerSupported()) {
      return new Observable(observer => {
        (navigator as any).contacts
          .select(props)
          .then((contacts: IContactInfo[]) => {
            observer.next(contacts);
            observer.complete();
          })
          .catch((error: any) => {
            observer.error(error);
            this.notificationService.danger(
              this.translateService.instant('errors.contact-picker-error')
            );
          });
      });
    } else {
      return of([]);
    }
  }

  public getContactProperties(): Observable<IContactProperty[]> {
    if (this.isContactPickerSupported()) {
      return new Observable(observer => {
        (navigator as any).contacts
          .getProperties()
          .then((props: IContactProperty[]) => {
            observer.next(props);
            observer.complete();
          })
          .catch((error: any) => {
            observer.error(error);
            this.notificationService.danger(
              this.translateService.instant('errors.contact-picker-error')
            );
          });
      });
    } else {
      return of([]);
    }
  }

  public extractFirstAndLastName(contactInfo: IContactInfo): {
    firstName?: string;
    lastName?: string;
  } {
    const names = contactInfo.name || [];

    const firstName = names[0];
    const lastName = names.length > 1 ? names[1] : undefined;

    return { firstName, lastName };
  }

  public extractLastNameFromFirstName = (
    name: string
  ): {
    firstName?: string;
    lastName?: string;
  } => {
    const names = name.split(' ');

    let firstName = names[0];
    let lastName = '';

    if (names.length >= 1) {
      lastName = names[1];
    }

    return { firstName, lastName };
  };

  extractPhoneNumber(contactInfo: IContactInfo): string {
    const phones = contactInfo.tel || [];
    return phones.length > 0 ? phones[0] : '';
  }
}
