<div
  *ngIf="!withFrame"
  (click)="previewImage()"
  [style]="imageStyle"
  class="lazyload rounded-full {{
    withPreview ? 'cursor-pointer' : ''
  }} align-middle bg-white flex-shrink-0 {{ classNames }}"></div>
<div
  *ngIf="withFrame"
  class=" {{
    caller && (caller === '_PROFILE_INNER' || caller === 'CAMPAIGN_ASSIGN')
      ? 'half-circle-white  shadow-backdrop'
      : 'half-circle'
  }} {{ classNames }} rounded-full relative cursor-pointer ">
  <div
    [style]="imageStyle"
    class="h-full w-full lazyload rounded-full align-middle bg-white flex-shrink-0 handle-click"
    (click)="previewImage()"></div>
  <app-file-input
    *ngIf="withEditSpeedDial"
    class="absolute  {{
      caller === 'CAMPAIGN_ASSIGN' ? '-right-1' : '-right-1 md:right-2'
    }} bottom-2 group z-base"
    accept=".jpg,.jpeg,.png"
    name="image"
    (changeEvent)="handleSpeedDialAction($event)"
    [kind]="'_SPEED_DIAL'">
    {{ 'kimbo-profile.image-button' | translate }}
  </app-file-input>
</div>
