import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Content,
  Group,
  IPublicMember,
  IRole,
  Invitation,
} from '../../models/models';

@Injectable()
export class UseCaseRoleService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Store
   * @param body
   */
  public invite(body: any): Observable<Invitation> {
    return this.http.post<Invitation>(
      `${this.basePath}/v1/usecase-role/verification`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * remove
   * @param body
   * @param groupId the group identifier to which the contact belongs
   */
  public removeInvitation(group_id: number, body: any): Observable<Group> {
    return this.http.post<Group>(
      `${this.basePath}/v1/usecase-role/remove_invitation/${group_id}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * list invitations
   * @param groupId the group identifier to which the contact belongs
   */
  public listInvitations(group_id: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/usecase-role/invitations/${group_id}`,
      this.httpOptions
    );
  }

  /**
   * list invitations
   * @param groupId the group identifier to which the contact belongs
   */
  public listRoles(
    page: number,
    search?: string,
    per_page?: number
  ): Observable<Content<IRole>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(search != null && { search: search }),
        ...(per_page != null && { per_page: per_page }),
      },
    });

    return this.http.get<Content<IRole>>(
      `${this.basePath}/v1/usecase-role/list-role`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * remove
   * @param uuid
   */
  public addPublicMember(uuid: string): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/usecase-role/store-member`,
      JSON.stringify({ key: uuid }),
      this.httpOptions
    );
  }

  /**
   * remove
   * @param uuid
   */
  public acceptInvitation(user_id: number, group_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/usecase-role/accept-member/${user_id}/${group_id}`,
      {},
      this.httpOptions
    );
  }

  /**
   * remove
   * @param uuid
   */
  public rejectInvitation(user_id: number, group_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/usecase-role/reject-member/${user_id}/${group_id}`,
      {},
      this.httpOptions
    );
  }

  /**
   * list invitations
   * @param groupId the group identifier to which the contact belongs
   */
  public listWaitingMember(group_id: number): Observable<IPublicMember[]> {
    const queryParameters = new HttpParams({
      fromObject: {},
    });

    return this.http.get<IPublicMember[]>(
      `${this.basePath}/v1/usecase-role/list-waiting-members/${group_id}`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
