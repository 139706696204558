import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content } from '../../models/Common';
import {
  IMetricQuestions,
  MetricQuestions,
} from '../../models/MetricQuestions';

@Injectable()
export class UseCaseHealthPackageService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public getQuestionsMetric(metric: string): Observable<MetricQuestions> {
    return this.http.get<MetricQuestions>(
      `${this.basePath}/v1/metrics/${metric}`
    );
  }
  /**
   * my_treatments
   * @param page the page number
   * @param order the datas order (ASC || DESC)
   */
  public addQuestionsMetric(
    body: MetricQuestions
  ): Observable<Content<MetricQuestions>> {
    return this.http.post<any>(
      `${this.basePath}/v1/metrics`,
      body,
      this.httpOptionsWithFromData
    );
  }

  public updateQuestionMetric(
    body: MetricQuestions,
    id: number
  ): Observable<Content<MetricQuestions>> {
    return this.http.put<any>(
      `${this.basePath}/v1/metrics/${id}`,
      body,
      this.httpOptionsWithFromData
    );
  }

  public updateVisibilitiesQuestionMetric(
    body: MetricQuestions,
    id: number
  ): Observable<Content<MetricQuestions>> {
    return this.http.put<any>(
      `${this.basePath}/v1/metrics/${id}/visibility`,
      body,
      this.httpOptionsWithFromData
    );
  }

  public deleteQuestionMetric(id: number): Observable<Content<any>> {
    return this.http.delete<any>(
      `${this.basePath}/v1/metrics/${id}`,
      this.httpOptionsWithFromData
    );
  }

  public addAnswer(body: any): Observable<Content<any>> {
    return this.http.post<any>(
      `${this.basePath}/v1/metrics/answer`,
      body,
      this.httpOptionsWithFromData
    );
  }

  /*
   * count view module
   * @param body
   */
  public count_view_module(
    id: number,
    type: 'by_hcp' | 'other' = 'other'
  ): Observable<any> {
    return this.http.post<any>(`${this.basePath}/v1/count_view_module`, {
      id: id,
      type: type,
    });
  }
}
