import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class KimboMatIconRegistry {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Register the custom SVG icon
    this.matIconRegistry.addSvgIcon(
      'info-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/info_sec.svg'
      )
    );
  }
}
