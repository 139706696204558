import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-online',
  templateUrl: './support-online.component.html',
  styleUrls: ['./support-online.component.scss'],
})
export class SupportOnlineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
