import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationLayoutComponent } from './pages/containers/application-layout/application-layout.component';
import { CorporateLayoutComponent } from './pages/containers/corporate-layout/corporate-layout.component';
import { PreAuthLayoutComponent } from './pages/containers/preauth-layout/pre-auth-layout.component';
import { CustomPreloadingStrategyService } from './core/services/custom-preloading-strategy.service';
import {
  adminGuard,
  authGuard,
  benefitGuard,
  compagnieGuard,
  guestGuard,
  hcpGuard,
  kimboGuard,
  loginGuard,
} from './core/guards/guards';
import { SupportOnlineComponent } from './pages/shared-pages/support-online/support-online.component';
import { AdminLayoutComponent } from './pages/admin-container/admin-layout/admin-layout.component';
import { BenefitLayoutComponent } from './pages/containers/benefit-container/benefit-layout/benefit-layout.component';

const routes: Routes = [
  //app routes goes here
  {
    path: '',
    component: PreAuthLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'pre-auth-portal',
        pathMatch: 'full',
      },
      {
        path: 'pre-auth-portal',
        loadChildren: () =>
          import('./pages/user-auth-portal/user-auth-portal.module').then(
            m => m.UserAuthPortalModule
          ),
        canActivate: [loginGuard()],
      },
    ],
  },
  //app routes goes here
  {
    path: '',
    component: ApplicationLayoutComponent,
    children: [
      {
        path: 'kimbo',
        loadChildren: () =>
          import(
            './pages/shared-pages/healthcare-providers/healthcare-providers.module'
          ).then(m => m.HealcareProvidersModule),
        canActivate: [authGuard(), kimboGuard()],
      },
      {
        path: 'hcp',
        loadChildren: () =>
          import('./pages/hcp/hcp.module').then(m => m.HcpModule),
        canActivate: [authGuard(), hcpGuard()],
      },
      {
        path: 'support',
        component: SupportOnlineComponent,
      },
    ],
  },

  //Benefit routes goes here
  {
    path: '',
    component: BenefitLayoutComponent,
    children: [
      {
        path: 'benefit',
        loadChildren: () =>
          import('./pages/benefit/benefit.module').then(m => m.BenefitModule),
        canActivate: [authGuard(), benefitGuard()],
      },
      {
        path: 'support',
        component: SupportOnlineComponent,
      },
    ],
  },

  //Admin routes goes here
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () =>
          import('./pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [authGuard(), adminGuard()],
      },
    ],
  },

  // corporate routes goes here here
  {
    path: '',
    component: CorporateLayoutComponent,
    children: [
      {
        path: 'corporate',
        loadChildren: () =>
          import('./pages/corporate/corporate.module').then(
            m => m.CorporateModule
          ),
        canActivate: [authGuard(), compagnieGuard()],
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/shared-pages/login/login-page.module').then(
        m => m.LoginPageModule
      ),
    canActivate: [guestGuard()],
  },
  {
    path: 'hcp-registration',
    loadChildren: () =>
      import('./pages/hcp/hcp-registration/hcp-registration.module').then(
        m => m.HcpRegistrationModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
