export interface Note {
  user_id: number;
  buyedpackage_id: number;
  type: string;
  text: string;
  updated_at: string;
  created_at: string;
  id: number;
}

export class NoteStore {
  type: string;
  text: string;

  constructor(type: string, text: string) {
    this.type = type;
    this.text = text;
  }
}
