import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { useDiscount } from 'src/app/core/models/Discount';
import { IRole, IUser } from 'src/app/core/models/User';
import { UserService } from 'src/app/core/rest-services/rest-services';
import { UseCaseDiscountService } from 'src/app/core/rest-services/usecases/usecase-discount.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { isEmptyString } from 'src/app/core/utils/core';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
})
export class DiscountComponent implements OnInit {
  input_cls = INPUT_CLASSES;
  isWrongCode = true;
  isLoading = false;
  currentRole!: IRole;
  code!: string;
  @Input() isModal = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {},
    public dialogRef: MatDialogRef<DiscountComponent>,
    private usecaseDiscountService: UseCaseDiscountService,
    private notificationService: NotificationService,
    private translanteService: TranslateService,
    private services: ServicesService,
    private userService: UserService
  ) {
    this.currentRole = this.services.sessionService.getCurrentRole();
  }

  ngOnInit(): void {}

  handleEnteredCode(event: any): void {
    let _code = event.target.value;
    if (isEmptyString(_code)) {
      this.isWrongCode = true;
    } else {
      this.code = _code;
      this.isWrongCode = false;
    }
  }

  validateCode() {
    this.isLoading = true;
    this.usecaseDiscountService
      .UseDiscount(new useDiscount(this.currentRole.group.id, this.code!))
      .subscribe({
        next: (res: string[]) => {
          this.getUser();
        },
        error: error => {
          let message =
            error.translate === 'errors.validation' ||
            error.translate === 'errors.code-not-found'
              ? 'errors.discount-code-error'
              : error.translate;
          this.notificationService.danger(
            this.translanteService.instant(message)
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = true;
        },
      });
  }

  getUser(): void {
    let user = this.services.sessionService.getUser();
    this.isLoading = true;
    this.userService.view(user.id).subscribe({
      next: (userRes: IUser) => {
        this.services.sessionService.deleteUser();
        this.services.sessionService.storeUser(userRes);
        let role: IRole = userRes.roles?.find(
          (role: IRole) => role.id === this.currentRole.id
        )!;
        this.services.sessionService.deleteCurrentRole();
        this.currentRole = role;
        this.services.sessionService.storeCurrentRole(role);
        this.dialogRef.close();
        this.notificationService.success(
          this.translanteService.instant('common.discount-code-success')
        );
        this.services.eventService.publish('healthcredit:used');
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
