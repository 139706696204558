import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-material-confirm-dialog',
  templateUrl: './material-confirm-dialog.component.html',
  styleUrls: ['./material-confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      title?: string;
      withAction: boolean;
      actionLabel: string;
    },
    public dialogRef: MatDialogRef<MaterialConfirmDialogComponent>
  ) {}

  handleChoise(choise: string): void {
    this.dialogRef.close(choise);
  }
}
