<div *ngIf="!isLoading" class="break-words w-full pb-6">
  <h1
    *ngIf="!userId && !isLoading"
    class="text-base sm:text-xl font-bold text-navy">
    {{ 'admin-users.create-user-page-heading' | translate }}
  </h1>
  <h1
    *ngIf="
      !isLoading &&
      userId &&
      user.properties?.email &&
      user.properties!.email.length > 0
    "
    class="text-base sm:text-xl font-bold text-navy">
    {{
      'admin-edit-user.page-heading'
        | translate : { name: user.properties.email }
    }}
  </h1>

  <h1
    *ngIf="
      !isLoading &&
      userId &&
      !(user.properties?.email && user.properties!.email.length > 0)
    "
    class="text-base sm:text-xl font-bold text-navy">
    {{
      'admin-edit-user.page-heading' | translate : { name: user.properties.tel }
    }}
  </h1>
  <button
    class="close-button"
    color="primary"
    mat-dialog-close
    mat-button
    (click)="closeModal()"
    aria-label="close icon">
    <i class="ri-close-large-line" style="font-size: 1.3rem !important"></i>
  </button>
</div>

<mat-dialog-content *ngIf="!isLoading" class="relative mt-2">
  <div *ngIf="!userId">
    <form class="mt-6 w-full">
      <app-form-field
        *ngIf="!isPhoneNumber"
        [label]="'forms.phone-label' | translate"
        [error]="fromError">
        <input
          (input)="setIdentifier($event)"
          [(ngModel)]="identifier"
          [ngModelOptions]="{ standalone: true }"
          class="
            {{ input_cls.common }}
            {{ invalid ? input_cls.invalid : '' }}
            {{ disabled ? input_cls.disabled : '' }}"
          type="text"
          name="identifier"
          id="tel"
          [placeholder]="'forms.phone-number-placeholder' | translate" />
      </app-form-field>

      <div *ngIf="isPhoneNumber" class="relative w-full mt-6">
        <button
          mat-button
          color="primary"
          style="position: absolute !important"
          class="-top-[8px] right-0 z-base"
          type="button"
          (click)="resetLoginForm()">
          <span class="inline-block p-1">{{
            'common.reset-label' | translate
          }}</span>
        </button>
        <app-phone-number-dropdown
          class="w-full"
          tabindex="1"
          (triggerEvent)="_handlePhoneEvent($event)"
          [options]="countryListToOptionObject()"
          [tel]="_identifier"
          [withWhatapp]="false"
          [placeholder]="'forms.phone-placeholder' | translate"
          [name]="'kimbo-create-contact.phone-label' | translate"
          caller="_PROFIL"></app-phone-number-dropdown>
      </div>
      <div class="flex justify-between w-full mt-6">
        <button
          class="h-12 w-32 !hidden sm:!block"
          mat-stroked-button
          color="primary"
          [disabled]="isTransitioning"
          type="button"
          (click)="closeModal()">
          <span>{{ 'forms.cancel-button' | translate }}</span>
        </button>
        <button
          class="h-12 w-full md:w-32 ml-4"
          mat-raised-button
          color="primary"
          [disabled]="
            isTransitioning || (form && !form.value.email && tel.length === 0)
          "
          type="submit"
          (click)="storeUser()">
          <svg
            *ngIf="isTransitioning"
            aria-hidden="true"
            class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span *ngIf="!isTransitioning">{{
            'forms.save-button' | translate
          }}</span>
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="user">
    <form
      class="flex flex-wrap items-start mt-8"
      [formGroup]="form"
      (ngSubmit)="handleSubmit()">
      <app-form-field
        [label]="'forms.first-name-label' | translate"
        [optional]="true"
        class="w-full md:w-1/3-with-gap">
        <input
          class="
    {{ input_cls.common }}
  {{ disabled ? input_cls.disabled : '' }}"
          type="text"
          formControlName="firstName"
          id="firstName" />
      </app-form-field>
      <app-form-field
        [label]="'forms.last-name-label' | translate"
        [optional]="true"
        class="w-full mt-6 md:mt-0 md:ml-4 md:w-1/3-with-gap">
        <input
          class="
    {{ input_cls.common }}
  {{ disabled ? input_cls.disabled : '' }}"
          type="text"
          formControlName="lastName"
          id="lastName" />
      </app-form-field>
      <app-form-field
        [label]="'forms.date-of-birth-label' | translate"
        [optional]="true"
        class="w-full mt-6 md:mt-0 md:ml-4 md:w-1/3-with-gap">
        <input
          class="
    {{ input_cls.common }}
  {{ disabled ? input_cls.disabled : '' }}"
          type="date"
          formControlName="dateOfBirth"
          id="dateOfBirth" />
      </app-form-field>

      <app-form-field
        [label]="'Email'"
        class="w-full mt-6 md:w-1/2-with-gap"
        [error]="
          !isValidAddressEmail(form.value.email)
            ? ('errors.invalid-email' | translate)
            : ''
        ">
        <input
          class="
    {{ input_cls.common }}
  {{ disabled ? input_cls.disabled : '' }}"
          type="text"
          formControlName="email"
          id="email" />
      </app-form-field>
      <app-phone-number-dropdown
        *ngIf="alreadyFetchUser"
        class="w-full md:w-1/2-with-gap mt-6 md:ml-4"
        (triggerEvent)="handlePhoneEvent($event)"
        [hasWhatapp]="hasWhatapp"
        [tel]="tel"
        [isValid]="validEmailOrPhone"
        [options]="countryListToOptionObject()"
        [placeholder]="'forms.phone-number-placeholder' | translate"
        [name]="
          'kimbo-create-contact.phone-label' | translate
        "></app-phone-number-dropdown>

      <app-input-dropdown
        class="w-1/2-with-gap mt-6"
        (triggerEvent)="handleGenderDropdownEvent($event)"
        [options]="genderOptions"
        [pickValue]="selectedGender"
        [name]="
          'kimbo-create-contact.gender-label' | translate
        "></app-input-dropdown>
      <app-input-dropdown
        class="w-1/2-with-gap mt-6 ml-4"
        (triggerEvent)="handleCurrencyDropdownEvent($event)"
        [options]="currencyOptions"
        [pickValue]="selectedCurrencie"
        [name]="'admin-fields.currency-label' | translate"></app-input-dropdown>

      <div class="flex justify-between w-full mt-6">
        <button
          class="h-12 w-full md:w-32"
          mat-raised-button
          color="primary"
          [disabled]="isTransitioning || !checkSubmitButtonState()"
          type="submit">
          <svg
            *ngIf="isTransitioning"
            aria-hidden="true"
            class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span *ngIf="!isTransitioning">{{
            'forms.save-button' | translate
          }}</span>
        </button>
      </div>
    </form>
    <div
      class="flex justify-end pt-6 mt-6 border-solid border-t-1 border-gray"></div>
    <div
      class="mt-6 flex flex-col items-center justify-center sm:flex-row sm:justify-between gap-4">
      <h2 class="text-base sm:text-xl font-medium text-navy">
        {{ 'admin-fields.create-group-heading' | translate }} /
        {{ 'admin-edit-user.update-user-group' | translate }}
      </h2>
      <div class="w-full sm:!w-1/4">
        <button
          class="w-full h-12 uppercase"
          mat-stroked-button
          type="button"
          color="primary"
          [disabled]="isDeleting || isGroupEditing"
          (click)="createGroup()">
          {{ 'admin-fields.create-group-heading' | translate }}
        </button>
      </div>
    </div>

    <div class="mt-6 flex flex-col sm:flex-row sm:justify-between gap-2">
      <app-search-dropdown
        class="w-full sm:w-1/2"
        (triggerEvent)="handleSearchEvent($event)"
        [searchPlaceholder]="'admin-fields.group-name-placeholder' | translate"
        [searchLabel]="'admin-fields.group-name-label' | translate"
        [options]="groupOptions"
        [isFinding]="isFinding"></app-search-dropdown>
      <div class="w-full sm:w-1/2 flex justify-end gap-2 items-end">
        <button
          *ngIf="
            groupSelected &&
            !isCreatingMember &&
            !isInsideTheGroup(groupSelected)
          "
          class="h-12 ml-4 uppercase"
          mat-raised-button
          type="button"
          color="primary"
          [disabled]="isDeleting || isGroupEditing"
          (click)="toggleCreateOverlayVisibility()">
          {{ 'admin-edit-user.add-user-to-group-label' | translate }}
        </button>
      </div>
    </div>
    <h2 class="mb-4 mt-4 md:mt-6 text-base sm:text-xl font-medium text-navy">
      {{ 'admin-edit-user.user-group-list-label' | translate }}
    </h2>
    <div>
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div
            class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
            <table
              class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 {{
                isTransitioning ? 'opacity-50 pointer-events-none' : ''
              }}">
              <thead class="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <div class="flex items-center gap-x-3">
                      <span>
                        {{ 'admin-fields.group-name-label' | translate }}
                      </span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    Permission(s)
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500 dark:text-gray-400">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="!isTransitioning"
                class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                <tr *ngFor="let item of paginatedData">
                  <td
                    class="px-4 flex flex-col md:flex-row py-4 text-left text-sm font-medium text-gray-500 whitespace-nowrap">
                    <img
                      appImg
                      class="mr-2"
                      *ngIf="getRoleName(item) === groupType.Kimbo"
                      width="20"
                      height="20"
                      src="assets/images/group.png"
                      alt="group" />
                    <img
                      appImg
                      class="mr-2"
                      *ngIf="getRoleName(item) === groupType.Benefit"
                      width="20"
                      height="20"
                      src="assets/images/Insurance.png"
                      alt="group" />
                    <img
                      appImg
                      class="mr-2"
                      *ngIf="getRoleName(item) === groupType.Companie"
                      width="20"
                      height="20"
                      src="assets/images/company.png"
                      alt="group" />
                    <img
                      appImg
                      class="mr-2"
                      *ngIf="getRoleName(item) === groupType.Hcp"
                      width="20"
                      height="20"
                      src="assets/images/hospital.png"
                      alt="group" />
                    <img
                      appImg
                      class="mr-2"
                      *ngIf="item.value === groupType.Admin"
                      width="20"
                      height="20"
                      src="assets/images/user-admin.png"
                      alt="group" />
                    {{ item.label }}
                  </td>
                  <td
                    class="p-4 text-sm text-left text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <span
                      *ngFor="let perm of getRolePermission(item)"
                      class="inline-block w-fit px-2 font-bold text-xxs text-center rounded-full mr-1 {{
                        perm === pType.Owner
                          ? 'bg-opacity-10 bg-green text-green'
                          : perm === pType.Member
                          ? 'bg-opacity-10 bg-blue text-blue'
                          : ''
                      }}">
                      {{ 'admin-layout.' + perm | translate }}
                    </span>
                  </td>
                  <td class="p-4 justify-end flex text-sm whitespace-nowrap">
                    <button
                      class="text-xl"
                      *ngIf="item.value !== 'admin'"
                      mat-icon-button
                      (click)="handleGroupActions('_EDIT_GROUP', item)"
                      color="primary"
                      aria-label="action see user"
                      [matTooltip]="
                        'admin-layout.see-action-label' | translate
                      ">
                      <i class="ri-eye-line"></i>
                    </button>
                    <button
                      class="text-xl"
                      *ngIf="item.value !== 'admin'"
                      mat-icon-button
                      color="primary"
                      aria-label="action edit user"
                      (click)="editPermissions(item)"
                      [matTooltip]="
                        'admin-layout.edit-action-label' | translate
                      ">
                      <i class="ri-pencil-line"></i>
                    </button>
                    <button
                      class="text-xl"
                      *ngIf="item.value !== 'admin'"
                      mat-icon-button
                      color="warn"
                      aria-label="action delete"
                      (click)="handleDeleteAction(item)"
                      [matTooltip]="
                        'admin-layout.delete-action-label' | translate
                      ">
                      <i class="ri-delete-bin-2-line"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="paginatedData.length === 0">
                  <td
                    colspan="4"
                    class="p-2 break-words flex-shrink-0 w-full text-center text-gray">
                    {{ 'forms.no-data' | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="isTransitioning"
              role="status"
              class="p-6 flex justify-center items-center w-full">
              <svg
                aria-hidden="true"
                class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-pagination
      *ngIf="!isTransitioning && this.paginationGroup.totalPages > 1"
      (triggerEvent)="setPageGroup($event)"
      shape="_COMPOSED"
      [params]="{
        className: 'my-6',
        pagination: paginationGroup
      }"></app-pagination>

    <h2 class="mb-4 mt-4 md:mt-0 text-base sm:text-xl font-medium text-navy">
      {{ 'admin-fields.treatment-label' | translate }}
    </h2>

    <div>
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div
            class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
            <table
              class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 {{
                onTraitment ? 'opacity-50 pointer-events-none' : ''
              }}">
              <thead class="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    Id
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <span>{{
                      'admin-fields.healthcare-provider-label' | translate
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <span>{{ 'admin-fields.status-label' | translate }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {{ 'admin-fields.created-at-label' | translate }}
                  </th>

                  <th
                    scope="col"
                    class="px-4 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500 dark:text-gray-400">
                    Action(s)
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="!onTraitment"
                class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                <tr *ngFor="let item of buyedPackage">
                  <td
                    class="px-4 flex flex-col md:flex-row py-4 text-left text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ item.id }}
                  </td>
                  <td
                    class="p-4 text-sm text-left text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {{
                      item?.healthcareprovider?.title?.languages!
                        | textLanguages
                    }}
                  </td>
                  <td class="p-4 text-left flex text-sm whitespace-nowrap">
                    <div
                      class="px-3 py-1 text-xs text-pink-500 rounded-full text-center w-fit {{
                        item.status === buyedPackageStatus.Ready
                          ? 'bg-opacity-10 bg-blue text-blue'
                          : item.status === buyedPackageStatus.Finished
                          ? 'bg-opacity-10 bg-green text-green'
                          : 'bg-opacity-10 bg-yellow text-yellow'
                      }}">
                      <span>{{
                        'admin-fields.' + item.status | translate
                      }}</span>
                    </div>
                  </td>
                  <td class="p-4 text-left text-sm whitespace-nowrap">
                    {{ toLocaleDateTime(item.created_at, language) }}
                  </td>
                  <td class="p-4 justify-end flex text-sm whitespace-nowrap">
                    <button
                      class="text-xl"
                      mat-icon-button
                      (click)="handleBuyedPackageEvent(item)"
                      color="primary"
                      aria-label="action see details"
                      [matTooltip]="
                        'admin-layout.see-action-label' | translate
                      ">
                      <i class="ri-eye-line"></i>
                    </button>
                    <button
                      *ngIf="item.healthcareprovider"
                      class="text-xl"
                      mat-icon-button
                      (click)="deleteHCProvider(item.healthcareprovider)"
                      color="warn"
                      aria-label="action delete"
                      [matTooltip]="
                        'admin-layout.delete-action-label' | translate
                      ">
                      <i class="ri-delete-bin-2-line"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="buyedPackage.length === 0">
                  <td
                    colspan="6"
                    class="p-2 break-words flex-shrink-0 w-full text-center text-gray">
                    {{ 'forms.no-data' | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="onTraitment"
              role="status"
              class="p-6 flex justify-center items-center w-full">
              <svg
                aria-hidden="true"
                class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <app-pagination
        *ngIf="!isTransitioning && this.pagination.totalPages > 1"
        (triggerEvent)="setPage($event)"
        shape="_COMPOSED"
        [params]="{ className: 'my-6', pagination }"></app-pagination>

      <!--PERMISSIONS FORM-->

      <div
        *ngIf="isCreateEditModalOpen"
        class="absolute inset-0 z-modal-backdrop flex items-center justify-center">
        <div
          class="w-full md:w-5/12 h-auto bg-white rounded-lg p-8 relative shadow-map-africa-box">
          <button
            class="close-button right-2 top-2"
            color="primary"
            mat-button
            (click)="closeEditRoleModal()">
            <mat-icon>close</mat-icon>
          </button>
          <form
            class="flex flex-wrap items-start mt-6"
            [formGroup]="formCreateRole">
            <label class="mb-2 pr-0 text-navy" htmlFor="{htmlFor}">
              {{ 'kimbo-layout.role-label' | translate }}
            </label>
            <ul
              class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex">
              <li
                class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                <div class="flex items-center pl-3 cursor-pointer">
                  <input
                    id="vue-checkbox-list"
                    type="radio"
                    formControlName="permission"
                    value="member"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2" />
                  <label
                    for="vue-checkbox-list"
                    class="py-3 ml-2 w-full text-sm font-medium text-gray-900 cursor-pointer"
                    >{{ 'kimbo-layout.member-label' | translate }}</label
                  >
                </div>
              </li>
              <li class="w-full">
                <div class="flex items-center pl-3">
                  <input
                    id="laravel-checkbox-list"
                    type="radio"
                    formControlName="permission"
                    value="owner"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2" />
                  <label
                    for="laravel-checkbox-list"
                    class="py-3 ml-2 w-full text-sm font-medium text-gray-900"
                    >{{ 'kimbo-layout.manager-label' | translate }}</label
                  >
                </div>
              </li>
            </ul>
            <div
              class="w-full flex-col sm:items-end flex justify-center flex-shrink-0 py-6">
              <div class="flex items-center justify-between w-full mt-6">
                <button
                  class="h-12"
                  mat-stroked-button
                  color="primary"
                  type="button"
                  [disabled]="isLoading"
                  (click)="closeEditRoleModal()">
                  {{ 'forms.cancel-button' | translate }}
                </button>
                <button
                  class="h-12 w-full md:w-1/2-with-gap ml-4"
                  mat-raised-button
                  color="primary"
                  [disabled]="isEditing || form.invalid"
                  type="button"
                  (click)="saveMember()">
                  <svg
                    *ngIf="isLoading"
                    aria-hidden="true"
                    class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span *ngIf="!isLoading">{{
                    'forms.save-button' | translate
                  }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="userId && !user"
    class="w-full flex items-center justify-center h-32">
    <svg
      aria-hidden="true"
      class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor" />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill" />
    </svg>
  </div>
</mat-dialog-content>
<div *ngIf="isLoading" class="w-full flex items-center justify-center h-32">
  <svg
    aria-hidden="true"
    class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor" />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill" />
  </svg>
</div>
