<h2 class="text-navy" mat-dialog-title>
  <span>{{ 'hcp-common.more-title' | translate }}</span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="Example icon button with a delete icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class="flex flex-wrap items-center justify-between w-full">
    <div *ngFor="let item of questions" class="w-full md:w-1/2-with-gap">
      <div class="flex items-start w-full mt-4">
        <app-form-field
          *ngIf="item.type !== 'boolean'"
          [label]="item.title.languages | textLanguages"
          class="w-full mt-1 md:mt-0">
          <input
            class="{{ input_cls.common }}"
            type="{{ item.type !== 'integer' ? item.type : 'number' }}"
            [(ngModel)]="item.value" />
        </app-form-field>

        <div *ngIf="item.type === 'boolean'" class="flex items-center">
          <label for="corporate" class="mr-3">
            {{ item.title.languages | textLanguages }}</label
          >
          <label class="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              class="sr-only peer"
              [(ngModel)]="item.value" />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>
    </div>

    <div class="flex justify-end w-full mt-6">
      <button class="h-12" mat-raised-button color="primary" (click)="save()">
        <app-spinner
          *ngIf="isLoading"
          class="inline-block w-6 h-6 filter-white"></app-spinner>
        <span *ngIf="!isLoading">{{ 'forms.save-button' | translate }}</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
