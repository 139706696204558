import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IUser, Language, UserStore } from 'src/app/core/models/models';
import {
  RestLanguageService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { ServicesService } from 'src/app/core/services/services.service';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit {
  @Input() caller: '_OTHER' | '_LOGIN' | 'C_WITH_BG' = '_OTHER';
  constructor(
    public translate: TranslateService,
    public services: ServicesService,
    public router: Router,
    private userService: UserService,
    private restLanguageService: RestLanguageService
  ) {}

  isOpen = false;
  userExpected?: UserStore;
  user!: IUser;
  langId!: number;

  ngOnInit(): void {
    this.user = this.services.sessionService.getUser();
  }

  clickedOutside(): void {
    this.isOpen = false;
  }

  selectedOption(option: OptionObject): void {
    this.translate.use(option.value);
    this.services.sessionService.deleteLanguage();
    this.services.sessionService.storeLanguage(option.value);
    this.services.eventService.publish('change:language');
    this.isOpen = false;
    if (this.caller !== '_LOGIN') {
      if (!window.location.href.includes('hcp-registration')) {
        this.fetchLanguage(option.value);
      }
    }
  }

  updateUser(): void {
    this.userExpected = this.initExpectedUser();
    this.userService.update(this.user.id, this.userExpected).subscribe({
      next: (res: IUser) => {
        this.getUser();
      },
    });
  }

  fetchLanguage(lang: string): void {
    this.restLanguageService.index().subscribe({
      next: (res: Language[]) => {
        const langs: Language = res.find(language => language.code === lang)!;
        this.langId = langs.id;

        this.updateUser();
      },
    });
  }

  initExpectedUser(): UserStore {
    const userStore: UserStore = new UserStore(
      this.user.email ? this.user.email : undefined,
      this.user.tel ? this.user.tel : '',
      this.user.gender ? this.user.gender : '',
      this.user.timezone ? this.user.timezone : '',
      this.services.sessionService.isGuest() && !this.user.data
        ? []
        : this.user.data,
      this.user.currencie_id,
      this.langId,
      this.user.has_whatsapp ? 1 : 0
    );
    return userStore;
  }

  getUser(): void {
    this.userService.view(this.user.id).subscribe({
      next: (userRes: IUser) => {
        this.services.sessionService.deleteUser();
        this.services.sessionService.storeUser(userRes);
      },
    });
  }

  openLangSelect(): void {
    this.isOpen = !this.isOpen;
  }
}
