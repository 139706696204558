import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {} from '../../models/models';
import { Dashboard } from '../../models/Dashboard';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  public dashboard(campaign_id: number): Observable<Dashboard> {
    return this.http.get<Dashboard>(
      `${this.basePath}/v1/campaign/${campaign_id}/dashboard`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
