import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Recommendation,
  RecommendationStore,
  StoreRatingCSAT,
} from '../../models/Buyedpackage';
import { OrcStore } from '../../models/Ocr';

@Injectable()
export class UseCaseBuyedPackageService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * assign_hcp
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public assign_hcp(buyedpackage_id: number, body: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/assign_hcp`,
      body,
      this.httpOptions
    );
  }

  /**
   * assign_hcp
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public mass_assign_hcp(campaignID: number, body: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/campaign/${campaignID}/mass_assign_hcp`,
      body,
      this.httpOptions
    );
  }

  /**
   * assign_patient
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public assign_patient(buyedpackage_id: number, body: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/assign_patient`,
      body,
      this.httpOptions
    );
  }

  /**
   * add_log
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public add_log(buyedpackage_id: number, body: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/add_log`,
      body,
      this.httpOptions
    );
  }

  /**
   * checkout
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public checkout(buyedpackage_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/checkout`,
      this.httpOptions
    );
  }

  /**
   * checkoutV2
   * @param body the extracted ocr model
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public checkoutV2(body: OrcStore, buyedpackage_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/checkout_v2`,
      body,
      this.httpOptions
    );
  }

  public view(buyedpackage_id: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/view`
    );
  }

  /**
   * no_show
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public no_show(buyedpackage_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/no_show`,
      undefined,
      this.httpOptions
    );
  }

  /**
   * add_recommendation
   * @param buyedpackage_id the buyed pacakage identifier
   */
  public add_recommendation(
    buyedpackage_id: number,
    body: RecommendationStore
  ): Observable<Recommendation> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/add_recommendation`,
      body,
      this.httpOptions
    );
  }

  /**
   * add_log
   * @param body the review to store
   */
  public rating_checkout(body: StoreRatingCSAT): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/store-review`,
      body,
      this.httpOptions
    );
  }
}
