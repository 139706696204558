import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Content,
  Healthcarepackage,
  HealthcarepackageStore,
  IHealthcarepackage,
} from '../../models/models';

@Injectable()
export class HealthPackageService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param packageId
   */
  public delete(packageId: number): Observable<Healthcarepackage> {
    return this.http.delete<Healthcarepackage>(
      `${this.basePath}/v1/resources/healthcarepackage/${packageId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: HealthcarepackageStore): Observable<Healthcarepackage> {
    return this.http.post<Healthcarepackage>(
      `${this.basePath}/v1/resources/healthcarepackage`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param packageId
   */
  public update(packageId: number, body?: any): Observable<IHealthcarepackage> {
    return this.http.put<IHealthcarepackage>(
      `${this.basePath}/v1/resources/healthcarepackage/${packageId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all healthcarepackage
   * @param page
   */
  public index(
    page?: number,
    search?: string,
    type?: string
  ): Observable<Content<IHealthcarepackage>> {
    let queryParameters = new HttpParams({
      fromObject: {
        ...(page != null && { page: page }),
        ...(search != null && { search: search }),
        ...(type != null && { type: type }),
      },
    });

    return this.http.get<Content<IHealthcarepackage>>(
      `${this.basePath}/v1/resources/healthcarepackage`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param packageId
   */
  public view(packageId: number): Observable<IHealthcarepackage> {
    return this.http.get<IHealthcarepackage>(
      `${this.basePath}/v1/resources/healthcarepackage/${packageId}`,
      this.httpOptions
    );
  }
}
