import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content } from '../../models/Common';
import { ISession } from '../../models/Session';

@Injectable()
export class UseCaseSessionService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public listSessions(): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${this.basePath}/v1/session`);
  }

  public deleteSessions(id: number): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/v1/session/${id}`);
  }

  public deleteAll(): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/v1/session`);
  }
}
