<div *ngIf="type === '_WITH_FLAG'" class="flex items-center {{ classNames }}">
  <div class="relative w-full">
    <app-form-field
      [label]="name"
      class="w-full md:w-1/3-with-gap"
      classNames="relative"
      [error]="phoneNumberError === true ? error : undefined">
      <div
        (click)="!readonly && selectedOption()"
        class="flex justify-between items-center bg-blue bg-opacity-5 rounded-md w-16 absolute left-2 cursor-pointer {{
          withLabel ? 'top-[2.4rem]' : 'top-[0.4rem]'
        }} "
        style="padding: 6px 4px 6px 4px">
        <span
          class="h-8 w-8 flex-shrink-0 flag ff-round ff-xl flag-{{
            (tel.trim().length > 0 && !pickValue
              ? phoneModel.phoneCode
              : pickValue?.label
            ) | lowercase
          }}"></span>
        <mat-icon color="primary" class="ml-1">keyboard_arrow_down</mat-icon>
      </div>

      <input
        #phoneInput
        (blur)="onBlurphoneNumberInput()"
        id="kimbo_phone_number"
        class="{{ withWhatapp ? 'pr-20' : 'pr-6' }}
        w-full rounded-lg pl-20 bg-[#f4f8fc] placeholder-dark-gray focus:shadow-inner-blue  focus:outline-none h-14"
        type="tel"
        [placeholder]="placeholder"
        [(ngModel)]="tel"
        [readonly]="readonly"
        (input)="handleChanges($event)" />
      <span
        *ngIf="withWhatapp"
        (click)="check()"
        class="h-8 w-8 top-[47px] mr-3 absolute right-6 cursor-pointer {{
          withLabel ? 'top-11' : 'top-4'
        }} flex-shrink-0 whatsapp"></span>
    </app-form-field>
  </div>
</div>
<div *ngIf="type === '_SIMPLE'" class="flex items-center {{ classNames }}">
  <div class="relative w-full">
    <app-form-field
      [label]="name"
      class="w-full md:w-1/3-with-gap"
      classNames="relative"
      [error]="phoneNumberError === true ? error : undefined">
      <div
        *ngIf="isValidPhoneNumber"
        (click)="selectedOption()"
        class="flex justify-between items-center bg-blue bg-opacity-5 rounded-md w-16 absolute left-2 cursor-pointer {{
          withLabel ? 'top-[2.4rem]' : 'top-[0.4rem]'
        }} "
        style="padding: 6px 4px 6px 4px">
        <span
          class="h-8 w-8 flex-shrink-0 flag ff-round ff-lg flag-{{
            (tel.trim().length > 0 && !pickValue
              ? phoneModel.phoneCode
              : pickValue?.label
            ) | lowercase
          }}"></span>
        <mat-icon color="primary" class="ml-1">keyboard_arrow_down</mat-icon>
      </div>

      <input
        #phoneInput
        (blur)="onBlurphoneNumberInput()"
        class="w-full rounded-lg pr-6 {{
          isValidPhoneNumber ? 'pl-20' : 'pl-6'
        }} bg-[#f4f8fc] placeholder-dark-gray focus:outline-none focus:shadow-inner-blue h-14"
        type="text"
        [placeholder]="placeholder"
        [(ngModel)]="tel" />
    </app-form-field>
  </div>
</div>
