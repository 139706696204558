declare let google: any;
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PostLoginAtions } from 'src/app/core/enums/enums';
import { IGoogleLogin, ILoginResponse } from 'src/app/core/models/Login';
import { IUser } from 'src/app/core/models/models';
import { UserService } from 'src/app/core/rest-services/rest-services';
import { AuthentificationService } from 'src/app/core/services/authentication.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { LoginPostActionParams } from 'src/app/core/utils/type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss'],
})
export class GoogleLoginComponent implements OnInit {
  @Input() onlyOneTap = false;
  @Input() data!: {
    action?: PostLoginAtions;
    params?: LoginPostActionParams;
    invitation?: {
      key: string | null;
      mode: string | null;
      identifier: string | null;
      invitation_key?: string | null;
    };
    caller: '_SIGN_UP' | '_SIGN_IN';
  };
  googleClientId = environment.google_client_id;

  constructor(
    private translateService: TranslateService,
    private authentificationService: AuthentificationService,
    private notificationService: NotificationService,
    private services: ServicesService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    google.accounts.id.initialize({
      client_id: this.googleClientId,
      ux_mode: 'popup',
      itp_support: 'true',
      login_uri: window.location.origin,
      callback: this.handleCredentialResponse.bind(this),
    });

    google.accounts.id.renderButton(document.getElementById('google-btn'), {
      theme: 'filled_blue',
      size: 'large',
      shape: 'pill',
      text: 'continue_with',
      locale: this.translateService.currentLang === 'en' ? 'en_US' : 'fr_FR',
    });
  }

  handleCredentialResponse(response: any): void {
    let decodedToken: any | null = null;
    try {
      decodedToken = JSON.parse(atob(response?.credential.split('.')[1]));
      const googleLogin: IGoogleLogin = new IGoogleLogin(
        decodedToken,
        response?.credential,
        this.services.sessionService.getUser()?.id ?? undefined
      );
      this.services.eventService.publish('show:loading');
      this.authentificationService
        .loginWithGoogle(googleLogin, this.data)
        .subscribe({
          next: (res: ILoginResponse) => {
            if (res.status_code !== 401) {
              this.userService.view(res.user_id).subscribe({
                next: (userRes: IUser) => {
                  this.services.sessionService.deleteUser();
                  this.services.sessionService.storeUser(userRes);
                },
              });
              this.services.eventService.publish('close:dialog');
            }
          },
          error: (err: any) => {
            this.notificationService.danger(
              this.services.translateService.instant(
                err.translate ?? 'errors.generic'
              )
            );
            this.services.eventService.publish('hide:loading');
          },
        });
    } catch (e) {
      console.error('Error while trying to decode token', e);
    }
  }
}
