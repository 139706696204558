import { inject } from '@angular/core';
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SessionService } from '../services/session.service';
import { ServicesService } from '../services/services.service';
import { CustomNavigationService } from '../services/custom-navigation.service';
import { Observable } from 'rxjs';
import { GroupType } from '../enums/enums';

export function benefitGuard(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const authService: SessionService = inject(SessionService);
    const services: ServicesService = inject(ServicesService);
    const navigationService: CustomNavigationService = inject(
      CustomNavigationService
    );

    if (authService.isLoggedIn() && authService.hasRole(GroupType.Benefit)) {
      let currentRole = authService.getCurrentRole();
      let groupType = GroupType;
      let role;
      if (currentRole.group.type !== groupType.Benefit) {
        if (authService.hasRole(GroupType.Benefit)) {
          role = authService.getRole(GroupType.Benefit);
          currentRole = role;
          services.roleService.changeRole(role, state.url);
        }
      }
      return true;
    } else {
      authService.clearStorage();
      // Notifier danger
      return false;
    }
  };
}
