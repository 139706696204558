import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UseCaseTotpService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public getQrCode(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/v1/twofa`);
  }
  /**
   * my_treatments
   * @param page the page number
   * @param order the datas order (ASC || DESC)
   */
  public enable2FA(code: string): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/twofa`,
      { code: code },
      this.httpOptions
    );
  }

  public check2FA(code: string): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/twofa/check`,
      { code: code },
      this.httpOptions
    );
  }

  public deactivate2FA(): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/v1/twofa`, this.httpOptions);
  }

  public enableRequired2FA(user_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/twofa/required/${user_id}`,
      this.httpOptions
    );
  }
}
