<div class="relative">
  <div class="flex items-center justify-center relative top-28">
    <img
      appImg
      class="w-[300px]"
      src="assets/gif/active-support-animate.svg"
      alt="Support animation" />
  </div>

  <iframe
    class="absolute z-[2] top-5"
    style="border: none; width: 100%; height: 600px"
    src="https://app.weconnect.chat/chat/embed/?botId=MTk5Mw=="></iframe>
</div>
