import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExportFileService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public exportUsers(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/v1/export/export_users`);
  }

  public exportCurrencies(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/v1/export/export_currencies`);
  }

  public exportHealthcarepackage(): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/export/export_healthcarepackages`
    );
  }

  public exportHealthcareprovider(country_id: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/export/export_healthcareproviders/${country_id}`
    );
  }

  public exportTransactions(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/v1/export/export_transactions`);
  }
}
