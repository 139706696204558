import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ComponentType } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BottomSheetService {
  constructor(private bottomSheet: MatBottomSheet) {}

  openBottomSheet(
    component: ComponentType<unknown>,
    options: any
  ): Observable<any> {
    options = {
      ...{
        disableClose: false,
        panelClass: ['default_bottom_sheet'],
        backdropClass: ['default_backdrop', 'popup_backdrop'],
        data: {},
      },
      ...options,
    };
    const bottomSheetRef = this.bottomSheet.open(component, options);
    return bottomSheetRef.afterDismissed();
  }
}
