import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-picture-of-hcp',
  templateUrl: './picture-of-hcp.component.html',
  styleUrls: ['./picture-of-hcp.component.scss'],
})
export class PictureOfHcpComponent implements OnInit {
  @Input() classNames: string = '';
  @Input() variant: 'cover' | 'contain' = 'cover';
  @Input() src?: string | null;
  @Input() style: string = '';
  @Input() borderadius!: string;
  @Input() showBg: boolean = true;
  @Input() isMobileLayout: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
