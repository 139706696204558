import { inject } from '@angular/core';
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SessionService } from '../services/session.service';
import { CustomNavigationService } from '../services/custom-navigation.service';
import { Observable } from 'rxjs';

export function authGuard(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const authService: SessionService = inject(SessionService);
    const navigationService: CustomNavigationService = inject(
      CustomNavigationService
    );

    if (authService.isLoggedIn()) {
      return true;
    }
    authService.clearStorage();
    // Optionally, redirect to a login page or show a notification
    // For example, using navigationService to redirect:
    // navigationService.navigateToLogin();
    return false;
  };
}

export function guestGuard(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const authService: SessionService = inject(SessionService);
    const navigationService: CustomNavigationService = inject(
      CustomNavigationService
    );

    if (!authService.isLoggedIn() || authService.isGuest()) {
      return true;
    }
    return false;
  };
}
