import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Content, Currency } from 'src/app/core/models/Common';
import {
  GroupeResponse,
  IUser,
  RoleStore,
  UserStore,
} from 'src/app/core/models/User';
import {
  BuyedPackageService,
  CurrencyService,
  GroupService,
  HealthProviderService,
  RestRoleService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import {
  Country,
  OptionObject,
  PaginationElement,
  Phone,
} from 'src/app/core/utils/type';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { getCountries } from 'src/app/core/utils/country';
import { SessionService } from 'src/app/core/services/session.service';
import { Group, IGroup } from 'src/app/core/models/Group';
import {
  BuyedpackageStatus,
  GroupType,
  Permission,
} from 'src/app/core/enums/enums';
import { ServicesService } from 'src/app/core/services/services.service';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { CreateEditRolesComponent } from 'src/app/pages/containers/components/create-edit-roles/create-edit-roles.component';
import { Subscription } from 'rxjs';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { PatientDetailsModalComponent } from 'src/app/pages/hcp/components/modals/patient-details-modal/patient-details-modal.component';
import {
  isValidEmailAdress,
  isValidPhoneNumber,
} from 'src/app/core/utils/core';
import { IHealthcareProvider, IRole } from 'src/app/core/models/models';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { UseCaseAdminRoleService } from 'src/app/core/rest-services/usecases/usecase-admin-role.service';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  input_cls = INPUT_CLASSES;
  invalid = false;
  disabled = false;
  isDeleting = false;
  isGroupEditing = false;
  currencyOptions: OptionObject[] = [
    {
      label: '',
      value: '',
    },
  ];
  genderOptions: OptionObject[] = [
    {
      label: 'M',
      value: this.translateService.instant('common.gender-male'),
    },
    {
      label: 'F',
      value: this.translateService.instant('common.gender-female'),
    },
    {
      label: 'O',
      value: this.translateService.instant('common.gender-other'),
    },
  ];
  selectedCurrencie?: OptionObject;
  selectedGender: OptionObject = {
    label: 'M',
    value: this.translateService.instant('common.gender-male'),
  };

  emailFieldEdited = false;

  form!: FormGroup;
  isLoading = false;
  isEditing = false;
  isError = false;
  isSuccess = false;
  isTransitioning = false;
  currencies: Currency[] = [];
  user!: IUser;
  userExpected?: UserStore;
  hasWhatapp = false;
  tel: any;
  phoneModel: Phone = {};
  currency!: Currency;
  groups!: Group[];
  alreadyFetchUser = false;
  groupOptions: OptionObject[] = [
    {
      label: '',
      value: '',
    },
  ];
  isFinding = false;
  filterOptions: OptionObject[] = [];
  paginatedData: OptionObject[] = [];
  paginationGroup: PaginationElement = { page: 1, totalPages: 1 };
  groupType = GroupType;
  $updateGroup!: Subscription;
  $createGroup!: Subscription;
  pType = Permission;
  groupSelected?: OptionObject;
  isCreatingMember = false;
  isEditingMember = false;
  onTraitment = false;
  isCreateEditModalOpen = false;
  formCreateRole!: FormGroup;
  userId!: number;
  page = 1;
  pagination: PaginationElement = { page: 1, totalPages: 1 };
  buyedPackageStatus = BuyedpackageStatus;
  language = '';
  buyedPackage: IBuyedpackage[] = [];
  withGroupCreated = false;
  //CREATE USER VARIABLES
  isPhoneNumber = false;
  public identifier = '';
  public _identifier = '';
  fromError!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      state: any;
      identifier: string;
      caller: '_CREATE' | '_UPDATE';
      action?: 'CREATE_ADMIN' | 'OTHER' | 'BENEFICIARY_CREATOR';
    },
    public dialogRef: MatDialogRef<EditUserComponent>,
    private fb: FormBuilder,
    private healthcareProviderService: HealthProviderService,
    private location: Location,
    private currencyService: CurrencyService,
    private userService: UserService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private customNavigationService: CustomNavigationService,
    private sessionService: SessionService,
    private groupService: GroupService,
    private services: ServicesService,
    private roleService: RestRoleService,
    private buyedPackageService: BuyedPackageService,
    private adminUseCaseService: UseCaseAdminRoleService
  ) {
    this.language = this.translateService.currentLang;
  }

  ngOnDestroy(): void {
    if (this.$updateGroup) this.$updateGroup.unsubscribe();
    if (this.$createGroup) this.$createGroup.unsubscribe();
  }

  ngOnInit(): void {
    this.userId = this.data.state as number;
    if (this.userId) {
      this.initEditForm();
    }
    if (this.data.identifier) {
      this.identifier = this.data.identifier;
      if (this.isValidPhoneNumber(this.identifier)) {
        this.isPhoneNumber = true;
        this.tel = this.identifier;
        this._identifier = this.identifier;
      }
    }
  }

  initEditForm(): void {
    this.form = this.fb.group({
      firstName: [''],
      lastName: [''],
      dateOfBirth: [''],
      phoneNumber: [''],
      email: [''],
      hasWhatapp: [false],
    });
    this.formCreateRole = this.fb.group({
      permission: ['member', Validators.required],
    });
    this.currency = this.sessionService.getCurrencie();
    this.getUser();
    this.$updateGroup = this.services.eventService.subscribe(
      'admin:group:update',
      (role: IGroup) => {
        this.getUser();
      }
    );
    this.$createGroup = this.services.eventService.subscribe(
      'admin:group:create',
      (groupeResponse: GroupeResponse) => {
        this.groupSelected = {
          label: groupeResponse.group.name,
          value: groupeResponse.group.type + '::' + groupeResponse.group.id,
        };
        this.isEditingMember = false;
        this.isCreatingMember = true;
        this.withGroupCreated = true;
        this.saveMember();
      }
    );
  }

  createGroup(): void {
    this.services.modalService.openModal(CreateEditRolesComponent, {
      height: 'auto',
      data: {
        action: '_CREATE_GROUP',
        state: {
          label: '',
          value: '_ADD_GROUP',
        } as OptionObject,
        caller: '_ADMIN',
      },
    });
  }

  isFieldInvalid() {
    const result =
      this.form.value.email !== '' || this.form.value.email === null;

    const res =
      (this.phoneModel.phone === '' || this.phoneModel.phone === undefined) &&
      this.form.value.email === '';
    return res;
  }

  patchForm(): void {
    this.isLoading = true;
    this.form.patchValue({
      firstName: this.user.data ? this.user.data.firstName : '',
      lastName: this.user.data ? this.user.data.lastName : '',
      email: this.user.properties ? this.user.properties.email : '',
      dateOfBirth: this.user.data ? this.user.data.dateOfBirth : '',
    });
    this.hasWhatapp = this.user.has_whatsapp === 0 ? false : true;
    this.tel =
      this.user?.properties && this.user.properties.tel
        ? this.user.properties.tel
        : '';
    this.alreadyFetchUser = true;
    this.phoneModel.hasWhatsapp = this.user.has_whatsapp === 0 ? false : true;
    this.selectedGender = {
      label:
        this.user.gender === 'M' ? 'M' : this.user.gender === 'F' ? 'F' : 'O',
      value:
        this.user.gender === 'M'
          ? this.translateService.instant('common.gender-male')
          : this.user.gender === 'F'
          ? this.translateService.instant('common.gender-female')
          : this.translateService.instant('common.gender-other'),
    };
    this.isLoading = false;
  }

  get f() {
    return this.form.controls;
  }

  fetchCurrencies(): void {
    this.isTransitioning = true;
    this.currencyService.index().subscribe({
      next: (res: Currency[]) => {
        this.currencies = res;
        this.currencies && this.initCurrenciesFilter();
        const currentCurrencie = this.currencies.filter(
          c => c.id === this.user?.currencie_id
        );
        this.selectedCurrencie = {
          label: String(currentCurrencie[0].id),
          value: `${currentCurrencie[0].code} (${currentCurrencie[0].symbol})`,
        };
        this.patchForm();
      },
      error: error => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  fetchBuyedPackages(_page?: number): void {
    this.isTransitioning = true;
    this.buyedPackageService
      .index(_page ? _page : 1, undefined, this.user?.id)
      .subscribe({
        next: (res: Content<IBuyedpackage>) => {
          this.buyedPackage = res.data;
          this.pagination = {
            page: _page ? _page : 1,
            totalPages: res.last_page,
            total: res.total,
            perPage: res.per_page,
            form: res.from,
            to: res.to,
          };
        },
        error: error => {
          this.isTransitioning = false;
        },
        complete: () => {
          this.isTransitioning = false;
        },
      });
  }

  getUser(): void {
    this.isTransitioning = true;
    this.isLoading = true;
    this.userService.view(this.userId).subscribe({
      next: (userRes: IUser) => {
        this.user = userRes;
        this.fetchCurrencies();
        this.getFilter();
        this.fetchBuyedPackages();
      },
      error: (err: any) => {
        this.isTransitioning = false;
        this.isLoading = false;
      },
      complete: () => {
        this.isTransitioning = false;
        this.isLoading = false;
      },
    });
  }

  handleSubmit(): void {
    const emailValue = this.form.value.email;
    const phone = this.phoneModel.phone!;
    if (
      (phone === '' && emailValue === '') ||
      (phone === undefined && emailValue === '') ||
      (phone === '' && emailValue === null) ||
      (phone === undefined && emailValue === null)
    ) {
      this.notificationService.danger(
        this.translateService.instant('errors.invalid-form')
      );
      if (emailValue === '' || emailValue === null)
        this.emailFieldEdited = true;
      return;
    }
    if (
      !this.validEmailOrPhone &&
      !isValidPhoneNumber(phone) &&
      phone !== undefined &&
      phone !== ''
    ) {
      this.notificationService.danger(
        this.translateService.instant('errors.field-format')
      );
      return;
    }
    this.isTransitioning = true;
    if (
      this.selectedCurrencie !== null ||
      this.selectedCurrencie !== undefined
    ) {
      this.userExpected = this.initExpectedUser(this.form.value);
      this.userService.update(this.user.id, this.userExpected).subscribe({
        next: (res: IUser) => {
          this.notificationService.success(
            this.translateService.instant('admin-edit-user.user-updated')
          );
          if (this.data.action && this.data.action !== 'BENEFICIARY_CREATOR')
            this.customNavigationService.goToWithState('/admin', {
              state: { dashBoardRedirection: false },
            });
          this.closeModal('_UPDATE');
        },
        error: error => {
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
          this.isTransitioning = false;
        },
        complete: () => {
          this.isTransitioning = false;
        },
      });
    } else {
      return;
    }
  }

  saveMember(): void {
    if (this.isCreatingMember && !this.isEditingMember) {
      this.saveRoleMember();
    } else if (this.isEditingMember) {
      this.update();
    }
  }

  saveRoleMember(): void {
    this.isTransitioning = true;
    const expectedRole = this.initExpectedMember(this.formCreateRole.value);
    if (expectedRole) {
      this.roleService.store(expectedRole).subscribe({
        next: (resRole: IRole) => {
          this.getUser();
          this.notificationService.success(
            this.translateService.instant('kimbo-layout.success-member-store')
          );
          this.withGroupCreated = false;
        },
        error: (err: any) => {
          this.isTransitioning = false;
          this.notificationService.danger(
            this.translateService.instant(err.translate)
          );
        },
        complete: () => {
          this.isTransitioning = false;
          this.closeEditRoleModal();
        },
      });
    }
  }

  update(): void {
    this.isEditing = true;
    let _role_selected = this.user.roles!.find(
      role => role.group_id == Number(this.groupSelected!.value.split('::')[1])
    );
    if (!_role_selected) {
      _role_selected = this.user.roles!.find(
        role => role.id == Number(this.groupSelected!.value)
      )!;
    }
    const expectedRole = this.initExpectedMember(this.formCreateRole.value);
    this.roleService.update(_role_selected.id, expectedRole).subscribe({
      next: (resRole: IRole) => {
        this.notificationService.success(
          this.translateService.instant('common.line-updated')
        );
        this.getUser();
      },
      error: (err: any) => {
        this.isEditing = false;
        this.notificationService.danger(
          this.translateService.instant('errors.generic')
        );
      },
      complete: () => {
        this.isEditing = false;
        this.closeEditRoleModal();
      },
    });
  }

  initExpectedMember(formValue: any): RoleStore {
    let roleStore = new RoleStore();
    const permissions: string[] = [];
    permissions.push(
      this.withGroupCreated ? Permission.Owner : formValue.permission
    );
    if (this.isCreatingMember) {
      roleStore = new RoleStore(
        this.user?.properties && this.user.properties.tel
          ? this.user.properties.tel
          : undefined,
        Number(this.groupSelected!.value.split('::')[1]),
        permissions,
        this.user?.properties && this.user.properties.email
          ? this.user.properties.email
          : undefined
      );
    } else if (this.isEditingMember) {
      roleStore = new RoleStore(
        undefined,
        Number(this.groupSelected!.value.split('::')[1]),
        permissions
      );
    }
    return roleStore;
  }

  fetchGroups(term: string): void {
    this.isFinding = true;
    this.groupService.index(1, term).subscribe({
      next: (res: Content<IGroup>) => {
        this.groups = res.data;
        this.initGroupOptions();
      },
      error: error => {
        this.isFinding = false;
      },
      complete: () => {
        this.isFinding = false;
      },
    });
  }

  deleteGroup(group: OptionObject) {
    this.isTransitioning = true;
    const _role_selected = this.user.roles!.find(
      role => role.id == Number(group.value)
    )!;
    this.roleService.delete(_role_selected.id).subscribe({
      next: (res: IRole) => {
        this.notificationService.success(
          this.translateService.instant('common.line-deleted')
        );
        this.getUser();
      },
      error: error => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  getFilter(): void {
    this.filterOptions = [];
    if (this.user.admin_type != null || this.user.admin_type != undefined) {
      this.filterOptions.push({
        label: this.user.admin_type,
        value: GroupType.Admin,
      });
    }
    if (this.user.roles) {
      this.user.roles.forEach(role => {
        this.filterOptions.push({
          label: role.group.name,
          value: role.id.toString(),
        });
      });
      this.paginationGroup.total = this.filterOptions.length;
      this.paginationGroup.perPage = 10;
      this.paginationGroup.totalPages = Math.ceil(
        this.paginationGroup.total / this.paginationGroup.perPage
      );
      this.setPageGroup(1);
    }
  }

  handleEditAction(option: OptionObject): void {
    const _role_selected = this.user.roles!.find(
      role => role.id == Number(option.value)
    )!;
    this.services.modalService.openModal(CreateEditRolesComponent, {
      height: 'auto',
      with: '1000px',
      data: {
        action: '_EDIT_GROUP',
        state: option,
        caller: '_ADMIN',
        role: _role_selected,
      },
    });
  }

  handleDeleteAction(option: OptionObject): void {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.deleteGroup(option);
        }
      });
  }

  getRoleName(option: OptionObject): string {
    let role!: IRole;
    if (option.value != 'admin')
      role = this.user.roles!.find(role => role.id == Number(option.value))!;
    return role ? role.group.type : '';
  }

  getRolePermission(option: OptionObject): string[] {
    let role!: IRole;
    if (option.value != 'admin')
      role = this.user.roles!.find(role => role.id == Number(option.value))!;
    return role ? role.permissions : [];
  }

  _getRolePermission(option: OptionObject): string[] {
    let role!: IRole;
    if (option.value != 'admin')
      role = this.user.roles!.find(
        role => role.group_id == Number(option.value.split('::')[1])
      )!;
    return role ? role.permissions : [];
  }

  isInsideTheGroup(item: OptionObject): boolean {
    let isIn = false;
    this.filterOptions.forEach(opt => {
      if (opt.label === item.label) isIn = true;
    });
    return isIn;
  }

  countryListToOptionObject(): OptionObject[] {
    this.isTransitioning = true;
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    this.isTransitioning = false;
    return countryOptions;
  }

  initExpectedUser(formValue: any): UserStore {
    const phone =
      this.phoneModel != null ? this.phoneModel.phone?.trim() : this.user.tel;
    const hasWhatsapp = this.phoneModel?.hasWhatsapp ?? this.user?.has_whatsapp;
    const data: any = {
      ...formValue,
    };
    const userStore: UserStore = new UserStore(
      formValue.email === '' || formValue.email === null
        ? undefined
        : formValue.email,
      phone ?? '',
      this.selectedGender.label,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      data,
      Number(this.selectedCurrencie?.label),
      1,
      hasWhatsapp ? 1 : 0
    );
    return userStore;
  }

  initGroupOptions(): void {
    this.groupOptions = [];
    this.groups.forEach(group => {
      const addInfos =
        group.type === this.groupType.Kimbo &&
        group.user &&
        group.user.user_data &&
        (group.user.user_data.firstName || group.user.user_data.lastName)
          ? (group.user.user_data?.firstName ?? '') +
            ' ' +
            (group.user.user_data?.lastName ?? ' ')
          : group.user?.user_properties.tel
          ? group.user.user_properties.tel
          : group.user?.user_properties.email
          ? group.user.user_properties.email
          : group.name;
      this.groupOptions.push({
        label:
          group.type === this.groupType.Kimbo
            ? `${group.name} (${addInfos})`
            : group.name,
        value: group.type + '::' + group.id,
      });
    });
  }

  initCurrenciesFilter(): void {
    this.isTransitioning = true;
    this.currencyOptions = [];
    this.currencies.forEach(currencie => {
      this.currencyOptions.push({
        label: String(currencie.id),
        value: `${currencie.code} (${currencie.symbol})`,
      });
    });
    this.isTransitioning = false;
  }

  handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
  }

  handleDelete(): void {
    this.isTransitioning = true;
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: '210px',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.isTransitioning = true;
          this.userService.delete(this.user.id).subscribe({
            next: (res: IUser) => {
              this.notificationService.success(
                this.translateService.instant('common.line-deleted')
              );
              this.services.eventService.publish('admin-count:refresh');
              this.closeModal('_UPDATE');
            },
            error: error => {
              this.translateService.instant('common.line-deleted-error');
              this.isTransitioning = false;
            },
            complete: () => {
              this.isTransitioning = false;
              this.isTransitioning = false;
            },
          });
        }
      });
  }

  handleCurrencyDropdownEvent(currency: OptionObject): void {
    this.selectedCurrencie = currency;
  }

  handleSearchEvent(event: any): void {
    if (typeof event === 'object') {
      this.groupSelected = event;
    } else {
      this.groupSelected = undefined;
      this.fetchGroups(event);
    }
  }

  handleGenderDropdownEvent(gender: OptionObject): void {
    this.selectedGender = gender;
  }

  handleGroupActions(action: string, data: OptionObject): void {
    const _role_selected = this.user.roles!.find(
      role => role.id == Number(data.value)
    )!;
    this.services.modalService.openModal(CreateEditRolesComponent, {
      height: 'auto',
      with: '1000px',
      data: {
        action: action,
        state: data,
        caller: '_ADMIN',
        target: '_TRANSACTION',
        role: _role_selected,
      },
    });
  }

  setPage(el: number | symbol): void {
    if (typeof el !== 'symbol') {
      this.page = el;
      this.fetchBuyedPackages(this.page);
    }
  }

  setPageGroup(page: number | symbol): void {
    if (typeof page !== 'symbol') {
      this.paginationGroup.page = page;
      const startIndex = (page - 1) * (this.paginationGroup.perPage ?? 10);
      const endIndex = Math.min(
        startIndex + (this.paginationGroup.perPage ?? 10),
        this.paginationGroup.total ?? 0
      );
      this.paginatedData = this.filterOptions.slice(startIndex, endIndex);
      this.paginationGroup.form = startIndex + 1;
      this.paginationGroup.to = endIndex;
    }
  }

  handleBuyedPackageEvent(item: IBuyedpackage): void {
    this.services.modalService.openModal(PatientDetailsModalComponent, {
      width: '950px',
      height: 'auto',
      data: {
        state: item,
        caller: '_FINISHED',
      },
    });
  }

  closeModal(action?: any): void {
    this.closeEditRoleModal();
    this.dialogRef.close(action);
  }

  closeEditRoleModal(): void {
    this.isCreatingMember = false;
    this.isEditingMember = false;
    this.isCreateEditModalOpen = false;
  }

  toggleCreateOverlayVisibility(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (this.isInsideTheGroup(this.groupSelected!)) {
      const permissions = this._getRolePermission(this.groupSelected!);
      this.isEditingMember = true;
      this.isGroupEditing = true;
      this.formCreateRole.patchValue({
        member: permissions?.includes(Permission.Member),
        manager: permissions?.includes(Permission.Owner),
      });
      this.isCreateEditModalOpen = true;
    } else {
      this.isCreateEditModalOpen = !this.isCreateEditModalOpen;
      this.isEditingMember = false;
      this.isGroupEditing = true;
      this.isCreatingMember = true;
    }
    this.isGroupEditing = false;
  }

  editPermissions(groupSelected: OptionObject): void {
    const permissions = this._getRolePermission(groupSelected);
    this.groupSelected = groupSelected;
    this.isEditingMember = true;
    this.formCreateRole.patchValue({
      member: permissions?.includes(Permission.Member),
      manager: permissions?.includes(Permission.Owner),
    });
    this.isCreateEditModalOpen = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public get validEmailOrPhone(): boolean {
    return (
      this.f['email'].value !== '' ||
      (this.phoneModel.phone !== '' && this.phoneModel.phone !== undefined)
    );
  }

  toLocaleDateTime(date: string, local: string): string {
    return toWordLocaleDateTime(new Date(date), local);
  }

  //CREATE USER

  setIdentifier(event: any): void {
    this.identifier = event.target.value;
    this._identifier = event.target.value;
    if (this._identifier.trim().length > 0) {
      const first = this._identifier[0];
      if (/^[0-9]|\+$/.test(first)) {
        this.isPhoneNumber = true;
      }
    }
  }

  resetLoginForm(): void {
    this.isPhoneNumber = false;
    this.identifier = '';
  }

  _handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
    this.identifier = this.phoneModel.phone!;
  }

  isValidPhoneNumber(value: string): boolean {
    return isValidPhoneNumber(value);
  }
  isValidAddressEmail(value: string): boolean {
    if (!value || value.trim() === '') {
      return true;
    }
    return isValidEmailAdress(value);
  }

  checkSubmitButtonState(): boolean {
    const emailValue = this.form.get('email')?.value;
    const phoneValue = this.phoneModel.phone;

    const isEmailValid = emailValue
      ? this.isValidAddressEmail(emailValue)
      : false;
    const isPhoneValid = phoneValue
      ? this.isValidPhoneNumber(phoneValue)
      : false;

    if (
      (isEmailValid && (isPhoneValid || !phoneValue)) ||
      (!emailValue && isPhoneValid) ||
      (isEmailValid && isPhoneValid)
    ) {
      return true;
    }

    return false;
  }

  storeUser(): void {
    if (!this.identifier) {
      this.fromError = this.translateService.instant('errors.required');
      return;
    }
    if (
      !this.isValidPhoneNumber(this.identifier) &&
      !this.isValidAddressEmail(this.identifier)
    ) {
      const first = this.identifier[0];
      if (/^[0-9]|\+$/.test(first)) {
        this.fromError = this.translateService.instant(
          'errors.invalid-phone-number'
        );
      } else {
        this.fromError = this.translateService.instant('errors.invalid-email');
      }

      return;
    }
    this.fromError = '';
    const userModel = new UserStore(
      !this.isPhoneNumber ? this.identifier : undefined,
      this.isPhoneNumber ? this.identifier : undefined,
      'M',
      'Europe/Stockholm',
      {},
      1,
      1,
      1
    );

    if (this.data.action && this.data.action === 'CREATE_ADMIN') {
      this.isEditing = true;
      this.adminUseCaseService
        .addAdminUser({
          email: !this.isPhoneNumber ? this.identifier : undefined,
          tel: this.isPhoneNumber ? this.identifier : undefined,
        })
        .subscribe({
          next: (res: IUser) => {
            this.notificationService.success(
              this.translateService.instant('common.user-added')
            );
            this.isEditing = false;
            this.closeModal(res);
          },
          error: err => {
            this.notificationService.danger(
              this.translateService.instant('common.user-added-error')
            );
            this.isEditing = false;
          },
          complete: () => {
            this.isEditing = false;
          },
        });
    } else {
      this.isTransitioning = true;
      this.userService.store(userModel).subscribe({
        next: (res: IUser) => {
          this.notificationService.success(
            this.translateService.instant('common.user-added')
          );
          this.isTransitioning = false;
          this.closeModal('_SAVE');
        },
        error: err => {
          this.notificationService.danger(
            this.translateService.instant(
              err?.translate ?? 'common.user-added-error'
            )
          );
          this.isTransitioning = false;
        },
        complete: () => {
          this.isTransitioning = false;
        },
      });
    }
  }

  deleteHCProvider(healthcareprovider?: IHealthcareProvider) {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: '210px',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.onTraitment = true;
          this.healthcareProviderService
            .delete(healthcareprovider?.id!)
            .subscribe({
              next: (res: IHealthcareProvider) => {
                this.notificationService.success(
                  this.translateService.instant('common.line-deleted')
                );
                this.services.eventService.publish('admin-count:refresh');
              },
              error: error => {
                this.translateService.instant('common.line-deleted-error');
                this.onTraitment = false;
              },
              complete: () => {
                this.onTraitment = false;
              },
            });
        }
      });
  }
}
