import { Component } from '@angular/core';
import { AppUpdateService } from 'src/app/core/services/app-update.service';
import { ServicesService } from 'src/app/core/services/services.service';

@Component({
  selector: 'app-corporate-layout',
  templateUrl: './corporate-layout.component.html',
  styleUrls: ['./corporate-layout.component.scss'],
})
export class CorporateLayoutComponent {
  constructor(
    public AppUpdateService: AppUpdateService,
    public services: ServicesService
  ) {
    this.services.sessionService.updateUserStorage();
    this.services.sessionService.storePushN();
  }
}
