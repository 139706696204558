import { Subscription } from 'rxjs';
import { Component, Input } from '@angular/core';
import { GroupType } from 'src/app/core/enums/enums';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { CountRecords } from 'src/app/core/models/AdminMonitoring';
import { parseTokNotion } from 'src/app/core/utils/misc';

@Component({
  selector: 'app-layout-nav-link',
  templateUrl: './layout-nav-link.component.html',
  styleUrls: ['./layout-nav-link.component.scss'],
})
export class LayoutNavLinkComponent {
  @Input() iconPath!: string;
  @Input() iconLabel!: string;
  @Input() location!: string;
  @Input() active = false;
  @Input() role?: string;
  @Input() countResponse?: any;
  groupeType = GroupType;
  @Input() contacts!: number;
  @Input() incomming_buyedPackages!: number;
  @Input() treatment_buyedPackages!: number;
  @Input() finished_buyedPackages!: string;
  @Input() count_records!: CountRecords;
  @Input() unuseBuyedPackages!: number;
  @Input() isLoading = true;
  elementToCount: string[] = [
    '/kimbo/my-beneficiary',
    '/benefit/employees',
    '/admin',
    '/admin/healthcare-providers',
    '/admin/healthcare-package',
    '/admin/transactions',
  ];
  $refreshUsers!: Subscription;

  constructor(
    private navigationService: CustomNavigationService,
    public services: ServicesService
  ) {}

  parseToKNotation(token: number): string {
    return parseTokNotion(token);
  }

  goTo(url: string): void {
    this.navigationService.goTo(url);
  }
}
