import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { ServicesService } from 'src/app/core/services/services.service';
import { urltoFile } from 'src/app/core/utils/misc';
import { WebcamComponent } from '../webcam/webcam.component';

interface InputFile {
  name: string;
  file: any;
  url?: string;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input() accept = 'image/jpg, image/jpeg, image/png, application/pdf';
  @Input() name!: string;
  @Input() id!: string;
  @Output() changeEvent: EventEmitter<InputFile> = new EventEmitter();
  @Output() dragChangeEvent: EventEmitter<File[]> = new EventEmitter();
  @Input() value?: File | null;
  @Input() kind?:
    | '_SIMPLE'
    | '_WEBCAM'
    | '_SIMPLE_FILE_EXPLORER'
    | '_SPEED_DIAL'
    | 'WITH_DRAGGABLE_ZONE' = '_SIMPLE';
  @Input() caller?: '_HCP' | '_KIMBO' = '_KIMBO';
  @Input() dndExtraParams?: {
    title: string;
    hint: string;
    error?: string;
  };

  webcamImage!: WebcamImage;
  sysImage!: string;
  isManual = false;
  speedDialisOpen = false;

  constructor(private services: ServicesService) {}

  handleChange = (evt: any) => {
    this.isManual = true;
    this.value = evt.target.files;
    if (this.value) {
      const reader = new FileReader();
      reader.onload = () => {
        this.sysImage = reader.result as string;
        this.changeEvent.emit({
          name: this.name,
          file: evt.target.files?.item(0) ?? null,
          url: this.sysImage,
        });
      };
      reader.readAsDataURL(evt.target.files?.item(0));
    }
  };

  ngOnInit(): void {
    if (this.value === null && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  openWebcam(): void {
    if (this.kind === '_SPEED_DIAL') this.speedDialisOpen = false;
    this.services.modalService
      .openModal(WebcamComponent, {
        width: '600px',
        height: 'auto',
      })
      .subscribe((webcamImage: WebcamImage) => {
        if (webcamImage) {
          this.isManual = false;
          this.webcamImage = webcamImage;
          this.sysImage = webcamImage.imageAsDataUrl;
          const filename = `camera_${Date.now()}_${new Date().getMilliseconds()}`;
          urltoFile(
            webcamImage.imageAsDataUrl,
            filename,
            this.getImageTypeFromDataUrl(webcamImage.imageAsDataUrl)
          ).then(file_res => {
            this.changeEvent.emit({
              name: filename,
              file: file_res,
              url: this.sysImage,
            });
          });
        }
      });
  }

  getImageTypeFromDataUrl(dataUrl: string): string {
    const matches = dataUrl.match(/^data:(image\/[a-zA-Z]+);base64,/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return 'text/plain';
  }

  toggleSpeedDialOpen() {
    this.speedDialisOpen = !this.speedDialisOpen;
  }

  clickedOutsideOfSpeedDial() {
    this.speedDialisOpen = false;
  }

  // For file with draggle with zone

  /**
   * on file drop handler
   */
  onFileDropped(files: any) {
    this.handleChangeForDragZone(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: any) {
    this.handleChangeForDragZone($event.target.files);
  }

  handleChangeForDragZone(files: File[]): void {
    this.dragChangeEvent.emit(files);
  }
}
