export enum PermissionEnums {
  SuperAdmin = 'super_admin',
  DiscountView = 'discount_view',
  DiscountEdit = 'discount_edit',
  DiscountDelete = 'discount_delete',
  BuyedpackageView = 'buyedpackage_view',
  BuyedpackageEdit = 'buyedpackage_edit',
  BuyedpackageDelete = 'buyedpackage_delete',
  SettingView = 'setting_view',
  SettingEdit = 'setting_edit',
  SettingDelete = 'setting_delete',
  InvoiceView = 'invoice_view',
  InvoiceEdit = 'invoice_edit',
  InvoiceDelete = 'invoice_delete',
  CampaignView = 'campaign_view',
  CampaignEdit = 'campaign_edit',
  CampaignDelete = 'campaign_delete',
  CurrencieView = 'currencie_view',
  CurrencieEdit = 'currencie_edit',
  CurrencieDelete = 'currencie_delete',
  CountryView = 'country_view',
  CountryEdit = 'country_edit',
  CountryDelete = 'country_delete',
  PaymentView = 'payment_view',
  PaymentEdit = 'payment_edit',
  PaymentDelete = 'payment_delete',
  HealthpackageView = 'healthpackage_view',
  HealthpackageEdit = 'healthpackage_edit',
  HealthpackageDelete = 'healthpackage_delete',
  HealthcareproviderView = 'healthcareprovider_view',
  HealthcareproviderEdit = 'healthcareprovider_edit',
  HealthcareproviderDelete = 'healthcareprovider_delete',
  UserView = 'user_view',
  UserEdit = 'user_edit',
  UserDelete = 'user_delete',
  Dashboard = 'dashboard',
  DashboardAlert = 'dashboard_alert',
  DashboardSupport = 'dashboard_support',
  DashboardMarketing = 'dashboard_marketing',
  DashboardOperation = 'dashboard_operation',
  DashboardFinance = 'dashboard_finance',
  AdminroleView = 'adminrole_view',
  AdminroleEdit = 'adminrole_edit',
  AdminroleDelete = 'adminrole_delete',
}
