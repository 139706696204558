<h2 class="text-navy" mat-dialog-title>
  {{ 'kimbo-layout.nav-support-link' | translate }}
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="Example icon button with a delete icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class="py-6">
    <div class="w-full font-bold bg-blue rounded-md text-white px-4 py-3">
      {{ 'kimbo-layout.nav-help-link' | translate }}
    </div>
    <div>
      <div
        class="flex gap-x-5 items-start sm:items-center flex-col sm:flex-row py-6 px-2">
        <a
          (click)="dialogRef.close()"
          class="text-xs font-semibold tracking-wide text-light-blue hover:underline focus:outline-none focus:shadow-lighter-blue flex items-center justify-center uppercase"
          [href]="'common.faq-link-url' | translate"
          rel="noreferrer noopener"
          target="_blank">
          <mat-icon color="primary" class="mr-1">contact_support</mat-icon>
          {{ 'kimbo-layout.nav-faq-link' | translate }}
        </a>
        <a
          (click)="dialogRef.close()"
          class="text-xs font-semibold tracking-wide text-light-blue hover:underline focus:outline-none focus:shadow-lighter-blue pt-2 sm:pt-0 flex items-center justify-center uppercase"
          routerLink="/support"
          rel="noreferrer noopener">
          <mat-icon color="primary" class="mr-1">forum</mat-icon>
          {{ 'common.online-support' | translate }}
        </a>
      </div>
    </div>
    <div class="w-full font-bold bg-blue rounded-md text-white px-4 py-3">
      Contact
    </div>
    <div *ngIf="isContactOpen" class="py-5">
      <div class="flex flex-col items-start px-2">
        <div class="flex flex-wrap flex-col sm:flex-row sm:items-start">
          <div class="flex items-center w-full sm:w-1/2-with-gap mb-3">
            <div class="w-[32px] h-[35px] flag ff-round ff-lg flag-ch"></div>
            <a
              class="font-semibold tracking-wide text-light-blue text-xs hover:underline focus:outline-none focus:shadow-lighter-blue px-2"
              href="https://api.whatsapp.com/send?phone=41766081127"
              rel="noreferrer noopener"
              target="_blank">
              +41 76 608 11 27
            </a>
            <img appImg class="w-5 h-5" src="assets/images/whatsapp.png" />
          </div>
          <div class="flex items-center w-full sm:w-1/2-with-gap mb-3">
            <div class="w-[32px] h-[35px] flag ff-round ff-lg flag-us"></div>
            <a
              class="font-semibold tracking-wide text-light-blue text-xs hover:underline focus:outline-none focus:shadow-lighter-blue px-2"
              href="https://api.whatsapp.com/send?phone=1(646)470-1122"
              rel="noreferrer noopener"
              target="_blank">
              +1 (646) 470-1122
            </a>
          </div>
          <div class="flex items-center w-full sm:w-1/2-with-gap mb-2">
            <div class="w-[32px] h-[35px] flag ff-round ff-lg flag-ke"></div>
            <a
              class="font-semibold tracking-wide text-light-blue text-xs hover:underline focus:outline-none focus:shadow-lighter-blue px-2"
              href="https://api.whatsapp.com/send?phone=254112307447"
              rel="noreferrer noopener"
              target="_blank">
              +254 112307447
            </a>
            <img appImg class="w-5 h-5" src="assets/images/whatsapp.png" />
          </div>
        </div>
        <div class="flex items-center mt-2">
          <a
            class="font-semibold tracking-wide text-light-blue text-xs hover:underline focus:outline-none focus:shadow-lighter-blue"
            href="mailto:Contact@kimbocare.com"
            target="_blank"
            rel="noreferrer noopener">
            contact@kimbocare.com
          </a>
        </div>
      </div>
    </div>
    <div class="w-full font-bold bg-blue rounded-md text-white px-4 py-3">
      {{ 'kimbo-layout.nav-legal-link' | translate }}
    </div>

    <div class="pt-5">
      <div class="flex flex-col items-start px-2 mt-2">
        <a
          class="font-semibold tracking-wide text-light-blue text-xs hover:underline focus:outline-none focus:shadow-lighter-blue"
          [href]="'common.terms-and-conditions-link-url' | translate"
          rel="noreferrer noopener"
          target="_blank">
          {{ 'common.terms-and-conditions-link' | translate }}
        </a>
        <a
          class="mt-2 font-semibold tracking-wide text-light-blue text-xs hover:underline focus:outline-none focus:shadow-lighter-blue"
          [href]="'common.privacy-policy-link-url' | translate"
          target="_blank"
          rel="noreferrer noopener">
          {{ 'common.privacy-policy-link' | translate }}
        </a>
      </div>
    </div>
  </div>
</mat-dialog-content>
