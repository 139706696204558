import { ConversationnableType } from '../enums/enums';
import { IGroup } from './Group';
import { IUser } from './models';

export class CreateConversationStore {
  conversationnables: ConversationableStore[];
  target_type?: string;
  target_id?: number;
  type?: string;

  constructor(
    conversationnables: ConversationableStore[],
    target_type?: string,
    target_id?: number,
    type?: string
  ) {
    this.target_type = target_type;
    this.target_id = target_id;
    this.type = type;
    this.conversationnables = conversationnables;
  }
}

export class ConversationableStore {
  conversationnable_type: string;
  conversationnable_id: number;
  right?: string;

  constructor(
    conversationnable_type: string,
    conversationnable_id: number,
    right?: string
  ) {
    this.conversationnable_type = conversationnable_type;
    this.conversationnable_id = conversationnable_id;
    this.right = right;
  }
}

export interface IConversationable {
  conversation_id: number;
  conversationnable_type: ConversationnableType;
  conversationnable_id: number;
  right: any;
  new_message: boolean;
  displayed: any;
}

export interface IMessageStore {
  text: string;
  group_id: number;
  conversationnable_type: ConversationnableType;
  conversationnable_id: number;
}

export interface IMessage {
  id: number;
  conversation_id: number;
  user_id: number;
  group_id: number;
  data: any[];
  created_at: string;
  updated_at: string;
  content: Content;
  group: IGroup;
  user: IUser;
}

export interface IConversation {
  id: number;
  type: string;
  data: any[];
  created_at: string;
  updated_at: string;
  target_type: null;
  target_id: null;
  conversationnables: any[];
}

interface Content {
  id: number;
  type: string;
  languages: Language[];
}

interface Language {
  id: number;
  name: string;
  code: string;
  pivot: Pivot;
}

interface Pivot {
  ressource_id: number;
  language_id: number;
  id: number;
  data: string;
}

interface Content {
  id: number;
  type: string;
  languages: Language[];
}

interface Language {
  id: number;
  name: string;
  code: string;
  pivot: Pivot;
}

interface Pivot {
  ressource_id: number;
  language_id: number;
  id: number;
  data: string;
}
