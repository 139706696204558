import { createReducer, MetaReducer, ActionReducer } from '@ngrx/store';

const initialState = {
  appName: 'KimboCare',
};

function log(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    const currentState = reducer(state, action);
    return currentState;
  };
}

export const metaReducers: MetaReducer[] = [log];

export const rootReducer = createReducer(initialState);
