/* eslint-disable no-constant-condition */
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/core/models/AdminMonitoring';
import { IUser } from 'src/app/core/models/models';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { NavService } from 'src/app/core/services/navservice';
import { ServicesService } from 'src/app/core/services/services.service';
import { shortenText } from 'src/app/core/utils/misc';
import { ProfileComponent } from 'src/app/pages/containers/components/profile/profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @Output() headerTriggerEvent: EventEmitter<any> = new EventEmitter();
  user!: IUser;
  $updateUserEvent!: Subscription;
  isProfilOpen = false;
  isNotificationsOpen = false;
  notifications: Alert[] = [];
  startDate!: Date;
  endDate!: Date;

  constructor(
    public navServices: NavService,
    private services: ServicesService,
    public navigationService: CustomNavigationService
  ) {
    this.user = this.services.sessionService.getUser();
    this.$updateUserEvent = this.services.eventService.subscribe(
      'user:updated',
      (user: IUser) => {
        this.user = user;
      }
    );
  }

  ngOnDestroy(): void {
    this.$updateUserEvent && this.$updateUserEvent.unsubscribe();
  }

  openProfilDialog(): void {
    this.services.modalService.openModal(ProfileComponent, {
      width: '1300px',
      height: 'auto',
    });
    this.isProfilOpen = false;
  }

  toggleSideMenuOpen(): void {
    this.services.eventService.publish('sidemenu:toggle');
  }

  closeProfileDropdown(): void {
    if (this.isProfilOpen) this.isProfilOpen = false;
  }

  toggleSideProfileOpen(): void {
    this.isProfilOpen = !this.isProfilOpen;
  }

  closeNotificationsDropdown(): void {
    if (this.isNotificationsOpen) this.isNotificationsOpen = false;
  }

  toggleSideNotificationsOpen(): void {
    this.isNotificationsOpen = !this.isNotificationsOpen;
  }

  shortenText(text: string): string {
    return shortenText(text);
  }
}
