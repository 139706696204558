import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponse } from '../models/Common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  protected basePath = environment.basePath;

  constructor(private swPush: SwPush, private http: HttpClient) {}

  public requestSubscription() {
    if (!this.swPush.isEnabled) {
      return;
    }

    this.swPush
      .requestSubscription({
        serverPublicKey: environment.vapid_key,
      })
      .then((sub: PushSubscription) => {
        this.storeToServer(sub).subscribe();
      })
      .catch(err => console.log(err));
  }

  public unsubscribePush() {
    this.http
      .delete<any>(
        `${this.basePath}/v1/user/push_notification`,
        this.httpOptions
      )
      .subscribe((data: GenericResponse) => {
        if (data.status_code == 200) {
          this.swPush.unsubscribe();
        }
      });
  }

  public storeToServer(subscription: PushSubscription): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/user/push_notification`,
      subscription,
      this.httpOptions
    );
  }
}
