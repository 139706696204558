import * as moment from 'moment';
import { lz } from './core';
import { deprecate } from 'util';

const formats = {
  DMY: { separator: '.' },
  YMD: { separator: '-' },
};

type DateFormat = keyof typeof formats;

export function parseToDate(dateString: string, format: DateFormat): Date {
  const { separator } = formats[format];
  let c: string[] = dateString.split(separator);

  switch (format) {
    case 'DMY': {
      c = c.reverse();
      break;
    }
  }

  return new Date(c.join('-'));
}

export function formatDate(
  dateOrString: Date | string,
  format: DateFormat
): string {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
  const c: string[] = [
    lz(date.getDate()),
    lz(date.getMonth() + 1),
    lz(date.getFullYear()),
  ];
  const { separator } = formats[format];

  switch (format) {
    case 'DMY': {
      // DD/MM/YYYY
      return c.join(separator);
    }
    case 'YMD': {
      // YYYY/MM/DD
      return c.reverse().join(separator);
    }
  }
}

export function toLocaleDate(
  dateOrString: Date | string,
  locale: string
): string {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
  return date.toLocaleDateString(locale);
}

export function toLocaleDateTime(
  dateOrString: Date | string,
  locale: string
): string {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
  const dateString = date.toLocaleDateString(locale);
  const timeString = date.toLocaleTimeString(locale);

  return `${dateString}, ${timeString.slice(0, -3)}`;
}

export function toWordLocaleDateTime(
  dateOrString: Date | string,
  locale: string
): string {
  let wordDateTime = '';
  moment.locale(locale);

  if (locale === 'en') {
    wordDateTime = moment(dateOrString).format('MMMM Do YYYY, h:mm a'); // English format
  } else if (locale === 'fr') {
    wordDateTime = moment(dateOrString).format('LL, LT'); // French format
  }

  return wordDateTime;
}

export function startOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function startOfYear(date: Date) {
  return new Date(date.getFullYear(), 0, 1);
}

export function getDiffAboutToDay(date: Date) {
  const today = new Date();
  return today.getFullYear() - date.getFullYear();
}

export function _enumerateDaysBetweenDates(startDate: any, endDate: any) {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.format('MM/DD/YYYY'));
  }

  return dates;
}

export function enumerateDaysBetweenDates(startDate: any, endDate: any) {
  let now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }
  return dates;
}

export function enumerateMonthsBetweenDates(startDate: any, endDate: any) {
  let betweenMonths = [];

  if (startDate < endDate) {
    let date = startDate.startOf('month');

    while (date < endDate.endOf('month')) {
      betweenMonths.push(date.format('YYYY-MM'));
      date.add(1, 'month');
    }
  }
  return betweenMonths;
}

export function enumerateYearsBetweenDates(startDate: any, endDate: any) {
  let betweenYears: Array<string> = [];
  let betweenMonths = enumerateYearsBetweenDates(startDate, endDate);
  betweenMonths.forEach(month => {
    let year = month.split('-')[0];
    if (!betweenYears.includes(year)) betweenYears.push(year);
  });
  betweenYears = betweenYears.sort(function (a, b) {
    return Number(a) - Number(b);
  });

  return betweenYears;
}

//take list of days and data and matche data to corresponding day
export function groupDataByDay(dates: any[], data: any[]) {
  const groups: any = {};
  dates.forEach((date: any) => {
    groups[date] = [];
    data.forEach((obj: any) => {
      const objDate = obj.created_at.split('T')[0];
      if (objDate === date) {
        groups[date].push(obj);
      }
    });
  });
  return groups;
}

//take list of months and data and matche data to corresponding month
export function groupDataByMonth(months: any[], data: any[]) {
  const groups: any = {};
  months.forEach((month: any) => {
    groups[month] = [];
    data.forEach((obj: any) => {
      const objMonth = obj.created_at.split('T')[0].slice(0, 7);
      if (objMonth === month) {
        groups[month].push(obj);
      }
    });
  });
  return groups;
}

//take list of years and data and matche data to corresponding year
export function groupDataByYear(years: any[], data: any[]) {
  const groups: any = {};
  years.forEach((year: any) => {
    groups[year] = [];
    data.forEach((obj: any) => {
      const objYear = obj.created_at.split('T')[0].slice(0, 4);
      if (objYear === year) {
        groups[year].push(obj);
      }
    });
  });
  return groups;
}

export function groupByMonth(data: any, locale: string = 'default') {
  return data.reduce((acc: any, item: any) => {
    const date = new Date(item.created_at);
    const month = date.toLocaleString(locale, { month: 'long' });
    const year = date.getFullYear();
    const key = `${month} ${year}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

export function groupByYear(data: any) {
  return data.reduce((acc: any, item: any) => {
    const date = new Date(item.created_at);
    const year = date.getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});
}

export function groupByDay(data: any) {
  return data.reduce((acc: any, item: any) => {
    const date = new Date(item.created_at);
    const day = date.toLocaleDateString();
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
}

export function formatMonths(months: any, locale: string = 'default') {
  return months.map((month: any) => {
    const date = new Date(`${month}-01T00:00:00Z`);
    const formattedMonth = date.toLocaleString(locale, { month: 'long' });
    const year = date.getFullYear();
    return `${formattedMonth} ${year}`;
  });
}

export function formatDateToText(dates: any, language: any) {
  const months: any = {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    fr: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
  };
  return dates.map((date: any) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}`;
  });
}
