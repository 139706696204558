import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent {
  currentLang = 'en';
  mobileDevice = '';
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<DownloadAppComponent>,
    private translateService: TranslateService,
    public utilsService: UtilsService
  ) {
    this.currentLang = this.translateService.currentLang;
    this.mobileDevice = this.utilsService.getMobileOperatingSystem();
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }
}
