import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  Currency,
  PayHealthcarepackageInitialState,
} from 'src/app/core/models/Common';
import { IHealthcarepackage } from 'src/app/core/models/Healthcarepackage';
import { IHealthcareProvider } from 'src/app/core/models/HealthcareProvider';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { TranslateService } from '@ngx-translate/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { IRole } from 'src/app/core/models/User';
import { GroupType, HealthProviderType } from 'src/app/core/enums/enums';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PaginationElement } from 'src/app/core/utils/type';
import { UseCaseHealthPackageService } from 'src/app/core/rest-services/usecases/usecase-healthpackage.service';

@Component({
  selector: 'app-healthcare-provider',
  templateUrl: './healthcare-provider.component.html',
  styleUrls: ['./healthcare-provider.component.scss'],
})
export class HealthcareProviderComponent implements OnInit {
  @Input() referrerModule: any;
  payHealthPackageInitialState: PayHealthcarepackageInitialState = {
    come_with: 'MODULE-HEALTHCAREPROVIDER',
  };

  @Input() healthcareProvider!: IHealthcareProvider;

  isLoading = false;
  page = 1;
  pagination: PaginationElement = { page: 1, totalPages: 1 };
  currency: Currency;
  hcplocation: { lat: string; lng: string; name: string; content: string } = {
    lat: '0',
    lng: '0',
    name: '',
    content: '',
  };
  patientReviews = 0;
  currentRole!: IRole;
  groupType = GroupType;
  mdReached = false;
  healthProviderType = HealthProviderType;
  isDetails = false;
  isAvailableHb = true;
  isSpecialities = false;
  isLocation = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private navigationService: CustomNavigationService,
    private service: ServicesService,
    private translate: TranslateService,
    private hcmUsecaseService: UseCaseHealthPackageService,
    @Inject(MAT_DIALOG_DATA)
    public data: { healthcareProvider: IHealthcareProvider; caller: string },
    public dialogRef: MatDialogRef<HealthcareProviderComponent>
  ) {
    this.currency = service.sessionService.getCurrencie();
    this.currentRole = this.service.sessionService.getCurrentRole();
    this.breakpointObserver.observe('(min-width: 815px)').subscribe(state => {
      if (state.matches) {
        this.mdReached = true;
      } else {
        this.mdReached = false;
      }
    });
  }

  ngOnInit(): void {
    this.healthcareProvider = this.data.healthcareProvider;
    this.hcplocation = {
      lat: this.healthcareProvider.adresse?.latitude,
      lng: this.healthcareProvider.adresse?.longitude,
      name: this.healthcareProvider.name,
      content:
        this.translate.instant('common.monday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.monday ?? ' - ') +
        '<br />' +
        this.translate.instant('common.tuesday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.tuesday ?? ' - ') +
        '<br />' +
        this.translate.instant('common.wednesday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.wednesday ?? ' - ') +
        '<br />' +
        this.translate.instant('common.thursday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.thursday ?? ' - ') +
        '<br />' +
        this.translate.instant('common.friday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.friday ?? ' - ') +
        '<br />' +
        this.translate.instant('common.saturday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.saturday ?? ' - ') +
        '<br />' +
        this.translate.instant('common.sunday-label') +
        ' : ' +
        (this.healthcareProvider.data.hours_overture?.sunday ?? ' - '),
    };
  }

  goToWithState(event: any, hp: IHealthcarepackage): void {
    this.hcmUsecaseService.count_view_module(hp.pivot.id, 'by_hcp').subscribe();

    if (
      event.target.getAttribute('class') === null ||
      event.target.getAttribute('class').indexOf('stop-click-propagation') ===
        -1
    ) {
      this.dialogRef.close();
      this.payHealthPackageInitialState = {
        come_with: 'MODULE-HEALTHCAREPROVIDER',
        bundle: hp,
        healthcareprovider: this.healthcareProvider,
      };
      this.navigationService.goToWithState(
        this.currentRole.group.type === this.groupType.Kimbo
          ? '/kimbo/send-health-bundle'
          : '/benefit/send-health-bundle',
        this.payHealthPackageInitialState
      );
    }
  }

  hasOpeningHours(): boolean {
    const days = this.healthcareProvider?.data?.hours_overture;

    if (!days) {
      return false;
    }

    return Object.values(days).some(day => day && day !== '-');
  }
}
