import { InvoiceStore } from '../../models/Invoice';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content, Invoice } from '../../models/models';

@Injectable()
export class InvoiceService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * List all Invoices
   * @param page
   */
  public index(
    page: number,
    search: string,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<Content<Invoice>> {
    let queryParameters = new HttpParams({
      fromObject: {
        page: page,
        search: search,
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });
    return this.http.get<Content<Invoice>>(
      `${this.basePath}/v1/resources/invoice`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param invoiceId
   */
  public view(invoiceId: number): Observable<Invoice> {
    return this.http.get<Invoice>(
      `${this.basePath}/v1/resources/invoice/${invoiceId}`,
      this.httpOptions
    );
  }

  public update(id: number, body: InvoiceStore) {
    return this.http.put<InvoiceStore>(
      `${this.basePath}/v1/resources/invoice/${id}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }
}
