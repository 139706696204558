import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AdminLog,
  Alert,
  AlertDatas,
  CountRecords,
  HomeDashoard,
  LogTarget,
} from '../../models/AdminMonitoring';
import { Content } from '../../models/Common';
import {
  AdminLogsTarget,
  AlertStatus,
  BuyedpackageStatus,
  PaymentStatus,
} from '../../enums/enums';
import { ITransaction } from '../../models/Transaction';

@Injectable()
export class AdminMonitoringService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public countRecords(): Observable<CountRecords> {
    return this.http.get<any>(`${this.basePath}/v1/admin/ressource_count`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public homeDashoard(
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string,
    new_date = 2
  ): Observable<HomeDashoard> {
    return this.http.post<any>(
      `${this.basePath}/v1/admin-dashboard/home?new_date=${new_date}`,
      {
        period: period,
        ...(start_date && { start_date: start_date }),
        ...(end_date && { end_date: end_date }),
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getUsersDashboard(
    page: number,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string,
    per_page = 10
  ): Observable<Content<any>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(per_page != null && { per_page: per_page }),
      },
    });
    return this.http.post<any>(
      `${this.basePath}/v1/admin-dashboard/user`,
      {
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getHcps(
    page: number,
    country_id: number,
    per_page = 10
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        country_id: country_id,
        ...(per_page != null && { per_page: per_page }),
      },
    });
    return this.http.get<any>(`${this.basePath}/v1/admin-dashboard/hcp`, {
      params: queryParameters,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public getCampaings(
    page: number,
    status: 'payed' | 'waiting' | 'finished' | 'all' | 'trashed',
    per_page = 10
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(per_page != null && { per_page: per_page }),
        ...(status != null && { status: status }),
      },
    });
    return this.http.get<any>(`${this.basePath}/v1/admin-dashboard/campaign`, {
      params: queryParameters,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public getBuyedPackage(
    page: number,
    status: BuyedpackageStatus | 'all',
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string,
    per_page = 10
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(per_page != null && { per_page: per_page }),
      },
    });
    return this.http.post<any>(
      `${this.basePath}/v1/admin-dashboard/buyedpackage`,
      {
        period: period,
        status: status,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getTransactions(
    page: number,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    status: PaymentStatus | 'all',
    start_date?: string,
    end_date?: string,
    per_page = 10
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(per_page != null && { per_page: per_page }),
        ...(status != null && { status: status }),
      },
    });
    return this.http.post<any>(
      `${this.basePath}/v1/admin-dashboard/payment`,
      {
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getPatients(
    page: number,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string,
    per_page = 10
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(per_page != null && { per_page: per_page }),
      },
    });
    return this.http.post<any>(
      `${this.basePath}/v1/admin-dashboard/patients`,
      {
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public hcpView(
    hcpID: number,
    status: BuyedpackageStatus | 'all'
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(status != null && { status: status }),
      },
    });
    return this.http.get<any>(
      `${this.basePath}/v1/admin-dashboard/hcp/${hcpID}`,

      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  // ALERTS DASHBOARD

  public dashboardAlert(
    status: AlertStatus | 'all',
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<AlertDatas> {
    return this.http.post<AlertDatas>(
      `${this.basePath}/v1/admin-dashboard/alerts`,
      {
        period: period,
        status: status,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public updateAlert(
    status: AlertStatus | 'all',
    alertID: number
  ): Observable<Alert> {
    return this.http.post<Alert>(
      `${this.basePath}/v1/admin-dashboard/alert/${alertID}`,
      {
        status: status,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getAdminLogs(
    page: number,
    model: AdminLogsTarget,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'year',
    target_type?: AdminLogsTarget,
    target_id?: number,
    user_id?: number,
    per_page = 30
  ): Observable<Content<AdminLog>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        period: period,
        ...(per_page != null && { per_page: per_page }),
        ...(model != null && { model: model }),
        ...(target_id != null && { target_id: target_id }),
        ...(target_type != null && { target_type: target_type }),
        ...(user_id != null && { user_id: user_id }),
      },
    });
    return this.http.get<Content<AdminLog>>(
      `${this.basePath}/v1/admin/list_admin_logs`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getWalletTransaction(
    page: number,
    search: string = '',
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<Content<ITransaction>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        period: period,
        search: search,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });
    return this.http.get<Content<ITransaction>>(
      `${this.basePath}/v1/admin/list-transactions`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
