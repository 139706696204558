import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.svg',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() classNames = '';
  @Input() properties =
    'text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-bluess';
  @Input() shape: '_CLASSIC' | '_NEW' = '_CLASSIC';

  constructor() {}

  ngOnInit(): void {}
}
