import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  ConversationnableType,
  ConversationTargetType,
} from 'src/app/core/enums/enums';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import {
  ConversationableStore,
  CreateConversationStore,
  IConversationable,
  IMessage,
  IMessageStore,
} from 'src/app/core/models/Conversation';
import { Content, IRole } from 'src/app/core/models/models';
import { ChatService } from 'src/app/core/rest-services/chat.service';
import { BuyedPackageService } from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { groupByDateLikeConversationFlow } from 'src/app/core/utils/misc';
import { PaginationElement } from 'src/app/core/utils/type';

@Component({
  selector: 'app-conversation-channel',
  templateUrl: './conversation-channel.component.html',
  styleUrls: ['./conversation-channel.component.scss'],
})
export class ConversationChannelComponent implements OnInit, AfterViewInit {
  @ViewChild('chatdiv', { static: false }) chatdiv!: ElementRef;
  @Input() readOnly = false;
  @Input() caller: 'HCP' | 'KIMBO' = 'HCP';
  @Input() buyedPackage!: IBuyedpackage;
  @Input() consversationableType = ConversationnableType.BUYEDPACKAGE;
  isCreatingConversation = false;
  isLoading = false;
  isSendingMessage = false;
  isAddingNewData = false;
  message: IMessageStore = {
    text: '',
    group_id: 0,
    conversationnable_type: ConversationnableType.BUYEDPACKAGE,
    conversationnable_id: 0,
  };
  currentRole: IRole;
  page = 1;
  pagination: PaginationElement = { page: 1, totalPages: 1 };
  messages: IMessage[] = [];
  groupedDatas!: {
    [key: string]: IMessage[];
  };
  _moment = moment;
  currentLang!: string;
  isOpenConversation = false;

  constructor(
    private services: ServicesService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    public translateService: TranslateService,
    private buyedPackageService: BuyedPackageService
  ) {
    this.currentRole = this.services.sessionService.getCurrentRole();
    this.message.group_id = this.currentRole.group.id;
    this.message.conversationnable_type = ConversationnableType.GROUP;

    this.message.conversationnable_id = this.currentRole.group.id;
    this._moment.locale(this.translateService.currentLang);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.chatdiv.nativeElement.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }

  ngOnInit(): void {
    if (this.buyedPackage?.conversations?.length > 0) this.getMessages();
    this.currentLang = this.services.translateService.currentLang;
    this._moment.locale(this.currentLang);
  }

  closeConversationPannel(): void {
    this.services.eventService.publish('show-conversation:logs:hide');
  }

  openConversationFlow(): void {
    if (this.readOnly)
      this.services.eventService.publish(
        'show-conversation:logs',
        this.buyedPackage
      );
  }

  createConversation(): void {
    const conversation = new CreateConversationStore(
      [
        new ConversationableStore(
          ConversationnableType.GROUP,
          this.currentRole.group.id
        ),
        new ConversationableStore(
          ConversationnableType.GROUP,
          this.caller === 'KIMBO'
            ? this.buyedPackage.healthcareprovider?.group_id!
            : this.buyedPackage?.group_id
        ),
      ],
      ConversationTargetType.BUYEDPACKAGE,
      this.consversationableType === ConversationnableType.BUYEDPACKAGE
        ? this.buyedPackage.id
        : 0, //WILL CHANGE THIS WHEN CONVERSATIONNABLE WILL HOLD MANY VALUES
      this.consversationableType
    );
    this.isCreatingConversation = true;
    this.chatService
      .createConversation(conversation, this.buyedPackage.id)
      .subscribe({
        next: (bp: IBuyedpackage) => {
          this.notificationService.success(
            this.translateService.instant(
              'common.conversation-open-successfully'
            )
          );
          this.buyedPackage = bp;
          this.services.eventService.publish(
            'show-conversation:logs',
            this.buyedPackage
          );
        },
        error: () => {
          this.notificationService.danger(
            this.translateService.instant('errors.generic')
          );
          this.isCreatingConversation = false;
        },
        complete: () => (this.isCreatingConversation = false),
      });
  }

  sendMessage(): void {
    this.isSendingMessage = true;
    this.chatService
      .sendMessage(this.message, this.buyedPackage.conversations[0].id)
      .subscribe({
        next: (content: Content<IMessage>) => {
          this.page = 1;
          this.messages = [];
          this.messages = [...this.messages, ...content.data];
          this.pagination = {
            page: this.page,
            totalPages: content.last_page,
            total: content.total,
            perPage: content.per_page,
            form: content.from,
            to: content.to,
          };
          this.groupedDatas = this._groupByDateLikeConversationFlow();
          this.message.text = '';
          setTimeout(() => {
            this.chatdiv.nativeElement.scroll({
              top: document.body.scrollHeight,
              left: 0,
              behavior: 'smooth',
            });
          }, 300);
        },
        error: () => {
          this.notificationService.danger(
            this.translateService.instant('errors.generic')
          );
          this.isSendingMessage = false;
        },
        complete: () => {
          this.isSendingMessage = false;
        },
      });
  }

  identify(index: number, item: IMessage) {
    return item.id;
  }

  getConversation(): void {
    this.isLoading = true;
    this.chatService
      .getConversation(this.buyedPackage.conversations[0].id)
      .subscribe({
        next: (conversation: IConversationable) => {
          // console.log(conversation);
        },
        error: () => {
          this.notificationService.danger(
            this.translateService.instant('errors.generic')
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  getMessages(isSetPage = false): void {
    if (isSetPage) this.isAddingNewData = isSetPage;
    else this.isLoading = true;
    this.chatService
      .getMessages(
        this.buyedPackage.conversations[0].id,
        this.page,
        this.caller === 'KIMBO'
          ? ConversationnableType.GROUP
          : ConversationnableType.GROUP,
        this.currentRole.group.id
      )
      .subscribe({
        next: (content: Content<IMessage>) => {
          this.messages = [...this.messages, ...content.data];
          this.pagination = {
            page: this.page,
            totalPages: content.last_page,
            total: content.total,
            perPage: content.per_page,
            form: content.from,
            to: content.to,
          };
          this.groupedDatas = this._groupByDateLikeConversationFlow();
        },
        error: () => {
          // console.log('Failed to fetch messages.');
          this.isAddingNewData = false;
          this.isLoading = false;
        },
        complete: () => {
          this.isAddingNewData = false;
          this.isLoading = false;
        },
      });
  }

  getGroupedKeys(): string[] {
    return Object.keys(this.groupedDatas);
  }

  _groupByDateLikeConversationFlow(): {
    [key: string]: IMessage[];
  } {
    return groupByDateLikeConversationFlow(
      this.messages.reverse(),
      this.services.translateService.currentLang.toLocaleLowerCase() as
        | 'fr'
        | 'en'
    );
  }

  handleSpeechTotextEvent(event: any): void {
    this.message.text = event;
  }

  openConverstionPanel(): void {
    this.services.eventService.publish(
      'show-conversation:logs',
      this.buyedPackage
    );
  }

  onScroll(): void {
    this.page++;
    if (this.page <= this.pagination.totalPages) {
      this.getMessages(true);
    }
  }

  openConversationPanel(): void {
    this.isOpenConversation = false;
    this.buyedPackageService.view(this.buyedPackage.id).subscribe({
      next: (bp: IBuyedpackage) => {
        this.isOpenConversation = false;
        this.buyedPackage.new_message = false;
        this.services.eventService.publish('show-conversation:logs', bp);
      },
      error: () => {
        this.isOpenConversation = false;
      },
      complete: () => {
        this.isOpenConversation = false;
      },
    });
  }
}
