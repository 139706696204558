import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-title-custom',
  templateUrl: './title-custom.component.html',
  styleUrls: ['./title-custom.component.scss'],
})
export class TitleCustomComponent {
  @Input() classNames = '';
  @Input() icon!: string;
  @Input() withArrow = false;
  @Input() isOpen = false;
  @Input() bgColor = 'bg-corporategreen'; //Only tailwind colors
  @Input() caller:
    | '_GOBAL'
    | '_BENEFIT'
    | '_WITHOUTDROPDOWN'
    | '_GOBAL_TRANS_BG' = '_GOBAL';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
