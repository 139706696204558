<!-- eslint-disable @angular-eslint/template/alt-text -->
<h2 class="text-navy" mat-dialog-title>
  <span>
    {{ 'common.pending-members-list' | translate }}
  </span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="close icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="relative">
  <div class="w-full flex flex-col justify-center items-start px-0 py-4">
    <div
      *ngFor="let member of members; let i = index"
      class="flex rounded-lg w-full bg-white text-navy mb-4 justify-between items-center shadow">
      <div class="flex py-4 px-3 w-full justify-between items-center">
        <div class="flex justify-start items-center w-9/12">
          <img
            src="assets/svg/user.svg"
            class="flex-shrink-0 w-10 h-10 p-2 object-cover rounded-full border-2 border-kimbo-blue"
            alt="role image" />

          <div class="flex flex-col justify-center items-start gap-1">
            <span
              [matTooltip]="member?.name ?? ''"
              class="w-[200px] font-medium text-sm pl-4 overflow-hidden whitespace-nowrap text-ellipsis">
              {{ member.name }}
            </span>
            <span
              [matTooltip]="member.email ?? member.tel ?? ''"
              class="w-[200px] font-medium text-sm pl-4 overflow-hidden whitespace-nowrap text-ellipsis">
              {{ member.email ?? member.tel }}
            </span>
            <div class="pl-4">
              <span
                class="inline-block w-auto font-medium text-xs text-center rounded-full py-1 px-2 mr-1 bg-opacity-5 bg-kimbo-blue text-kimbo-blue">
                {{ 'kimbo-layout.member' | translate }}
              </span>
            </div>
          </div>
        </div>
        <button
          *ngIf="!isLoading || member.id !== currentMember?.id"
          mat-icon-button
          (click)="acceptInvitationMember(member)"
          [matTooltip]="'common.accept-invitation-tooltip' | translate">
          <i class="ri-check-double-line text-green text-xl"></i>
        </button>
        <button
          *ngIf="!isLoading || member.id !== currentMember?.id"
          mat-icon-button
          (click)="rejectInvitationMember(member)"
          [matTooltip]="'common.reject-invitation-tooltip' | translate">
          <i class="ri-close-line text-kimbo-ruby text-xl"></i>
        </button>
        <app-spinner
          *ngIf="isLoading && member.id === currentMember?.id"
          class="inline-block h-5 w-5 filter-blue mr-2"></app-spinner>
      </div>
    </div>
  </div>
</mat-dialog-content>
