import { BreakpointState } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';

@Component({
  selector: 'app-benefit-layout',
  templateUrl: './benefit-layout.component.html',
  styleUrls: ['./benefit-layout.component.scss'],
})
export class BenefitLayoutComponent {
  isSideBarOpen = false;
  breackpointMatche = false;

  constructor(public breakpointMatcherService: BreakpointMatcherService) {
    this.breakpointMatcherService
      .initObserver('2xl')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
          this.isSideBarOpen = true;
        } else {
          this.breackpointMatche = false;
          this.isSideBarOpen = false;
        }
      });
  }

  setIsSideBarOpen(isOpen: boolean): void {
    this.isSideBarOpen = isOpen;
  }
}
