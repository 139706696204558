import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { UserType } from '../enums/enums';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: SessionService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.authService.getUser() &&
      this.authService.getUser().user_type === UserType.Inactive
    ) {
      this.authService.clearStorage();
      // notifier
    }

    if (this.authService.getAccessToken()) {
      request = this.addToken(request, this.authService.getAccessToken());
    }

    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
