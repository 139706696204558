<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="p-1 relative">
  <div
    class="flex flex-col items-start mb-5 outline-none md:flex-row md:items-center sticky top-0 z-dropdown px-1">
    <app-search-bar
      (searchEvent)="search($event)"
      class="block w-full"></app-search-bar>
  </div>
  <div class="h-[360px] overflow-y-scroll px-1">
    <ul class="flex flex-wrap items-start justify-start">
      <p
        class="text-sm font-semibold pb-4 text-blue flex justify-start items-center">
        <i class="ri-hospital-line mr-1 text-lg"></i>
        {{ 'hcp-common.current-patient-status' | translate }}
      </p>
      <div class="w-full flex items-center justify-between mb-5">
        <div
          class="flex rounded-md py-2 px-4 w-full justify-between items-center transition-cus bg-kimbo-blue text-white">
          <div class="flex justify-start items-center w-10/12 gap-2">
            <i class="ri-bookmark-fill"></i>
            <span
              class="font-semibold text-sm pl-2 overflow-hidden whitespace-nowrap text-ellipsis"
              >{{ pickValue?.value }}</span
            >
          </div>
          <i
            class="ri-arrow-right-line text-xl p-1 text-kimbo-blue cursor-pointer pl-1"></i>
        </div>
      </div>
      <p
        class="text-sm font-semibold pb-2 text-blue flex justify-start items-center">
        <i class="ri-arrow-left-right-line mr-1 text-lg"></i>
        {{ 'hcp-common.change-patient-status' | translate }}
      </p>

      <div
        *ngFor="let status of statusListToRender"
        (click)="selectedOption(status)"
        class="w-full flex items-center justify-between mb-5 cursor-pointer">
        <div
          class="flex rounded-md py-2 px-4 w-full justify-between items-center transition-cus hover:bg-kimbo-blue hover:text-white bg-white hover:shadow-none shadow">
          <div class="flex justify-start items-center w-10/12 gap-2">
            <i class="ri-bookmark-line"></i>
            <span
              class="font-semibold text-sm pl-2 overflow-hidden whitespace-nowrap text-ellipsis"
              >{{ status.value }}</span
            >
          </div>
          <i class="ri-arrow-right-line text-xl p-1 cursor-pointer pl-1"></i>
        </div>
      </div>
    </ul>
  </div>
</div>
