<!--BUNDLE SKELETON-->
<div
  *ngIf="skeletonType === 'BUNDLE_LIST'"
  class="flex flex-wrap items-stretch justify-between mt-4 animate-pulse">
  <div
    *ngFor="let number of [].constructor(skeletonOfSameTypeToGenerate)"
    class="w-full mt-4 sm:w-1/2-with-gap lg:w-1/3-with-gap flex flex-col rounded-4xl h-full bg-white shadow-backdrop">
    <ng-container [ngTemplateOutlet]="bundleItem"></ng-container>
  </div>

  <!-- Add a ghost element so `justify-content: space-between` works correctly
       when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
  <div
    *ngIf="[].constructor(skeletonOfSameTypeToGenerate).length % 3 === 2"
    class="hidden lg:block lg:w-1/3-with-gap bg-gray-300"></div>
</div>

<div
  *ngIf="skeletonType === 'BUNDLE_ITEM'"
  class="w-full flex flex-col rounded-4xl h-full bg-white shadow-backdrop">
  <ng-container [ngTemplateOutlet]="bundleItem"></ng-container>
</div>

<!--END BUNDLE SKELETON-->

<!--HCP PATIENT SKELETON-->

<div
  *ngIf="skeletonType === 'HCP_PATIENT_LIST'"
  class="flex flex-wrap items-stretch justify-between mt-4 animate-pulse">
  <div
    *ngFor="let number of [].constructor(skeletonOfSameTypeToGenerate)"
    class="w-full mt-4 sm:w-1/2-with-gap lg:w-1/3-with-gap flex flex-col rounded-4xl h-full bg-white shadow-backdrop relative">
    <ng-container [ngTemplateOutlet]="patientItem"></ng-container>
  </div>

  <!-- Add a ghost element so `justify-content: space-between` works correctly
       when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
  <div
    *ngIf="[].constructor(skeletonOfSameTypeToGenerate).length % 3 === 2"
    class="hidden lg:block lg:w-1/3-with-gap bg-gray-300"></div>
</div>

<div
  *ngIf="skeletonType === 'HCP_PATIENT'"
  class="w-full flex flex-col rounded-4xl overflow-hidden shadow-backdrop h-full hover:shadow-inner-blue-sm transition-all relative">
  <ng-container [ngTemplateOutlet]="patientItem"></ng-container>
</div>

<!--END HCP PATIENT SKELETON-->

<!--HCP SKELETON-->

<div
  *ngIf="skeletonType === 'HCP_LIST'"
  class="flex flex-wrap items-stretch justify-between mt-4 animate-pulse">
  <div
    *ngFor="let number of [].constructor(skeletonOfSameTypeToGenerate)"
    class="w-full mt-4 sm:w-1/2-with-gap flex flex-col rounded-4xl h-full bg-white shadow-backdrop relative">
    <ng-container [ngTemplateOutlet]="hcpItem"></ng-container>
  </div>

  <!-- Add a ghost element so `justify-content: space-between` works correctly
       when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
  <div
    *ngIf="[].constructor(skeletonOfSameTypeToGenerate).length % 2 === 1"
    class="hidden lg:block sm:w-1/2-with-gap"></div>
</div>

<div
  *ngIf="skeletonType === 'HCP'"
  class="flex flex-col justify-between rounded-4xl h-full overflow-hidden shadow-backdrop transition-all">
  <ng-container [ngTemplateOutlet]="hcpItem"></ng-container>
</div>

<!--TREATMENT SKELETON-->

<div
  *ngIf="skeletonType === 'TREATMENTS_LIST'"
  class="flex flex-wrap items-stretch justify-between mt-4 animate-pulse">
  <div
    *ngFor="let number of [].constructor(skeletonOfSameTypeToGenerate)"
    class="w-full mt-4 sm:w-1/2-with-gap flex flex-col rounded-4xl h-full bg-white shadow-backdrop relative p-4 md:p-8">
    <ng-container [ngTemplateOutlet]="treatmentItem"></ng-container>
  </div>

  <!-- Add a ghost element so `justify-content: space-between` works correctly
       when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
  <div
    *ngIf="[].constructor(skeletonOfSameTypeToGenerate).length % 2 === 1"
    class="hidden lg:block sm:w-1/2-with-gap"></div>
</div>

<div
  *ngIf="skeletonType === 'TREATMENT_ITEM'"
  class="flex flex-col justify-between rounded-4xl h-full overflow-hidden shadow-backdrop transition-all p-4 md:p-8">
  <ng-container [ngTemplateOutlet]="treatmentItem"></ng-container>
</div>

<!--END TREATMENT SKELETON-->

<!--CAMPAIGN MODULES SKELETON-->

<div
  *ngIf="skeletonType === 'HCP_CAMPAIGN_MODULE_LIST'"
  class="flex flex-wrap items-stretch justify-between mt-4 animate-pulse">
  <div
    *ngFor="let number of [].constructor(skeletonOfSameTypeToGenerate)"
    class="w-full mt-4 rounded-4xl h-full bg-white shadow-backdrop relative p-4 md:p-8">
    <ng-container [ngTemplateOutlet]="campagneAssignation"></ng-container>
  </div>

  <!-- Add a ghost element so `justify-content: space-between` works correctly
       when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
  <div
    *ngIf="[].constructor(skeletonOfSameTypeToGenerate).length % 2 === 1"
    class="hidden lg:block sm:w-1/2-with-gap"></div>
</div>

<div
  *ngIf="skeletonType === 'HCP_CAMPAIGN_MODULE_ITEM'"
  class="w-full mt-4 rounded-4xl h-full bg-white shadow-backdrop relative p-4 md:p-8">
  <ng-container [ngTemplateOutlet]="campagneAssignation"></ng-container>
</div>

<!--END CAMPAIGN MODULES SKELETON-->

<!--KIMBO PATIENT SKELETON-->

<div
  *ngIf="skeletonType === 'KIMBO_PATIENT'"
  class="flex flex-wrap items-stretch justify-between mt-4 animate-pulse">
  <!-- Add a ghost element so `justify-content: space-between` works correctly
       when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
  <div
    *ngFor="let number of [].constructor(skeletonOfSameTypeToGenerate)"
    class="rounded-2xl shadow-backdrop md:rounded-4xl overflow-hidden mb-4 relative h-full transition-all justify-between flex flex-col w-full lg:w-1/2-with-gap">
    <span
      class="text-xs absolute left-8 top-4 px-2 h-4 rounded-full flex items-center justify-center gap-1">
      <app-skeleton-loader
        skeletonType="DETAILS-TEXT"
        size="w-10 h-4"></app-skeleton-loader>
    </span>
    <app-skeleton-loader
      skeletonType="DETAILS-TEXT"
      size="w-24 h-8"
      class="absolute right-5 z-base top-4"></app-skeleton-loader>

    <div
      class="px-8 pt-8 pb-2 mt-0 md:mt-4 flex justify-between items-center relative handle-click">
      <div
        class="w-full flex flex-col justify-center items-center gap-4 sm:flex-row sm:justify-start sm:gap-0 handle-click">
        <app-skeleton-loader
          skeletonType="CIRCLE"
          size="w-14 h-14"></app-skeleton-loader>
        <div
          class="sm:ml-4 flex flex-col items-center justify-center sm:block handle-click">
          <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
        </div>
      </div>
    </div>
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-full h-14"
      [withBorderRadius]="false"></app-skeleton-loader>

    <div
      class="flex w-full px-8 items-center justify-between bg-blue bg-opacity-10 h-12">
      <app-skeleton-loader
        skeletonType="DETAILS-TEXT"
        size="w-full h-3"></app-skeleton-loader>
      <app-skeleton-loader
        skeletonType="CARRE"
        size="w-4 h-4"></app-skeleton-loader>
    </div>
  </div>

  <div
    *ngIf="[].constructor(skeletonOfSameTypeToGenerate).length % 3 === 2"
    class="hidden lg:block lg:w-1/2-with-gap bg-gray-300"></div>
</div>

<ng-container
  *ngIf="skeletonType === 'KIMBO_PATIENT_ITEM'"
  [ngTemplateOutlet]="kimboPatientItem"></ng-container>

<!--END KIMBO PATIENT SKELETON-->

<!--START MESSAGE LIST SKELETON-->
<div
  *ngIf="skeletonType === 'MESSAGE_LIST'"
  class="w-full grid grid-cols-12 gap-y-2">
  <div class="col-start-1 col-end-12 p-3 rounded-lg">
    <div class="flex flex-row items-center">
      <app-skeleton-loader
        skeletonType="CIRCLE"
        size="h-10 w-10"></app-skeleton-loader>
      <div
        class="relative ml-3 text-sm bg-white shadow py-3 px-4 rounded-b-xl rounded-tr-xl">
        <div>
          <app-skeleton-loader
            skeletonType="DETAILS-TEXT"
            class="block w-[200px] h-3"
            size="w-full h-2"></app-skeleton-loader>
          <app-skeleton-loader
            skeletonType="DETAILS-TEXT"
            class="block w-[200px] h-3"
            size="w-full h-2"></app-skeleton-loader>
        </div>
        <div class="absolute text-xs bottom-1 -right-10 text-gray-500">
          <app-skeleton-loader
            skeletonType="RECTANGLE"
            size="w-8 h-2 block"></app-skeleton-loader>
        </div>
        <div class="absolute text-xs bottom-0 left-0 -mb-5 mr-2 text-gray-500">
          <app-skeleton-loader
            skeletonType="RECTANGLE"
            size="w-[128px] h-3 block"></app-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <div class="col-start-3 col-end-13 p-3 rounded-lg">
    <div class="flex items-center justify-start flex-row-reverse">
      <app-skeleton-loader
        skeletonType="CIRCLE"
        size="h-10 w-10"></app-skeleton-loader>

      <div
        class="relative mr-3 text-sm text-white bg-white shadow py-3 px-4 rounded-b-xl rounded-tl-xl">
        <app-skeleton-loader
          skeletonType="DETAILS-TEXT"
          class="block w-[200px] h-3"
          size="w-full h-2"></app-skeleton-loader>
        <app-skeleton-loader
          skeletonType="DETAILS-TEXT"
          class="block w-[200px] h-3"
          size="w-full h-2"></app-skeleton-loader>
        <div class="absolute text-xs bottom-1 -left-10 text-gray-500">
          <app-skeleton-loader
            skeletonType="RECTANGLE"
            size="w-8 h-2 block"></app-skeleton-loader>
        </div>
        <div class="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500">
          <app-skeleton-loader
            skeletonType="RECTANGLE"
            size="w-[128px] h-3 block"></app-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
<!--END MESSAGE LIST SKELETON-->

<ng-template #campagneAssignation>
  <div
    class="flex flex-col items-start justify-between lg:flex-row lg:items-center">
    <div class="flex flex-col">
      <div class="flex items-center justify-start gap-2">
        <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
        <app-skeleton-loader
          skeletonType="RECTANGLE"
          size="w-8 h-8"></app-skeleton-loader>
      </div>
      <div class="flex mt-2">
        <app-skeleton-loader
          skeletonType="RECTANGLE"
          size="w-14 h-4"></app-skeleton-loader>
      </div>
    </div>
    <div
      class="flex flex-col w-full mt-4 items-end xs:items-center xs:justify-between xs:flex-row lg:mt-0 lg:w-auto">
      <app-skeleton-loader
        skeletonType="RECTANGLE"
        size="w-28 h-12"></app-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #treatmentItem>
  <div class="w-full flex flex-col xs:flex-row gap-2">
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-full xs:w-14 h-20 xs:h-14"></app-skeleton-loader>
    <div class="xs:ml-4">
      <h3 class="text-lg font-bold text-navy flex flex-col items-start">
        <app-skeleton-loader
          skeletonType="TITLE"
          class="mr-3 block"></app-skeleton-loader>
      </h3>
      <div class="my-3">
        <div class="flex mt-2 items-center mb-4">
          <app-skeleton-loader
            skeletonType="RECTANGLE"
            size="w-6 h-6 mr-1 block"></app-skeleton-loader>
          <app-skeleton-loader
            skeletonType="TITLE"
            class="xs:ml-4 mr-3"></app-skeleton-loader>
        </div>
        <div class="flex mt-2">
          <div class="text-sm font-normal">
            <app-skeleton-loader
              skeletonType="RECTANGLE"
              size="w-14 h-4"></app-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #bundleItem>
  <div class="flex-grow p-6 md:p-8 relative">
    <div class="flex items-center justify-between">
      <div
        class="h-14 w-14 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
      <div class="w-1/3 h-2.5 bg-gray-300 rounded-full dark:bg-gray-700"></div>
    </div>
    <div role="status">
      <div
        class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
      <div
        class="h-2.5 mx-auto bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5 max-w-[540px]"></div>
      <div class="flex justify-between w-9/12 gap-2 mx-auto">
        <div
          class="w-1/2 h-2.5 mx-auto bg-gray-300 rounded-full dark:bg-gray-700"></div>
        <div
          class="w-1/2 h-2.5 mx-auto bg-gray-300 rounded-full dark:bg-gray-700"></div>
      </div>

      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #patientItem>
  <div class="flex flex-col items-center justify-start pt-3 h-[70%] gap-3">
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-36 h-5"></app-skeleton-loader>
    <div class="flex flex-col items-center justify-center w-full">
      <app-skeleton-loader
        skeletonType="CIRCLE"
        size="h-16 w-16"></app-skeleton-loader>

      <div class="my-4 mx-auto">
        <h3 class="text-lg font-bold text-navy w-full text-center">
          <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
        </h3>
      </div>
    </div>
  </div>
  <div
    class="flex items-center justify-between px-7 py-4 text-center rounded-4xl overflow-hidden">
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-28 h-10"></app-skeleton-loader>
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-28 h-10"></app-skeleton-loader>
  </div>
</ng-template>

<ng-template #hcpItem>
  <div class="flex flex-col md:flex-row gap-4 px-6 pt-6">
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-[90px] h-[90px]"></app-skeleton-loader>

    <div class="md:px-6">
      <div class="text-xl font-bold">
        <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
      </div>
      <div class="mt-2 text-dark-gray">
        <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
      </div>
      <app-skeleton-loader
        skeletonType="RECTANGLE"
        size="w-28 h-5 mt-4"></app-skeleton-loader>
    </div>
  </div>
  <div
    class="flex flex-col items-center justify-center h-20 rounded-4xl text-center overflow-hidden">
    <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
  </div>
</ng-template>

<ng-template #kimboPatientItem>
  <div
    class="rounded-2xl shadow-backdrop md:rounded-4xl overflow-hidden mb-4 relative h-full transition-all justify-between flex flex-col w-full">
    <span
      class="text-xs absolute left-8 top-4 px-2 h-4 rounded-full flex items-center justify-center gap-1">
      <app-skeleton-loader
        skeletonType="DETAILS-TEXT"
        size="w-10 h-4"></app-skeleton-loader>
    </span>
    <app-skeleton-loader
      skeletonType="DETAILS-TEXT"
      size="w-24 h-8"
      class="absolute right-5 z-base top-4"></app-skeleton-loader>

    <div
      class="px-8 pt-8 pb-2 mt-0 md:mt-4 flex justify-between items-center relative handle-click">
      <div
        class="w-full flex flex-col justify-center items-center gap-4 sm:flex-row sm:justify-start sm:gap-0 handle-click">
        <app-skeleton-loader
          skeletonType="CIRCLE"
          size="w-14 h-14"></app-skeleton-loader>
        <div
          class="sm:ml-4 flex flex-col items-center justify-center sm:block handle-click">
          <app-skeleton-loader skeletonType="TITLE"></app-skeleton-loader>
        </div>
      </div>
    </div>
    <app-skeleton-loader
      skeletonType="RECTANGLE"
      size="w-full h-14"
      [withBorderRadius]="false"></app-skeleton-loader>

    <div
      class="flex w-full px-8 items-center justify-between bg-blue bg-opacity-10 h-12">
      <app-skeleton-loader
        skeletonType="DETAILS-TEXT"
        size="w-full h-3"></app-skeleton-loader>
      <app-skeleton-loader
        skeletonType="CARRE"
        size="w-4 h-4"></app-skeleton-loader>
    </div>
  </div>
</ng-template>

<!--END KIMBO PATIENT SKELETON-->

<div
  *ngIf="skeletonType === 'RECTANGLE'"
  class="animate-pulse {{ withBorderRadius ? 'rounded-xl' : '' }}  {{
    size
  }} bg-gray-300 dark:bg-gray-700"></div>

<div
  *ngIf="skeletonType === 'CIRCLE'"
  class="animate-pulse {{
    size
  }} bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>

<div
  *ngIf="skeletonType === 'TITLE'"
  class="min-w-[200px] w-6/12 h-4 bg-gray-300 rounded-full dark:bg-gray-700"></div>

<div
  *ngIf="skeletonType === 'DETAILS-TEXT'"
  class="{{ size }} bg-gray-300 rounded-full dark:bg-gray-700"></div>
