<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div
  *ngIf="variant === '_DROPDOWN' || variant === '_MENU'"
  class="flex items-center w-full md:w-fit
{{ classNames }}"
  appClickOutside
  (clickOutside)="clickedOutside()">
  <div
    *ngIf="isOpen && backdrop"
    class="fixed z-modal-backdrop inset-0 bg-blue opacity-50"
    (click)="close()"></div>

  <div class="relative w-full md:w-fit">
    <div
      class="cursor-pointer w-full {{
        caller === '_CORPORATE'
          ? 'md:w-60 bg-white px-2 rounded-md py-2'
          : caller === '_BENEFIT'
          ? 'bg-blue bg-opacity-5 px-2 rounded-md py-2'
          : variant === '_MENU'
          ? 'bg-black bg-opacity-20 w-full h-[56px] px-6 flex items-center justify-center'
          : 'bg-blue bg-opacity-5 px-2 rounded-md py-2'
      }}  "
      (click)="openRoleModal()">
      <ng-container [ngTemplateOutlet]="filterDropdown"></ng-container>
    </div>
  </div>
</div>

<div *ngIf="variant === '_MODAL'" class="w-full">
  <span
    (click)="openRoleModal()"
    class="w-full flex items-center justify-between p-3 -mt-2 text-sm cursor-pointer text-gray-600 transition-colors duration-300 transform hover:bg-gray-100">
    <div class="flex justify-start gap-2 items-center">
      <app-avatar
        class="w-9 h-9 cursor-pointer flex-shrink"
        classNames="w-9 h-9"
        [withFrame]="false"
        caller="_ROLE_SELECT"
        [src]="pickValue?.src!"
        [withPreview]="false"></app-avatar>
      <h1
        class="text-sm font-semibold text-gray-700 overflow-hidden whitespace-nowrap overflow-ellipsis">
        {{ pickValue?.label }}
      </h1>
    </div>
    <p class="text-sm text-gray-500 dark:text-gray-400">
      <i class="ri-arrow-left-right-line text-lg"></i>
    </p>
  </span>
</div>

<ng-template #filterDropdown>
  <div
    class="w-full flex items-center gap-3 {{
      variant === '_MENU' ? 'justify-around' : 'justify-between'
    }} blank-image overflow-hidden">
    <app-avatar
      class="{{
        variant === '_MENU' ? 'h-8 w-8 flex-shrink-0' : 'h-10 w-10'
      }} cursor-pointer"
      classNames="{{ variant === '_MENU' ? 'h-8 w-8' : 'h-10 w-10' }}"
      [withFrame]="false"
      caller="_ROLE_SELECT"
      [src]="pickValue?.src!"
      [withPreview]="false"></app-avatar>

    <div
      class="flex-grow w-[185px] flex items-center justify-center uppercase flex-col {{
        variant === '_MENU' && caller !== '_BENEFIT'
          ? 'text-white'
          : 'text-blue font-semibold'
      }} overflow-hidden">
      <span
        class="{{
          caller === '_CORPORATE'
            ? 'text-xs w-[120px]'
            : variant === '_MENU'
            ? 'text-[14px] w-full'
            : 'text-sm w-full'
        }} text-center overflow-hidden whitespace-nowrap text-ellipsis">
        {{ pickValue?.label }}
      </span>
    </div>

    <i
      class="ri-arrow-left-right-line text-xl p-1 {{
        caller !== '_BENEFIT'
          ? 'text-white md:text-kimbo-blue'
          : 'text-kimbo-blue'
      }} cursor-pointer flex-shrink-0 ml-3"></i>
  </div>
</ng-template>
