import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { OtpLimitModalComponent } from '../otp-limit-modal/otp-limit-modal.component';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { UseCaseTotpService } from 'src/app/core/rest-services/rest-services';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IUser } from 'src/app/core/models/models';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-totp',
  templateUrl: './totp.component.html',
  styleUrls: ['./totp.component.scss'],
})
export class TotpComponent implements OnInit {
  input_cls = INPUT_CLASSES;
  invalid = false;
  disabled = false;
  twoFaCode: string = '';
  isLoading = false;
  user!: IUser;
  isClosable = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { qr_code?: string; mode: 'FULL' | 'VERIFICATION' },
    public dialogRef: MatDialogRef<OtpLimitModalComponent>,
    public totpService: UseCaseTotpService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
  }

  close(): void {
    this.dialogRef.close();
  }

  enable2FA() {
    this.isLoading = true;
    if (this.data.mode === 'FULL') {
      this.totpService.enable2FA(this.twoFaCode).subscribe({
        next: () => {
          this.notificationService.success(
            this.translateService.instant('common.totp_success')
          );
          this.close();
          this.isLoading = false;
        },
        error: (error: any) => {
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    } else {
      this.check2FA();
    }
  }

  verify() {
    if (this.twoFaCode.length == 6) {
      this.enable2FA();
    }
  }

  check2FA() {
    this.totpService.check2FA(this.twoFaCode).subscribe({
      next: () => {
        this.notificationService.success(
          this.translateService.instant('common.totp_verification_success')
        );
        this.dialogRef.close();
        this.isLoading = false;
      },
      error: (error: any) => {
        this.notificationService.danger(
          this.translateService.instant(error.translate)
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
