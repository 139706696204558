import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VisibilityStateService } from 'src/app/core/services/services';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit {
  @Input() classNames = '';
  @Input() htmlFor!: string;
  @Input() label!: string;
  @Input() error!: string | undefined;
  @Input() infos!: string;
  @Input() color?: string;
  @Input() optional = false;
  info = false;
  visibilitySubscription?: Subscription;
  constructor(private visibilityState: VisibilityStateService) {}

  ngOnInit(): void {
    this.subcriptions();
  }

  subcriptions(): void {
    this.visibilitySubscription = this.visibilityState
      .getVisibility()
      .subscribe(visibility => {
        this.info = visibility;
      });
  }

  toggleVisibility(): void {
    if (this.info) this.visibilityState.hide();
    else this.visibilityState.show();
  }

  hide(): void {
    this.visibilityState.hide();
  }

  show(): void {
    this.visibilityState.show();
  }
}
