import { TranslateService } from '@ngx-translate/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { TransactionService } from 'src/app/core/rest-services/rest-services';
import { ITransaction } from 'src/app/core/models/Transaction';
import { PaymentStatus } from 'src/app/core/enums/enums';
import { Currency } from 'src/app/core/models/Common';
import { IRole } from 'src/app/core/models/User';
import { SessionService } from 'src/app/core/services/session.service';
import { RoleService } from 'src/app/core/services/role.service';
import { toHealthCredit, toMoney } from 'src/app/core/utils/misc';

@Component({
  selector: 'app-payment-waiting-info',
  templateUrl: './payment-waiting-info.component.html',
  styleUrls: ['./payment-waiting-info.component.scss'],
})
export class PaymentWaitingInfoComponent implements OnDestroy, OnInit {
  isLoading = false;
  intervalID: any;
  currentRole: IRole;
  userCurrency: Currency;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bankInfos: any;
      stripeInfos: any;
      id: number;
      totalPrice: number;
      callerTo: 'TOPUP' | 'BUY';
      currentRole: IRole;
      currency: Currency;
      isMobile: boolean;
    },
    public dialogRef: MatDialogRef<PaymentWaitingInfoComponent>,
    private transactionService: TransactionService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private roleService: RoleService
  ) {
    this.userCurrency = this.data.currency
      ? this.data.currency
      : this.sessionService.getCurrencie();
    this.currentRole = this.data.currentRole
      ? this.data.currentRole
      : this.sessionService.getCurrentRole();
  }

  ngOnInit(): void {
    if (this.data.isMobile) this.paymentStatusObserver();
  }

  ngOnDestroy(): void {
    if (this.intervalID) clearInterval(this.intervalID);
  }

  paymentStatusObserver(): void {
    this.isLoading = true;
    this.intervalID = setInterval(() => {
      this.fetchPaymentStatus();
    }, 3000);
  }

  fetchPaymentStatus = () => {
    this.transactionService.view(this.data.id).subscribe({
      next: (res: ITransaction) => {
        if (res.status === PaymentStatus.Payed) {
          clearInterval(this.intervalID);
          this.isLoading = false;
          this.dialogRef.close('payment_done');
        } else if (res.status === PaymentStatus.Cancelled) {
          clearInterval(this.intervalID);
          this.isLoading = false;
          this.dialogRef.close('payment_cancelled');
        }
      },
      error: error => {
        this.isLoading = false;
      },
      complete: () => {},
    });
  };

  walletIsEmpty(): boolean {
    return Number(this.currentRole.group.health_credit_amount) === 0;
  }

  _toMoney(hc: number, rate: number) {
    return toMoney(hc, rate);
  }

  _toHealthCredit(amount: number) {
    return toHealthCredit(amount, this.userCurrency.rate);
  }

  toNumber(e: string | undefined): number {
    return Number(e);
  }

  close() {
    this.dialogRef.close();
  }
}
