import { KimboCareStation } from './type';

export const kimboCareStation: KimboCareStation[] = [
  {
    id: '_CAMEROUN',
    name: 'Cameroun',
    lat: 7.365302,
    lng: 12.343439499999988,
  },
  {
    id: '_COTE_D_IVOIRE',
    name: "Côte d'Ivoire",
    lat: 7.5468545,
    lng: -5.547099500000002,
  },
  {
    id: '_KENYA',
    name: 'Kenya',
    lat: 0.1709454999999993,
    lng: 37.90396850000002,
  },
];

export const MAPCOUNTRYCODEPHONECODE = [
  { countryCode: 'AF', phoneCode: '93' },
  { countryCode: 'AL', phoneCode: '355' },
  { countryCode: 'DZ', phoneCode: '213' },
  { countryCode: 'AS', phoneCode: '1' },
  { countryCode: 'AD', phoneCode: '376' },
  { countryCode: 'AO', phoneCode: '244' },
  { countryCode: 'AI', phoneCode: '1' },
  { countryCode: 'AG', phoneCode: '1' },
  { countryCode: 'AR', phoneCode: '54' },
  { countryCode: 'AM', phoneCode: '374' },
  { countryCode: 'AW', phoneCode: '297' },
  { countryCode: 'AU', phoneCode: '61' },
  { countryCode: 'AT', phoneCode: '43' },
  { countryCode: 'AZ', phoneCode: '994' },
  { countryCode: 'BS', phoneCode: '1' },
  { countryCode: 'BH', phoneCode: '973' },
  { countryCode: 'BD', phoneCode: '880' },
  { countryCode: 'BB', phoneCode: '1' },
  { countryCode: 'BY', phoneCode: '375' },
  { countryCode: 'BE', phoneCode: '32' },
  { countryCode: 'BZ', phoneCode: '501' },
  { countryCode: 'BJ', phoneCode: '229' },
  { countryCode: 'BM', phoneCode: '1' },
  { countryCode: 'BT', phoneCode: '975' },
  { countryCode: 'BA', phoneCode: '387' },
  { countryCode: 'BW', phoneCode: '267' },
  { countryCode: 'BR', phoneCode: '55' },
  { countryCode: 'IO', phoneCode: '246' },
  { countryCode: 'BG', phoneCode: '359' },
  { countryCode: 'BF', phoneCode: '226' },
  { countryCode: 'BI', phoneCode: '257' },
  { countryCode: 'KH', phoneCode: '855' },
  { countryCode: 'CM', phoneCode: '237' },
  { countryCode: 'CA', phoneCode: '1' },
  { countryCode: 'CV', phoneCode: '238' },
  { countryCode: 'KY', phoneCode: '345' },
  { countryCode: 'CF', phoneCode: '236' },
  { countryCode: 'TD', phoneCode: '235' },
  { countryCode: 'CL', phoneCode: '56' },
  { countryCode: 'CN', phoneCode: '86' },
  { countryCode: 'CX', phoneCode: '61' },
  { countryCode: 'CO', phoneCode: '57' },
  { countryCode: 'KM', phoneCode: '269' },
  { countryCode: 'CG', phoneCode: '242' },
  { countryCode: 'CK', phoneCode: '682' },
  { countryCode: 'CR', phoneCode: '506' },
  { countryCode: 'HR', phoneCode: '385' },
  { countryCode: 'CU', phoneCode: '53' },
  { countryCode: 'CY', phoneCode: '537' },
  { countryCode: 'CZ', phoneCode: '420' },
  { countryCode: 'DK', phoneCode: '45' },
  { countryCode: 'DJ', phoneCode: '253' },
  { countryCode: 'DM', phoneCode: '1' },
  { countryCode: 'DO', phoneCode: '1' },
  { countryCode: 'EC', phoneCode: '593' },
  { countryCode: 'EG', phoneCode: '20' },
  { countryCode: 'SV', phoneCode: '503' },
  { countryCode: 'GQ', phoneCode: '240' },
  { countryCode: 'ER', phoneCode: '291' },
  { countryCode: 'EE', phoneCode: '372' },
  { countryCode: 'ET', phoneCode: '251' },
  { countryCode: 'FO', phoneCode: '298' },
  { countryCode: 'FJ', phoneCode: '679' },
  { countryCode: 'FI', phoneCode: '358' },
  { countryCode: 'FR', phoneCode: '33' },
  { countryCode: 'GF', phoneCode: '594' },
  { countryCode: 'PF', phoneCode: '689' },
  { countryCode: 'GA', phoneCode: '241' },
  { countryCode: 'GM', phoneCode: '220' },
  { countryCode: 'GE', phoneCode: '995' },
  { countryCode: 'DE', phoneCode: '49' },
  { countryCode: 'GH', phoneCode: '233' },
  { countryCode: 'GI', phoneCode: '350' },
  { countryCode: 'GR', phoneCode: '30' },
  { countryCode: 'GL', phoneCode: '299' },
  { countryCode: 'GD', phoneCode: '1' },
  { countryCode: 'GP', phoneCode: '590' },
  { countryCode: 'GU', phoneCode: '1' },
  { countryCode: 'GT', phoneCode: '502' },
  { countryCode: 'GN', phoneCode: '224' },
  { countryCode: 'GW', phoneCode: '245' },
  { countryCode: 'GY', phoneCode: '595' },
  { countryCode: 'HT', phoneCode: '509' },
  { countryCode: 'HN', phoneCode: '504' },
  { countryCode: 'HU', phoneCode: '36' },
  { countryCode: 'IS', phoneCode: '354' },
  { countryCode: 'IN', phoneCode: '91' },
  { countryCode: 'ID', phoneCode: '62' },
  { countryCode: 'IQ', phoneCode: '964' },
  { countryCode: 'IE', phoneCode: '353' },
  { countryCode: 'IL', phoneCode: '972' },
  { countryCode: 'IT', phoneCode: '39' },
  { countryCode: 'JM', phoneCode: '1' },
  { countryCode: 'JP', phoneCode: '81' },
  { countryCode: 'JO', phoneCode: '962' },
  { countryCode: 'KZ', phoneCode: '77' },
  { countryCode: 'KE', phoneCode: '254' },
  { countryCode: 'KI', phoneCode: '686' },
  { countryCode: 'KW', phoneCode: '965' },
  { countryCode: 'KG', phoneCode: '996' },
  { countryCode: 'LV', phoneCode: '371' },
  { countryCode: 'LB', phoneCode: '961' },
  { countryCode: 'LS', phoneCode: '266' },
  { countryCode: 'LR', phoneCode: '231' },
  { countryCode: 'LI', phoneCode: '423' },
  { countryCode: 'LT', phoneCode: '370' },
  { countryCode: 'LU', phoneCode: '352' },
  { countryCode: 'MG', phoneCode: '261' },
  { countryCode: 'MW', phoneCode: '265' },
  { countryCode: 'MY', phoneCode: '60' },
  { countryCode: 'MV', phoneCode: '960' },
  { countryCode: 'ML', phoneCode: '223' },
  { countryCode: 'MT', phoneCode: '356' },
  { countryCode: 'MH', phoneCode: '692' },
  { countryCode: 'MQ', phoneCode: '596' },
  { countryCode: 'MR', phoneCode: '222' },
  { countryCode: 'MU', phoneCode: '230' },
  { countryCode: 'YT', phoneCode: '262' },
  { countryCode: 'MX', phoneCode: '52' },
  { countryCode: 'MC', phoneCode: '377' },
  { countryCode: 'MN', phoneCode: '976' },
  { countryCode: 'ME', phoneCode: '382' },
  { countryCode: 'MS', phoneCode: '1' },
  { countryCode: 'MA', phoneCode: '212' },
  { countryCode: 'MM', phoneCode: '95' },
  { countryCode: 'NA', phoneCode: '264' },
  { countryCode: 'NR', phoneCode: '674' },
  { countryCode: 'NP', phoneCode: '977' },
  { countryCode: 'NL', phoneCode: '31' },
  { countryCode: 'AN', phoneCode: '599' },
  { countryCode: 'NC', phoneCode: '687' },
  { countryCode: 'NZ', phoneCode: '64' },
  { countryCode: 'NI', phoneCode: '505' },
  { countryCode: 'NE', phoneCode: '227' },
  { countryCode: 'NG', phoneCode: '234' },
  { countryCode: 'NU', phoneCode: '683' },
  { countryCode: 'NF', phoneCode: '672' },
  { countryCode: 'MP', phoneCode: '1' },
  { countryCode: 'NO', phoneCode: '47' },
  { countryCode: 'OM', phoneCode: '968' },
  { countryCode: 'PK', phoneCode: '92' },
  { countryCode: 'PW', phoneCode: '680' },
  { countryCode: 'PA', phoneCode: '507' },
  { countryCode: 'PG', phoneCode: '675' },
  { countryCode: 'PY', phoneCode: '595' },
  { countryCode: 'PE', phoneCode: '51' },
  { countryCode: 'PH', phoneCode: '63' },
  { countryCode: 'PL', phoneCode: '48' },
  { countryCode: 'PT', phoneCode: '351' },
  { countryCode: 'PR', phoneCode: '1' },
  { countryCode: 'QA', phoneCode: '974' },
  { countryCode: 'RO', phoneCode: '40' },
  { countryCode: 'RW', phoneCode: '250' },
  { countryCode: 'WS', phoneCode: '685' },
  { countryCode: 'SM', phoneCode: '378' },
  { countryCode: 'SA', phoneCode: '966' },
  { countryCode: 'SN', phoneCode: '221' },
  { countryCode: 'RS', phoneCode: '381' },
  { countryCode: 'SC', phoneCode: '248' },
  { countryCode: 'SL', phoneCode: '232' },
  { countryCode: 'SG', phoneCode: '65' },
  { countryCode: 'SK', phoneCode: '421' },
  { countryCode: 'SI', phoneCode: '386' },
  { countryCode: 'SB', phoneCode: '677' },
  { countryCode: 'ZA', phoneCode: '27' },
  { countryCode: 'GS', phoneCode: '500' },
  { countryCode: 'ES', phoneCode: '34' },
  { countryCode: 'LK', phoneCode: '94' },
  { countryCode: 'SD', phoneCode: '249' },
  { countryCode: 'SR', phoneCode: '597' },
  { countryCode: 'SZ', phoneCode: '268' },
  { countryCode: 'SE', phoneCode: '46' },
  { countryCode: 'CH', phoneCode: '41' },
  { countryCode: 'TJ', phoneCode: '992' },
  { countryCode: 'TH', phoneCode: '66' },
  { countryCode: 'TG', phoneCode: '228' },
  { countryCode: 'TK', phoneCode: '690' },
  { countryCode: 'TO', phoneCode: '676' },
  { countryCode: 'TT', phoneCode: '1' },
  { countryCode: 'TN', phoneCode: '216' },
  { countryCode: 'TR', phoneCode: '90' },
  { countryCode: 'TM', phoneCode: '993' },
  { countryCode: 'TC', phoneCode: '1' },
  { countryCode: 'TV', phoneCode: '688' },
  { countryCode: 'UG', phoneCode: '256' },
  { countryCode: 'UA', phoneCode: '380' },
  { countryCode: 'AE', phoneCode: '971' },
  { countryCode: 'GB', phoneCode: '44' },
  { countryCode: 'US', phoneCode: '1' },
  { countryCode: 'UY', phoneCode: '598' },
  { countryCode: 'UZ', phoneCode: '998' },
  { countryCode: 'VU', phoneCode: '678' },
  { countryCode: 'WF', phoneCode: '681' },
  { countryCode: 'YE', phoneCode: '967' },
  { countryCode: 'ZM', phoneCode: '260' },
  { countryCode: 'ZW', phoneCode: '263' },
  { countryCode: 'BO', phoneCode: '591' },
  { countryCode: 'BN', phoneCode: '673' },
  { countryCode: 'CC', phoneCode: '61' },
  { countryCode: 'CD', phoneCode: '243' },
  { countryCode: 'CI', phoneCode: '225' },
  { countryCode: 'FK', phoneCode: '500' },
  { countryCode: 'GG', phoneCode: '44' },
  { countryCode: 'VA', phoneCode: '379' },
  { countryCode: 'HK', phoneCode: '852' },
  { countryCode: 'IR', phoneCode: '98' },
  { countryCode: 'IM', phoneCode: '44' },
  { countryCode: 'JE', phoneCode: '44' },
  { countryCode: 'KP', phoneCode: '850' },
  { countryCode: 'KR', phoneCode: '82' },
  { countryCode: 'LA', phoneCode: '856' },
  { countryCode: 'LY', phoneCode: '218' },
  { countryCode: 'MO', phoneCode: '853' },
  { countryCode: 'MK', phoneCode: '389' },
  { countryCode: 'FM', phoneCode: '691' },
  { countryCode: 'MD', phoneCode: '373' },
  { countryCode: 'MZ', phoneCode: '258' },
  { countryCode: 'PS', phoneCode: '970' },
  { countryCode: 'PN', phoneCode: '872' },
  { countryCode: 'RE', phoneCode: '262' },
  { countryCode: 'RU', phoneCode: '7' },
  { countryCode: 'BL', phoneCode: '590' },
  { countryCode: 'SH', phoneCode: '290' },
  { countryCode: 'KN', phoneCode: '1' },
  { countryCode: 'LC', phoneCode: '1' },
  { countryCode: 'MF', phoneCode: '590' },
  { countryCode: 'PM', phoneCode: '508' },
  { countryCode: 'VC', phoneCode: '1' },
  { countryCode: 'ST', phoneCode: '239' },
  { countryCode: 'SO', phoneCode: '252' },
  { countryCode: 'SJ', phoneCode: '47' },
  { countryCode: 'SY', phoneCode: '963' },
  { countryCode: 'TW', phoneCode: '886' },
  { countryCode: 'TZ', phoneCode: '255' },
  { countryCode: 'TL', phoneCode: '670' },
  { countryCode: 'VE', phoneCode: '58' },
  { countryCode: 'VN', phoneCode: '84' },
  { countryCode: 'VG', phoneCode: '284' },
  { countryCode: 'VI', phoneCode: '340' },
];

export const MAPCOUNTRYCODECOUNTRY = [
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AL', name: 'Albania' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BR', name: 'Brazil' },
  { code: 'BN', name: 'Brunei Darussalam' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'CM', name: 'Cameroun' },
  { code: 'CV', name: 'Cape Verde' },
  { code: 'CF', name: 'Central African Republic' },
  { code: 'TD', name: 'Chad' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoros' },
  { code: 'CG', name: 'Congo' },
  { code: 'CD', name: 'Congo, RDC' },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'HR', name: 'Croatia' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypt' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estonia' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Greece' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IN', name: 'India' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IR', name: 'Iran' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'JP', name: 'Japan' },
  { code: 'JO', name: 'Jordan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KP', name: "Korea, Democratic People's Republic of Korea" },
  { code: 'KR', name: 'Korea, Republic of Korea' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'LA', name: "Lao People's Democratic Republic" },
  { code: 'LV', name: 'Latvia' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MO', name: 'Macao' },
  { code: 'MK', name: 'Macedonia, the Former Yugoslav Republic of Macedonia' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Maldives' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'MX', name: 'Mexico' },
  { code: 'FM', name: 'Micronesia, Federated States of Micronesia' },
  { code: 'MD', name: 'Moldova, Republic of Moldova' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NO', name: 'Norway' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PS', name: 'Palestine, State of Palestine' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Romania' },
  { code: 'RU', name: 'Russian Federation' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbia' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'ES', name: 'Spain' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SZ', name: 'Swaziland' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'SY', name: 'Syrian Arab Republic' },
  { code: 'TW', name: 'Taiwan, Province of China' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TZ', name: 'Tanzania, United Republic of Tanzania' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TG', name: 'Togo' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TR', name: 'Turkey' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VE', name: 'Venezuela, Bolivarian Republic of Venezuela' },
  { code: 'VN', name: 'Viet Nam' },
  { code: 'YE', name: 'Yemen' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' },
];
