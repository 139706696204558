<a
  class="flex items-center py-4 px-6 focus:outline-none md:text-center md:px-2 md:flex-col relative"
  [routerLink]="[location]"
  [state]="{ state: { filterPatient: false, dashBoardRedirection: false } }"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: true }">
  <div
    *ngIf="
      ((role === groupeType.Kimbo || role === groupeType.Benefit) &&
        !services.sessionService.isGuest() &&
        elementToCount.includes(location)) ||
      (role === groupeType.Hcp && location !== '/hcp/dashboard-hcp') ||
      (role === groupeType.Admin && elementToCount.includes(location))
    "
    class="absolute bg-white top-1 right-4 w-6 h-6 items-center justify-center flex rounded-full text-blue font-semibold pointer-events-none cursor-default">
    <div
      *ngIf="isLoading"
      class="w-full flex items-center justify-center h-full">
      <app-spinner class="inline-block h-full w-full"></app-spinner>
    </div>
    <span *ngIf="!isLoading && location === '/hcp'">{{
      incomming_buyedPackages
    }}</span>
    <span *ngIf="!isLoading && location === '/hcp/patient-in-treatment'">{{
      treatment_buyedPackages
    }}</span>
    <span *ngIf="!isLoading && location === '/hcp/patient-treated'">{{
      finished_buyedPackages
    }}</span>
    <span *ngIf="!isLoading && location === '/hcp/campaigns'">{{
      unuseBuyedPackages
    }}</span>
    <span *ngIf="!isLoading && location === '/admin'">{{
      parseToKNotation(count_records.users)
    }}</span>
    <span *ngIf="!isLoading && location === '/admin/healthcare-providers'">{{
      parseToKNotation(count_records.hcp_count)
    }}</span>
    <span *ngIf="!isLoading && location === '/admin/healthcare-package'">{{
      parseToKNotation(count_records.hcm)
    }}</span>
    <span *ngIf="!isLoading && location === '/admin/transactions'">{{
      parseToKNotation(count_records.transactions)
    }}</span>
    <span
      *ngIf="
        !isLoading &&
        (location === '/kimbo/my-beneficiary' ||
          location === '/benefit/employees')
      "
      >{{ parseToKNotation(contacts) }}</span
    >
  </div>
  <div class="circle-active-icon">
    <img
      class="w-6 h-6 fill-current"
      *ngIf="!iconPath.includes('.svg')"
      [src]="iconPath"
      alt="path" />
    <div
      class="w-6 h-6 fill-current"
      *ngIf="iconPath.includes('.svg')"
      [inlineSVG]="iconPath"></div>
  </div>

  <span class="ml-2 md:ml-0 md:mt-1"><ng-content></ng-content></span>
</a>
