import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupType } from 'src/app/core/enums/enums';
import { IGroup } from 'src/app/core/models/Group';
import { IRole, IUser } from 'src/app/core/models/User';
import { ServicesService } from 'src/app/core/services/services.service';
import {
  DEFAULT_IMAGE_BENEFIT,
  DEFAULT_IMAGE_CORPORATE,
  DEFAULT_IMAGE_HCP,
  DEFAULT_IMAGE_KIMBO,
} from 'src/app/core/utils/constant';
import { PreviewImageComponent } from '../preview-image/preview-image.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnDestroy {
  @Input() src!: string;
  @Input() classNames!: string;
  @Input() withFrame!: boolean;
  @Input() withPreview = true;
  @Input() caller:
    | '_OTHER'
    | '_PROFILE'
    | '_PROFILE_INNER'
    | '_ROLE_SELECT'
    | 'CAMPAIGN_ASSIGN' = '_OTHER';
  @Input() withEditSpeedDial!: boolean;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  source: string = '';
  imageStyle = '';
  $updateUser!: Subscription;
  $rolechange!: Subscription;
  $updateGroup!: Subscription;

  constructor(public services: ServicesService) {}

  ngOnDestroy(): void {
    if (this.$updateUser) this.$updateUser.unsubscribe();
    if (this.$rolechange) this.$rolechange.unsubscribe();
    if (this.$updateGroup) this.$updateGroup.unsubscribe();
  }

  ngOnInit(): void {
    this.source = this.src ?? 'assets/svg/rect-gray.svg';
    this.imageStyle =
      'background-image: url(' +
      this.source +
      '); background-size: cover; background-repeat: no-repeat; background-position: center;';
    if (this.caller === '_PROFILE') {
      this.$updateUser = this.services.eventService.subscribe(
        'user:update',
        (user: IUser) => {
          this.source =
            this.services.utilsService.getServerUrl(user.image_profil.path) ??
            'assets/svg/rect-gray.svg';
          this.imageStyle =
            'background-image: url(' +
            this.source +
            '); background-size: cover; background-repeat: no-repeat; background-position: center;';
        }
      );
    } else if (this.caller === '_ROLE_SELECT') {
      this.$rolechange = this.services.eventService.subscribe(
        'change:role',
        (role: IRole) => {
          this.source =
            role.group.name === GroupType.Admin
              ? 'assets/images/settings.png'
              : role.group.image_profil &&
                role.group.image_profil.path !== 'public/general/unknow.png' &&
                role.group.image_profil.path !== 'storage/general/group.png'
              ? this.services.utilsService.getServerUrl(
                  role.group.image_profil.path
                )
              : role.group.type === 'hcp'
              ? DEFAULT_IMAGE_HCP
              : role.group.type === 'company'
              ? DEFAULT_IMAGE_CORPORATE
              : role.group.type === GroupType.Benefit
              ? DEFAULT_IMAGE_BENEFIT
              : DEFAULT_IMAGE_KIMBO;

          this.imageStyle =
            'background-image: url(' +
            this.source +
            '); background-size: cover; background-repeat: no-repeat; background-position: center;';
        }
      );
      this.$updateGroup = this.services.eventService.subscribe(
        'group:update',
        (group: IGroup) => {
          let user = this.services.sessionService.getUser();
          let role = user.roles!.find(role => role.group_id == group.id)!;
          this.source =
            role.group.name === GroupType.Admin
              ? 'assets/images/settings.png'
              : role.group.image_profil &&
                role.group.image_profil.path !== 'public/general/unknow.png' &&
                role.group.image_profil.path !== 'storage/general/group.png'
              ? this.services.utilsService.getServerUrl(
                  role.group.image_profil.path
                )
              : role.group.type === 'hcp'
              ? DEFAULT_IMAGE_HCP
              : role.group.type === 'company'
              ? DEFAULT_IMAGE_CORPORATE
              : role.group.type === GroupType.Benefit
              ? DEFAULT_IMAGE_BENEFIT
              : DEFAULT_IMAGE_KIMBO;

          this.imageStyle =
            'background-image: url(' +
            this.source +
            '); background-size: cover; background-repeat: no-repeat; background-position: center;';
        }
      );
    }
  }

  handleSpeedDialAction(event: any) {
    this.triggerEvent.emit(event);
  }

  previewImage(): void {
    if (this.withPreview) {
      this.services.modalService.openModal(PreviewImageComponent, {
        width: '400px',
        height: 'auto',
        data: {
          image: this.source,
        },
      });
    }
  }
}
