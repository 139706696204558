import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Content,
  ITransaction,
  TransactionCommonResponse,
  TransactionStore,
} from '../../models/models';

@Injectable()
export class TransactionService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param transactionId
   */
  public delete(transactionId: number): Observable<TransactionCommonResponse> {
    return this.http.delete<TransactionCommonResponse>(
      `${this.basePath}/v1/resources/payment/${transactionId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: TransactionStore): Observable<TransactionCommonResponse> {
    return this.http.post<TransactionCommonResponse>(
      `${this.basePath}/v1/resources/payment`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param transactionId
   */
  public update(transactionId: number, body?: any): Observable<ITransaction> {
    return this.http.put<ITransaction>(
      `${this.basePath}/v1/resources/payment/${transactionId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Transactions
   * @param page
   */
  public index(
    page: number,
    search: string,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<Content<ITransaction>> {
    let queryParameters = new HttpParams({
      fromObject: {
        page: page,
        search: search,
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });
    return this.http.get<Content<ITransaction>>(
      `${this.basePath}/v1/resources/payment`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param transactionId
   */
  public view(transactionId: number): Observable<ITransaction> {
    return this.http.get<ITransaction>(
      `${this.basePath}/v1/resources/payment/${transactionId}`,
      this.httpOptions
    );
  }
}
