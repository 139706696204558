import { Data } from './Common';
import { Healthcarepackage } from './Healthcarepackage';

export interface IPayHealthcarepackage {
  healthcarepackages: Healthcarepackage[];
  patient_id: number;
  group_id: number;
  healthcareprovider_id: number;
  wallet_hc_amount: number;
  pay_hc_amount: number;
  method: string;
}

export interface IPayTopUp {
  group_id: number;
  method: string;
  pay_hc_amount: number;
}

export interface IPayCampaign {
  healthcarepackages: Healthcarepackage[];
  group_id: number;
  name_campaign: string;
  data_campaign: Data;
  wallet_hc_amount: number;
  pay_hc_amount: number;
  method: string;
  reference?: string;
  email_copy?: string;
}

export class PayHealthcarepackage implements IPayHealthcarepackage {
  healthcarepackages: any[] = [];
  patient_id: number;
  group_id: number;
  healthcareprovider_id: number;
  wallet_hc_amount: number;
  pay_hc_amount: number;
  method: string;
  currencie_id: number;

  constructor(
    healthcarepackages: any[],
    patient_id: number,
    group_id: number,
    healthcareprovider_id: number,
    wallet_hc_amount: number,
    pay_hc_amount: number,
    method: string,
    currencie_id: number
  ) {
    this.healthcarepackages = healthcarepackages;
    this.patient_id = patient_id;
    this.group_id = group_id;
    this.healthcareprovider_id = healthcareprovider_id;
    this.wallet_hc_amount = wallet_hc_amount;
    this.pay_hc_amount = pay_hc_amount;
    this.method = method;
    this.currencie_id = currencie_id;
  }
}

export class PayTopUp implements IPayTopUp {
  group_id: number;
  currencie_id: number;
  method: string;
  pay_hc_amount: number;

  constructor(
    group_id: number,
    currencie_id: number,
    method: string,
    pay_hc_amount: number
  ) {
    this.group_id = group_id;
    this.currencie_id = currencie_id;
    this.method = method;
    this.pay_hc_amount = pay_hc_amount;
  }
}

export class PayCampaign implements IPayCampaign {
  healthcarepackages: Healthcarepackage[];
  group_id: number;
  name_campaign: string;
  data_campaign: Data;
  wallet_hc_amount: number;
  pay_hc_amount: number;
  method: string;
  currencie_id: number;
  reference?: string;
  email_copy?: string;

  constructor(
    healthcarepackages: Healthcarepackage[],
    group_id: number,
    name_campaign: string,
    data_campaign: Data,
    wallet_hc_amount: number,
    pay_hc_amount: number,
    method: string,
    currencie_id: number,
    reference?: string,
    email_copy?: string
  ) {
    this.healthcarepackages = healthcarepackages;
    this.group_id = group_id;
    this.name_campaign = name_campaign;
    this.data_campaign = data_campaign;
    this.wallet_hc_amount = wallet_hc_amount;
    this.pay_hc_amount = pay_hc_amount;
    this.method = method;
    this.currencie_id = currencie_id;
    this.reference = reference;
    this.email_copy = email_copy;
  }
}

export interface IPayBenefit {
  amount: number;
  group_ids: number[];
  currencie_id: number;
  method: string;
}

export class PayBenefit implements IPayBenefit {
  amount: number;
  group_ids: number[];
  currencie_id: number;
  method: string;

  constructor(
    amount: number,
    group_ids: number[],
    currencie_id: number,
    method: string
  ) {
    this.amount = amount;
    this.group_ids = group_ids;
    this.currencie_id = currencie_id;
    this.method = method;
  }
}
