<button
  *ngIf="isClosable"
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button>
  <mat-icon>close</mat-icon>
</button>

<div
  class="flex flex-col items-center justify-between w-full gap-6 xs:flex-row">
  <div
    class="flex flex-col items-center justify-center w-full xs:w-1/2-with-gap"
    *ngIf="data.mode && data.mode === 'FULL'">
    <div class="flex flex-col items-center justify-center w-full gap-4 mb-4">
      <div class="flex flex-row items-center justify-start w-full gap-4">
        <img
          src="assets/images/padlock.png"
          alt=""
          srcset=""
          class="w-12 h-12" />
        <div class="flex flex-col items-start">
          <span class="text-xl font-semibold"> TOTP </span>
          <span class="text-base font-normal"> Configuration </span>
        </div>
      </div>
      <div
        class="flex flex-col items-start w-full gap-2 p-4 mx-auto mt-2 text-sm rounded-lg text-kimbo-blue bg-kimbo-blue bg-opacity-10"
        role="alert">
        <p>{{ 'common.totp-step-title' | translate }}</p>
        <p class="ml-4">
          <span class="font-semibold">1.</span
          >{{ 'common.totp-step-1' | translate }}
        </p>
        <p class="ml-4">
          <span class="font-semibold">2.</span
          >{{ 'common.totp-step-2' | translate }}
        </p>
        <p class="ml-4">
          <span class="font-semibold">3.</span
          >{{ 'common.totp-step-3' | translate }}
        </p>
      </div>
      <img [src]="data.qr_code!" alt="" srcset="" class="w-48 h-48" />
    </div>
  </div>
  <div
    class="flex flex-col items-center justify-center border-gray-200 {{
      data.mode && data.mode === 'FULL'
        ? 'xs:w-1/2-with-gap w-full xs:border-l-1 xs:pl-6'
        : 'w-full'
    }}">
    <img
      appImg
      src="assets/svg/illustration-verify.svg"
      alt="forgot password"
      class="hidden w-6/12 mt-4 xs:flex" />

    <div class="flex flex-col items-center justify-between w-full gap-6">
      <label for="twoAuthCode" class="mt-6 text-lg font-semibold">
        {{ 'sign-in.2fa-label' | translate }}
      </label>

      <input
        class="{{ input_cls.common }} {{ invalid ? input_cls.invalid : '' }} {{
          disabled ? input_cls.disabled : ''
        }}"
        type="text"
        [(ngModel)]="twoFaCode"
        name="twoAuthCode"
        id="twoAuthCode"
        placeholder="XXXXXX"
        (input)="verify()"
        [disabled]="isLoading"
        autofocus />

      <button
        mat-raised-button
        color="primary"
        class="w-full h-12 mt-2 rounded-sm"
        (click)="enable2FA()"
        [disabled]="isLoading || twoFaCode.length < 5">
        {{ 'hcp-common.verify' | translate }}
      </button>

      <div
        class="flex flex-col items-start w-full gap-2 p-4 mx-auto mt-2 text-sm text-center rounded-lg text-kimbo-blue bg-kimbo-blue bg-opacity-10"
        role="alert">
        <span class="text-xs">
          {{ 'sign-in.2fa-application' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
