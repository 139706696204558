import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsecasePdfService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public createPdf(body: any, buyedpackage_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/pdf/generate/${buyedpackage_id}`,
      body
    );
  }

  public createFlyInvoice(buyedpackage_id: number): Observable<any> {
    return this.http.get(
      `${this.basePath}/v1/pdf/generate/fly-invoice/${buyedpackage_id}`,
      { responseType: 'blob' }
    );
  }

  public createTransactionFlyInvoice(trasaction_id: number): Observable<any> {
    return this.http.get(
      `${this.basePath}/v1/pdf/generate/fly-transaction-invoice/${trasaction_id}`,
      { responseType: 'blob' }
    );
  }

  public fetchPdf(url: string): Observable<Blob> {
    const body = { url };
    return this.http.post<Blob>(
      `${this.basePath}/v1/pdfs/generate/fetch-pdf`,
      body,
      { ...this.httpOptions, responseType: 'blob' as 'json' }
    );
  }
}
