import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CoporateMonitoringService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public getBudgetGraph(
    start_date: string,
    end_date: string,
    campagneId: number,
    period = 'custom'
  ): Observable<any> {
    let queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });
    return this.http.get<any>(
      `${this.basePath}/v1/campaign/${campagneId}/budget_graph`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getModuleGraph(
    start_date: string,
    end_date: string,
    campagneId: number,
    period = 'custom'
  ): Observable<any> {
    let queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });
    return this.http.get<any>(
      `${this.basePath}/v1/campaign/${campagneId}/module_graph`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public getDrugstoreGraph(
    start_date: string,
    end_date: string,
    campagneId: number,
    period = 'custom'
  ): Observable<any> {
    let queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });
    return this.http.get<any>(
      `${this.basePath}/v1/campaign/${campagneId}/patient_graph`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
