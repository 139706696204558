import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models/Common';
import { ServicesService } from './services.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public deviceLanguage: string = '';
  protected deeplApiKey = environment.deeplApiKey;

  constructor(
    private translateService: TranslateService,
    public services: ServicesService,
    private http: HttpClient
  ) {}

  public changeLanguage(lang: string): void {
    this.deviceLanguage = lang;
    this.translateService.use(this.deviceLanguage);
    this.services.eventService.publish('change:language');
  }

  public get(key: string[], options?: any) {
    if (options) return this.translateService.get(key, options);
    return this.translateService.get(key);
  }

  public getTextOnLanguages(languages: Language[]) {
    if (languages == null) {
      return '/!\\ Translation error, null';
    }

    var lang = this.translateService.currentLang;
    for (const element of languages) {
      if (element.code == lang) {
        if (element.pivot.data != null) {
          return element.pivot.data;
        } else {
          return '-';
        }
      }
    }
    return '/!\\ Translation error';
  }

  deeplTranslate(text: string, targetLang: string) {
    const body = new URLSearchParams();
    body.set('text', text);
    body.set('target_lang', targetLang);

    const authKey = `[${this.deeplApiKey}]`; // Replace with your actual authorization key

    const headers = {
      Authorization: 'DeepL-Auth-Key ' + authKey,
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.http.post(
      'https://api-free.deepl.com/v2/translate',
      body.toString(),
      { headers }
    );
  }
}
