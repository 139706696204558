<div *ngIf="isLoading" class="w-full flex items-center justify-center h-32">
  <app-spinner class="inline-block ml-4 h-8 w-8"></app-spinner>
</div>
<div
  *ngIf="notes && notes.length === 0 && !isLoading"
  class="w-full h-full mt-4 no-campaign-bg relative">
  <div class="w-full flex flex-col justify-center items-center">
    <!--img appImg class="grayscale" src="assets/images/add-note.png" />
    <p-- class="my-6 text-gray font-bold text-lg">
      {{ 'hcp-common.no-notes' | translate }}
    </p-->
    <app-no-content
      imgPath="assets/gif/add-notes-animate.svg"
      [message]="'hcp-common.no-notes' | translate"
      textWidth="text-base"
      iconWidth="w-52"
      customHeight="h-fit"
      class="block w-full sm:w-9/12 my-4 mx-auto" />
  </div>
</div>
<div *ngIf="!isLoading">
  <div
    *ngFor="let note of notes"
    class="p-4 rounded-2xl bg-light-gray md:p-8 md:rounded-4xl mb-6">
    <div
      class="flex flex-col items-start justify-between lg:flex-row lg:items-center">
      <div class="w-full flex justify-between items-center">
        <div class="flex justify-start items-center">
          <app-image-render
            [img]="'assets/images/add-note.png'"></app-image-render>
          <div class="ml-4">
            <h3 class="text-lg font-bold text-navy 'h-full flex items-center">
              {{ note.type }}
            </h3>
          </div>
        </div>
        <div class="w-fit flex items-center">
          <app-square-button
            *ngIf="canEdit"
            type="button"
            size="normal"
            theme="blue"
            class="ml-2 flex items-center"
            (click)="handleNoteEdition(note)">
            <div
              class="w-6 h-6 stroke-current text-blue"
              aria-label="pencil icon"
              [inlineSVG]="'assets/svg/pencil.svg'"></div>
          </app-square-button>
          <app-square-button
            *ngIf="canEdit"
            type="button"
            size="normal"
            theme="blue"
            class="ml-2 mr-3 flex items-center"
            (click)="handleDeleteAction(note)">
            <div
              class="w-6 h-6 stroke-current text-red"
              aria-label="pencil icon"
              [inlineSVG]="'assets/svg/trash.svg'"></div>
          </app-square-button>
        </div>
      </div>
    </div>
    <div class="mt-8">{{ note.text }}</div>
  </div>
  <div *ngIf="this.pagination.totalPages > 1" class="w-fit mx-auto mt-8">
    <app-pagination
      (triggerEvent)="setPage($event)"
      [params]="{ className: 'mt-8', pagination }"></app-pagination>
  </div>
  <div *ngIf="canEdit" class="w-full flex justify-center mt-8">
    <button
      class="h-12"
      mat-raised-button
      color="primary"
      type="button"
      (click)="openNoteModal()">
      <mat-icon>add</mat-icon>
      {{ 'common.add-button-note' | translate }}
    </button>
  </div>
</div>
