/* eslint-disable no-constant-condition */
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AlertStatus, AlertType, PERIOD } from 'src/app/core/enums/enums';
import { PermissionEnums } from 'src/app/core/enums/permission-enums';
import { Alert, AlertDatas } from 'src/app/core/models/AdminMonitoring';
import {
  IBuyedpackage,
  IHealthcareProvider,
  ITransaction,
  IUser,
} from 'src/app/core/models/models';
import { AdminMonitoringService } from 'src/app/core/rest-services/rest-services';
import { AdminRoleService } from 'src/app/core/services/admin-role.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { NavService } from 'src/app/core/services/navservice';
import { ServicesService } from 'src/app/core/services/services.service';
import { shortenText } from 'src/app/core/utils/misc';
import { ProfileComponent } from 'src/app/pages/containers/components/profile/profile.component';
import { PatientDetailsModalComponent } from 'src/app/pages/hcp/components/modals/patient-details-modal/patient-details-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @Output() headerTriggerEvent: EventEmitter<any> = new EventEmitter();
  user!: IUser;
  $updateUserEvent!: Subscription;
  isProfilOpen = false;
  isNotificationsOpen = false;
  notifications: Alert[] = [];
  period = PERIOD.MONTH;
  status = AlertStatus.ALL;
  startDate!: Date;
  endDate!: Date;
  isFetchingAlert = false;
  constructor(
    public navServices: NavService,
    private services: ServicesService,
    private adminMonitoringServices: AdminMonitoringService,
    public navigationService: CustomNavigationService,
    private adminRoleService: AdminRoleService
  ) {
    this.user = this.services.sessionService.getUser();
    this.$updateUserEvent = this.services.eventService.subscribe(
      'user:updated',
      (user: IUser) => {
        this.user = user;
      }
    );

    const hasAlertView = this.adminRoleService.hasPermission(
      PermissionEnums.DashboardAlert
    );
    if (hasAlertView) this.fetchAlerts();
  }

  ngOnDestroy(): void {
    this.$updateUserEvent && this.$updateUserEvent.unsubscribe();
  }

  openProfilDialog(): void {
    this.services.modalService.openModal(ProfileComponent, {
      width: '1300px',
      height: 'auto',
    });
    this.isProfilOpen = false;
  }

  toggleSideMenuOpen(): void {
    this.headerTriggerEvent.emit('toggle');
  }

  closeProfileDropdown(): void {
    if (this.isProfilOpen) this.isProfilOpen = false;
  }

  toggleSideProfileOpen(): void {
    this.isProfilOpen = !this.isProfilOpen;
  }

  closeNotificationsDropdown(): void {
    if (this.isNotificationsOpen) this.isNotificationsOpen = false;
  }

  toggleSideNotificationsOpen(): void {
    this.isNotificationsOpen = !this.isNotificationsOpen;
  }

  fetchAlerts(_page?: number): void {
    this.isFetchingAlert = true;
    this.adminMonitoringServices
      .dashboardAlert(
        this.status,
        this.period,
        this.period === PERIOD.CUSTOM
          ? moment(this.startDate).format('YYYY-MM-DD')
          : undefined,
        this.period === PERIOD.CUSTOM
          ? moment(this.endDate).format('YYYY-MM-DD')
          : undefined
      )
      .subscribe({
        next: (res: AlertDatas) => {
          this.notifications = res.alerts.data.filter(
            alert => alert.status === AlertStatus.PENDING
          );
        },
        error: error => {},
        complete: () => {
          this.isFetchingAlert = false;
        },
      });
  }

  getTranslateParams(alert: Alert): any {
    return {
      ...(alert.target_type === AlertType.BUYEDPACKAGE && {
        patient:
          (alert.target as IBuyedpackage).patient_data.adresse &&
          (alert.target as IBuyedpackage).patient_data.adresse.first_name +
            ' ' +
            (alert.target as IBuyedpackage).patient_data.adresse.last_name,
      }),
      ...(alert.target_type === AlertType.BUYEDPACKAGE && {
        hcp: (alert.target as IBuyedpackage).healthcareprovider?.name,
      }),
      ...(alert.target_type === AlertType.HEALTHCAREPROVIDER && {
        hcp: (alert.target as IHealthcareProvider).name,
      }),
      ...(alert.target_type === AlertType.PAYMENT && {
        reference: (alert.target as ITransaction).reference,
      }),
    };
  }

  showNotification(alert: Alert) {
    this.closeNotificationsDropdown();
    this.handleAlertMessageEvent(alert);
  }

  handleAlertMessageEvent(alert: Alert): void {
    if (alert.target_type === AlertType.BUYEDPACKAGE) {
      this.services.modalService
        .openModal(PatientDetailsModalComponent, {
          width: '950px',
          height: 'auto',
          data: {
            state: alert.target,
            caller: '_FINISHED',
          },
        })
        .subscribe(() => {
          alert.status = AlertStatus.READ;
          this.fetchAlerts();
          this.services.eventService.publish('alert:readed');
        });
    } else if (alert.target_type === AlertType.HEALTHCAREPROVIDER)
      this.navigationService.goToWithState(
        '/admin/edit-healthcare-provider',
        alert.target
      );
    else
      this.navigationService.goToWithState(
        '/admin/edit-transaction',
        alert.target
      );

    if (alert.status === AlertStatus.PENDING) {
      this.updateAlert(AlertStatus.READ, alert.id);
    }
  }

  updateAlert(_status: AlertStatus, alertID: number): void {
    this.adminMonitoringServices.updateAlert(_status, alertID).subscribe({
      next: () => {
        if (_status === AlertStatus.CLOSE) {
          this.fetchAlerts();
        }
      },
      complete: () => {
        this.isFetchingAlert = false;
      },
    });
  }

  logout(): void {
    this.services.sessionService.logout();
  }

  shortenText(text: string): string {
    return shortenText(text);
  }
}
