import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Currency, CurrencyStore } from '../../models/Common';

@Injectable()
export class CurrencyService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * List all Currencies
   */
  public index(): Observable<Currency[]> {
    return this.http.get<Currency[]>(
      `${this.basePath}/v1/resources/currencie`,
      this.httpOptions
    );
  }

  /**
   * View
   * @param currencieId the currencie identifier
   */
  public view(currencieId: number): Observable<Currency> {
    return this.http.get<Currency>(
      `${this.basePath}/v1/resources/currencie/${currencieId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: CurrencyStore): Observable<Currency> {
    return this.http.post<Currency>(
      `${this.basePath}/v1/resources/currencie`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param curencieId
   */
  public update(
    curencieId: number,
    body?: CurrencyStore
  ): Observable<Currency> {
    return this.http.put<Currency>(
      `${this.basePath}/v1/resources/currencie/${curencieId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param curencieId
   */
  public delete(curencieId: number): Observable<Currency> {
    return this.http.delete<Currency>(
      `${this.basePath}/v1/resources/currencie/${curencieId}`,
      this.httpOptions
    );
  }
}
