<div
  class="relative flex flex-col w-full bg-transparent"
  (mouseenter)="clearAutoSlider()"
  (mouseleave)="startAutoSlider()">
  <app-picture-of-hcp
    *ngIf="_caller === 'ADMIN'"
    class="block w-full h-75"
    [classNames]="'block w-full h-full'"
    [src]="currentImage | imageToUrl"></app-picture-of-hcp>
  <app-picture-of-hcp
    *ngIf="_caller === 'KIMBO' || _caller === 'MAP'"
    [classNames]="'block w-full h-64 bg-transparent'"
    [showBg]="_caller === 'MAP' ? false : true"
    [isMobileLayout]="isMobileLayout"
    [borderadius]="
      _caller === 'MAP' && !isMobileLayout
        ? 'rounded-tl-2xl rounded-tr-2xl'
        : _caller === 'MAP' && isMobileLayout
        ? 'rounded-tl-2xl rounded-bl-2xl'
        : ''
    "
    [variant]="_caller === 'MAP' ? 'cover' : 'contain'"
    [src]="
      currentImage.path.endsWith('no_image.jpg') &&
      type.toLowerCase() === healthProviderType.CLINIC
        ? 'assets/gif/hospital-building-animate.svg'
        : currentImage.path.endsWith('no_image.jpg') &&
          type.toLowerCase() === healthProviderType.PHARMACY
        ? 'assets/gif/remedy-animate.svg'
        : currentImage.path.endsWith('no_image.jpg') &&
          type.toLowerCase() !== healthProviderType.PHARMACY &&
          type.toLowerCase() !== healthProviderType.CLINIC
        ? 'assets/gif/hospital-building-animate.svg'
        : (currentImage | imageToUrl)
    "></app-picture-of-hcp>
  <div
    class="absolute flex flex-row gap-1 top-3 {{
      _caller !== 'MAP' ? 'right-3' : ''
    }} {{
      _caller === 'MAP' && isMobileLayout === true ? 'left-2' : 'right-3'
    }}">
    <span
      *ngIf="_caller === 'ADMIN'"
      class="flex w-10 h-10 rounded-full items-center justify-center active:ring-2 active:ring-kimbo-blue hover:opacity-80 text-[#1e68c2] bg-white bg-opacity-80"
      [class]="
        isLoading ? 'pointer-events-none bg-opacity-60' : 'bg-opacity-80'
      ">
      <label
        for="image-viwer-button"
        class="flex items-center justify-center w-full h-full cursor-pointer">
        <mat-icon>edit</mat-icon>
        <span class="text-sm font-semibold tracking-wide"
          ><ng-content></ng-content
        ></span>
      </label>
      <input
        accept=".jpg,.jpeg,.png,.gif"
        class="hidden"
        id="image-viwer-button"
        (change)="updateImage($event)"
        type="file" />
    </span>
    <span
      (click)="remove()"
      *ngIf="_caller === 'ADMIN' && currentImage.type !== 'image_profil'"
      [class]="
        isLoading ? 'pointer-events-none bg-opacity-60' : 'bg-opacity-80'
      "
      class="flex w-10 h-10 rounded-full items-center justify-center cursor-pointer active:ring-2 active:ring-kimbo-ruby hover:opacity-80 text-[#e0115f] bg-white bg-opacity-80">
      <mat-icon>delete</mat-icon>
    </span>
    <span
      (click)="close()"
      *ngIf="_caller === 'MAP'"
      [class]="
        isLoading ? 'pointer-events-none bg-opacity-60' : 'bg-opacity-80'
      "
      class="bg-white bg-opacity-90 text-[#222222] flex w-10 h-10 rounded-full items-center justify-center cursor-pointer hover:opacity-80 bg-opacity-80">
      <mat-icon>close</mat-icon>
    </span>
  </div>

  <div
    class="absolute flex flex-row top-60 right-3"
    *ngIf="_caller === 'ADMIN'">
    <div
      *ngIf="currentImage.type === 'image_profil'"
      class="flex p-2 gap-2 items-center justify-center shadow-1 rounded-xl cursor-pointer active:ring-2 active:ring-kimbo-blue text-[#1e68c2] bg-white">
      <i class="ri-bookmark-3-line"></i>
      {{ 'common.image-profil' | translate }}
    </div>
    <div
      (click)="updateProfile()"
      *ngIf="currentImage.type !== 'image_profil'"
      [class]="isLoading ? 'pointer-events-none' : ''"
      class="flex items-center justify-center shadow-1 rounded-xl cursor-pointer active:ring-2 active:ring-kimbo-blue text-[#1e68c2] bg-white">
      <div
        class="__cell p-2 break-words items-center flex justify-end flex-shrink-0 w-full float-right">
        <span class="mr-2">
          {{ 'common.image-profil-update' | translate }}
        </span>
        <label class="relative inline-flex items-baseline cursor-pointer">
          <input
            type="checkbox"
            value=""
            class="sr-only peer"
            [checked]="false" />
          <div
            class="w-11 h-6 bg-gray-200 relative rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
        </label>
      </div>
    </div>
  </div>

  <div
    *ngIf="images.length > 1 || _caller === 'ADMIN'"
    class="carounin-w-[300px] w-full mx-auto flex justify-start items-center"
    [ngClass]="{ 'my-6': _caller !== 'MAP' }">
    <owl-carousel-o
      class="items-center block w-full pr-1"
      [options]="customOptions"
      #owlCarousel>
      <ng-container *ngIf="_caller !== 'MAP'">
        <div *ngFor="let image of images; let i = index">
          <ng-template carouselSlide [id]="i + ''">
            <div
              class="relative w-auto h-20 rounded-md cursor-pointer {{
                currentIndex === i ? 'border-kimbo-blue border-1' : ''
              }}"
              (click)="showImage(image, i)">
              <img
                [src]="image | imageToUrl"
                class="object-cover h-full rounded-md"
                alt="slide {{ i }}" />
            </div>
          </ng-template>
        </div>
      </ng-container>
    </owl-carousel-o>
  </div>

  <div
    class="customNavigation"
    [ngClass]="{
      customNavigationMap: _caller === 'MAP' && !isMobileLayout,
      customNavigationMapMobile: _caller === 'MAP' && isMobileLayout
    }">
    <button
      type="button"
      [disabled]="isLoading"
      [class]="
        isLoading ? 'pointer-events-none bg-opacity-60' : 'bg-opacity-100'
      "
      class="prev  hover:bg-opacity-60 active:bg-light-gray {{
        _caller === 'MAP' ? '' : ' focus:ring-1 focus:ring-kimbo-blue '
      }}"
      [style]="
        _caller === 'ADMIN' ? 'top: calc(50% - 105px)' : 'top: calc(50% - 80px)'
      "
      (click)="preview()"
      *ngIf="
        (currentIndex > 0 && (_caller === 'ADMIN' || _caller === 'MAP')) ||
        (images.length > 1 && _caller !== 'ADMIN' && _caller !== 'MAP')
      ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-left">
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
    </button>
    <button
      type="button"
      [class]="
        isLoading ? 'pointer-events-none bg-opacity-60' : 'bg-opacity-100'
      "
      class="next   hover:bg-opacity-60 active:bg-light-gray {{
        _caller === 'MAP' ? '' : ' focus:ring-kimbo-blue focus:ring-1 '
      }}"
      [style]="
        _caller === 'ADMIN' ? 'top: calc(50% - 105px)' : 'top: calc(50% - 80px)'
      "
      [disabled]="isLoading"
      (click)="next()"
      *ngIf="
        (currentIndex < images.length - 1 &&
          (_caller === 'ADMIN' || _caller === 'MAP')) ||
        (images.length > 1 && _caller !== 'ADMIN' && _caller !== 'MAP')
      ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-right">
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    </button>
  </div>

  <div
    class="flex flex-col items-center justify-center w-full mt-4"
    *ngIf="_caller === 'ADMIN'">
    <app-file-input
      class="{{
        images!.length >= 8 || isLoading ? 'pointer-events-none opacity-60' : ''
      }}"
      accept=".jpg,.jpeg,.png,.gif"
      name="image"
      id="create-contact.image"
      (changeEvent)="setFieldValue($event)"
      caller="_HCP"
      theme="blue-outline">
      {{ 'kimbo-profile.image-button' | translate }}
    </app-file-input>
    <p class="p-1 text-xs text-kimbo-ruby" *ngIf="images!.length >= 8">
      {{ 'errors.photo-limit' | translate }}
    </p>
  </div>
</div>
