import { inject, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ServicesService } from '../../services/services.service';

@Pipe({
  name: 'dateTimeToTime',
})
export class DateTimeToTimePipe implements PipeTransform {
  services = inject(ServicesService);
  transform(value: string, ...args: { toTime: boolean }[]): unknown {
    const currentLang = this.services.translateService.currentLang;
    let formattedTime = '';
    moment.locale(currentLang);

    if (args.some(ar => ar?.toTime === true)) {
      formattedTime =
        currentLang === 'fr'
          ? moment(value).format('HH:mm')
          : moment(value).format('hh:mm A');
      return formattedTime;
    } else {
      return moment(value).fromNow();
    }
  }
}
