export interface IVerificationCode {
  identifiant?: string;
  language_id?: string;
  user_id?: number;
  date?: string;
}
export interface IVerificationResponse {
  twofa_mode: 'verification' | 'creation' | undefined;
  status_code: number;
  message: string;
  translate: string;
  qr_code: string;
}

export class VerificationCode implements IVerificationCode {
  identifiant?: string;
  language_id?: string;
  user_id?: number;
  date: string;
  constructor(
    date: string,
    identifiant?: string,
    language_id?: string,
    user_id?: number
  ) {
    this.identifiant = identifiant;
    this.language_id = language_id;
    this.user_id = user_id;
    this.date = date;
  }
}
