import { MAPCOUNTRYCODECOUNTRY, MAPCOUNTRYCODEPHONECODE } from './data';
import { Country } from './type';

// for contact/maddie we need a short list of country codes
const countryCodesForContact = [
  'DZ', // Algeria
  'CM', // Cameroun
  'CD', // Democratic Republic of the Congo
  'CI', // Ivory Coast
  'KE', // Kenya
  'SN', // Senegal
  'ZA', // South Africa
  'CH', // Switzerland
];

const countryCodesForCampaign = [
  'CM', // Cameroun
  'CI', // Ivory Coast
  'KE', // Kenya
];

export function getCountries({
  longList,
  campaign,
}: { longList?: boolean; campaign?: boolean } = {}): Country[] {
  return MAPCOUNTRYCODECOUNTRY.filter(country => {
    if (longList) return true;

    if (campaign) return countryCodesForCampaign.includes(country.code);

    return countryCodesForContact.includes(country.code);
  });
}

export function getCountriePhoneCode(countryCode: string) {
  const countries = MAPCOUNTRYCODEPHONECODE;

  return (
    '+' +
    countries.find(obj => {
      return obj.countryCode === countryCode;
    })?.phoneCode!
  );
}
