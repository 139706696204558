import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() searchEvent = new EventEmitter();
  @Input() disabled = false;
  @Input() size = 'w-full';
  searchValue = '';
  $inputSubject = new Subject<string>();

  constructor() {}

  ngOnDestroy(): void {
    this.$inputSubject && this.$inputSubject.unsubscribe();
  }

  ngOnInit(): void {
    this.$inputSubject
      .pipe(debounceTime(1000)) // Adjust the debounce time to your needs (e.g., 300ms)
      .subscribe(searchValue => {
        this.searchEvent.emit(searchValue);
      });
  }

  search(event: any): void {
    this.searchValue = event.target ? event.target.value : '';
    this.$inputSubject.next(this.searchValue);
  }
}
