import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() triggerEvent: EventEmitter<string> = new EventEmitter();
  @Input() filename?: string;
  @Input() invalid: boolean = false;
  @Input() classNames: string = '';
  @Input() editable = false;

  constructor() {}

  ngOnInit(): void {}

  edit(): void {
    this.triggerEvent.emit('edit');
  }

  delete(): void {
    this.triggerEvent.emit('delete');
  }
}
