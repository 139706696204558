export const SEPARATOR = Symbol();

// This is the longest chain that won't ever have dots separator
// no matter which page is the current one.
// Why 7? Because we have 3 center pages (current + 1 on the left and 1 on the right) plus
// first page and last one + two separators
const STATIC_PAGES_NUMS: number[] = [1, 2, 3, 4, 5, 6, 7];

// Defines how many previous and next pages we want to have in center part of navigation.
const PREV_OR_NEXT_COUNT: number = 1;

export function isSeparator(el: any): el is symbol {
  return el === SEPARATOR;
}

export function getPaginationElements(
  total: number,
  current: number
): (number | symbol)[] {
  // For quite short pagination.
  if (total <= STATIC_PAGES_NUMS.length) {
    return STATIC_PAGES_NUMS.slice(0, total);
  }

  const paginationPages: (number | symbol)[] = [];

  // Below we are calculating the first and last page of center part of pagination.
  // Those calculations include the edge case when we are on the first page and want to render
  // two previous pages. In this case we cannot do that so we are adding those two pages to the
  // next pages part so we will render current one and four next.
  // The same story goes if we are on the last page.
  const leftmostPossible = Math.max(1, current - PREV_OR_NEXT_COUNT);
  let leftLinksCount = current - leftmostPossible;

  const rightmostPossible = Math.min(total, current + PREV_OR_NEXT_COUNT);
  let rightLinksCount = rightmostPossible - current;

  rightLinksCount += PREV_OR_NEXT_COUNT - leftLinksCount;
  leftLinksCount += PREV_OR_NEXT_COUNT - rightLinksCount;

  // When there is no first separator, add 2 or 1 to rightLinksCount (so that total number of
  // number of elements is always 7)
  if (current <= 3) {
    rightLinksCount += Math.min(2, 4 - current);
  }

  // When there is no second separator, add 2 or 1 to leftLinksCount (so that total number of
  // number of elements is always 7)
  if (current >= total - 2) {
    leftLinksCount += Math.min(2, current - (total - 3));
  }

  const firstPageInPreviousLinks = current - leftLinksCount;
  const lastPageInNextLinks = current + rightLinksCount;

  // Add first page if it won't be included in center part.
  if (firstPageInPreviousLinks > 1) {
    paginationPages.push(1);
  }

  // If the center part of pagination is just one page away from the first page,
  // we want to have the page link instead of dots.
  if (firstPageInPreviousLinks === 3) {
    paginationPages.push(2);
  }
  // If the gap between center part of pagination and first page is bigger that one,
  // render dots.
  else if (firstPageInPreviousLinks > 3) {
    paginationPages.push(SEPARATOR);
  }

  // Render all pages for center part of pagination.
  for (
    let page = current - leftLinksCount;
    page <= current + rightLinksCount;
    page += 1
  ) {
    paginationPages.push(page);
  }

  // If the center part of pagination is just one page away from the last page,
  // we want to have the page link instead of dots.
  if (lastPageInNextLinks === total - 2) {
    paginationPages.push(total - 1);
  }
  // If the gap between center part of pagination and last page is bigger that one,
  // render dots.
  else if (lastPageInNextLinks < total - 2) {
    paginationPages.push(SEPARATOR);
  }

  // Add last page if it won't be included in center part.
  if (lastPageInNextLinks < total) {
    paginationPages.push(total);
  }

  return paginationPages;
}
