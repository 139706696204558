import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() skeletonOfSameTypeToGenerate = 1;
  @Input() skeletonType:
    | 'BUNDLE_LIST'
    | 'BUNDLE_ITEM'
    | 'KIMBO_PATIENT'
    | 'KIMBO_PATIENT_ITEM'
    | 'HCP_PATIENT'
    | 'HCP_PATIENT_LIST'
    | 'TREATMENTS_LIST'
    | 'TREATMENT_ITEM'
    | 'HCP_CAMPAIGN_MODULE_LIST'
    | 'HCP_CAMPAIGN_MODULE_ITEM'
    | 'HCP'
    | 'HCP_LIST'
    | 'TEXT'
    | 'IMAGE'
    | 'RECTANGLE'
    | 'CARRE'
    | 'DETAILS-TEXT'
    | 'LONG-TEXT'
    | 'CIRCLE'
    | 'TITLE'
    | 'MESSAGE_LIST' = 'BUNDLE_LIST';
  @Input() height = 'h-14';
  @Input() size = 'w-6 h-6';
  @Input() withBorderRadius = true;

  constructor() {}
}
