<div class="relative w-auto" appClickOutside (clickOutside)="clickedOutside()">
  <button
    class="h-12 uppercase {{
      caller === 'C_WITH_BG' ? 'bg-transparent' : 'bg-white'
    }}  bg-opacity-80"
    mat-button
    color="primary"
    tabindex="-1"
    (click)="openLangSelect()">
    <div class="flex items-center gap-2">
      <span
        class="h-8 w-8 flag ff-round ff-lg flag-{{
          translate.currentLang.toLocaleLowerCase() === 'en'
            ? 'us'
            : translate.currentLang.toLocaleLowerCase()
        }}"></span>
      <span class="{{ caller === 'C_WITH_BG' ? 'text-white' : 'text-blue' }}">{{
        translate.currentLang
      }}</span>
      <img
        appImg
        src="assets/svg/arrow-bottom.svg"
        class="h-6 w-6 {{ isOpen ? 'rotate-180' : '' }}"
        alt="arrow-bottom" />
    </div>
  </button>
  <ul
    *ngIf="isOpen"
    class="absolute w-full bg-[#f4f8fc] rounded-lg shadow-inner-blue-sm overflow-auto mt-1 z-dropdown">
    <app-option
      *ngFor="let lang of translate.getLangs()"
      [InCOption]="{ value: lang, label: '' }"
      [isSelected]="lang === translate.currentLang"
      (selectEvent)="selectedOption($event)"
      classNames="h-12">
      <div class="flex items-center justify-start gap-2">
        <span
          class="h-8 w-8 flag ff-round ff-lg flag-{{
            lang === 'en' ? 'us' : lang.toLocaleLowerCase()
          }}"></span>
        <span class="text-base">{{ lang | uppercase }}</span>
      </div>
    </app-option>
  </ul>
</div>
