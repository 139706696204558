<div class="flex flex-col justify-center items-center gap-4">
  <img
    appImg
    src="assets/gif/download-animate.svg"
    class="w-[190px]"
    alt="download image" />
  <p class="text-white font-bold mb-6 text-center">
    {{ 'common.update-available-label' | translate }}
  </p>
  <p class="text-white mb-6 text-center text-[14px]">
    {{ 'common.update-available-desc' | translate }}
  </p>
  <div class="flex justify-center items-center text-center mb-6">
    <button
      (click)="reload()"
      type="submit"
      style="box-shadow: rgba(0, 168, 147, 0.36) 0rem 0.625rem 1.875rem"
      class="w-full px-11 text-lg text-center tracking-wider h-14 disabled:bg-opacity-20 flex justify-center items-center bg-transparent rounded-full font-semibold bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding text-white cursor-pointer">
      <span>{{ 'common.reload-label' | translate }}</span>
    </button>
  </div>
</div>
