import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { LanguageService } from './language.service';
import { StorageService } from './storage.service';
import { CustomNavigationService } from './custom-navigation.service';
import { RoleService } from './role.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from './event.service';
import { UtilsService } from './utils.service';
import { ModalService } from './modal.service';
import { PushNotificationService } from './push-notification.service';
@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  public sessionService!: SessionService;
  public languagesService!: LanguageService;
  public roleService!: RoleService;
  public storageService!: StorageService;
  public customNavigationService!: CustomNavigationService;
  public translateService!: TranslateService;
  public eventService!: EventService;
  public utilsService!: UtilsService;
  public modalService!: ModalService;
  public pushNotificationService!: PushNotificationService;
  constructor() {}
}
