import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RecommendationStore } from 'src/app/core/models/Buyedpackage';
import { Content, Currency } from 'src/app/core/models/Common';
import {
  HealthcareProviders,
  IHealthcareProvider,
} from 'src/app/core/models/HealthcareProvider';
import {
  Healthcarepackage,
  IHealthcarepackage,
} from 'src/app/core/models/Healthcarepackage';
import { TextLanguagesPipe } from 'src/app/core/pipes/text-languages/text-languages.pipe';
import {
  HealthPackageService,
  HealthProviderService,
  UseCaseBuyedPackageService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { isEmptyString, isString } from 'src/app/core/utils/core';
import { toHealthCredit, toMoney } from 'src/app/core/utils/misc';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-add-recommendation-modal',
  templateUrl: './add-recommendation-modal.component.html',
  styleUrls: ['./add-recommendation-modal.component.scss'],
})
export class AddRecommendationModalComponent implements OnInit, OnDestroy {
  isLoading = false;
  healthPackageOptions: OptionObject[] = [
    {
      label: '',
      value: '',
    },
  ];
  hcpOptions: OptionObject[] = [
    {
      label: '',
      value: '',
    },
  ];
  healthPackageSelected?: OptionObject;
  healthPackage?: Healthcarepackage;
  selectedHcpElement?: OptionObject;
  healthcarepackages: IHealthcarepackage[] = [];
  healthcareProviders: IHealthcareProvider[] = [];
  isFindingHp = false;
  isFindingHcp = false;
  page = 1;
  hcpDefaultOption!: OptionObject;
  currency!: Currency;
  pickAmount!: number;
  note!: string;
  $clearInput!: Subscription;
  input_cls = INPUT_CLASSES;
  error: string | undefined;
  isWrongAmount = false;
  minRecommendedAmount!: number;
  maxRecommendedAmount!: number;
  textLanguagesPipe: TextLanguagesPipe;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { b_id: number; hcp: HealthcareProviders },
    public dialogRef: MatDialogRef<AddRecommendationModalComponent>,
    private healthPackageService: HealthPackageService,
    private translateService: TranslateService,
    private healthcareproviderservice: HealthProviderService,
    private sessionService: SessionService,
    private services: ServicesService,
    private changeDetectorRef: ChangeDetectorRef,
    private useCaseBuyedPackageService: UseCaseBuyedPackageService,
    private notificationService: NotificationService
  ) {
    this.currency = this.sessionService.getCurrencie();
    this.textLanguagesPipe = new TextLanguagesPipe(this.changeDetectorRef);
  }

  ngOnDestroy(): void {
    this.$clearInput && this.$clearInput.unsubscribe();
  }

  ngOnInit(): void {
    this.hcpDefaultOption = {
      label: this.data.hcp.name!,
      value: this.data.hcp.id + '',
    };
    this.minRecommendedAmount = this._toMoney(8);
    this.selectedHcpElement = this.hcpDefaultOption;
    this.$clearInput = this.services.eventService.subscribe(
      'input:clear',
      (searchKey: string) => {
        if (searchKey === '_HCP') {
          this.selectedHcpElement = undefined;
        } else if (searchKey === '_HP') {
          this.healthPackageSelected = undefined;
          this.healthPackage = undefined;
        }
      }
    );
  }

  handleSubmit(): void {
    this.isLoading = true;
    this.useCaseBuyedPackageService
      .add_recommendation(this.data.b_id, this.initRecommendation())
      .subscribe({
        next: (res: any) => {
          this.notificationService.success(
            this.translateService.instant(
              'hcp-common.add-recommendation-success'
            )
          );
          this.close('_REFRESH');
        },
        error: error => {
          this.notificationService.danger(
            this.translateService.instant(error.translate ?? 'errors.generic')
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  handleSearchHpEvent(event: any): void {
    if (typeof event === 'object') {
      this.healthPackageSelected = event;
      this.healthPackage = this.healthcarepackages.find(
        hp => hp.id === Number(this.healthPackageSelected!.value.split('::')[1])
      )!;
      this.pickAmount = this._toMoney(this.healthPackage.amount!);
      this.maxRecommendedAmount = this.getMaxRecommendAmountForCurrency();
    } else {
      this.fetchHealthCarePackages(event);
    }
  }

  handleSearchHcpEvent(event: any): void {
    if (typeof event === 'object') {
      this.selectedHcpElement = event;
    } else {
      this.fetchHealthcareProvider(event);
    }
  }

  fetchHealthCarePackages(name: string): void {
    this.isLoading = true;
    this.isFindingHp = true;
    this.healthPackageService.index(1, name, 'kimbo').subscribe({
      next: (res: Content<IHealthcarepackage>) => {
        this.healthcarepackages = res.data;
        this.initHealthcarePackageOptions();
      },
      error: error => {
        error = this.translateService.instant('errors.generic');
        this.isFindingHp = false;
      },
      complete: () => {
        this.isLoading = false;
        this.isFindingHp = false;
      },
    });
  }

  fetchHealthcareProvider(term: string): void {
    this.isLoading = true;
    this.isFindingHcp = true;
    this.healthcareproviderservice.index(this.page, term).subscribe({
      next: (res: Content<IHealthcareProvider>) => {
        this.healthcareProviders = res.data;
        this.initHealcareProviderOptions();
      },
      error: error => {
        error = this.translateService.instant('errors.generic');
        this.isFindingHcp = false;
      },
      complete: () => {
        this.isLoading = false;
        this.isFindingHcp = false;
      },
    });
  }

  initHealcareProviderOptions(): void {
    this.hcpOptions = [];
    this.healthcareProviders.forEach(hcp => {
      this.hcpOptions.push({
        label: hcp.name,
        value: hcp.id + '',
      });
    });
  }

  initHealthcarePackageOptions(): void {
    this.healthPackageOptions = [];
    this.healthcarepackages.forEach(hp => {
      this.healthPackageOptions.push({
        label: this.textLanguagesPipe.transform(hp.title.languages),
        value: hp.type + '::' + hp.id,
      });
    });
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency ? this.currency.rate : 0);
  }

  _toHealthCredit(amount: number): number {
    return toHealthCredit(amount, this.currency ? this.currency.rate : 0) + 1;
  }

  getMaxRecommendAmountForCurrency(): number {
    if (this.services.utilsService.getIsXAForXOF(this.currency)) {
      return 300000;
    }

    if (this.services.utilsService.getIsKES(this.currency)) {
      return 60000;
    }

    return 2000;
  }

  close(purpose: string): void {
    this.dialogRef.close(purpose);
  }

  cansubmit(): boolean {
    return (
      this.healthPackage !== undefined &&
      this.selectedHcpElement !== undefined &&
      this.note !== undefined &&
      this.note.length > 0 &&
      !this.isWrongAmount
    );
  }

  initRecommendation(): RecommendationStore {
    return new RecommendationStore(
      this.healthPackage?.id!,
      Number(this.selectedHcpElement!.value),
      this.note,
      this._toHealthCredit(this.pickAmount),
      []
    );
  }

  handleRangeEvent(event: any): void {
    this.pickAmount = event.target.value;
    this.isWrongAmount = false;
    this.error = undefined;
  }

  handleCustomAmount(event: any): void {
    const amount = event.target.value;
    if (isEmptyString(amount + '')) {
      this.error = this.translateService.instant('errors.required');
      this.isWrongAmount = true;
    } else if (amount < this.minRecommendedAmount) {
      this.error = this.translateService.instant(
        'errors.recommended-amount-too-low',
        {
          minAmount: this.minRecommendedAmount + ' ' + this.currency.code,
        }
      );
      this.isWrongAmount = true;
    } else if (amount > this.maxRecommendedAmount) {
      this.error = this.translateService.instant(
        'errors.recommended-amount-too-high',
        {
          maxAmount: this.maxRecommendedAmount + ' ' + this.currency.code,
        }
      );
      this.isWrongAmount = true;
    } else {
      this.pickAmount = amount;
      this.error = undefined;
      this.isWrongAmount = false;
    }
  }
}
