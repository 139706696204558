<div
  *ngIf="shape === '_ONE'"
  class="w-full h-full bg-white rounded-[10px] shadow-box-outer-sh relative {{
    boxClasses
  }} {{ variant === 'border' ? 'shadow-inner-blue-sm' : '' }}">
  <div
    *ngIf="params.withWave"
    style="
      background-image: url(assets/images/wave-5.png);
      background-size: cover;
      background-repeat: no-repeat;
    "
    class="left-0 right-0 bottom-0 top-0 absolute bg-opacity-40"></div>
  <div
    *ngIf="params.dataInRight"
    class="absolute top-5 right-3 font-bold text-xs text-navy">
    {{ params.dataInRight }}
  </div>
  <div
    *ngIf="params.withIcon || params.withTitle"
    class="w-full flex justify-between items-center h-fit pl-[23px] pt-3 z-base">
    <div class="w-full flex items-center justify-start">
      <img
        appImg
        *ngIf="params.withIcon"
        class="images mr-2 {{ boxImageClasses }}"
        src="{{ params.iconPath }}"
        alt="icon cards" />

      <span
        *ngIf="params.withTitle"
        class="{{ params.titleClasses }}  block pr-2  ">
        {{ params.title }}
      </span>
    </div>

    <app-info-component *ngIf="info" [info]="info"></app-info-component>
  </div>
  <div
    class="relative z-base {{
      !params.withIcon && !params.withTitle ? 'h-full' : 'height-c'
    }} ">
    <ng-content></ng-content>
  </div>
</div>

<div
  *ngIf="shape === '_TWO'"
  class="w-full h-full bg-white rounded-[5px] border-1 border-[#E2E8F0] relative">
  <div class="w-full flex justify-between items-center h-fit p-5 z-base">
    <div
      class="w-12 h-12 flex-shrink-0 rounded-full bg-opacity-15 {{
        paramsShapeTwo.titleClasses
          ? paramsShapeTwo.titleClasses
          : 'bg-kimbo-blue text-kimbo-blue'
      }} flex justify-center items-center">
      <i class="ri-{{ paramsShapeTwo.icon }} text-xl"></i>
    </div>

    <div class="flex justify-center ml-3 items-end flex-col">
      <div
        class="text-navy text-[24px] font-semibold flex justify-end items-center {{
          info ? '-mr-3' : ''
        }}">
        {{ paramsShapeTwo.value }}
        <app-info-component *ngIf="info" [info]="info"></app-info-component>
      </div>
      <span class="block text-[14px] text-dark-gray">
        {{ paramsShapeTwo.title }}
      </span>
    </div>
  </div>
</div>
