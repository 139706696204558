import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-external-nav-link',
  templateUrl: './layout-external-nav-link.component.html',
  styleUrls: ['./layout-external-nav-link.component.scss'],
})
export class LayoutExternalNavLinkComponent implements OnInit {
  @Input() iconPath!: string;
  @Input() iconLabel!: string;
  @Input() location!: string;

  constructor() {}

  ngOnInit(): void {}
}
