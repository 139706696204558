<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<button
  *ngIf="!isGettingMember && isOwer"
  (click)="toggleCreateOverlayVisibility()"
  type="button"
  color="primary"
  mat-fab
  class="!absolute bottom-5 right-5 z-base">
  <mat-icon>add</mat-icon>
</button>
<div
  class="py-2 px-3 flex flex-row justify-between items-center z-dropdown absolute top-0 left-0 right-0 glass-morphism rounded-tl-lg rounded-tr-lg">
  <div class="flex items-center p-3">
    <div>
      <app-avatar
        class="flex-shrink w-10 h-10 cursor-pointer"
        classNames="w-10 h-10"
        [withFrame]="false"
        [src]="
          currentRole.group.image_profil
            ? (currentRole.group.image_profil | imageToUrl)
            : 'assets/svg/rect-gray.svg'
        "></app-avatar>
    </div>
    <div class="ml-4">
      <p
        class="w-[210px] text-grey-darkest overflow-hidden text-nowrap overflow-ellipsis">
        {{ currentRole.group.name }}
      </p>
    </div>
  </div>
  <button
    type="button"
    mat-dialog-close
    class="absolute flex items-center justify-center w-10 h-10 text-white bg-white rounded-full hover:bg-opacity-75 ring-4 focus:ring-blue-300 focus:outline-none top-5 right-5">
    <img
      appImg
      src="assets/svg/close.svg"
      alt="close icon"
      class="w-6 h-6 text-white" />
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<div
  *ngIf="!isGettingMember && formatedMemberList.length > 0"
  class="bg-grey-lighter flex-1 overflow-auto mt-10 px-1">
  <h5 class="text-xs text-kimbo-blue py-4 font-semibold text-gray-900">
    <i class="ri-group-line mr-1"></i>
    {{
      'kimbo-layout.members-label' | translate : { number: numberOfGroupMember }
    }}
  </h5>

  <button
    *ngIf="members && members.length > 0"
    (click)="openPendingMemberModal()"
    type="button"
    class="relative w-full bg-kimbo-blue bg-opacity-10 rounded-md mb-4 px-4 py-[6px] text-kimbo-blue font-semibold text-center">
    <i class="ri-group-line mr-2"></i>
    <span class="text-[13px]">{{
      'common.pending-invitation' | translate : { count: members.length }
    }}</span>
    <i class="ri-arrow-right-line ml-2"></i>
  </button>

  <div class="w-full flex flex-wrap items-start justify-start mb-4">
    <div
      *ngFor="let member of formatedMemberList; let i = index"
      (click)="toggleCreateOverlayVisibility(member)"
      class="flex rounded-lg w-full bg-white text-navy mb-4 justify-between items-center shadow {{
        isOwer &&
          currentRole.group.user_admin_id !== member.user_id &&
          member.user_id !== user.id &&
          'cursor-pointer'
      }}">
      <div class="flex py-4 px-3 w-full justify-between items-center">
        <div class="flex justify-start items-center w-10/12">
          <img
            [src]="
              member.image
                ? (member.image | imageToUrl)
                : 'assets/svg/rect-gray.svg'
            "
            class="flex-shrink-0 w-12 h-12 object-cover rounded-full {{
              currentRole.group.user_admin_id === member.user_id
                ? 'border-4 border-kimbo-gold'
                : 'border-2  border-kimbo-blue'
            }}"
            alt="role image" />

          <div class="flex flex-col justify-center items-start gap-1">
            <span
              [matTooltip]="
                (member.name?.trim() !== ''
                  ? member.name
                  : member.tel?.trim() !== ''
                  ? member.tel
                  : member.email)!
              "
              class="w-[220px] font-medium text-sm pl-4 overflow-hidden whitespace-nowrap text-ellipsis">
              {{
                member.name?.trim() !== ''
                  ? member.name
                  : member.tel?.trim() !== ''
                  ? member.tel
                  : member.email
              }}</span
            >
            <div class="pl-4">
              <span
                *ngFor="let perm of member.permissions"
                class="inline-block  w-auto font-medium text-xs text-center rounded-full py-1 px-2 mr-1 bg-opacity-5 {{
                  perm === pOwer
                    ? 'bg-green text-green'
                    : perm === pMember
                    ? 'bg-kimbo-blue text-kimbo-blue'
                    : ''
                }}">
                {{ 'kimbo-layout.' + perm | translate }}
              </span>
            </div>
          </div>
        </div>
        <button
          *ngIf="
            (currentRole.group.user_admin_id === user.id &&
              member.user_id !== user.id) ||
            (isOwer &&
              member.user_id !== user.id &&
              currentRole.group.user_admin_id !== member.user_id &&
              member.permissions &&
              !member.permissions.includes(permission.Owner))
          "
          mat-icon-button
          class="text-xl"
          [disabled]=""
          (click)="deleteMember($event, member)"
          color="warn"
          aria-label="action delete"
          [matTooltip]="'admin-layout.delete-action-label' | translate">
          <i class="ri-delete-bin-2-line"></i>
        </button>
      </div>
    </div>
    <div
      *ngFor="let member of invitationsMember"
      class="flex rounded-lg w-full bg-white text-navy mb-4 justify-between items-center shadow">
      <div class="flex py-4 pl-3 pr-3 w-full justify-between items-center">
        <div class="flex justify-start items-center w-10/12 pr-3">
          <img
            [src]="
              member.user?.image_profil
                ? (member.user?.image_profil | imageToUrl)
                : 'assets/images/unknow.png'
            "
            class="flex-shrink w-12 h-12 object-cover rounded-full"
            alt="role image" />

          <div class="flex flex-col justify-center items-start gap-1">
            <span
              [matTooltip]="member.identifiant"
              class="w-[220px] font-medium text-sm pl-4 overflow-hidden whitespace-nowrap text-ellipsis">
              {{ member.identifiant }}</span
            >
            <div class="pl-4">
              <span
                class="w-auto inline-block font-medium text-xs text-center rounded-full py-1 px-2 mr-1 bg-opacity-5 bg-red-pink text-red-pink">
                {{ 'common.invitation-send' | translate }}
              </span>
            </div>
          </div>
        </div>
        <button
          *ngIf="
            (currentRole.group.user_admin_id === user.id &&
              member.user_id !== user.id) ||
            isOwer
          "
          mat-icon-button
          class="text-xl stop-edit-action"
          (click)="removeInvitation($event, member)"
          color="warn"
          aria-label="action delete"
          [matTooltip]="'admin-layout.delete-action-label' | translate">
          <i class="ri-delete-bin-2-line"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<app-no-content
  *ngIf="!isGettingMember && formatedMemberList.length === 0"
  imgPath="assets/gif/community-animate.svg"
  [message]="'common.no-group-member-found' | translate"
  textWidth="text-base"
  iconWidth="w-44"
  customHeight="h-60"
  class="w-full mt-20"></app-no-content>

<!--ADD MEMBER FORM-->

<div
  *ngIf="isCreateEditModalOpen"
  class="absolute inset-0 flex items-center justify-center rounded-lg wating-overlay z-modal-backdrop bg-kimbo-blue bg-opacity-15">
  <div
    class="relative w-full h-auto px-3 py-4 bg-white rounded-lg shadow-light-backdrop-sm">
    <div class="flex items-center p-3">
      <div>
        <app-avatar
          class="flex-shrink w-10 h-10 pointer-events-none"
          classNames="w-10 h-10"
          [withFrame]="false"
          [withPreview]="false"
          [src]="'assets/images/users-group.png'">
        </app-avatar>
      </div>
      <div class="ml-4">
        <p
          class="w-[210px] text-grey-darkest overflow-hidden text-nowrap overflow-ellipsis">
          {{
            (isCreatingMember
              ? 'forms.invite-members'
              : 'forms.edit-permissions'
            ) | translate
          }}
        </p>
      </div>
    </div>

    <button
      type="button"
      (click)="closeEditRoleModal()"
      class="absolute flex close-button right-2 top-2 items-center justify-center w-10 h-10 text-white bg-white rounded-full hover:bg-opacity-75 ring-4 focus:ring-blue-300 focus:outline-none">
      <img
        appImg
        src="assets/svg/close.svg"
        alt="close icon"
        class="w-6 h-6 text-white" />
    </button>

    <!--ADD MEMBERS LINK-->
    <div *ngIf="isCreatingMember">
      <p class="text-center mt-4">
        {{ 'forms.share-link-text' | translate }}
      </p>
      <div
        class="sharing-buttons flex flex-wrap mt-6 items-center justify-center mb-6">
        <a
          class="border-2 duration-200 ease inline-flex items-center mr-1 px-2 py-1 transition rounded-md text-white border-kimbo-blue bg-kimbo-blue hover:bg-blue-700 hover:border-blue-700"
          target="_blank"
          rel="noopener"
          [href]="'https://www.facebook.com/sharer/sharer.php?u=' + shareLink">
          <i class="ri-facebook-fill text-xl"></i>
        </a>
        <a
          class="border-2 duration-200 ease inline-flex items-center px-2 py-1 transition rounded-md text-white border-kimbo-blue bg-kimbo-blue hover:bg-blue-700 hover:border-blue-700"
          target="_blank"
          rel="noopener"
          [href]="'whatsapp://send?text=' + shareLink">
          <i class="ri-whatsapp-line text-xl"></i>
        </a>
      </div>
      <div
        class="border border-light-gray rounded-sm relative h-16 mt-2 flex items-center pl-1 mb-4">
        <span
          (click)="copyText()"
          class="whitespace-nowrap w-full overflow-hidden text-ellipsis pl-2 cursor-pointer">
          {{ groupLink }}
        </span>
        <button
          mat-raised-button
          [disabled]="!groupLink"
          color="primary"
          (click)="copyText()"
          class="h-12 absolute -left-1 w-40">
          <mat-icon>layers</mat-icon>{{ 'forms.copy-link' | translate }}
        </button>
      </div>

      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-px my-8 bg-gray-200 border-0" />
        <span
          class="absolute px-3 font-medium -translate-x-1/2 bg-white left-1/2">
          {{ 'hcp-home.or' | translate }}
        </span>
      </div>
    </div>
    <form
      class="flex flex-wrap items-start"
      [formGroup]="formCreateRole"
      (ngSubmit)="saveMember()">
      <label
        *ngIf="isEditingMember"
        class="mb-2 text-navy mt-6"
        htmlFor="{htmlFor}">
        {{ 'kimbo-layout.role-label' | translate }}
      </label>
      <ul
        *ngIf="isEditingMember"
        class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
          <div class="flex items-center pl-3 cursor-pointer">
            <input
              id="vue-checkbox-list"
              type="radio"
              formControlName="permission"
              value="member"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
            <label
              for="vue-checkbox-list"
              class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
              >{{ 'kimbo-layout.member-label' | translate }}</label
            >
          </div>
        </li>
        <li class="w-full">
          <div class="flex items-center pl-3 cursor-pointer">
            <input
              id="laravel-checkbox-list"
              type="radio"
              formControlName="permission"
              value="owner"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
            <label
              for="laravel-checkbox-list"
              class="w-full py-3 ml-2 text-sm font-medium text-gray-900"
              >{{ 'kimbo-layout.manager-label' | translate }}</label
            >
          </div>
        </li>
      </ul>
      <app-form-error *ngIf="!formCreateRole.valid" class="px-4 mt-1">
        {{ 'errors.required' | translate }}
      </app-form-error>
      <p *ngIf="isCreatingMember" class="text-center mt-4 mb-4">
        {{ 'forms.add-member-text' | translate }}
      </p>
      <div class="relative w-full mt-4">
        <button
          *ngIf="isCreatingMember"
          style="position: absolute !important"
          class="-top-[14px] right-0 z-base"
          mat-button
          color="primary"
          (click)="toggleAggingMethod()"
          type="button"
          [disabled]="isGettingMember">
          <app-spinner
            *ngIf="isGettingMember"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span
            *ngIf="!addMemberWithPhone && !isGettingMember"
            class="inline-block p-1"
            >{{ 'common.add-with-phone-label' | translate }}</span
          >
          <span
            *ngIf="addMemberWithPhone && !isGettingMember"
            class="inline-block p-1 bg-white"
            >{{ 'common.add-with-email-label' | translate }}</span
          >
        </button>

        <app-form-field
          *ngIf="isCreatingMember && !addMemberWithPhone"
          [label]="'forms.email-label-mini' | translate"
          class="w-full mt-6 md:w-1/3-with-gap md:mt-0"
          [error]="
            !_isValidEmail(memberEmail)
              ? ('errors.invalid-email' | translate)
              : ''
          ">
          <input
            class="w-full
        {{ input_cls.common }}"
            type="email"
            (input)="setMemberEmail($event)"
            [placeholder]="'forms.email-placeholder' | translate" />
        </app-form-field>
        <app-phone-number-dropdown
          *ngIf="isCreatingMember && addMemberWithPhone"
          class="w-full mt-6"
          (triggerEvent)="handlePhoneEvent($event)"
          [options]="countryListToOptionObject()"
          [country]="country"
          [withWhatapp]="false"
          [placeholder]="'forms.phone-placeholder' | translate"
          [name]="
            'forms.tel-label-mini' | translate
          "></app-phone-number-dropdown>
      </div>
      <div
        class="flex flex-col justify-center flex-shrink-0 w-full pt-6 sm:items-end">
        <div class="flex items-center justify-end w-full">
          <button
            *ngIf="!isEditingMember"
            class="h-12 uppercase"
            mat-raised-button
            color="primary"
            type="submit"
            class="w-full"
            [disabled]="
              isGettingMember ||
              (addMemberWithPhone &&
                !_isValidPhoneNumber(this.phoneModel?.phone!)) ||
              (!addMemberWithPhone && !_isValidEmail(memberEmail))
            ">
            <app-spinner
              *ngIf="isGettingMember"
              class="inline-block w-6 h-6 filter-blue"></app-spinner>
            <span *ngIf="!isGettingMember" class="inline-block p-1">{{
              (isCreatingMember
                ? 'common.send-invitation-label'
                : 'common.save-label'
              ) | translate
            }}</span>
            <mat-icon *ngIf="!isGettingMember" class="ml-2"
              >arrow_forward</mat-icon
            >
          </button>
          <button
            *ngIf="isEditingMember"
            class="h-12 uppercase"
            mat-raised-button
            color="primary"
            type="submit"
            class="w-full">
            <app-spinner
              *ngIf="isGettingMember"
              class="inline-block w-6 h-6 filter-white"></app-spinner>
            <span *ngIf="!isGettingMember" class="inline-block p-1">{{
              'common.save-label' | translate
            }}</span>
            <mat-icon *ngIf="!isGettingMember" class="ml-2"
              >arrow_forward</mat-icon
            >
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div
  *ngIf="isGettingMember"
  class="w-full items-start justify-start mb-4 mt-16 cursor-pointer">
  <app-skeleton-loader
    skeletonType="TITLE"
    class="block mb-4"></app-skeleton-loader>
  <app-skeleton-loader
    *ngFor="let number of [].constructor(4)"
    skeletonType="RECTANGLE"
    class="block mb-4"
    size="w-full h-16"></app-skeleton-loader>
</div>
