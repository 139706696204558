import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content } from '../../models/models';
import { IBuyedpackage } from '../../models/Buyedpackage';

@Injectable()
export class BuyedPackageService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * @param page
   * @param healthcareprovider_id
   * @param patient_id
   * @param group_id
   * @param campaign_id
   * @param status
   * @param without_status
   * @param orderby_column
   * @param orderby_direction
   */
  public index(
    page: number,
    healthcareprovider_id?: number,
    patient_id?: number,
    group_id?: number,
    campaign_id?: number,
    status?: string,
    without_status?: string,
    orderby_column = 'id',
    orderby_direction: 'DESC' | 'ASC' = 'DESC',
    country_id?: number,
    period?: 'year' | 'month' | 'day' | 'sevent_day' | 'custom',
    start_date?: string,
    end_date?: string
  ): Observable<Content<IBuyedpackage>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(page != null && { page: page }),
        ...(healthcareprovider_id != null && {
          healthcareprovider_id: healthcareprovider_id,
        }),
        ...(patient_id != null && { patient_id: patient_id }),
        ...(group_id != null && { group_id: group_id }),
        ...(campaign_id != null && { campaign_id: campaign_id }),
        ...(status != null && { status: status }),
        ...(without_status != null && { without_status: without_status }),
        ...(orderby_column != null && { orderby_column: orderby_column }),
        ...(orderby_direction != null && {
          orderby_direction: orderby_direction,
        }),
        ...(country_id != null && { country_id: country_id }),
        ...(period != null && { period: period }),
        ...(start_date != null && { start_date: start_date }),
        ...(end_date != null && { end_date: end_date }),
      },
    });

    return this.http.get<Content<IBuyedpackage>>(
      `${this.basePath}/v1/resources/buyedpackage`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param buyedpackageId the buyedpackage identifier
   */
  public view(buyedpackageId: number): Observable<IBuyedpackage> {
    return this.http.get<IBuyedpackage>(
      `${this.basePath}/v1/resources/buyedpackage/${buyedpackageId}`,
      this.httpOptions
    );
  }

  public getVerificationCode(buyedpackageId: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackageId}/send_verification_code`,
      this.httpOptions
    );
  }

  public confirmVerificationCode(
    body: any,
    buyedpackageId: number
  ): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/buyedpackage/${buyedpackageId}/confirm_sms_verification`,
      JSON.stringify(body),
      this.httpOptions
    );
  }
}
