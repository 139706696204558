<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="relative">
  <div
    class="flex justify-between items-center border-light-gray border-b-1 pl-4 pr-1 py-5">
    <div class="flex items-center justify-start gap-2">
      <h1
        class="sticky top-0 glass-morphism text-navy text-lg font-medium z-base flex items-start gap-2">
        <i class="ri-shield-user-line text-kimbo-blue text-2xl"></i>
        {{ 'common.interation-log-heading' | translate }}
      </h1>
      <app-global-dropdown
        *ngIf="optionPeriodDropdown"
        (triggerEvent)="handlePeriodDropdownEvent($event)"
        [optionWidth]="'w-52'"
        [pickValue]="filterPickedValue"
        [options]="optionPeriodDropdown"></app-global-dropdown>
    </div>

    <button
      matTooltipPosition="after"
      type="button"
      (click)="closeSideLogsPanel()"
      class="absolute flex items-center justify-center w-10 h-10 text-white bg-white rounded-full hover:bg-opacity-75 ring-4 focus:ring-blue-300 focus:outline-none top-5 right-5">
      <img
        appImg
        src="assets/svg/close.svg"
        alt="close icon"
        class="w-6 h-6 text-white" />
      <span class="sr-only">Open actions menu</span>
    </button>
  </div>

  <div
    *ngIf="!isLoading && adminLogs && groupedDatas"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="onInLogsScroll()"
    class="p-2">
    <div *ngFor="let group of getGroupedKeys()">
      <h2
        *ngIf="group.length > 0"
        class="w-full pt-2 pb-[17px] flex items-center justify-center text-navy font-medium text-[12px]">
        {{ group }}
      </h2>
      <div
        *ngFor="let item of groupedDatas[group]"
        class="bg-opacity-5 p-2 mb-3 shadow-card rounded-r-md {{
          item.tag === adminLogTag.ADD ||
          item.tag === adminLogTag.ADD_ADMIN ||
          item.tag === adminLogTag.ATTACH
            ? 'border-kimbo-turquoise bg-kimbo-turquoise'
            : item.tag === adminLogTag.DELETE || item.tag === adminLogTag.DETACH
            ? 'border-red-pink bg-red-pink'
            : 'border-warning bg-warning'
        }} border-l-[3px]  relative">
        <div class="flex items-center justify-start gap-3">
          <app-avatar
            class="flex-shrink w-10 h-10 cursor-pointer"
            classNames="w-10 h-10"
            [withFrame]="false"
            [src]="item.user.image_profil | imageToUrl"></app-avatar>
          <div class="flex flex-col items-start ml-1 justify-center">
            <h1
              (click)="openUserModal(item.user.id)"
              class="text-base font-bold capitalize cursor-pointer text-navy hover:opacity-80">
              <h2 class="font-medium text-gray-800">
                {{
                  (item.user.data?.firstName ?? '') +
                    ' ' +
                    (item.user.data?.lastName ?? ' ')
                }}
              </h2>
              <p
                *ngIf="item.user.properties.tel"
                class="text-sm font-normal text-gray-600 dark:text-gray-400">
                {{ item.user.properties.tel }}
              </p>
              <p
                *ngIf="!item.user.properties.tel && item.user.properties.email"
                class="text-sm font-normal text-gray-600 dark:text-gray-400">
                {{ item.user.properties.email }}
              </p>
            </h1>
            <div class="flex items-center gap-1 justify-start">
              <p
                class="px-2 py-1 text-white ml-1 {{
                  item.tag === adminLogTag.ADD ||
                  item.tag === adminLogTag.ADD_ADMIN ||
                  item.tag === adminLogTag.ATTACH
                    ? 'bg-kimbo-turquoise'
                    : item.tag === adminLogTag.DELETE ||
                      item.tag === adminLogTag.DETACH
                    ? 'bg-red-pink'
                    : 'bg-warning'
                }} text-[10px] rounded-sm glass-morphism">
                {{
                  (item.tag === adminLogTag.ADD
                    ? 'common.add-log-label'
                    : item.tag === adminLogTag.ADD_ADMIN
                    ? 'common.add-admin-log-label'
                    : item.tag === adminLogTag.UPDATE
                    ? 'common.update-log-label'
                    : item.tag === adminLogTag.DELETE
                    ? 'common.delete-log-label'
                    : item.tag === adminLogTag.ATTACH
                    ? 'common.attach-log-label'
                    : item.tag === adminLogTag.DETACH
                    ? 'common.unlink-role-log-label'
                    : 'common.generate-role-log-label'
                  ) | translate
                }}
              </p>
            </div>

            <p
              *ngIf="
                item.tag === adminLogTag.ADD ||
                item.tag === adminLogTag.UPDATE ||
                item.tag === adminLogTag.DELETE
              "
              class="text-navy mt-1 text-[13px]"
              [innerHtml]="
                (item.tag === adminLogTag.ADD
                  ? 'common.interation-log-add'
                  : item.tag === adminLogTag.UPDATE
                  ? 'common.interation-log-update'
                  : 'common.interation-log-delete'
                )
                  | translate
                    : {
                        target:
                          'common.logs-target.' + currentTarget | translate,
                        targetName:
                          currentTarget === adminLogTarget.User &&
                          (item.target?.data?.lastName ||
                            item.target?.data?.firstName)
                            ? (item.target?.data?.firstName ?? '') +
                              ' ' +
                              (item.target?.data?.lastName ?? ' ')
                            : currentTarget === adminLogTarget.User &&
                              item.target?.properties?.tel
                            ? item.target?.properties?.tel
                            : currentTarget === adminLogTarget.User &&
                              item.target?.properties?.email
                            ? item.target?.properties?.email
                            : currentTarget === adminLogTarget.Payment
                            ? item.target?.reference
                            : currentTarget === adminLogTarget.Currencie
                            ? item.target?.code
                            : currentTarget === adminLogTarget.Countrie
                            ? (item.target?.name?.languages | textLanguages)
                            : currentTarget === adminLogTarget.Healthcarepackage
                            ? (item.target?.title?.languages | textLanguages)
                            : item.target?.name
                      }
              "></p>
            <p
              *ngIf="item.tag === adminLogTag.ADD_ADMIN"
              class="text-navy mt-1 text-[13px]"
              [innerHtml]="
                'common.interation-log-add-admin'
                  | translate
                    : {
                        targetName:
                          item.target?.data?.lastName ||
                          item.target?.data?.firstName
                            ? (item.target?.data?.firstName ?? '') +
                              ' ' +
                              (item.target?.data?.lastName ?? ' ')
                            : item.target?.properties?.tel
                            ? item.target?.properties?.tel
                            : item.target?.properties?.email
                            ? item.target?.properties?.email
                            : item.target?.name
                      }
              "></p>
            <p
              *ngIf="item.tag === adminLogTag.ATTACH"
              class="text-navy mt-1 text-[13px]"
              [innerHtml]="
                'common.interation-log-attach'
                  | translate
                    : {
                        roleName: item.old_data?.name,
                        targetName:
                          item.target?.data?.lastName ||
                          item.target?.data?.firstName
                            ? (item.target?.data?.firstName ?? '') +
                              ' ' +
                              (item.target?.data?.lastName ?? ' ')
                            : item.target?.properties?.tel
                            ? item.target?.properties?.tel
                            : item.target?.properties?.email
                            ? item.target?.properties?.email
                            : item.target?.name
                      }
              "></p>
            <p
              *ngIf="item.tag === adminLogTag.DETACH"
              class="text-navy mt-1 text-[13px]"
              [innerHTML]="
                'common.interation-log-detach'
                  | translate
                    : {
                        roleName: item.old_data?.name,
                        targetName:
                          item.target?.data?.lastName ||
                          item.target?.data?.firstName
                            ? (item.target?.data?.firstName ?? '') +
                              ' ' +
                              (item.target?.data?.lastName ?? ' ')
                            : item.target?.properties?.tel
                            ? item.target?.properties?.tel
                            : item.target?.properties?.email
                            ? item.target?.properties?.email
                            : item.target?.name
                      }
              "></p>
            <p
              *ngIf="item.tag === adminLogTag.GENERATE"
              class="text-navy mt-1 text-[13px]"
              [innerHTML]="
                'common.interation-log-generate'
                  | translate
                    : {
                        targetName: item.target?.name
                      }
              "></p>
          </div>
        </div>
        <span class="absolute text-xs font-medium top-8 right-5 text-navy">
          {{ item.created_at | dateTimeToTime }}
        </span>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isLoading && adminLogs?.length === 0"
    class="flex items-center justify-center">
    <app-no-content
      imgPath="assets/gif/site-stats-animate.svg"
      [message]="'common.no-logs-message' | translate"
      textWidth="text-lg"
      iconWidth="w-52"
      class="w-full"></app-no-content>
  </div>

  <div *ngIf="isLoading" class="p-2 w-full">
    <app-skeleton-loader
      *ngFor="let number of [].constructor(10)"
      skeletonType="RECTANGLE"
      class="block mb-4"
      size="w-full h-16"></app-skeleton-loader>
  </div>
</div>
