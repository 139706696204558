<button
  type="button"
  (click)="close()"
  class="flex items-center justify-center text-white bg-white hover:bg-opacity-75 rounded-full w-10 h-10 ring-4 focus:ring-blue-300 focus:outline-none absolute top-5 right-5">
  <img
    appImg
    src="assets/svg/close.svg"
    alt="close icon"
    class="text-white w-6 h-6" />
</button>
<div class="flex flex-col justify-center items-center gap-4">
  <img
    appImg
    src="assets/gif/download-animate.svg"
    class="w-[250px]"
    alt="downlaod imge" />
  <p class="text-navy font-bold mb-6 text-center">
    {{ 'common.download-app-msg' | translate }}
  </p>
  <div
    class="btns_cnt-downloads flex justify-center md:justify-around lg:justify-around xl:justify-around flex-col md:flex-row lg:flex-row xl:flex-row text-center lg:text-left mb-6">
    <a
      *ngIf="mobileDevice === 'iOS'"
      class="dwnld_lnk-apple href cursor-pointer"
      target="_blank"
      href="https://apps.apple.com/fr/app/kimbocare-mobile/id1617607005?l=en">
      <div
        class="{{
          currentLang === 'en' ? 'ios-image-wrapper-en' : 'ios-image-wrapper-fr'
        }}"></div>
    </a>

    <a
      *ngIf="mobileDevice === 'Android'"
      class="dwnld_lnk-android mt-4 md:mt-0 lg:mt-0 xl:mt-0 md:ml-4 lg:ml-4 xl:ml-4 cursor-pointer"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.kimbocare.kimboCareMobile">
      <div
        className="{{
          currentLang === 'en' ? 'an-image-wrapper-en' : 'an-image-wrapper-fr'
        }}"></div>
    </a>
  </div>
</div>
