import { Component, Inject, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-custom-range-calendar',
  templateUrl: './custom-range-calendar.component.html',
  styleUrls: ['./custom-range-calendar.component.scss'],
})
export class CustomRangeCalendarComponent implements OnInit {
  @Input() selectedDateRange!: DateRange<Date>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { incomingDateRange: DateRange<Date> },
    public dialogRef: MatDialogRef<CustomRangeCalendarComponent>
  ) {
    if (this.data.incomingDateRange) {
      this.selectedDateRange = this.data.incomingDateRange;
    }
  }

  ngOnInit(): void {}

  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
      this.dialogRef.close(this.selectedDateRange);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }
}
