<button
  matRipple
  matRippleColor="rgba(30,104,194,.1)"
  [matTooltip]="'hcp-common.change-patient-status' | translate"
  matTooltipClass="custom-material-tooltip"
  (click)="openfilterSelect()"
  class="flex items-center justify-between cursor-pointer rounded-full w-full gap-2 px-2 focus:outline-none {{
    colorClassName
  }} {{ bgClassName }} bg-opacity-5 {{ classNames }}">
  <i class="ri-filter-3-line text-lg"></i>
  <button class="uppercase focus:outline-none">
    <div
      class="inline-flex items-center justify-center h-10 bg-transparent font-bold  text-xs {{
        colorClassName
      }}">
      {{ pickValue?.value }}
    </div>
  </button>
  <button
    class="w-9 h-9 flex items-center justify-center focus:outline-none bg-white bg-opacity-50 rounded-full text-current">
    <i class="ri-pencil-line text-lg"></i>
  </button>
</button>
