import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VisibilityStateService {
  isVisible = new BehaviorSubject<boolean>(false);
  constructor() {}

  hide(): void {
    this.isVisible.next(false);
  }
  show(): void {
    this.isVisible.next(true);
  }

  getVisibility(): Observable<boolean> {
    return this.isVisible.asObservable();
  }
}
