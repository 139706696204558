<div class="flex flex-shrink-0 items-center justify-between {{ classNames }}">
  <div class="flex items-center w-fit">
    <div
      *ngIf="invalid"
      class="flex-shrink-0 w-8 h-8 fill-current text-red"
      aria-label="warning icon"
      [inlineSVG]="'assets/svg/warning.svg'"></div>
    <div
      *ngIf="!invalid && filename"
      class="flex-shrink-0 w-8 h-8 fill-current text-green"
      aria-label="check circled icon"
      [inlineSVG]="'assets/svg/check-circled.svg'"></div>
    <span class="inline-block ml-2 break-all">{{ filename }}</span>
  </div>
  <div *ngIf="editable" class="flex items-center ml-2">
    <app-square-button
      class="ml-2"
      (click)="delete()"
      type="button"
      size="normal"
      theme="blue">
      <div
        class="w-6 h-6 stroke-current text-blue"
        aria-label="trash icon"
        [inlineSVG]="'assets/svg/trash.svg'"></div>
    </app-square-button>
  </div>
</div>
