import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BacktotopComponent } from './components/backtotop/backtotop.component';
import { ToggleThemeDirective } from 'src/app/core/directives/toggle-theme.directive';
import { HoverEffectSidebarDirective } from 'src/app/core/directives/hover-effect-sidebar.directive';
import { FullscreenDirective } from 'src/app/core/directives/fullscreen.directive';
import { SidemenuToggleDirective } from 'src/app/core/directives/sidemenuToggle';
import { NavService } from 'src/app/core/services/navservice';
import { SwitcherService } from 'src/app/core/services/switcher.service';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UsageLogsComponent } from './components/usage-logs/usage-logs.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    SidebarComponent,
    FullscreenDirective,
    FooterComponent,
    BacktotopComponent,
    ToggleThemeDirective,
    HoverEffectSidebarDirective,
    SidemenuToggleDirective,
    HeaderComponent,
    UsageLogsComponent,
  ],
  providers: [NavService, SwitcherService],
  imports: [
    RouterModule,
    CommonModule,
    InlineSVGModule,
    SharedComponentsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
    MatSidenavModule,
    InfiniteScrollModule,
    PipesModule,
  ],
  exports: [
    AdminLayoutComponent,
    ToggleThemeDirective,
    HoverEffectSidebarDirective,
    SidemenuToggleDirective,
    FullscreenDirective,
  ],
})
export class AdminContainerModule {}
