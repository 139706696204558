import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { StatusLogs } from 'src/app/core/enums/enums';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { OptionObject } from 'src/app/core/utils/type';
import { FilterStatusModalComponent } from '../filter-status-modal/filter-status-modal.component';
import { HcpService } from 'src/app/core/services/hcp.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-by-status-dropdown',
  templateUrl: './filter-by-status-dropdown.component.html',
  styleUrls: ['./filter-by-status-dropdown.component.scss'],
})
export class FilterByStatusDropdownComponent implements OnInit, OnDestroy {
  isOpen = false;
  colorClassName = 'text-yellow';
  bgClassName = 'bg-yellow';
  @Input() buyedPackage!: IBuyedpackage;
  @Input() currentPatientStatus?: OptionObject;
  options!: OptionObject[];
  @Input() classNames = '';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() pickValue?: OptionObject;
  @Input() shift = false;
  statusLogs = StatusLogs;
  $patientStatusChange!: Subscription;

  constructor(
    private languageService: LanguageService,
    private services: ServicesService,
    private hcpService: HcpService
  ) {}

  ngOnDestroy(): void {
    this.$patientStatusChange.unsubscribe();
    document.removeEventListener('click', () => {
      this.isOpen = false;
    });
  }

  ngOnInit(): void {
    this.getStatusColor();
    this.options = this.hcpService.getFilter();
    this.setLastLog();
    this.$patientStatusChange = this.services.eventService.subscribe(
      'patient:status:change',
      (state: { pickValue: OptionObject; buyedPackage: IBuyedpackage }) => {
        if (this.buyedPackage.id === state.buyedPackage.id) {
          this.pickValue = state.pickValue;
          this.getStatusColor();
          this.triggerEvent.emit(state.pickValue);
        }
      }
    );
  }

  setLastLog(): void {
    this.pickValue = this.hcpService.setLastLog(this.buyedPackage);
    if (!this.pickValue) {
      this.pickValue = this.options[0];
    }
    this.getStatusColor();
  }

  getStatusColor(): void {
    switch (this.pickValue?.label) {
      case this.statusLogs.ATRECEPTIONIST: {
        this.bgClassName = 'bg-yellow';
        this.colorClassName = 'text-yellow';
        break;
      }
      case this.statusLogs.TREATMENTCOMPLETED: {
        this.bgClassName = 'bg-green';
        this.colorClassName = 'text-green';
        break;
      }
      default: {
        this.bgClassName = 'bg-dark-gray';
        this.colorClassName = 'text-dark-gray';
        break;
      }
    }
  }

  clickedOutside(): void {
    this.isOpen = false;
  }

  openfilterSelect(): void {
    this.services.modalService
      .openModal(FilterStatusModalComponent, {
        width: '400px',
        height: '500px',
        data: {
          pickValue: this.pickValue,
        },
      })
      .subscribe((data: OptionObject) => {
        if (data) {
          this.pickValue = data;
          this.getStatusColor();
          this.triggerEvent.emit(data);
        }
      });
  }
}
