<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div
  class="absolute top-0 left-0 right-0 h-6 z-dropdown bg-white rounded-tl-lg rounded-tr-lg"></div>
<div class="p-2 sticky top-0 z-dropdown bg-white">
  <h3 class="text-base font-medium text-kimbo-blue mb-4">
    <i class="ri-group-line ml-1"></i>
    {{ 'kimbo-layout.current-label-heading' | translate }}
  </h3>
  <div
    *ngIf="currentRoleOption"
    (click)="editCurrentRole('EDIT_GROUP')"
    class="w-full flex items-center justify-between mb-4 {{
      currentRole.group.is_benefit ? 'pointer-events-none' : ''
    }} {{ isOwner && currentRole.id !== -1 ? 'cursor-pointer' : '' }}">
    <div
      class="flex rounded-lg w-full {{
        currentRole.group.user_admin_id !== currentUser.id
          ? 'bg-white text-navy'
          : 'bg-kimbo-blue text-kimbo-blue bg-opacity-5'
      }} justify-between items-center shadow">
      <div class="flex py-4 px-4 w-full justify-between items-center">
        <div class="flex justify-start items-center w-10/12">
          <app-avatar
            [classNames]="'w-8 h-8'"
            [withFrame]="false"
            [withPreview]="false"
            caller="_ROLE_SELECT"
            [src]="currentRoleOption.src!"></app-avatar>

          <div class="flex flex-col justify-center items-start gap-1 pl-4">
            <span
              class="w-[220px] font-medium text-sm overflow-hidden whitespace-nowrap text-ellipsis">
              {{ currentRoleOption.label }}</span
            >
            <div>
              <span
                class="inline-block w-auto text-xs text-center rounded-full font-bold py-1 px-2 mr-1 bg-opacity-5 {{
                  currentRole.group.user_admin_id === currentUser.id
                    ? 'bg-green text-green'
                    : 'bg-kimbo-blue text-kimbo-blue'
                }}">
                {{
                  currentRole.group.user_admin_id === currentUser.id
                    ? ('kimbo-layout.owner' | translate)
                    : ('kimbo-layout.member' | translate)
                }}
              </span>
            </div>
          </div>
        </div>
        <i
          *ngIf="
            isOwner && currentRole.id !== -1 && !currentRole.group.is_benefit
          "
          class="ri-edit-line text-xl p-1 cursor-pointer pl-1"></i>
      </div>
    </div>
  </div>
  <div
    *ngIf="isOwner && currentRoleOption && currentRole.id !== -1"
    (click)="editCurrentRole('EDIT_MEMBERS')"
    class="w-full flex items-center justify-between cursor-pointer mb-4">
    <div
      class="flex rounded-lg w-full bg-white text-navy justify-between items-center shadow">
      <div class="flex py-4 px-4 w-full justify-between items-center">
        <i class="ri-group-line text-xl mr-2 text-kimbo-blue"></i>
        <div class="flex justify-start items-center w-10/12">
          <span
            class="font-medium text-sm pl-4 overflow-hidden whitespace-nowrap text-ellipsis"
            >{{ 'common.edit-group-button' | translate }}</span
          >
        </div>
        <i
          class="ri-arrow-right-line text-xl text-kimbo-blue p-1 cursor-pointer pl-1"></i>
      </div>
    </div>
  </div>
  <div
    *ngIf="optionCreateGroup"
    (click)="selectedOption(optionCreateGroup)"
    class="flex rounded-lg w-full pl-4 pr-4 bg-white justify-between items-center shadow cursor-pointer">
    <div class="flex py-4 w-full justify-between items-center">
      <div class="flex justify-start items-center w-10/12">
        <app-avatar
          [classNames]="'w-8 h-8'"
          [withFrame]="false"
          [withPreview]="false"
          caller="_ROLE_SELECT"
          [src]="optionCreateGroup.src!"></app-avatar>

        <span
          class="font-medium text-sm pl-4 overflow-hidden whitespace-nowrap text-ellipsis"
          >{{ optionCreateGroup.label }}</span
        >
      </div>
      <i
        class="ri-add-circle-line text-xl p-1 text-kimbo-blue cursor-pointer pl-1 ml-3"></i>
    </div>
  </div>
</div>
<div class="p-2 relative">
  <h3 class="text-base font-medium text-kimbo-blue mb-4">
    <i class="ri-arrow-left-right-line ml-1"></i>
    {{ 'kimbo-layout.change-group-label-heading' | translate }}
  </h3>

  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
    class="
     {{
      isOwner &&
      currentRoleOption &&
      currentRole.id !== -1 &&
      roleList &&
      roleList.length! > 1
        ? 'h-[230px]'
        : !(isOwner && currentRoleOption && currentRole.id !== -1) &&
          roleList &&
          roleList.length! > 1
        ? 'h-[305px]'
        : 'h-fit'
    }}
       overflow-y-scroll px-1 pb-1
    ">
    <app-search-bar
      *ngIf="searchValue.trim().length > 0 || roleList?.length! > 1"
      (searchEvent)="search($event)"
      class="block w-full mb-3"></app-search-bar>
    <app-no-content
      *ngIf="roleList?.length === 0"
      imgPath="assets/gif/community-animate.svg"
      [message]="'common.no-group-found' | translate"
      textWidth="text-base"
      iconWidth="w-44    "
      customHeight="h-"
      class="w-full h-48"></app-no-content>
    <ul
      *ngIf="!isLoading && roleList"
      class="flex flex-wrap items-start justify-start {{
        roleList && roleList.length! === 1 ? 'mt-4' : ''
      }}">
      <div
        *ngFor="let role of roleList"
        (click)="selectedOption(role)"
        class="w-full flex items-center justify-between mb-5 cursor-pointer">
        <div
          class="flex rounded-lg w-full {{
            role.value === data.selectedRole.value
              ? 'bg-kimbo-blue text-white'
              : 'bg-white text-navy'
          }} justify-between items-center shadow">
          <div class="flex py-4 px-4 w-full justify-between items-center">
            <div class="flex justify-start items-center w-10/12">
              <img
                [src]="role.src!"
                class="w-8 h-8 object-cover flex-shrink rounded-full"
                alt="role image" />

              <div class="flex flex-col justify-center items-start gap-1 pl-4">
                <span
                  class="w-[220px] font-medium text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                  {{ role.label }}</span
                >
                <div>
                  <span
                    class="inline-block w-auto text-xs text-center rounded-full font-bold py-1 px-2 mr-1 bg-opacity-5 {{
                      roleGroups[role.value]?.user_admin_id === currentUser.id
                        ? 'bg-green text-green'
                        : 'bg-kimbo-blue text-kimbo-blue'
                    }}">
                    {{
                      roleGroups[role.value]?.user_admin_id === currentUser.id
                        ? ('kimbo-layout.owner' | translate)
                        : ('kimbo-layout.member' | translate)
                    }}
                  </span>
                </div>
              </div>
            </div>
            <i
              *ngIf="role.value !== data.selectedRole.value"
              class="ri-arrow-right-line text-xl p-1 cursor-pointer pl-1"></i>
          </div>
        </div>
      </div>
    </ul>
    <div *ngIf="isLoading" class="w-full items-start justify-start mb-4">
      <app-skeleton-loader
        *ngFor="let number of [].constructor(4)"
        skeletonType="RECTANGLE"
        class="block mb-4"
        size="w-full h-16"></app-skeleton-loader>
    </div>
    <div *ngIf="isAddingNewData" class="w-full items-start justify-start mb-4">
      <app-skeleton-loader
        *ngFor="let number of [].constructor(1)"
        skeletonType="RECTANGLE"
        class="block mb-4"
        size="w-full h-16"></app-skeleton-loader>
    </div>
  </div>
</div>
