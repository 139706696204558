import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent {
  @Input() imgPath = '';
  @Input() message = '';
  @Input() textWidth!: string;
  @Input() iconWidth!: string;
  @Input() customHeight = 'h-[500px]';

  constructor() {}
}
