<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<mat-progress-bar
  *ngIf="loadingEventsCount > 0"
  mode="indeterminate"
  color="primary"></mat-progress-bar>

<mat-sidenav-container
  [hasBackdrop]="true"
  (backdropClick)="closeSidenav($event)"
  class="bg-white {{ loadingEventsCount > 0 ? 'pointer-events-none' : '' }}">
  <mat-sidenav
    #drawer
    class="example-sidenav"
    [mode]="'over'"
    position="end"
    [fixedInViewport]="drawerOptions.fixed"
    [fixedTopGap]="drawerOptions.top"
    [fixedBottomGap]="drawerOptions.bottom"
    class="w-full {{
      mapData ? 'sm:w-[500px] md:w-[800px]' : 'sm:w-[500px]'
    }} ">
    <app-map
      *ngIf="mapData"
      class="block w-full h-full"
      [KimboHcps]="mapData.kimboHcps"
      [selectedHCPType]="selectedHCPType!"
      [selectedHCPForMap]="selectedHCPForMap!"
      [googleHcps]="mapData.googleHcps"></app-map>
    <app-conversation-channel
      *ngIf="buyedPackage"
      [buyedPackage]="buyedPackage"
      [caller]="
        currentRole.group.type === groupType.Hcp ? 'HCP' : 'KIMBO'
      "></app-conversation-channel>
  </mat-sidenav>

  <div
    class="flex flex-col items-stretch mx-auto bg-white {{
      currentRoleType === groupType.Benefit ? 'max-w-7xl' : 'max-w-6xl'
    }}  min-h-screen relative">
    <!--div class="fixed bottom-0 left-0 right-0 h-28 bg-blue"></div-->
    <header
      class="hidden md:flex items-center justify-between w-full h-16 px-6 bg-white shadow-light-backdrop-sm md:shadow-none md:h-24 {{
        !breackpointMatche ? 'sticky top-0' : 'glass-morphism'
      }} z-header rounded-b-[16px]">
      <div class="w-header-col md:hidden"></div>
      <div class="flex justify-center md:w-24">
        <a
          href="https://www.kimbocare.com/"
          target="_blank"
          class="inline-block w-10 h-10 rounded-xl focus:outline-none focus:shadow-blue">
          <div
            class="w-10 h-10 lazyload"
            aria-label="menu icon"
            [inlineSVG]="'assets/svg/logo-signature.svg'"></div>
        </a>
      </div>
      <div
        class="flex items-center justify-between w-header-col md:flex-grow md:w-auto">
        <div class="md:pl-8" id="layout-header-slot"></div>
        <div class="flex items-center justify-end gap-2">
          <span
            *ngIf="badge"
            class="hidden lg:inline-block bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded capitalize"
            >{{ enviromentname }}</span
          >
          <app-language-select *ngIf="isGuest"></app-language-select>
          <app-menu-role-select
            *ngIf="!isGuest"
            class="mt-2 md:mt-0 hidden md:block"
            optionWidth="w-72"
            variant="_DROPDOWN"></app-menu-role-select>
          <app-avatar
            *ngIf="!sessionService.isGuest()"
            (click)="openProfilDialog()"
            class="h-12 w-12 cursor-pointer ml-4"
            [classNames]="'h-12 w-12'"
            [withFrame]="true"
            [withPreview]="false"
            [src]="this._user.image_profil | imageToUrl"
            caller="_PROFILE"></app-avatar>
          <div *ngIf="!isGuest">
            <div class="flex-shrink-0 ml-4">
              <app-health-credits-panel
                *ngIf="currentRoleType !== 'admin'"></app-health-credits-panel>
            </div>
          </div>
          <!--a
            *ngIf="sessionService.isGuest()"
            (click)="openSignUpModal()"
            class="px-11 text-lg text-center tracking-wider h-14 flex justify-center items-center bg-transparent rounded-full uppercase font-semibold text-kimbo-turquoise border-2 cursor-pointer">
            {{ 'sign-up.h1' | translate }}
          </a-->
          <a
            *ngIf="sessionService.isGuest()"
            (click)="openLoginModal('_SIGN_IN')"
            style="box-shadow: rgba(0, 168, 147, 0.36) 0rem 0.625rem 0.5rem"
            class="px-11 text-lg text-center tracking-wider h-14 flex justify-center items-center bg-transparent rounded-full uppercase font-semibold text-kimbo-turquoise border-2 cursor-pointer">
            {{ 'sign-in.2fa-submit-button' | translate }}
          </a>
        </div>
      </div>
    </header>

    <!--Navigation + page content.-->
    <div
      *ngIf="breackpointMatche"
      class="flex flex-col items-stretch flex-grow md:flex-row scrollbar">
      <!--Navigation.-->
      <div
        class="{{
          isVisible ? '' : 'hidden md:block'
        }} flex-shrink-0 rounded-tr-[3rem] bg-blue fixed top-0 w-64 z-navigation h-screen md:h-auto md:relative md:w-32">
        <div
          class="absolute hidden h-full bg-blue right-full w-max md:block"></div>
        <app-square-button
          classNames="w-8 h-8 mt-4 ml-6 md:hidden"
          (click)="hide()"
          size="medium"
          theme="light-blue">
          <div
            class="w-6 h-6 text-white stroke-current"
            aria-label="close icon"
            [inlineSVG]="'assets/svg/close-s.svg'"></div>
        </app-square-button>

        <div class="overflow-auto">
          <div
            class="flex flex-col items-center mt-12 text-center text-white md:hidden">
            <div class="mt-2">
              {{
                _user.data && _user.data.firstName && _user.data.lastName
                  ? _user.data.firstName + ' ' + _user.data.lastName
                  : '__'
              }}
            </div>
            <div class="text-sm text-lighter-blue capitalize">
              {{ currentRole && currentRole.group.type }}
            </div>
          </div>
          <!--translation here has role param-->
          <app-layout-navigation
            [breakpointMatch]="breackpointMatche"></app-layout-navigation>
        </div>
        <div
          *ngIf="currentRole.group.type !== groupType.Admin"
          class="absolute translate-y-1/2 translate-x-1/2 bottom-4 right-1/2 text-white">
          {{ 'v' + appVersion }}
        </div>
      </div>
      <!--Page content container-->
      <div
        class="flex-grow p-6 md:pt-0 rounded-tl-2xl rounded-tr-2xl shadow-layout-elevation md:shadow-none">
        <div class="mb-6 md:hidden" id="layout-header-slot-mobile"></div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-mobile-drawer *ngIf="!breackpointMatche"></app-mobile-drawer>
    <div *ngIf="!breackpointMatche" class="p-6 mb-16 w-full max-w-full">
      <router-outlet></router-outlet>
    </div>

    <div *ngIf="!breackpointMatche" class="h-fit overflow-hidden z-header">
      <div class="w-full h-fit">
        <div
          id="bottom-navigation"
          class="block fixed inset-x-0 bottom-0 z-10 bg-k glass-morphism shadow-light-backdrop-sm rounded-t-[30px]">
          <app-layout-navigation
            [breakpointMatch]="breackpointMatche"></app-layout-navigation>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>
<app-google-one-tap *ngIf="isGuest"></app-google-one-tap>
