import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IHealthcareProvider,
  HealcareProviderStore,
  Content,
} from '../../models/models';

@Injectable()
export class HealthProviderService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param providerId
   */
  public delete(providerId: number): Observable<IHealthcareProvider> {
    return this.http.delete<IHealthcareProvider>(
      `${this.basePath}/v1/resources/healthcareprovider/${providerId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: HealcareProviderStore): Observable<IHealthcareProvider> {
    return this.http.post<IHealthcareProvider>(
      `${this.basePath}/v1/resources/healthcareprovider`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param providerId
   */
  public update(
    providerId: number,
    body?: any
  ): Observable<IHealthcareProvider> {
    return this.http.put<IHealthcareProvider>(
      `${this.basePath}/v1/resources/healthcareprovider/${providerId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all providers
   * @param page
   */
  public index(
    page: number,
    search?: string,
    country?: string
  ): Observable<Content<IHealthcareProvider>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(search != null && { search: search }),
        ...(country != null && { country: country }),
      },
    });

    return this.http.get<Content<IHealthcareProvider>>(
      `${this.basePath}/v1/resources/healthcareprovider`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param providerId
   */
  public view(providerId: number): Observable<IHealthcareProvider> {
    return this.http.get<IHealthcareProvider>(
      `${this.basePath}/v1/resources/healthcareprovider/${providerId}`,
      this.httpOptions
    );
  }

  /**
   * Update
   * Update a file
   * @param file
   * @param keymap
   * @param groupId
   */
  public massHcpImport(
    groupId: number,
    file?: File,
    keymap?: any
  ): Observable<any> {
    const formParams = new FormData();
    if (file) {
      formParams.append('file', file, file.name);
    }
    if (keymap) {
      formParams.append('keymap', JSON.stringify(keymap));
    }
    return this.http.post<any>(
      `${this.basePath}/v1/import/import_hcps/${groupId}`,
      formParams,
      this.httpOptionsWithFromData
    );
  }

  /**
   * mass activate
   * @param body
   */
  public massActivate(countrie_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/mass-activate`,
      { country_id: countrie_id },
      this.httpOptions
    );
  }

  /**
   * export all HCP of all country
   * @param body
   */
  public exportAllHCP(): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/export/export_all_healthcareproviders`,
      this.httpOptions
    );
  }
}
