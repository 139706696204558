import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebcamComponent implements OnInit {
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<WebcamComponent>,
    public notificationService: NotificationService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    let deviceExist = false;
    navigator.mediaDevices.enumerateDevices().then(res => {
      res.forEach(device => {
        if (device.kind === 'videoinput') {
          if (device.deviceId === '') {
            this.notificationService.warning(
              this.translateService.instant('common.autorize-camera')
            );
          }
          deviceExist = true;
        }
      });
      if (!deviceExist) {
        this.notificationService.warning(
          this.translateService.instant('common.camera-notExist')
        );
        this.dialogRef.close();
      }
    });

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then(stream => {
        if (!stream.active) {
          this.notificationService.danger(
            this.translateService.instant('common.camera-problem')
          );
          this.dialogRef.close();
        }
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
      })
      .catch(() => {
        this.notificationService.danger(
          this.translateService.instant('common.camera-problem')
        );
        this.dialogRef.close();
      });
  }

  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.closeModal(webcamImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  closeModal(webcamImage: WebcamImage): void {
    this.dialogRef.close(webcamImage);
  }
}
