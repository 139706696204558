import { IntouchData } from '../../models/IntouchData';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ExtractedFieldsMap,
  Extraction,
  IOcrModelResponse,
  OcrModelResponse,
} from '../../models/Ocr';
import { deprecate } from 'util';

@Injectable()
export class OCRService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * @deprecated The method should not be used
   * extractTextFromPDF
   * @param file the file in witch we extract data
   */
  public extractTextFromPDF(
    file: Blob,
    healthcareprovider_id: string
  ): Observable<Extraction> {
    const formParams = new FormData();

    formParams.append('file', file);
    formParams.append('healthcareprovider_id', healthcareprovider_id);

    return this.http.post<Extraction>(
      `${this.basePath}/v1/ocr/extract`,
      formParams,
      this.httpOptionsWithFromData
    );
  }

  /**
   * extractTextFromPDF
   * @param file the file in witch we extract data
   */
  public gptExtractTextFromPDF(file: Blob): Observable<IOcrModelResponse> {
    const formParams = new FormData();
    formParams.append('file', file);

    return this.http.post<IOcrModelResponse>(
      `${this.basePath}/v1/ocr/extract`,
      formParams,
      this.httpOptionsWithFromData
    );
  }

  /**
   * add_model
   * @param body the extracted fields position
   */
  add_model(body: ExtractedFieldsMap): Observable<OcrModelResponse> {
    return this.http.post<OcrModelResponse>(
      `${this.basePath}/v1/ocr/add_model`,
      body,
      this.httpOptions
    );
  }
}
