import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { IPublicMember } from 'src/app/core/models/models';
import { UseCaseRoleService } from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { ConfirmationComponent } from '../../create-edit-roles/confirmation/confirmation.component';

@Component({
  selector: 'app-pending-members',
  templateUrl: './pending-members.component.html',
  styleUrls: ['./pending-members.component.scss'],
})
export class PendingMembersComponent implements OnInit {
  members!: IPublicMember[];
  currentMember!: IPublicMember | undefined;
  isLoading: boolean = false;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: {
      members: IPublicMember[];
      group_id: number;
    },
    public dialogRef: MatLegacyDialogRef<PendingMembersComponent>,
    public useCaseRoleService: UseCaseRoleService,
    public service: ServicesService,
    public notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.members = this.data.members;
  }

  acceptInvitationMember(member: IPublicMember) {
    this.currentMember = member;
    this.isLoading = true;
    this.useCaseRoleService
      .acceptInvitation(member.id, this.data.group_id)
      .subscribe({
        next: (res: any) => {
          this.fetchPendingMember();
          this.notificationService.success(
            this.service.translateService.instant(
              'common.accept-member-successfull'
            )
          );
        },
        error: (err: any) => {
          this.notificationService.danger(
            this.service.translateService.instant(
              err.translate ?? 'errors.generic'
            )
          );
          this.currentMember = undefined;
          this.isLoading = false;
        },
        complete: () => {
          this.currentMember = undefined;
        },
      });
  }

  rejectInvitationMember(member: IPublicMember) {
    this.currentMember = member;
    this.isLoading = true;
    this.useCaseRoleService
      .rejectInvitation(member.id, this.data.group_id)
      .subscribe({
        next: (res: any) => {
          this.fetchPendingMember();
          this.notificationService.success(
            this.service.translateService.instant(
              'common.reject-member-successfull'
            )
          );
        },
        error: (err: any) => {
          this.notificationService.danger(
            this.service.translateService.instant(
              err.translate ?? 'errors.generic'
            )
          );
          this.currentMember = undefined;
          this.isLoading = false;
        },
        complete: () => {
          this.currentMember = undefined;
        },
      });
  }

  fetchPendingMember() {
    this.isLoading = true;
    this.useCaseRoleService.listWaitingMember(this.data.group_id).subscribe({
      next: (res: IPublicMember[]) => {
        this.members = res;
        this.isLoading = false;
        if (this.members.length === 0) this.dialogRef.close();
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
