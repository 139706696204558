import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pre-auth-layout',
  templateUrl: './pre-auth-layout.component.html',
  styleUrls: ['./pre-auth-layout.component.scss'],
})
export class PreAuthLayoutComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
