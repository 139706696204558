<div
  *ngIf="kind === '_SIMPLE'"
  class="w-fit flex items-center justify-around gap-3">
  <button
    tabIndex="0"
    class="w-full h-12"
    mat-raised-button
    color="primary"
    type="button">
    <label
      for="_simple-f"
      class="flex items-center cursor-pointer h-full w-full">
      <mat-icon>cloud_upload</mat-icon>
      <span class="font-semibold tracking-wide ml-2 text-sm"
        ><ng-content></ng-content
      ></span>
    </label>
    <input
      #fileInput
      [accept]="accept"
      class="hidden"
      id="_simple-f"
      [name]="name"
      (change)="handleChange($event)"
      type="file" />
  </button>
  <app-square-button
    *ngIf="caller !== '_HCP'"
    (click)="openWebcam()"
    [params]="{ size: 'semibig', theme: 'blue' }"
    type="button"
    class="py-4 flex items-center">
    <img appImg src="assets/images/camera.png" alt="camera" />
  </app-square-button>
</div>

<div
  *ngIf="kind === '_SIMPLE_FILE_EXPLORER'"
  class="w-fit flex items-center justify-around gap-3">
  <button mat-icon-button color="primary" type="button">
    <label for="simple-file-explorer" class="cursor-pointer h-fit w-fit">
      <mat-icon aria-label="create icon">insert_photo</mat-icon>
    </label>
    <input
      id="simple-file-explorer"
      type="file"
      class="hidden"
      [accept]="accept"
      (change)="handleChange($event)" />
  </button>
</div>

<ng-container
  *ngIf="kind === '_SPEED_DIAL'"
  [ngTemplateOutlet]="speedDial"></ng-container>

<div *ngIf="kind === '_WEBCAM'" class="flex items-center justify-center w-full">
  <label
    for="dropzone-file"
    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 relative">
    <img
      appImg
      *ngIf="webcamImage && !isManual"
      [src]="webcamImage.imageAsDataUrl"
      class="w-full h-full object-contain rounded-md"
      alt="webImage" />
    <img
      appImg
      *ngIf="value && isManual"
      [src]="sysImage"
      class="w-full h-full object-contain rounded-md"
      alt="system image" />

    <ng-container
      *ngIf="webcamImage || value"
      [ngTemplateOutlet]="speedDial"></ng-container>
    <button
      *ngIf="!webcamImage && !value"
      (click)="openWebcam()"
      type="button"
      [matTooltip]="'common.camera-label' | translate"
      matTooltipPosition="after"
      class="flex justify-center items-center w-10 h-10 absolute top-1 right-1 text-gray-500 hover:opacity-75 bg-white rounded-full border border-gray-200">
      <i class="ri-camera-line text-xl text-kimbo-blue"></i>
      <span class="sr-only">Download</span>
    </button>
    <div
      *ngIf="!sysImage"
      class="flex flex-col items-center justify-center pt-5 pb-6">
      <img
        appImg
        src="assets/gif/upload-animate.svg"
        class="w-24"
        alt="upload inimate" />
      <p
        class="mb-2 text-sm text-gray-500 dark:text-gray-400 flex flex-col justify-center text-center items-center py-3 px-8"
        [innerHtml]="'common.upload-message' | translate"></p>
      <p class="text-xs text-gray-500 dark:text-gray-400 text-center">
        {{ 'common.max-file-size' | translate }}
      </p>
    </div>
    <input
      *ngIf="!sysImage"
      id="dropzone-file"
      [accept]="accept"
      type="file"
      class="hidden"
      (change)="handleChange($event)" />
  </label>
</div>

<div
  *ngIf="kind === 'WITH_DRAGGABLE_ZONE'"
  class="container rounded-md w-full mx-auto flex flex-col justify-center gap-4 items-center cursor-pointer bg-kimbo-blue bg-opacity-5 text-kimbo-blue"
  appDnd
  (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    [accept]="accept"
    class="cursor-pointer"
    multiple
    #fileDropRef
    id="fileDropRef"
    (change)="fileBrowseHandler($event)" />

  <div><i class="ri-file-line text-2xl"></i></div>
  <h3 class="text-kimbo-blue">
    {{ dndExtraParams?.title }}
  </h3>
  <h3 class="text-kimbo-blue">
    {{ 'hcp-registration.or-label' | translate }}
  </h3>
  <label
    for="fileDropRef"
    class="flex items-center justify-center px-4 py-4 text-white bg-blue">
    <mat-icon class="mr-2">add</mat-icon>
    {{ 'hcp-registration.browse-file-label' | translate }}</label
  >
  <span class="text-sm text-dark-gray">
    {{ dndExtraParams?.hint }}
  </span>
  <span *ngIf="dndExtraParams?.error" class="text-sm text-red-pink">
    {{ dndExtraParams?.error }}
  </span>
</div>

<ng-template #speedDial>
  <div
    appClickOutside
    (clickOutside)="clickedOutsideOfSpeedDial()"
    class=" {{
      kind === '_WEBCAM' ? 'absolute top-1 right-1' : 'relative'
    }}  z-base">
    <div
      id="speed-dial-menu-click"
      class="flex {{
        speedDialisOpen ? 'flex' : 'hidden'
      }} flex-col items-center mb-4 space-y-2">
      <button
        (click)="openWebcam()"
        type="button"
        [matTooltip]="'common.camera-label' | translate"
        matTooltipPosition="after"
        class="flex justify-center items-center w-10 h-10 text-gray-500 hover:opacity-75 bg-white rounded-full border border-gray-200">
        <mat-icon color="primary">camera</mat-icon>

        <span class="sr-only">Download</span>
      </button>
      <button
        type="button"
        [matTooltip]="'common.document-upload-button' | translate"
        matTooltipPosition="after"
        class="flex justify-center items-center w-10 h-10 text-gray-500 hover:opacity-75 bg-white rounded-full border border-gray-200">
        <label
          class="flex justify-center items-center cursor-pointer h-full w-full"
          for="upload-button-tag">
          <mat-icon color="primary">cloud_upload</mat-icon>
          <span class="sr-only">Download</span>
        </label>
        <input
          #fileInput
          [accept]="accept"
          class="hidden"
          id="upload-button-tag"
          name="image"
          (change)="handleChange($event)"
          type="file" />
      </button>
    </div>
    <button
      (click)="toggleSpeedDialOpen()"
      [matTooltip]="'common.edit-button-label' | translate"
      matTooltipPosition="after"
      type="button"
      class="flex items-center justify-center text-white bg-blue hover:bg-opacity-75 rounded-full w-10 h-10 focus:ring-4 {{
        speedDialisOpen && 'rotate-[360deg]'
      }} hover: focus:ring-blue-300 focus:outline-none">
      <img
        appImg
        src="assets/svg/edit-g.svg"
        alt="upload icon"
        class="text-white w-5 h-5" />
      <span class="sr-only">Open actions menu</span>
    </button>
  </div>
</ng-template>

<ng-template #uploadInputSimple>
  <label class="flex items-center cursor-pointer" for="upload-button-tag">
    <div
      class="h-6 w-6 stroke-current"
      aria-label="Upload icon"
      [inlineSVG]="'assets/svg/upload.svg'"></div>
    <span class="font-semibold tracking-wide ml-2 text-sm">
      {{ 'kimbo-profile.image-button' | translate }}</span
    >
  </label>
  <input
    #fileInput
    [accept]="accept"
    class="hidden"
    id="upload-button-tag"
    name="image"
    (change)="handleChange($event)"
    type="file"
/></ng-template>
