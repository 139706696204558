import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-render',
  templateUrl: './image-render.component.html',
  styleUrls: ['./image-render.component.scss'],
})
export class ImageRenderComponent implements OnInit {
  @Input() img: string = '';
  @Input() classNames: string = '';
  @Input() imgClass: string = '';
  @Input() isIcon: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
