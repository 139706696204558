<h2 *ngIf="isModal" class="text-navy" mat-dialog-title>
  <span>{{ 'common.enter-coupon-code-label' | translate }}</span>
</h2>
<button
  *ngIf="isModal"
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button>
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content *ngIf="isModal">
  <ng-container [ngTemplateOutlet]="discountForm"></ng-container>
</mat-dialog-content>
<div *ngIf="!isModal">
  <ng-container [ngTemplateOutlet]="discountForm"></ng-container>
</div>
<ng-template #discountForm>
  <div class="flex justify-center items-center flex-col gap-4 mt-6">
    <img
      appImg
      src="assets/images/gift-card.png"
      class="w-80"
      alt="gift card illustration" />
    <div class="flex flex-col justify-center items-center gap-6 w-80">
      <input
        class="
           {{ input_cls.common }}
    "
        type="text"
        [placeholder]="'common.enter-coupon-code-label' | translate"
        id="code"
        (input)="handleEnteredCode($event)" />
      <button
        class="h-12 w-full"
        mat-raised-button
        color="primary"
        [disabled]="isLoading || isWrongCode"
        type="button"
        (click)="validateCode()">
        <app-spinner
          *ngIf="isLoading"
          class="inline-block h-6 w-6 filter-blue"></app-spinner>
        <span *ngIf="!isLoading">{{
          'common.validate-label' | translate
        }}</span>
      </button>
    </div>
  </div>
</ng-template>
