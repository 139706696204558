import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageResponse } from '../../models/language';
import { Language } from '../../models/Common';

@Injectable()
export class RestLanguageService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * List all languages
   */
  public index(): Observable<Language[]> {
    return this.http.get<Language[]>(
      `${this.basePath}/v1/resources/language`,
      this.httpOptions
    );
  }
}
