import { Pipe, PipeTransform } from '@angular/core';
import { IHealthcarepackage } from '../../models/Healthcarepackage';
import { TextLanguagesPipe } from '../text-languages/text-languages.pipe';

@Pipe({
  name: 'healthcarepackagesTostring',
})
export class HealthcarepackagesTostringPipe implements PipeTransform {
  textLanguagesPipe = new TextLanguagesPipe();
  transform(value: IHealthcarepackage[], ...args: unknown[]): unknown {
    if (value.length == 1) {
      return this.textLanguagesPipe.transform(value[0].title.languages);
    }
    let module = '';
    value.forEach((hcm, index) => {
      const virgule = index === value.length - 1 ? '' : ', ';
      module =
        module +
        this.textLanguagesPipe.transform(hcm.title.languages) +
        virgule;
    });

    return module;
  }
}
