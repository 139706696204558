import { IntouchData } from '../../models/IntouchData';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class IntouchService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * getpaiementData
   * @param country the country in which the payment will be made
   */
  public getpaiementData(country: string): Observable<IntouchData> {
    const queryParameters = new HttpParams({
      fromObject: {
        country: country,
      },
    });

    return this.http.get<IntouchData>(
      `${this.basePath}/v1/intouch/paiement_data`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * mobileCountries
   */
  public mobileCountries(): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/intouch/mobile_countries`,
      this.httpOptions
    );
  }
}
