<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<li
  class="px-4 flex items-center cursor-pointer focus:outline-none
{{
    isSelected
      ? 'font-semibold text-white bg-blue'
      : ' hover:bg-blue hover:bg-opacity-10 text-navy'
  }}
 {{ classNames }} {{ classNameForVariant }}"
  (click)="handleSelect()">
  <ng-content></ng-content>
</li>
