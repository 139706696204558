declare let google: any;
import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IGoogleLogin, ILoginResponse } from 'src/app/core/models/Login';
import { IUser } from 'src/app/core/models/models';
import { UserService } from 'src/app/core/rest-services/rest-services';
import { AuthentificationService } from 'src/app/core/services/authentication.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-google-one-tap',
  templateUrl: './google-one-tap.component.html',
  styleUrls: ['./google-one-tap.component.scss'],
})
export class GoogleOneTapComponent implements OnInit {
  googleClientId = environment.google_client_id;

  constructor(
    private authentificationService: AuthentificationService,
    private services: ServicesService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    google.accounts.id.initialize({
      client_id: this.googleClientId,
      ux_mode: 'popup',
      itp_support: 'true',
      login_uri: window.location.origin,
      auto_select: false,
      cancel_on_tap_outside: true,
      callback: this.handleCredentialResponse.bind(this),
    });
    if (this.services.sessionService.isGuest()) {
      timer(5000).subscribe(() => google.accounts.id.prompt());
    }
  }

  handleCredentialResponse(response: any): void {
    // Decoding  JWT token...

    let decodedToken: any | null = null;
    try {
      decodedToken = JSON.parse(atob(response?.credential.split('.')[1]));
      const googleLogin: IGoogleLogin = new IGoogleLogin(
        decodedToken,
        response?.credential,
        this.services.sessionService.getUser()?.id ?? undefined
      );
      this.services.eventService.publish('show:loading');
      this.authentificationService.loginWithGoogle(googleLogin).subscribe({
        next: (res: ILoginResponse) => {
          if (res.status_code !== 401) {
            this.userService.view(res.user_id).subscribe({
              next: (userRes: IUser) => {
                this.services.sessionService.deleteUser();
                this.services.sessionService.storeUser(userRes);
              },
            });
            this.services.eventService.publish('close:dialog');
          }
        },
        error: (err: any) => {
          console.log(err);

          this.notificationService.danger(
            this.services.translateService.instant(
              err.translate ?? 'errors.generic'
            )
          );
          this.services.eventService.publish('hide:loading');
        },
      });
    } catch (e) {
      this.notificationService.danger(
        this.translateService.instant('errors.invalid-google-account')
      );
      console.error('Error while trying to decode token', e);
    }
  }
}
