import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { Title } from 'src/app/core/models/Common';
import { MetricQuestions } from 'src/app/core/models/MetricQuestions';
import { BuyedPackageService } from 'src/app/core/rest-services/rest-services';
import { UseCaseHealthPackageService } from 'src/app/core/rest-services/usecases/usecase-healthpackage.service';
import { EventService } from 'src/app/core/services/event.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';

@Component({
  selector: 'app-metric-form-modal',
  templateUrl: './metric-form-modal.component.html',
  styleUrls: ['./metric-form-modal.component.scss'],
})
export class MetricFormModalComponent implements OnInit {
  questions!: {
    title: Title;
    type: string;
    value?: string;
    id: number;
    answer_id?: number;
  }[];
  isLoading = false;
  input_cls = INPUT_CLASSES;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      buyedpackage_id: number;
    },
    public dialogref: MatDialogRef<MetricFormModalComponent>,
    private buyedPackageService: BuyedPackageService,
    public packageService: UseCaseHealthPackageService,
    public eventService: EventService
  ) {}

  ngOnInit(): void {
    this.getBuyedPackage();
  }

  getBuyedPackage() {
    this.isLoading = true;
    this.buyedPackageService.view(this.data.buyedpackage_id).subscribe({
      next: (res: IBuyedpackage) => {
        this.questions = [];
        res.metric_questions!.forEach((q: MetricQuestions) => {
          const answers = q.ansewrs;
          const value =
            answers.length > 0 && answers[0].data
              ? answers[0].data!.label ?? null
              : null;
          const answer_id = answers.length > 0 ? answers[0].id : null;
          this.questions.push({
            title: q.title!,
            type: q.data!.type,
            value: value,
            id: q.id!,
            answer_id: answer_id,
          });
        });
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  save() {
    this.isLoading = true;
    this.packageService
      .addAnswer({
        datas: this.questions,
        buyedpackage_id: this.data.buyedpackage_id,
      })
      .subscribe({
        next: () => {
          this.eventService.publish('buyedpackage_status_change');
          this.dialogref.close();
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
}
