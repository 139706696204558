<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<mat-sidenav-container [hasBackdrop]="true" class="example-container">
  <mat-sidenav
    #drawer
    class="example-sidenav"
    [mode]="breackpointMatche ? 'side' : 'over'"
    position="end"
    [fixedInViewport]="options.value.fixed"
    [fixedTopGap]="options.value.top"
    [fixedBottomGap]="options.value.bottom"
    class="w-full sm:w-[400px]">
    <app-usage-logs></app-usage-logs>
  </mat-sidenav>

  <div class="example-sidenav-content">
    <main
      class="w-full lg:w-[calc(100%-288px)] lg:ml-72 bg-[#f4f8fc] min-h-screen transition-all main relative">
      <div
        *ngIf="hasSuperAdminRole && canShowLogs"
        (click)="toggleLogPannel()"
        matRipple
        matRippleColor="primary"
        class="rounded-l-md shadow-backdrop-light p-2 hover:opacity-70 absolute top-[170px] right-0 bg-white z-[10] cursor-pointer">
        <i class="ri-shield-user-line text-kimbo-blue text-2xl"></i>
      </div>
      <app-sidebar
        *ngIf="isSideBarOpen"
        appHoverEffectSidebar
        class="relative -left-80"
        (triggerEvent)="handleSideBarEvent($event)"></app-sidebar>
      <!-- navbar -->
      <app-header
        (headerTriggerEvent)="handleSideBarEvent($event)"></app-header>
      <!-- Content -->
      <div class="px-6 pb-6 bg-[#f4f8fc]">
        <router-outlet></router-outlet>
      </div>
      <!-- End Content -->
    </main>
  </div>
</mat-sidenav-container>
