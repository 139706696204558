<div
  class="{{ classNames }} flex relative  {{
    borderadius ? borderadius : 'rounded-2xl'
  }} bg-center bg-no-repeat bg-cover {{
    variant === 'contain' ? 'backdrop-glass' : ''
  }}
   {{ showBg === true ? ' bg-light-gray ' : '' }}
     {{ showBg === false && isMobileLayout === true ? 'max-h-[126px]' : '' }} {{
    showBg === false && isMobileLayout === false ? 'max-h-[200px]' : ''
  }}
   "
  [style.backgroundImage]="'url(' + src + ')'">
  <div
    *ngIf="variant === 'contain'"
    class="absolute inset-0 z-[2] bg-center bg-no-repeat bg-transparent bg-contain box-shadow {{
      borderadius ? borderadius : 'rounded-2xl'
    }}"
    [style.backgroundImage]="'url(' + src + ')'"></div>
</div>
