import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-component',
  templateUrl: './info-component.component.html',
  styleUrls: ['./info-component.component.scss'],
})
export class InfoComponentComponent {
  @Input() info = '';
  @Input() color = 'text-blue';
  @Input() height = 'h-auto';
  @Input() type: 'WARNING' | 'INFO' = 'INFO';
  @Input() buttonAddclassNames = 'rounded-bl-xl rounded-tr-3xl';
  isVisible = false;

  constructor() {}

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  hide(): void {
    this.isVisible = false;
  }
}
