<div class="p-2 relative">
  <div
    class="flex flex-col items-start mb-5 outline-none md:flex-row md:items-center sticky top-0 z-dropdown">
    <label class="relative w-full" for="search-input">
      <div
        class="absolute top-[0.20rem] left-0 w-6 h-6 mt-4 ml-4 pointer-events-none fill-dark-gray text-dark-gray grayscale"
        aria-label="search icon"
        [inlineSVG]="'assets/svg/search.svg'"></div>
      <input
        (input)="search($event)"
        type="text"
        value=""
        class="w-full pl-14 {{ input_cls.common }}"
        id="search-input"
        [placeholder]="'kimbo-layout.search-placeholder' | translate" />
    </label>
  </div>
  <div class="h-[360px] overflow-y-scroll px-1">
    <p
      class="text-sm font-semibold pb-4 text-blue flex justify-start items-center">
      <mat-icon class="mr-1" color="primary">flag</mat-icon>
      {{ 'common.choose-country' | translate }}
    </p>
    <ul class="flex flex-wrap items-start justify-start">
      <div
        *ngFor="let country of countryList"
        (click)="selectedOption(country)"
        class="w-full flex items-center justify-between mb-5 cursor-pointer">
        <div
          class="flex rounded-lg px-4 w-full bg-white justify-between items-center shadow">
          <div class="flex py-2 w-full justify-between items-center">
            <div class="flex justify-start items-center w-10/12">
              <span
                class="w-8 h-8 flex-shrink-0 flag ff-round ff-lg flag-{{
                  country.label | lowercase
                }}"></span>
              <span
                class="font-semibold text-sm pl-2 overflow-hidden whitespace-nowrap text-ellipsis"
                >{{ 'common.' + country.label | lowercase | translate }}</span
              >
            </div>
            <i
              class="ri-arrow-right-line text-xl p-1 text-kimbo-blue cursor-pointer pl-1"></i>
          </div>
        </div>
      </div>
    </ul>
  </div>
</div>
