import { Injectable } from '@angular/core';
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from 'google-libphonenumber';
import { MAPCOUNTRYCODEPHONECODE } from '../utils/data';

let utils = PhoneNumberUtil.getInstance();
// Here are some country codes supported by "google-libphonenumber"
// but we don't have flags for them, so let's just ignore those for now.
@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {
  unsupportedCoutryCodes = [
    '001',
    'AC',
    'CW',
    'FK',
    'GF',
    'IO',
    'NF',
    'NU',
    'PM',
    'SH',
    'SS',
    'TK',
    'WF',
    'XK',
  ];

  isValidPhoneNumberForCountryCode(
    phoneNumber: string,
    countryCode: string
  ): boolean {
    try {
      const numberParsed = utils.parse(phoneNumber, countryCode);
      return (
        utils.isValidNumber(numberParsed) &&
        utils.isValidNumberForRegion(numberParsed, countryCode)
      );
    } catch {
      // If some error is thrown by "google-libphonenumber"
      // it's probably invalid phone number.
      return false;
    }
  }

  getCountryCodeFromPhoneNumber(phoneNumber: string): string {
    try {
      const numberParsed = utils.parse(phoneNumber);
      return utils.getRegionCodeForNumber(numberParsed) ?? 'US';
    } catch {
      // Fallback to US format.
      return 'US';
    }
  }

  getPhoneCodeByCountryCode(countryCode: string): string | undefined {
    const result = MAPCOUNTRYCODEPHONECODE.find(
      item => item.countryCode.toUpperCase() === countryCode.toUpperCase()
    );
    return result ? `+${result.phoneCode}` : undefined;
  }

  removeDashesFromPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/-/g, '');
  }

  ensureCoutryCodeInPhoneNumber(
    phoneNumber: string,
    countryCode: string
  ): string {
    try {
      return utils.format(
        utils.parseAndKeepRawInput(phoneNumber, countryCode),
        PNF.E164
      );
    } catch {
      return phoneNumber;
    }
  }

  removeCoutryCodeInPhoneNumber(
    phoneNumber: string,
    countryCode?: string
  ): number | undefined {
    let rawPhoneNumber: libphonenumber.PhoneNumber;
    if (countryCode)
      rawPhoneNumber = utils.parseAndKeepRawInput(phoneNumber, countryCode);
    else
      rawPhoneNumber = utils.parseAndKeepRawInput(
        phoneNumber,
        this.getCountryCodeFromPhoneNumber(phoneNumber)
      );
    return rawPhoneNumber.getNationalNumber();
  }

  formatPhoneNumber(phoneNumber: string, countryCode?: string): string {
    try {
      let countryCodeForPhoneNumber: string | undefined = countryCode;

      if (!countryCodeForPhoneNumber) {
        countryCodeForPhoneNumber =
          this.getCountryCodeFromPhoneNumber(phoneNumber);
      }

      return utils.format(
        utils.parse(phoneNumber, countryCodeForPhoneNumber),
        PNF.INTERNATIONAL
      );
    } catch {
      return phoneNumber;
    }
  }

  findCountryCodeByPhoneCode = (phoneCode: string): string | undefined => {
    const result = MAPCOUNTRYCODEPHONECODE.find(
      item => item.phoneCode === phoneCode
    );
    return result ? result.countryCode : undefined;
  };
}
