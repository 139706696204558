import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  public contact!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contact: string;
    },
    public dialogRef: MatDialogRef<ConfirmationComponent>
  ) {}

  ngOnInit(): void {
    this.contact = this.data.contact;
  }

  close() {
    this.dialogRef.close(true);
  }
}
