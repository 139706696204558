import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject,
  OnDestroy,
} from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { noop } from 'src/app/core/utils/misc';
import { OptionObject } from 'src/app/core/utils/type';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Note, NoteStore } from 'src/app/core/models/Note';
import { NoteService } from 'src/app/core/rest-services/rest-services';
import { SessionService } from 'src/app/core/services/session.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from 'src/app/core/services/services.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss'],
})
export class NoteModalComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean = false;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  addFollowUpFormOpen = false;
  input_cls = INPUT_CLASSES;
  disabled = false;
  noteTypeOptions: OptionObject[] = [];
  selectedOption!: OptionObject;
  translations: any = {
    noteTypeByDoctor: '',
    noteTypebyStaff: '',
    noteTypeOther: '',
  };
  translationKeys: string[] = [
    'hcp-common.note-type-by-doctor',
    'hcp-common.note-type-by-staff',
    'hcp-common.note-type-other',
  ];
  form!: FormGroup;
  isError: boolean = false;
  isSuccess: boolean = false;
  noteStore?: NoteStore;
  incommingNote?: Note;

  $languageChange!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { action: string; b_id: number; state: Note },
    public dialogRef: MatDialogRef<NoteModalComponent>,
    private languageService: LanguageService,
    private fb: FormBuilder,
    private noteService: NoteService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private services: ServicesService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.listenForLanguageChangeEvents();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.$languageChange && this.$languageChange.unsubscribe();
  }

  public initForm(): void {
    this.form = this.fb.group({
      textNote: ['', Validators.required],
    });

    this.selectedOption = {
      label: this.translations.noteTypeByDoctor,
      value: this.translations.noteTypeByDoctor,
    };

    if (this.data.action === '_EDIT') {
      this.selectedOption = this.selectedOption = {
        label: this.data.state?.type,
        value: this.data.state?.type,
      };
      this.form.patchValue({
        textNote: this.data!.state.text,
      });
    }
  }

  get f() {
    return this.form.controls;
  }

  close(): void {
    this.dialogRef.close('_ACT');
  }

  handleClose = (event: any) => {
    this.isLoading ? noop : this.close();
  };

  public listenForLanguageChangeEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }

  save() {
    this.isLoading = true;
    if (this.selectedOption !== null || this.selectedOption !== undefined) {
      this.noteStore = this.initExpectedNote(this.form.value);
      if (this.data.action === '_CREATE') {
        this.noteService.store(this.data.b_id, this.noteStore).subscribe({
          next: (res: Note) => {
            this.notificationService.success(
              this.translateService.instant('forms.success-created-note')
            );
          },
          error: error => {
            this.notificationService.danger(
              this.translateService.instant('errors.generic')
            );
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.close();
          },
        });
      } else {
        this.noteService
          .update(this.data.b_id, this.data.state.id, this.noteStore)
          .subscribe({
            next: (res: Note) => {
              this.notificationService.success(
                this.translateService.instant('forms.success-update-note')
              );
            },
            error: error => {
              this.notificationService.danger(
                this.translateService.instant('errors.generic')
              );
              this.isLoading = false;
            },
            complete: () => {
              this.isLoading = false;
              this.close();
            },
          });
      }
    }
  }

  initExpectedNote(formValue: any): NoteStore {
    let noteStore: NoteStore = new NoteStore(
      this.selectedOption!.label,
      formValue.textNote
    );
    return noteStore;
  }

  handleNoteTypeDropdownEvent(event: any): void {
    this.selectedOption = event;
  }

  setFilter(event: any): void {
    if (event === 'open') this.getTranslations();
    else this.selectedOption = event;
  }

  getFilter(): void {
    this.noteTypeOptions = [
      {
        label: this.translations.noteTypeByDoctor,
        value: this.translations.noteTypeByDoctor,
      },
      {
        label: this.translations.noteTypebyStaff,
        value: this.translations.noteTypebyStaff,
      },
      {
        label: this.translations.noteTypeOther,
        value: this.translations.noteTypeOther,
      },
    ];
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.noteTypeByDoctor =
        translations['hcp-common.note-type-by-doctor'];
      this.translations.noteTypebyStaff =
        translations['hcp-common.note-type-by-staff'];
      this.translations.noteTypeOther =
        translations['hcp-common.note-type-other'];
      this.getFilter();
    });
  }
}
