import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServicesService } from './services.service';
import { ErrorsService } from '../rest-services/rest-services';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private router: Router) {}
  errors: any[] = [];

  handleError(error: any) {
    const services = this.injector.get(ServicesService);
    const apiService = this.injector.get(ErrorsService);

    let stack = error.stack;
    let message = error.message;

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const chunkFailedMessage_ = /Loading chunk [^\s]+ failed/;
    if (
      chunkFailedMessage.test(error.message) ||
      chunkFailedMessage_.test(error.message)
    ) {
      window.location.reload();
      return;
    }

    if (!this.errors.includes(message)) {
      this.errors.push(message);
      if (environment.name !== 'development') {
        var data = {
          message: message,
          stack: stack,
          navigator:
            'URL : ' +
            this.router.url +
            ' - Nav : ' +
            services.utilsService.detectBrowserName() +
            ' ' +
            services.utilsService.detectBrowserVersion() +
            ' - V: ' +
            environment.appVersion,
          version: environment.appVersion,
        };
        apiService.post_error(data).subscribe((data: any) => {});
        console.error(error);
      }
    } else {
      if (environment.name === 'development') {
      }
    }
  }
}
