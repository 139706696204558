<div
  class="flex items-center w-full md:w-fit
{{ classNames }}"
  appClickOutside
  (clickOutside)="clickedOutside()">
  <div
    *ngIf="isOpen && backdrop"
    class="fixed z-modal-backdrop inset-0 bg-blue opacity-50"
    (click)="close()"></div>

  <div
    class="relative w-full md:w-fit {{ isOpen && backdrop ? 'z-modal' : '' }} ">
    <button
      *ngIf="caller === '_COUNTRY'"
      class="h-8 uppercase flex items-center bg-transparent rounded-full px-1"
      matRipple
      matRippleColor="rgba(30,104,194,.1)"
      color="primary"
      type="button"
      id="dropdownTrigger"
      (click)="openfilterSelect()">
      <div class="flex items-center gap-2">
        <span
          *ngIf="pickValue?.value !== '_ALL'"
          class="{{
            countryIconSize ? countryIconSize : 'h-8 w-8'
          }} flex-shrink-0 flag ff-round ff-lg flag-{{
            pickValue?.src | lowercase
          }}"></span>
        <i
          *ngIf="pickValue?.value === '_ALL'"
          class="ri-{{ pickValue?.src }} text-2xl text-kimbo-blue"></i>
        <span
          *ngIf="withPickValueText && pickValue?.value !== '_ALL'"
          class="text-kimbo-blue font-medium text-sm"
          >{{ 'common.' + pickValue?.src | lowercase | translate }}</span
        >
        <span
          *ngIf="withPickValueText && pickValue?.value === '_ALL'"
          class="text-kimbo-blue font-medium text-sm"
          >{{ pickValue?.label }}</span
        >
        <img
          appImg
          src="assets/svg/arrow-bottom.svg"
          class="h-6 w-6 {{ isOpen ? 'rotate-180' : '' }}"
          alt="arrow" />
      </div>
    </button>
    <button
      *ngIf="
        caller === '_GLOBAL' || caller === '_SPECIALTY' || caller === '_CITY'
      "
      class="{{
        caller === '_CITY'
          ? 'h-8 items-center bg-transparent rounded-full px-1'
          : 'h-12'
      }}  uppercase"
      mat-button
      color="primary"
      id="dropdownTrigger"
      (click)="openfilterSelect()">
      <div class="flex items-center gap-2">
        <img
          *ngIf="caller === '_GLOBAL'"
          appImg
          src="assets/svg/filter.svg"
          class="h-6 w-6"
          alt="arrow" />
        <span *ngIf="withPickValueText">{{ pickValue?.label }}</span>
        <img
          appImg
          src="assets/svg/arrow-bottom.svg"
          class="h-6 w-6 {{ isOpen ? 'rotate-180' : '' }}"
          alt="arrow" />
      </div>
    </button>
    <button
      *ngIf="caller === '_BENEFIT'"
      matRipple
      matRippleColor="rgba(30,104,194,.1)"
      class="h-10 text-base font-medium border-1 flex items-center gap-2 justify-between bg-white text-kimbo-blue-darker border-kimbo-blue-light rounded-md px-4"
      (click)="openfilterSelect()">
      <span *ngIf="withPickValueText">{{ pickValue?.label }}</span>
      <i class="ri-arrow-down-s-fill text-xl"></i>
    </button>
    <ul
      *ngIf="isOpen"
      id="dropdown"
      class="absolute w-fit bg-[#f4f8fc] max-h-[192px] {{
        formRight
          ? 'right-0'
          : formCenter
          ? '-left-[100px] sm:left-0 '
          : 'left-0'
      }}  rounded-lg shadow-light-backdrop-sm overflow-y-scroll mt-1 z-dropdown {{
        caller === '_SPECIALTY' ? 'h-[100px]' : ''
      }}">
      <app-option
        *ngFor="let option of options"
        [InCOption]="option"
        [isSelected]="option.label === pickValue?.label"
        (selectEvent)="selectedOption($event)"
        class="{{ optionWidth ? optionWidth : ' w-75' }} h-12 "
        classNames="{{ optionWidth ? optionWidth : ' w-75' }} h-12">
        <span
          *ngIf="caller === '_COUNTRY' && option?.value !== '_ALL'"
          class="h-8 w-8 flag ff-round ff-lg flag-{{
            option?.src | lowercase
          }} mr-3"></span>
        <i
          *ngIf="caller === '_COUNTRY' && option?.value === '_ALL'"
          class="ri-{{ pickValue?.src }} text-2xl mr-3"></i>
        <span
          *ngIf="
            caller === '_GLOBAL' ||
            caller === '_SPECIALTY' ||
            caller === '_CITY' ||
            caller === '_COUNTRY' ||
            caller === '_BENEFIT'
          "
          >{{ option.label }}</span
        >
      </app-option>
    </ul>
  </div>
</div>
