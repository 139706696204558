export enum UserType {
  Guest = 'guest',
  Inactive = 'inactive',
  Registred = 'registred',
}
export enum Language {
  EN = 'en',
  FR = 'fr',
}

export enum GroupType {
  Admin = 'admin',
  Kimbo = 'family',
  Hcp = 'hcp',
  Clinique = 'Clinique' /* In some strange cases 👀 */,
  Companie = 'company',
  Benefit = 'benefit',
}

export enum AdminRoleType {
  Operation = 'operation',
  Support = 'support',
  Finance = 'finance',
  Marketing = 'marketing',
  Super = 'super_admin',
}

export enum HCPInvoiceType {
  DIAGNOSTIC = 'diagnostic',
  LABORATORY_RESULTS = 'laboratory_results',
  INTERIM_INVOICE = 'intermediate_bill',
  MEDICAL_REPORT = 'medical_report',
  MEDICAL_CERTIFICATE = 'medical_certificate',
  FINAL_INVOICE = 'final_invoice',
}

export enum PatientFilterCriteria {
  Contacts_last_activity = 'contacts.last_activity',
  Contacts_adresse_name = 'contacts.adresse.name',
}

export enum Order {
  Ascendent = 'ASC',
  Descendent = 'DESC',
}

export enum VerificationType {
  byHcp = 'byhcp',
  byGroup = 'bygroup',
}

export enum toastPosition {
  topRight = 'toast-top-right',
  topCenter = 'toast-top-center',
  bottomCenter = 'toast-bottom-center',
  bottomRight = 'toast-bottom-right',
}

export enum PaymentStatus {
  Payed = 'payed',
  Waiting = 'waiting',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export enum BuyedpackageStatus {
  HcpAssignation = 'hcp_assignation',
  PatientAssignation = 'patient_assignation',
  Ready = 'ready',
  Verification = 'verification',
  InUse = 'inuse',
  Finished = 'finished',
}

export enum HealthPackageType {
  Campaign = 'campaign',
  Kimbo = 'kimbo',
}

export enum HealthProviderType {
  CLINIC = 'clinic',
  PHARMACY = 'pharmacy',
  OTHER = 'other',
}

export enum TransactionTarget {
  Payement = 'payment',
  BuyedPackage = 'buyed_package',
  DiscountCard = 'discount_card',
  Invoice = 'invoice',
}

export enum Permission {
  Owner = 'owner',
  Member = 'member',
}

export enum ImpactEnum {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  ALL = 'ALL',
}

export enum PERIOD {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
  WEEK = 'sevent_day',
  CUSTOM = 'custom',
}

export enum PERIOD_ {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
  WEEK = 'week',
}

export enum CampaignStatus {
  PAYED = 'payed',
  WAITING = 'waiting',
  FINISHED = 'finished',
  ALL = 'all',
  TRASHED = 'trashed',
}

export enum PaymentMothod {
  STRIPE = 'stripe',
  BANK_TRANSFER = 'bank_transfer',
  MOBILE = 'mobile',
}

export enum TransactionTag {
  CREDIT = 'credit',
  DEBIT = 'debit',
  BUY = 'buy',
  REST = 'rest',
}

export enum TransactionType {
  TOPUP = 'topup',
  GIFT = 'gift',
  HEALTHCAREPACKAGEBUY = 'healthcarepackage_buy',
  HEALTHCAREPACKAGEREST = 'healthcarepackage_rest',
  HEALTHCAREPACKAGEDEBIT = 'healthcarepackage_debit',
  CAMPAIGNBUY = 'campaign_buy',
  DISCOUNTCARD = 'discountcard',
  DISCOUNTCARDINFLUENCER = 'discountcard_influencer',
  TRANSFERTCREDITS = 'transfert_credit',
  RECIVECREDITS = 'receive_credit',
  RECOMMENDATIONBUY = 'recommendation_buy',
  BUYEDPACKAGE_CANCLED = 'buyedpackage_cancelled',
}

export enum PayableType {
  GROUP = 'App\\Models\\Group',
  HEALTHCAREPROVIDER = 'App\\Models\\Healthcareprovider',
}

export enum UserType {
  INACTIVE = 'inactive',
  REGISTERED = 'registered',
}

export enum StatusLogs {
  CURRENTSERVICEOVER = 'current_service_over',
  INPATIENTSERVICES = 'inpatient_services',
  SPECIALISTCARE = 'specialist_care',
  PRIMARYCARE = 'primary_care',
  ATLABORATORY = 'at_laboratory',
  ATIMAGERY = 'at_imagery',
  ATDRUGSTORE = 'at_drugstore',
  NOTSTARTED = 'not_started',
  TREATMENTCOMPLETED = 'treatment_completed',
  ATRECEPTIONIST = 'at_receptionist',
  NOSHOWED = 'noshowed_success',
}

export enum RecommendationStatus {
  ACCEPTED = 'accepted',
  WAITING = 'waiting',
  REFUSED = 'refused',
}

export enum DiscountActionType {
  CREDIT = 'credit',
  CREDITSPECIFICGROUP = 'credit_specific_group',
}

export enum PostLoginAtions {
  PAYHEALTHCARE = 'PAYHEALTHCARE',
  CREATEGROUP = 'CREATEGROUP',
  EDITGROUP = 'EDITGROUP',
  DISCOUNTCARD = 'DISCOUNTCARD',
}

export enum kimboCareStationName {
  CAMEROUN = 'Cameroun',
  IVOIRECOAST = "Côte d'Ivoire",
  KENYA = 'Kenya',
}

export enum AlertStatus {
  PENDING = 'PENDING',
  READ = 'READ',
  CLOSE = 'CLOSED',
  ALL = 'all',
}

export enum AlertType {
  BUYEDPACKAGE = 'App\\Models\\Buyedpackage',
  HEALTHCAREPROVIDER = 'App\\Models\\Healthcareprovider',
  PAYMENT = 'App\\Models\\Payment',
}

export enum DiscountCardType {
  OFFER = 'offer',
  INFLUENCER = 'influencer',
  GIFCARD = 'giftcard',
}

export enum AdminLogTag {
  ADD = 'add',
  ADD_ADMIN = 'add_admin',
  UPDATE = 'update',
  DELETE = 'delete',
  ATTACH = 'attach',
  DETACH = 'detach',
  GENERATE = 'generate',
}

export enum AdminLogsTarget {
  Buyedpackage = 'Buyedpackage',
  Transaction = 'Transaction',
  Setting = 'Setting',
  Payment = 'Payment',
  MetricQuestion = 'MetricQuestion',
  Invoice = 'Invoice',
  Insight = 'Insight',
  Healthcareprovider = 'Healthcareprovider',
  Healthcarepackage = 'Healthcarepackage',
  Group = 'Group',
  File = 'File',
  Discount = 'Discount',
  Currencie = 'Currencie',
  Countrie = 'Countrie',
  Campaign = 'Campaign',
  Alert = 'Alert',
  Adminrole = 'Adminrole',
  User = 'User',
}

export enum ConversationnableType {
  BUYEDPACKAGE = 'App\\Models\\Buyedpackage',
  GROUP = 'App\\Models\\Group',
  HEALTHCAREPROVIDER = 'App\\Models\\Healthcareprovider',
  USER = 'App\\Models\\User',
}

export enum TransactionableType {
  BUYEDPACKAGE = 'App\\Models\\Buyedpackage',
  GROUP = 'App\\Models\\Group',
  HEALTHCAREPROVIDER = 'App\\Models\\Healthcareprovider',
  USER = 'App\\Models\\User',
}

export enum ConversationTargetType {
  BUYEDPACKAGE = 'App\\Models\\Buyedpackage',
}

export enum ConversationType {
  BUYEDPACKAGE = 'buyedpackage',
}

export enum STORAGE_TYPE {
  INTERNAL = 'internal',
  ONLINE = 'online',
}

export enum RatingType {
  BuyedPackageRating = 'buyed_package_rating',
  KimboBuyRating = 'kimbo_buy_rating',
}

export enum IRewiewType {
  MARK_INTEREST = 'mark_interest',
}
