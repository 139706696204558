<div class="w-full {{ customHeight }}">
  <div
    class="flex flex-col justify-center h-full items-center content-center text-center">
    <img
      appImg
      [src]="imgPath"
      class=" {{ iconWidth ? iconWidth : 'w-60' }} "
      alt="no-content image" />
    <p
      class="mt-6 text-gray {{
        textWidth ? textWidth : 'text-lg md:text-2xl'
      }} font-bold  text-center">
      {{ message }}
    </p>
  </div>
</div>
