import { BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AdminLogsTarget } from 'src/app/core/enums/enums';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { Menu, NavService } from 'src/app/core/services/navservice';
import { ServicesService } from 'src/app/core/services/services.service';
import { SwitcherService } from 'src/app/core/services/switcher.service';
import { AdminRoleService } from 'src/app/core/services/admin-role.service';
import { PermissionEnums } from 'src/app/core/enums/permission-enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnDestroy, OnInit {
  @ViewChild('drawer') sidenav!: MatSidenavContainer;
  public menuItems!: Menu[];
  isSideBarOpen = true;
  breackpointMatche = false;
  download_app_state!: string;
  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });
  activeUrl = '';
  logsTargets: { path: string; value: AdminLogsTarget }[] = [
    { path: '/admin', value: AdminLogsTarget.User },
    { path: '/admin/role', value: AdminLogsTarget.Adminrole },
    { path: '/admin/settings', value: AdminLogsTarget.Setting },
    { path: '/admin/country', value: AdminLogsTarget.Countrie },
    { path: '/admin/campaigns', value: AdminLogsTarget.Campaign },
    {
      path: '/admin/healthcare-providers',
      value: AdminLogsTarget.Healthcareprovider,
    },
    {
      path: '/admin/healthcare-package',
      value: AdminLogsTarget.Healthcarepackage,
    },
    { path: '/admin/buyedpackages', value: AdminLogsTarget.Buyedpackage },
    { path: '/admin/currencies', value: AdminLogsTarget.Currencie },
    { path: '/admin/transactions', value: AdminLogsTarget.Payment },
    { path: '/admin/invoice', value: AdminLogsTarget.Invoice },
    { path: '/admin/discount', value: AdminLogsTarget.Discount },
  ];
  canShowLogs = false;
  hasSuperAdminRole = false;
  closeSideBarEvent$!: Subscription;

  constructor(
    public SwitcherService: SwitcherService,
    public navService: NavService,
    private elementRef: ElementRef,
    public breakpointMatcherService: BreakpointMatcherService,
    private services: ServicesService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private adminRoleService: AdminRoleService
  ) {
    this.hasSuperAdminRole = this.adminRoleService.hasPermission(
      PermissionEnums.SuperAdmin
    );
    this.services.sessionService.updateUserStorage();
    this.breakpointMatcherService
      .initObserver('lg')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
          this.isSideBarOpen = true;
        } else {
          this.breackpointMatche = false;
          this.isSideBarOpen = false;
        }
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = this.router.url;
        this.canShowLogs = !!this.logsTargets.find(
          (log: { path: string; value: AdminLogsTarget }) =>
            log.path === this.activeUrl
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.closeSideBarEvent$?.unsubscribe();
  }

  ngOnInit(): void {
    this.closeSideBarEvent$ = this.services.eventService.subscribe(
      'admin-logs:close',
      () => {
        this.sidenav.close();
      }
    );
  }

  toggleLogPannel(): void {
    this.sidenav.open();
    this.services.eventService.publish('show-admin:logs');
  }

  clearToggle() {
    const html = this.elementRef.nativeElement.ownerDocument.documentElement;
    html?.setAttribute('toggled', 'close');
    document.querySelector('#responsive-overlay')?.classList.remove('active');
  }

  handleSideBarEvent($event: string): void {
    if ($event === 'closed') this.isSideBarOpen = false;
    else if ($event === 'toggle') {
      this.isSideBarOpen = !this.isSideBarOpen;
    }
  }
}
