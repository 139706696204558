<button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button>

<div class="flex flex-col justify-center items-center">
  <img
    appImg
    src="assets/gif/forgot-password.gif"
    alt="forgot password"
    class="w-10/12" />
  <p class="text-center mb-3 text-navy text-sm">
    {{ 'sign-in.otp-limit-message' | translate }}
  </p>
  <button mat-button color="primary" (click)="close()">
    {{ 'common.get-it-button' | translate }}
  </button>
</div>
