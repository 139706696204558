<div class="absolute inset-0 flex items-center z-modal-backdrop">
  <button
    type="button"
    (click)="close()"
    class="flex items-center z-header justify-center text-white bg-white hover:bg-opacity-75 rounded-full w-8 h-8 ring-4 focus:outline-none absolute top-10 right-3">
    <img
      appImg
      src="assets/svg/close-invoice.svg"
      alt="close icon"
      class="text-white w-6 h-6" />
  </button>

  <!-- Aperçu d'image -->
  <pinch-zoom *ngIf="data.isImage" class="relative z-dropdown">
    <img [src]="source_secure" alt="image" />
  </pinch-zoom>

  <!-- Aperçu PDF/Blob -->
  <div *ngIf="!data.isImage && isPdf" class="w-full h-full relative z-dropdown">
    <div class="row-container">
      <ngx-extended-pdf-viewer
        class="second-row"
        [src]="source"
        [useBrowserLocale]="true"
        [textLayer]="true"
        [showHandToolButton]="true">
      </ngx-extended-pdf-viewer>
    </div>
  </div>

  <!-- Spinner de chargement -->
  <div
    *ngIf="!source_secure"
    class="flex absolute inset-0 items-center justify-center z-base">
    <app-spinner class="inline-block h-full w-full filter-white"></app-spinner>
  </div>
</div>
