<h2 class="text-navy" mat-dialog-title>
  <span>{{ 'kimbo-home.treatment-details' | translate }}</span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="Example icon button with a delete icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div *ngIf="isLoading" class="flex items-center justify-center w-full h-20">
    <app-spinner class="inline-block w-8 h-8 ml-4"></app-spinner>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="py-6 overflow-y-auto h-fit sm:p-8 md:py-8">
      <app-patient-modal-header
        [buyedPackage]="buyedPackage!"
        [_buyedPackage]="_buyedPackage!"
        [caller]="data.caller"
        (closeModal)="closeModal()"></app-patient-modal-header>
      <h1 class="mt-10 mb-8 text-xl font-bold text-navy">
        {{ 'hcp-home.follow-up' | translate }}
      </h1>
      <app-filter-by-status-dropdown
        *ngIf="_buyedPackage?.status === 'inuse'"
        class="block mb-8 w-full sm:w-1/3"
        [buyedPackage]="_buyedPackage"
        (triggerEvent)="
          handleFilterDropdownEvent($event)
        "></app-filter-by-status-dropdown>
      <div>
        <ol class="relative mt-4 border-l border-gray-200 dark:border-gray-700">
          <li *ngIf="_buyedPackage.logs.length > 0" class="mb-6 ml-4">
            <div
              class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time
              class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
              >{{ _toLocaleDateTime(_buyedPackage.logs[0].created_at) }}</time
            >
            <div class="pt-3">
              {{ 'common.' + _buyedPackage.logs[0].status | translate }}
              {{
                _buyedPackage.logs[0].status !== 'checkout_success' &&
                _buyedPackage.logs[0].status !== 'noshowed_success'
                  ? calculateTime(_buyedPackage.logs[0])
                  : ''
              }}
            </div>
          </li>
          <div *ngIf="allFollowUpIsVisible">
            <li
              *ngFor="let log of _buyedPackage.logs; let i = index"
              class="mb-6 ml-4 {{ i === 0 ? 'hidden' : '' }}">
              <div
                class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time
                class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                >{{ _toLocaleDateTime(log.created_at) }}</time
              >
              <div class="pt-3">
                {{ 'common.' + log.status | translate }}
                {{
                  i > 0
                    ? calculateTime2Dates(log, _buyedPackage.logs[i - 1])
                    : ''
                }}
              </div>
            </li>
          </div>
          <li *ngIf="_buyedPackage.logs.length > 1" class="mb-6 ml-4">
            <div
              class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time
              class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              <button
                class="h-8"
                mat-button
                color="primary"
                (click)="toggleAllFlowUpVisible()"
                [disabled]="isLoading"
                type="button">
                <div class="flex items-center gap-1">
                  <span *ngIf="!allFollowUpIsVisible">{{
                    'kimbo-layout.show-more' | translate
                  }}</span>
                  <span *ngIf="allFollowUpIsVisible">{{
                    'kimbo-layout.show-less' | translate
                  }}</span>
                  <img
                    appImg
                    src="assets/svg/arrow-bottom.svg"
                    class="h-6 w-6 {{
                      allFollowUpIsVisible ? 'rotate-180' : ''
                    }}"
                    alt="arrow-bottom" />
                </div>
              </button>
            </time>
            <div class="pt-3"></div>
          </li>
          <li *ngIf="_buyedPackage.logs.length === 0" class="mb-6 ml-4">
            <div
              class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time
              class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              {{ 'hcp-home.no-action' | translate }}
            </time>
          </li>
        </ol>
      </div>
      <div *ngIf="!isLoading && role.group.type === groupType.Admin">
        <div class="my-5">
          <app-title-custom
            (click)="collapseSections('_FINANCIALINFOS')"
            [classNames]="'text-[18px] bg-opacity-10 text-kimbo-blue'"
            [withArrow]="true"
            bgColor="bg-kimbo-blue"
            [isOpen]="isFinancialInfosOpen"
            caller="_GOBAL_TRANS_BG"
            icon="attach_money">
            <span slot="globaltransbg">{{
              'hcp-common.financial-metrics-label' | translate
            }}</span>
          </app-title-custom>
        </div>
        <div
          *ngIf="isFinancialInfosOpen"
          class="flex flex-wrap items-center justify-center gap-2 gap-y-2 mt-5">
          <app-box
            class="w-full md:w-1/2-with-gap"
            boxClasses="hover:shadow-inner-blue-sm overflow-hidden"
            shape="_TWO"
            [paramsShapeTwo]="{
              icon:  'wallet-3-line',
              title: 'hcp-common.amount-topup-label' | translate : {amount : _toMoney(financialMetrics.amountTopUp, userCurrency.rate),currency:userCurrency.code},
              value: _toMoney(financialMetrics.amountTopUp, hcpCurrency.rate)+' '+hcpCurrency.code,
              titleClasses: 'bg-green text-green',
            }">
          </app-box>
          <app-box
            *ngIf="_buyedPackage.status !== buyedpackageStatus.Finished"
            class="w-full md:w-1/2-with-gap"
            boxClasses="hover:shadow-inner-blue-sm overflow-hidden"
            shape="_TWO"
            [paramsShapeTwo]="{
            icon:  'wallet-3-line',
            title: 'hcp-common.total-traitment-fees-label' | translate : {amount : _toMoney(financialMetrics.traitmentFees, userCurrency.rate),currency:userCurrency.code},
            value: _toMoney(financialMetrics.traitmentFees, hcpCurrency.rate)+' '+hcpCurrency.code,
            titleClasses: 'bg-green text-green',
          }">
          </app-box>
          <app-box
            class="w-full md:w-1/2-with-gap"
            boxClasses="hover:shadow-inner-blue-sm overflow-hidden"
            shape="_TWO"
            [paramsShapeTwo]="{
              icon:  'wallet-3-line',
              title: 'hcp-common.insue-amount-label' | translate : {amount : _toMoney(financialMetrics.InUseAmount, userCurrency.rate),currency:userCurrency.code},
              value: _toMoney(financialMetrics.InUseAmount, hcpCurrency.rate)+' '+hcpCurrency.code,
              titleClasses: 'bg-green text-green',
            }">
          </app-box>
          <app-box
            class="w-full md:w-1/2-with-gap"
            boxClasses="hover:shadow-inner-blue-sm overflow-hidden"
            shape="_TWO"
            [paramsShapeTwo]="{
              icon:  'wallet-3-line',
              title: 'hcp-common.kimbocare-margin-label' | translate: {percentage: _buyedPackage.margin},
              value: _toMoney(financialMetrics.kimbocareFees, hcpCurrency.rate)+' '+hcpCurrency.code,
              titleClasses: 'bg-green text-green',
            }">
          </app-box>
          <app-box
            *ngIf="_buyedPackage.status === buyedpackageStatus.Finished"
            class="w-full md:w-1/2-with-gap"
            boxClasses="hover:shadow-inner-blue-sm overflow-hidden"
            shape="_TWO"
            [paramsShapeTwo]="{
              icon:  'wallet-3-line',
              title: 'hcp-common.remaining-amount-label' | translate:  {amount : _toMoney(financialMetrics.amountLeft, userCurrency.rate),currency:userCurrency.code},
              value: _toMoney(financialMetrics.amountLeft, hcpCurrency.rate)+' '+hcpCurrency.code,
              titleClasses: 'bg-green text-green',
            }">
          </app-box>
        </div>
      </div>
      <div
        *ngIf="
          data.caller !== '_INCOMMING' && role.group.type !== groupType.Admin
        ">
        <div class="my-5">
          <app-title-custom
            (click)="collapseSections('_DOCUMENTS')"
            [classNames]="'text-[18px] bg-opacity-10 text-kimbo-blue'"
            [withArrow]="true"
            [isOpen]="isDocumentsOpen"
            bgColor="bg-kimbo-blue"
            caller="_GOBAL_TRANS_BG"
            icon="attach_file">
            <span slot="globaltransbg">Documents</span>
          </app-title-custom>
        </div>
        <div *ngIf="isDocumentsOpen">
          <div
            class="p-4 my-6 text-sm text-kimbo-blue rounded-sm bg-kimbo-blue bg-opacity-5 flex justify-center gap-2 items-center"
            role="alert">
            <i class="ri-information-2-line text-xl"></i>
            <span>{{ 'hcp-home.patient-no-see-doc-message' | translate }}</span>
          </div>
          <app-treatment-documents
            (triggerEvent)="handleDocumentActions($event)"
            [_buyedPackage]="_buyedPackage"
            [canEdit]="data.caller !== '_FINISHED'"></app-treatment-documents>
        </div>
      </div>
      <div *ngIf="role.group.type !== groupType.Admin" class="w-full">
        <div class="my-5">
          <app-title-custom
            (click)="collapseSections('_NOTES')"
            [classNames]="'text-[18px] bg-opacity-10 text-kimbo-blue'"
            [isOpen]="isNotesOpen"
            bgColor="bg-kimbo-blue"
            caller="_GOBAL_TRANS_BG"
            icon="insert_drive_file">
            <span slot="globaltransbg">Notes</span>
          </app-title-custom>
        </div>
        <div
          *ngIf="isNotesOpen"
          class="flex items-center justify-center text-sm p-6 rounded-sm gap-3 bg-kimbo-blue bg-opacity-10 text-kimbo-blue">
          <i class="ri-information-2-line text-xl"></i>
          {{ 'hcp-common.treatment-note-infos' | translate }}
        </div>
        <div *ngIf="isNotesOpen" class="pt-5">
          <app-treatment-notes
            [buyedPackage]="_buyedPackage"
            class="inline-block w-full mb-6"
            [canEdit]="data.caller !== '_FINISHED'"></app-treatment-notes>
        </div>
      </div>
      <div
        class="w-full my-5"
        *ngIf="
          (buyedPackage.status === buyedpackageStatus.InUse ||
            buyedPackage.status === buyedpackageStatus.Finished) &&
          buyedPackage.healthcarepackages_hcp[0]?.healthcarepackage.type ===
            healthPackageType.Campaign &&
          _buyedPackage.metric_questions &&
          _buyedPackage.metric_questions!.length > 0
        ">
        <app-title-custom
          (click)="collapseSections('_ADDITIONALINFOS')"
          [classNames]="'text-[18px] bg-opacity-10 text-kimbo-blue'"
          [isOpen]="isAdditionalInfos"
          bgColor="bg-kimbo-blue"
          caller="_GOBAL_TRANS_BG"
          icon="info">
          <span slot="globaltransbg">{{ 'hcp-common.more' | translate }}</span>
        </app-title-custom>

        <div
          *ngIf="isAdditionalInfos"
          class="flex flex-wrap items-center justify-between w-full">
          <div *ngFor="let item of questions" class="w-full md:w-1/2-with-gap">
            <div class="flex items-start w-full mt-4">
              <app-form-field
                *ngIf="item.type !== 'boolean'"
                [label]="item.title.languages | textLanguages"
                class="w-full mt-1 md:mt-0">
                <input
                  class="{{ input_cls.common }}"
                  type="{{ item.type !== 'integer' ? item.type : 'number' }}"
                  [(ngModel)]="item.value" />
              </app-form-field>

              <div *ngIf="item.type === 'boolean'" class="flex items-center">
                <label for="corporate" class="mr-3">
                  {{ item.title.languages | textLanguages }}</label
                >
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    class="sr-only peer"
                    [(ngModel)]="item.value" />
                  <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>

          <div class="flex justify-end w-full mt-6">
            <button
              class="h-12"
              mat-stroked-button
              color="primary"
              (click)="save()">
              <app-spinner
                *ngIf="isLoading"
                class="inline-block w-6 h-6 filter-white"></app-spinner>
              <span *ngIf="!isLoading">{{
                'forms.save-button' | translate
              }}</span>
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          buyedPackage.healthcarepackages_hcp[0]?.healthcarepackage.type !==
            healthPackageType.Campaign &&
          (buyedPackage.status === buyedpackageStatus.InUse ||
            buyedPackage.status === buyedpackageStatus.Finished)
        "
        class="w-full">
        <div class="mt-5 mb-3">
          <app-title-custom
            (click)="collapseSections('_RECOMMANDATIONS')"
            [classNames]="'text-[18px] bg-opacity-10 text-kimbo-blue'"
            [withArrow]="true"
            [isOpen]="isRecommandationOpen"
            bgColor="bg-kimbo-blue"
            caller="_GOBAL_TRANS_BG"
            icon="lightbulb">
            <span slot="globaltransbg">{{
              'hcp-home.recommendation-heading' | translate
            }}</span>
          </app-title-custom>
        </div>

        <div
          *ngIf="isRecommandationOpen"
          class="flex items-center justify-center text-sm p-6 rounded-sm gap-3 bg-kimbo-blue bg-opacity-10 text-kimbo-blue">
          <i class="ri-information-2-line text-xl"></i>
          {{ 'hcp-common.treatment-recommedation-infos' | translate }}
        </div>

        <app-treatment-recommendation
          *ngIf="isRecommandationOpen"
          (triggerEvent)="handleRecommendatiotActions()"
          [buyedPackage]="_buyedPackage"
          class="inline-block w-full mb-6"
          [canEdit]="
            data.caller !== '_FINISHED'
          "></app-treatment-recommendation>
      </div>
      <div
        *ngIf="
          buyedPackage.healthcarepackages_hcp[0]?.healthcarepackage.type !==
            healthPackageType.Campaign &&
          role.group.type !== groupType.Admin &&
          (buyedPackage.status === buyedpackageStatus.InUse ||
            buyedPackage.status === buyedpackageStatus.Ready)
        "
        class="w-full">
        <div class="mt-5">
          <app-title-custom
            (click)="collapseSections('_CONVERSATION')"
            [classNames]="'text-[18px] bg-opacity-10 text-kimbo-blue'"
            [isOpen]="isConversationOpen"
            bgColor="bg-kimbo-blue"
            caller="_GOBAL_TRANS_BG"
            icon="chat">
            <span slot="globaltransbg">{{
              'common.chat-with-sender' | translate
            }}</span>
          </app-title-custom>
        </div>

        <div *ngIf="isConversationOpen" class="pt-5">
          <app-conversation-channel
            [buyedPackage]="_buyedPackage"
            caller="HCP"
            [readOnly]="true"></app-conversation-channel>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>

<ng-template #checkOut>
  {{ 'hcp-common.check-out' | translate }}
  <div
    class="ml-4 fill-current"
    aria-label="logout icon"
    [inlineSVG]="'assets/svg/out.svg'"></div>
</ng-template>
