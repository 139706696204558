<h2 class="text-navy" mat-dialog-title>
  <span *ngIf="data.action === '_CREATE'">{{
    'forms.add-document-heading' | translate
  }}</span>
  <span *ngIf="data.action === '_EDIT'">{{
    'forms.edit-document-heading' | translate
  }}</span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="Example icon button with a delete icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class="flex flex-wrap items-start justify-between mt-8">
    <app-input-dropdown
      *ngIf="data.action !== '_EDIT'"
      class="w-full md:w-1/2-with-gap"
      (triggerEvent)="handleDocumentTypeDropdownEvent($event)"
      [options]="documentTypeOptions"
      [pickValue]="selectedOption"
      [name]="'forms.document-type-label' | translate"></app-input-dropdown>
    <app-form-field
      *ngIf="!disabled && data.action !== '_EDIT'"
      [label]="
        'common.final-amount' | translate : { currency: currency.symbol }
      "
      class="w-full mt-6 md:w-1/2-with-gap md:mt-0"
      [error]="
        !billAmount
          ? ('errors.required' | translate)
          : billAmount > validAmount
          ? ('errors.invalid-bill-amount'
            | translate
              : {
                  balance: validAmount,
                  currency: currency.symbol
                })
          : billAmount < fileAmount
          ? ('errors.small-bill-amount'
            | translate
              : {
                  balance: fileAmount,
                  currency: currency.symbol
                })
          : ''
      ">
      <input
        class="
       {{ input_cls.common }}
     {{ disabled ? input_cls.disabled : '' }}"
        type="number"
        [placeholder]="'3000000'"
        (input)="setBillAmount($event)"
        [value]="billAmount"
        id="name" />
    </app-form-field>
    <app-form-field [label]="'Document' | translate" class="w-full mt-6">
      <app-file-input
        *ngIf="data.action === '_EDIT'"
        accept="image/jpg, image/jpeg, image/png, application/pdf"
        caller="_HCP"
        name="image"
        (changeEvent)="setFieldValue($event)"
        id="create-contact.image"
        theme="blue-outline"
        class="w-full">
        {{ 'kimbo-profile.image-button' | translate }}
      </app-file-input>
      <ul class="w-full py-4">
        <li>
          <app-file-upload [editable]="false" [filename]="fileName">
          </app-file-upload>
        </li>
      </ul>
    </app-form-field>

    <div
      class="flex flex-col justify-center flex-shrink-0 w-full h-24 mt-6 sm:items-end border-t-1 border-gray">
      <div
        class="flex flex-col items-center justify-between w-full gap-2 py-4 mt-6 md:py-0 xs:flex-row xs:justify-between">
        <button
          class="w-full h-12 sm:w-fit"
          mat-stroked-button
          color="primary"
          [disabled]="isLoading"
          type="button"
          (click)="close('')">
          {{ 'forms.cancel-button' | translate }}
        </button>
        <button
          *ngIf="data.action !== '_EDIT' && !disabled"
          class="w-full h-12 sm:w-fit"
          mat-raised-button
          color="primary"
          [disabled]="
            !(
              billAmount &&
              billAmount <= validAmount &&
              billAmount >= fileAmount
            ) || isLoading
          "
          (click)="save()"
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>

        <button
          *ngIf="data.action !== '_EDIT' && disabled"
          class="w-full h-12 sm:w-fit"
          mat-raised-button
          color="primary"
          [disabled]="isLoading"
          (click)="save()"
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>

        <button
          *ngIf="data.action === '_EDIT'"
          class="w-full h-12 sm:w-fit"
          mat-raised-button
          color="primary"
          [disabled]="isLoading"
          (click)="save()"
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
