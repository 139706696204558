<h2 class="text-navy" mat-dialog-title>
  <span>{{ data.title ?? 'common.confirm-button' | translate }}</span>
</h2>
<button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class="text-navy mt-6">
    <span
      *ngIf="!data.message"
      [innerHtml]="'common.confirm-modal-content' | translate"></span>
    <span
      *ngIf="data.message"
      class="block my-4"
      [innerHtml]="data.message"></span>
  </div>
  <div *ngIf="!data.withAction" class="flex justify-end">
    <button mat-button color="warn" (click)="handleChoise('_NO')">
      {{ 'common.no-button' | translate }}
    </button>
    <button
      mat-button
      class="ml-4"
      color="primary"
      (click)="handleChoise('_YES')">
      {{ 'common.yes-button' | translate }}
    </button>
  </div>
  <div *ngIf="data.withAction" class="flex justify-end">
    <button
      mat-button
      color="primary"
      class="uppercase"
      (click)="handleChoise('_YES')">
      {{ data.actionLabel }}
      <i class="ri-arrow-right-line ml-2 text-lg"></i>
    </button>
  </div>
</mat-dialog-content>
