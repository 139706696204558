import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './app-rating.component.html',
  styleUrls: ['./app-rating.component.scss'],
})
export class AppRatingComponent implements OnInit {
  @Input() rate!: number;
  @Input() classNames: string = '';
  repetitions = Array(5).fill(0);

  constructor() {}

  ngOnInit(): void {}
}
