import {
  getCountryByPhoneNumber,
  makeCurrencytoShow,
  showMobilePaiement,
} from 'src/app/core/utils/functions';
import { PaymentMobileService } from '../../core/services/paymentMobile.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  GroupType,
  PaymentMothod,
  PaymentStatus,
} from 'src/app/core/enums/enums';
import { Currency } from 'src/app/core/models/Common';
import { PayTopUp } from 'src/app/core/models/Payement';
import { ITransaction } from 'src/app/core/models/Transaction';
import { IRole, IUser } from 'src/app/core/models/User';
import {
  PaymentService,
  TransactionService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { isEmptyString, isString } from 'src/app/core/utils/core';
import { toHealthCredit, toMoney } from 'src/app/core/utils/misc';
import { OptionObject } from 'src/app/core/utils/type';
import { IntouchService } from 'src/app/core/rest-services/usecases/intouch.service';
import { IntouchData } from 'src/app/core/models/IntouchData';
import { PaymentWaitingInfoComponent } from '../payment-waiting-info/payment-waiting-info.component';

declare let sendPaymentInfos: any;
@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent implements OnInit, OnDestroy {
  paymentMethods = {
    CREDIT_CARD: {
      name: this.translate.instant('kimbo-send-credits.credit-card-label'),
      img: ['assets/images/visa.png', 'assets/images/mastercard.png'],
    },
    BANK_TRANSFER: {
      name: this.translate.instant('kimbo-send-credits.bank-transfert-label'),
      img: [],
    },
  };
  currency!: Currency;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isTransitioning = false;
  currencyOptions!: OptionObject[];
  selectedCurrencie?: OptionObject;
  pickCurrency!: Currency;
  currencyVariants!: number[];
  minTopUpAmount!: number;
  maxTopUpAmount!: number;
  amountSelected?: number;
  input_cls = INPUT_CLASSES;
  error: string | undefined;
  iscrediCard = false;
  ismobile = false;
  isbankTransfert = false;
  isWrongAmount = true;
  intervalID: any;
  paymentSuccess = false;
  currentUser!: IUser;
  country?: string;
  mobileData!: IntouchData;
  groupType = GroupType;
  currentRole: IRole;
  chfCurrency!: Currency;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { currencies: Currency[] },
    public dialogRef: MatDialogRef<TopUpComponent>,
    private translate: TranslateService,
    public services: ServicesService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private payementService: PaymentService,
    private transactionService: TransactionService,
    private userService: UserService,
    private paymentMobileService: PaymentMobileService,
    private intouchService: IntouchService
  ) {
    this.currentRole = this.services.sessionService.getCurrentRole();
  }

  ngOnDestroy(): void {
    if (this.intervalID) clearInterval(this.intervalID);
  }

  ngOnInit(): void {
    this.currency = this.services.sessionService.getCurrencie();
    this.data.currencies.forEach(c => {
      if (c.code === 'CHF') {
        this.chfCurrency = c;
      }
    });
    this.selectedCurrencie = {
      label: String(
        this.currency.id +
          '::' +
          this.currency.symbol +
          '::' +
          this.currency.rate
      ),
      value: `${makeCurrencytoShow(this.currency.code)}`,
    };
    this.currencyVariants = this.getTopUpAmountsForCurrency();
    this.minTopUpAmount = this.getMinTopUpAmountForCurrency();
    this.maxTopUpAmount = this.getMaxTopUpAmountForCurrency();
    this.currentUser = this.services.sessionService.getUser();
    this.data && this.data.currencies && this.initCurrenciesFilter();
  }

  getMobileData() {
    const country = getCountryByPhoneNumber(
      this.services.sessionService.getUser().tel
    );
    if (!country) {
      return;
    }
    this.isTransitioning = true;
    this.intouchService.getpaiementData(country).subscribe({
      next: (res: IntouchData) => {
        this.ismobile = true;
        this.mobileData = res;
      },
      error: error => {
        this.translateService.instant(error.translate);
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  get showMobilePaiement(): boolean {
    const country = getCountryByPhoneNumber(
      this.services.sessionService.getUser().tel
    );
    return (
      country !== undefined &&
      showMobilePaiement(this.selectedCurrencie!.value, country)
    );
  }

  topUp(): void {
    this.isTransitioning = true;
    const topUp: PayTopUp = new PayTopUp(
      this.services.sessionService.getCurrentRole().group_id,
      Number(this.selectedCurrencie!.label.split('::')[0]),
      this.iscrediCard
        ? PaymentMothod.STRIPE
        : this.isbankTransfert
        ? PaymentMothod.BANK_TRANSFER
        : PaymentMothod.MOBILE,
      this._toHealthCredit(this.amountSelected!)
    );
    this.payementService.payTopUp(topUp).subscribe({
      next: (res: any) => {
        if (this.iscrediCard) {
          //window.open(res.url, '_blank');
          this.services.modalService
            .openModal(PaymentWaitingInfoComponent, {
              width: '500px',
              height: 'auto',
              data: {
                stripeInfos: res,
                id: res.payment_id,
                totalPrice: this.amountSelected,
                callerTo: 'TOPUP',
                currency: this.pickCurrency,
              },
            })
            .subscribe(() => {
              if (this.intervalID) clearInterval(this.intervalID);
            });
          this.paymentStatusObserver(res.payment_id);
        } else if (this.isbankTransfert) {
          this.services.modalService
            .openModal(PaymentWaitingInfoComponent, {
              width: '500px',
              height: 'auto',
              data: {
                bankInfos: res.bankinfo,
              },
            })
            .subscribe(() => {
              if (this.intervalID) clearInterval(this.intervalID);
              this.close();
            });
        } else if (this.ismobile) {
          this.services.modalService
            .openModal(PaymentWaitingInfoComponent, {
              width: '500px',
              height: 'auto',
              data: {
                isMobile: true,
                id: res.payment_id,
                totalPrice: this.amountSelected,
                callerTo: 'TOPUP',
                currency: this.pickCurrency,
              },
            })
            .subscribe(() => {
              if (this.intervalID) clearInterval(this.intervalID);
              this.close();
            });

          this.paymentStatusObserver(res.payment_id);

          this.paymentMobileService.callTouchpay(
            Number(res.order_number),
            this.mobileData.agency,
            this.mobileData.secureKey,
            this.mobileData.webSite,
            res.first_name!,
            res.last_name!,
            res.email!,
            Number(res.amount!),
            res.city!
          );
        }
      },
      error: () => {
        this.notificationService.danger(
          this.translateService.instant('common.completed-error')
        );
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  paymentStatusObserver(id: number): void {
    this.intervalID = setInterval(() => {
      this.fetchPaymentStatus(id);
    }, 1000);
  }

  fetchPaymentStatus = (id: number) => {
    this.isTransitioning = false;
    this.transactionService.view(id).subscribe({
      next: (res: ITransaction) => {
        if (res.status === PaymentStatus.Payed) {
          this.paymentSuccess = true;
          this.notificationService.success(
            this.translateService.instant(
              'kimbo-top-up.payment-success-notification-content'
            )
          );
          this.services.eventService.publish('healthcredit:used');
          this.getUser();
          clearInterval(this.intervalID);
        } else if (res.status === PaymentStatus.Cancelled) {
          this.paymentSuccess = false;
          this.notificationService.danger(
            this.translateService.instant(
              'kimbo-top-up.payment-cancelled-notification'
            )
          );
        }
      },
      error: error => {
        error = this.translateService.instant('errors.generic');
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  };

  getIsXAForXOF(): boolean {
    return ['XOF', 'XAF', 'CFA'].includes(this.selectedCurrencie!.value);
  }

  getIsKES() {
    return this.selectedCurrencie!.value === 'KES';
  }

  getTopUpAmountsForCurrency(): number[] {
    if (this.getIsXAForXOF()) {
      return this.currentRole.group.type === GroupType.Kimbo
        ? [5000, 10000, 20000, 50000]
        : [100000, 250000, 500000, 1000000];
    }

    if (this.getIsKES()) {
      return this.currentRole.group.type === GroupType.Kimbo
        ? [2000, 5000, 6000, 10000]
        : [25000, 55000, 150000, 250000];
    }

    return this.currentRole.group.type === GroupType.Kimbo
      ? [30, 60, 90, 120]
      : [1000, 2500, 5000, 10000];
  }

  getMinTopUpAmountForCurrency(): number {
    if (this.getIsXAForXOF()) {
      return this.currentRole.group.type === GroupType.Kimbo ? 5000 : 100000;
    }

    if (this.getIsKES()) {
      return this.currentRole.group.type === GroupType.Kimbo ? 1000 : 25000;
    }

    return this.currentRole.group.type === GroupType.Kimbo ? 30 : 1000;
  }

  getAmountInKHC(amount: number): number {
    if (this.getIsXAForXOF()) {
      return amount / 250;
    }

    if (this.getIsKES()) {
      return amount / 50;
    }

    return amount;
  }

  getMaxTopUpAmountForCurrency(): number {
    if (this.getIsXAForXOF()) {
      return this.currentRole.group.type === GroupType.Kimbo
        ? 150000
        : 30000000;
    }

    if (this.getIsKES()) {
      return this.currentRole.group.type === GroupType.Kimbo ? 50000 : 10000000;
    }

    return this.currentRole.group.type === GroupType.Kimbo ? 2000 : 50000;
  }

  handleAmountChange(event: any): void {
    this.amountSelected = Number(String(event.target.id).split('-')[1]);
    this.error = undefined;
    this.isWrongAmount = false;
  }

  handleCustomAmount(event: any): void {
    const amount = event.target.value;
    if (isEmptyString(amount.trim())) {
      this.error = this.translateService.instant('errors.required');
      this.isWrongAmount = true;
    } else if (isString(amount.trim()) && !Number(amount.trim())) {
      this.error = this.translateService.instant('errors.must-be-num');
      this.isWrongAmount = true;
    } else if (Number(amount.trim()) < this.minTopUpAmount) {
      this.error = this.translateService.instant(
        'errors.top-up-amount-too-low',
        {
          minAmount:
            this.minTopUpAmount +
            ' ' +
            this.selectedCurrencie!.label.split('::')[1],
        }
      );
      this.isWrongAmount = true;
    } else if (Number(amount.trim()) > this.maxTopUpAmount) {
      this.error = this.translateService.instant(
        'errors.top-up-amount-too-high',
        {
          maxAmount:
            this.maxTopUpAmount +
            ' ' +
            this.selectedCurrencie!.label.split('::')[1],
        }
      );
      this.isWrongAmount = true;
    } else {
      this.amountSelected = amount;
      this.error = undefined;
      this.isWrongAmount = false;
    }
  }

  getUser(): void {
    const currentRole = this.services.roleService.getCurrentRole();
    const user = this.services.sessionService.getUser();
    this.isTransitioning = true;
    this.userService.view(user.id).subscribe({
      next: (userRes: IUser) => {
        this.services.sessionService.deleteUser();
        this.services.sessionService.storeUser(userRes);
        const role: IRole = userRes.roles?.find(
          (role: IRole) => role.id === currentRole.id
        )!;
        this.services.sessionService.deleteCurrentRole();
        this.services.sessionService.storeCurrentRole(role);
      },
      error: (err: any) => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  handleCurrencyDropdownEvent(currency: OptionObject): void {
    this.selectedCurrencie = currency;
    this.pickCurrency = this.data.currencies.find(
      c => c.id === Number(this.selectedCurrencie!.label.split('::')[0])
    )!;
    this.currencyVariants = this.getTopUpAmountsForCurrency();
    this.minTopUpAmount = this.getMinTopUpAmountForCurrency();
    this.maxTopUpAmount = this.getMaxTopUpAmountForCurrency();
    this.amountSelected = undefined;
    this.isWrongAmount = true;
  }

  initCurrenciesFilter(): void {
    this.currencyOptions = [];
    this.data.currencies.forEach(currencie => {
      this.currencyOptions.push({
        label: String(
          currencie.id + '::' + currencie.symbol + '::' + currencie.rate
        ),
        value: `${makeCurrencytoShow(currencie.code)}`,
      });
    });
  }

  public get showCreditCard(): boolean {
    if (this.chfCurrency) {
      return (
        toMoney(
          toHealthCredit(
            this.amountSelected!,
            Number(this.selectedCurrencie!.label.split('::')[2])
          ),
          this.chfCurrency.rate
        ) <= 10000
      );
    }
    return this.amountSelected! <= 10000;
  }

  paymentMethodChecked(event: any): void {
    if (event.target.id === '_CARD_NUMBER') {
      this.iscrediCard = true;
      this.ismobile = false;
      this.isbankTransfert = false;
    } else if (event.target.id === '_BANK') {
      this.iscrediCard = false;
      this.ismobile = false;
      this.isbankTransfert = true;
    } else if (event.target.id === '_MOBILE_PAYMENT') {
      this.iscrediCard = false;
      this.isbankTransfert = false;
      this.ismobile = false;
      this.getMobileData();
    }
  }

  _toHealthCredit(amount: number) {
    return toHealthCredit(
      amount,
      Number(this.selectedCurrencie!.label.split('::')[2])
    );
  }

  close(): void {
    this.dialogRef.close();
  }
}
