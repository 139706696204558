import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PayRecommendation } from '../../models/models';

@Injectable()
export class RecommendationService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Pay recommendation
   * @param body
   */
  public payRecommendation(
    recommendation_id: number,
    body: PayRecommendation
  ): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/recommendation/${recommendation_id}/pay`,
      body,
      this.httpOptions
    );
  }

  /**
   * Delete recommendation
   * @param recommendation_id
   */
  public deleteRecommendation(recommendation_id: number): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/v1/recommendation/${recommendation_id}/delete`,
      this.httpOptions
    );
  }

  /**
   * View recommendation
   * @param recommendation_id
   */
  public viewRecommendation(recommendation_id: number): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/v1/recommendation/${recommendation_id}/view`,
      this.httpOptions
    );
  }

  /**
   * Refused recommendation
   * @param recommendation_id
   */
  public rejectRecommendation(recommendation_id: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/recommendation/${recommendation_id}/refuse`,
      this.httpOptions
    );
  }
}
