import { Component, Input, OnInit } from '@angular/core';

const themes = {
  blue: 'focus:shadow-blue',
  'light-blue': 'focus:shadow-lighter-blue',
};

const sizes = {
  normal: 'h-6 w-6 rounded',
  medium: 'h-8 w-8 rounded',
  semibig: 'h-12 w-12 rounded',
  big: 'h-16 w-16 rounded',
};

type Props = {
  size: keyof typeof sizes;
  theme: keyof typeof themes;
};

@Component({
  selector: 'app-square-button',
  templateUrl: './square-button.component.html',
  styleUrls: ['./square-button.component.scss'],
})
export class SquareButtonComponent implements OnInit {
  @Input() params: Props = {
    size: 'normal',
    theme: 'blue',
  };
  @Input() classNames: string = '';

  sizeClassNames: string = '';
  themeClassNames: string = '';

  constructor() {}

  ngOnInit(): void {
    this.sizeClassNames = sizes[this.params.size];
    this.themeClassNames = themes[this.params.theme];
  }
}
