import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { HCPInvoiceType } from 'src/app/core/enums/enums';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { Currency } from 'src/app/core/models/Common';
import { File as ModelFile, FileResponse } from 'src/app/core/models/File';
import {
  FileService,
  CurrencyService,
} from 'src/app/core/rest-services/rest-services';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import {
  shortenFileName,
  toHealthCredit,
  toMoney,
} from 'src/app/core/utils/misc';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss'],
})
export class DocumentModalComponent implements OnInit {
  input_cls = INPUT_CLASSES;
  invalid = false;
  disabled = true;
  isLoading: boolean = false;
  fileName?: string;
  file?: File;
  isError: boolean = false;
  isSuccess: boolean = false;
  incommingNote?: File;
  documentTypeOptions: OptionObject[] = [];
  selectedOption!: OptionObject;
  translations: any = {
    finalBillCardTitle: '',
    intermediateBill: '',
    medicalCertificate: '',
    laboratoryresults: '',
    medicalreport: '',
  };
  translationKeys: string[] = [
    'hcp-home.final-bill-card-title',
    'hcp-home.intermediate-bill',
    'hcp-home.medical-certificate',
    'hcp-home.medical-report',
  ];
  billAmount!: number;
  currency!: Currency;
  currencyCHF!: Currency;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: string;
      buyedPackage: IBuyedpackage;
      fileName: string;
      file: File;
      storedFile: ModelFile;
      file_amount?: number;
      caller: string;
    },
    public dialogRef: MatDialogRef<DocumentModalComponent>,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private services: ServicesService,
    private currencyService: CurrencyService
  ) {}

  ngOnInit(): void {
    this.currency = this.services.sessionService.getCurrencie();
    this.disabled = false;
    this.getTranslations();
    this.getCHFCurrency();
  }

  patchFrom(): void {
    if (this.data.action === '_CREATE') {
      this.fileName = this.data.fileName;
      this.file = this.data.file;
    } else if (this.data.action === '_EDIT') {
      this.fileName = this.data.storedFile.data
        ? this.data.storedFile.data.name
        : '__';
      this.selectedOption = {
        label: this.data.storedFile.type,
        value:
          this.data.storedFile.type == HCPInvoiceType.FINAL_INVOICE
            ? this.translations.finalBillCardTitle
            : this.data.storedFile.type == HCPInvoiceType.INTERIM_INVOICE
            ? this.translations.intermediateBill
            : this.data.storedFile.type == HCPInvoiceType.MEDICAL_CERTIFICATE
            ? this.translations.medicalCertificate
            : this.translations.medicalreport,
      };
      this.billAmount = this.data.storedFile.data.amount;
    }
  }

  save(): void {
    if (this.file || this.selectedOption) {
      if (this.data.action === '_CREATE') {
        if (!this.disabled) {
          if (!this.billAmount) return;
        }
        this.isLoading = true;
        this.fileService
          .store(
            this.data.file,
            undefined,
            this.data.buyedPackage.id,
            'buyedpackage',
            this.selectedOption.label,
            !this.disabled
              ? '{"amount":' +
                  toHealthCredit(this.billAmount, this.currency.rate) +
                  ',"name":"' +
                  this.fileName +
                  '","amount_chf":' +
                  toMoney(
                    toHealthCredit(this.billAmount, this.currency.rate),
                    this.currencyCHF.rate
                  ) *
                    100 +
                  ',"amount_client":' +
                  this.billAmount * 100 +
                  '}'
              : '{"name":"' + this.fileName + '"}'
          )
          .subscribe({
            next: (res: FileResponse) => {
              this.notificationService.success(
                this.translateService.instant('forms.success-created-document')
              );
            },
            error: error => {
              this.notificationService.danger(
                this.translateService.instant(error.translate)
              );
              this.isLoading = false;
            },
            complete: () => {
              this.isLoading = false;
              this.close('_SAVE');
            },
          });
      } else {
        this.fileService.update(this.data.storedFile.id, this.file).subscribe({
          next: (res: FileResponse) => {
            this.notificationService.success(
              this.translateService.instant('forms.success-update-document')
            );
          },
          error: error => {
            // this.notificationService.danger(this.translateService.instant(error.translate));
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.close('_SAVE');
          },
        });
      }
    }
  }

  setBillAmount(event: any): void {
    this.billAmount = event.target.value;
  }

  close(action: string): void {
    this.dialogRef.close(action);
  }

  handleDocumentTypeDropdownEvent(event: any): void {
    this.selectedOption = event;
    if (
      this.selectedOption.label === HCPInvoiceType.FINAL_INVOICE ||
      this.selectedOption.label === HCPInvoiceType.INTERIM_INVOICE
    )
      this.disabled = false;
    else this.disabled = true;
  }

  setFieldValue(event: any): void {
    this.fileName = shortenFileName(event.file.name);
    this.file = event.file;
  }

  getFilter(): void {
    this.documentTypeOptions =
      this.data.caller && this.data.caller === '_CHECKOUT'
        ? [
            {
              label: HCPInvoiceType.FINAL_INVOICE,
              value: this.translations.finalBillCardTitle,
            },
          ]
        : [
            {
              label: HCPInvoiceType.INTERIM_INVOICE,
              value: this.translations.intermediateBill,
            },
            {
              label: HCPInvoiceType.MEDICAL_CERTIFICATE,
              value: this.translations.medicalCertificate,
            },
            {
              label: HCPInvoiceType.MEDICAL_REPORT,
              value: this.translations.medicalreport,
            },
          ];
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.finalBillCardTitle =
        translations['hcp-home.final-bill-card-title'];
      this.translations.intermediateBill =
        translations['hcp-home.intermediate-bill'];
      this.translations.medicalCertificate =
        translations['hcp-home.medical-certificate'];
      this.translations.medicalreport = translations['hcp-home.medical-report'];
      this.getFilter();
      this.patchFrom();
    });
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency.rate);
  }

  getCHFCurrency() {
    this.currencyService.view(1).subscribe({
      next: (res: Currency) => {
        this.currencyCHF = res;
      },
      error: () => {},
      complete: () => {},
    });
  }

  public get validAmount(): number {
    if (this.selectedOption.label === 'final_invoice') {
      return this._toMoney(this.data.buyedPackage.health_credit_amount);
    } else {
      return Math.round(
        this.data.buyedPackage.money_amount / 100 -
          this._toMoney(this.data.buyedPackage.file_amount ?? 0)
      );
    }
  }

  public get fileAmount(): number {
    if (this.selectedOption.label === 'final_invoice') {
      return this._toMoney(this.data.buyedPackage?.file_amount ?? 0);
    }
    return 0;
  }

  getCurrencyCode(code: string) {
    return this.services.sessionService.getCurrencyCode(code);
  }
}
