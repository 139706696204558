import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppUpdateSnackBarComponent } from 'src/app/pages/containers/components/app-update-snack-bar/app-update-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(
    private readonly updates: SwUpdate,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
    updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
      .subscribe(() => {
        const snack = snackBar.openFromComponent(AppUpdateSnackBarComponent);

        snack.afterDismissed().subscribe(() => {
          window.location.reload();
        });

        snack.onAction().subscribe(() => {
          window.location.reload();
        });
      });
  }
}
