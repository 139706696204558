import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  @Input() label: string = '';
  @Input() classNames: string = '';

  constructor() {}

  ngOnInit(): void {}
}
