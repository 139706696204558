<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- Start::app-sidebar -->
<!--sidenav -->
<aside
  class="app-sidebar fixed left-0 top-0 w-72 z-50 sidebar-menu transition-transform flex flex-col h-screen overflow-y-auto bg-[#1E293B] dark:bg-gray-900 dark:border-gray-700">
  <a
    href="#"
    class="h-[68px] px-8 border-b-1 border-white border-opacity-10 py-4 bg-[#1E293B]">
    <img class="w-auto" src="assets/svg/logo.svg" alt="" />
  </a>

  <div class="flex flex-col justify-between flex-1 mt-6 px-6 pb-8">
    <div class="main-sidebar" id="sidebar-scroll">
      <!-- Start::nav -->
      <nav
        class="main-menu-container nav nav-pills flex-column sub-open active open">
        <ul class="main-menu">
          <!-- 1st Level Menu -->
          <ng-container *ngFor="let menuItem of menuItems">
            <li
              *ngIf="menuItem.headTitle || menuItem.rights"
              class="slide py-1 sm:py-0"
              #activeMenuItems
              [ngClass]="{'slide__category':menuItem.headTitle,
                  'slide has-sub':menuItem.title,
                  'open': menuItem.active,
                  'active': menuItem.selected,}">
              <!-- head title  -->
              <span *ngIf="menuItem.headTitle" class="category-name mb-6">{{
                menuItem.headTitle
              }}</span>
              <!-- has-Link -->
              <div
                *ngIf="
                  menuItem.type === 'link' &&
                  menuItem.badgeValue !== 'coming-soon'
                "
                class="side-menu__item flex items-center justify-between {{
                  menuItem.badgeValue && menuItem.badgeValue === 'coming-soon'
                    ? 'pointer-events-none'
                    : 'cursor-pointer'
                }}"
                [routerLink]="!menuItem.type ? null : [menuItem.path]"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                (click)="setNavActive(menuItem)">
                <div class="flex gap-2">
                  <i
                    *ngIf="menuItem.icon"
                    class="side-menu__icon demoicon ri-{{ menuItem.icon }}"></i>
                  <span class="side-menu__label">{{ menuItem.title }}</span>
                </div>

                <span
                  *ngIf="menuItem.badgeValue"
                  class="{{ menuItem.badgeClass }}"
                  >{{ 'common.' + menuItem.badgeValue | translate }}</span
                >
              </div>
              <!-- has-empty -->
              <a
                class="side-menu__item"
                href="javascript:;"
                *ngIf="menuItem.type === 'empty'"
                (click)="setNavActive(menuItem)">
                <i
                  *ngIf="menuItem.icon"
                  class="side-menu__icon demoicon ri-{{ menuItem.icon }}"></i>
                <span class="side-menu__label">{{ menuItem.title }}</span>
              </a>
              <!-- has-Sub -->
              <a
                class="side-menu__item with-sub"
                [routerLink]="menuItem.type ? null : [menuItem.path]"
                [ngClass]="{ active: menuItem.selected }"
                *ngIf="menuItem.type === 'sub'"
                (click)="toggleNavActive(menuItem)">
                <i
                  *ngIf="menuItem.icon"
                  class="side-menu__icon demoicon ri-{{ menuItem.icon }}"></i>
                <span class="side-menu__label">{{ menuItem.title }}</span>
                <span
                  class="badge {{ menuItem.badgeClass }} side-badge"
                  *ngIf="menuItem.badgeClass && menuItem.badgeValue">
                  {{ menuItem.badgeValue }}
                </span>
                <i class="ri ri-arrow-right-s-line side-menu__angle"></i>
              </a>

              <!-- 2nd Level menu -->
              <ul
                class="slide-menu child1"
                *ngIf="menuItem.children"
                [ngStyle]="{ display: menuItem.active ? 'block' : 'none' }">
                <li class="slide side-menu__label1">
                  <a href="javascript:void(0)">{{ menuItem.title }}</a>
                </li>
                <ng-container *ngFor="let childrenItem of menuItem.children">
                  <li
                    class="slide"
                    activeMenuItems
                    [ngClass]="{
                      'is-expanded': childrenItem.active,
                      active: childrenItem.active,
                      'sub-slide': childrenItem.type === 'sub'
                    }">
                    <!-- link -->
                    <a
                      class="side-menu__item"
                      [routerLink]="
                        !childrenItem.type ? null : [childrenItem.path]
                      "
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      *ngIf="childrenItem.type === 'link'"
                      (click)="setNavActive(childrenItem)">
                      {{ childrenItem.title }}
                    </a>
                    <!-- empty -->
                    <a
                      class="side-menu__item"
                      href="javascript:;"
                      *ngIf="childrenItem.type === 'empty'"
                      (click)="setNavActive(childrenItem)">
                      {{ childrenItem.title }}
                    </a>
                    <!-- sub -->
                    <a
                      class="side-menu__item"
                      [ngClass]="{ active: childrenItem.selected }"
                      [routerLink]="
                        childrenItem.type ? null : [childrenItem.path]
                      "
                      *ngIf="childrenItem.type === 'sub'"
                      (click)="toggleNavActive(childrenItem)">
                      <span class="">{{ childrenItem.title }}</span>
                      <i class="ri ri-arrow-right-s-line side-menu__angle"></i>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </nav>
      <!-- End::nav -->
    </div>
    <!--nav class="-mx-3 space-y-6">
      <div class="space-y-3">
        <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">analytics</label>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Dashboard</span>
        </a>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Preformance</span>
        </a>
      </div>

      <div class="space-y-3">
        <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">content</label>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Guides</span>
        </a>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Hotspots</span>
        </a>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Checklists</span>
        </a>
      </div>

      <div class="space-y-3">
        <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">Customization</label>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Themes</span>
        </a>

        <a
          class="flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white hover:text-gray-700"
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <span class="mx-2 text-sm font-medium">Setting</span>
        </a>
      </div>
    </nav-->
  </div>
  <div
    class="w-full static text-[#64748b] flex justify-center py-2 bottom-0 text-sm">
    {{ enviromentname }}@{{ 'v' + appVersion }}
  </div>
</aside>

<div
  class="fixed top-0 left-0 w-full h-full bg-black/50 z-40 lg:hidden sidebar-overlay cursor-pointer"
  (click)="closeSideBar()"></div>
<!-- end sidenav -->
