<div class="flex items-center {{ classNames }}">
  <div class="relative w-full">
    <app-form-field
      [label]="name"
      class="w-full md:w-1/3-with-gap"
      classNames="relative"
      (click)="openfilterSelect()">
      <div
        *ngIf="pickValue"
        class="w-full truncate rounded-lg pl-4 pr-10 bg-[#f4f8fc] placeholder-dark-gray focus:outline-none focus:shadow-inner-blue h-14 flex items-center justify-between cursor-pointer relative">
        <div class="flex items-center justify-start truncate">
          <span
            *ngIf="pickValue.label"
            class="h-8 w-8 flex-shrink-0 flag ff-round ff-xl flag-{{
              pickValue.label | lowercase
            }}"></span>
          <span class="ml-2 truncate">{{ pickValue.value }}</span>
        </div>
        <mat-icon color="primary" class="absolute right-3 top-4"
          >open_in_new</mat-icon
        >
      </div>
      <input
        *ngIf="!pickValue"
        class="w-full rounded-lg pl-4 pr-20 bg-[#f4f8fc] placeholder-dark-gray focus:outline-none focus:shadow-inner-blue h-14 cursor-pointer"
        type="text"
        [placeholder]="placeholder"
        readonly
        [name]="name"
        id="lastName" />
    </app-form-field>
  </div>
</div>
