import { DiscountCardType } from '../enums/enums';

export class Discount {
  id?: number;
  name: string;
  code: string;
  active: number;
  usable_time: number;
  total_usable: number;
  data: any[];
  actions: Action[];
  campaign_id: any;
  created_at?: string;
  updated_at?: string;
  deleted_at?: any;
  type?: string;

  constructor(
    name: string,
    code: string,
    active: number,
    usable_time: number,
    total_usable: number,
    data: any[],
    actions: Action[],
    id?: number,
    type?: string
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.active = active;
    this.usable_time = usable_time;
    this.total_usable = total_usable;
    this.data = data;
    this.actions = actions;
    this.type = type;
  }
}

export class useDiscount {
  group_id: number;
  code: string;

  constructor(group_id: number, code: string) {
    this.group_id = group_id;
    this.code = code;
  }
}

export interface Action {
  type: string;
  number?: number;
  group_id?: number;
}
