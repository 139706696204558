import { IGroup } from '../models/Group';
import { IHealthcareProvider } from '../models/HealthcareProvider';
import { IHealthcarepackage } from '../models/Healthcarepackage';
import { IPatient } from '../models/Patient';

export type OptionObject<
  Value extends string | number = string,
  Label extends string | number = string,
  Image extends string = string,
  Id extends number = number
> = {
  label: Label;
  value: Value;
  src?: Image;
  id?: Id;
  active?: boolean;
};

export type Country = {
  name: string;
  /**
   * In ISO 3166 format.
   */
  code: string;
};
export type Phone = {
  option?: OptionObject;
  phone?: string;
  phoneCode?: string;
  hasWhatsapp?: boolean;
};

export type KimboCareStation = {
  id: string;
  name: string;
  lat: number;
  lng: number;
};

export type LoginPostActionParams = {
  activeLocation?: string;
  healthcareModules?: IHealthcarepackage[];
  healthcareProvider?: IHealthcareProvider[];
  patient?: IPatient;
  group?: IGroup;
  identifier?: string;
};

export type CountryForMap = {
  key: string;
  code: string;
  name: string;
  keyFact: any;
};

export enum BROWSER_ENUM {
  EDGE,
  EDGE_CHROMIUM,
  INTERNET_EXPLORER,
  FIRE_FOX,
  OPERA,
  UC_BROWSER,
  SAMSUNG_BROWSER,
  CHROME,
  SAFARI,
  UNKNOWN,
}

export type PaginationElement = {
  page: number;
  totalPages: number;
  total?: number;
  perPage?: number;
  form?: number;
  to?: number;
};
