<div
  class="h-full flex flex-col {{
    htmlFor === '_PHOTO' ? 'items-center' : 'items-start'
  }} {{ classNames }}">
  <div
    class="flex flex-row items-start relative overflow-hidden whitespace-normal">
    <label
      *ngIf="label"
      class="mb-2 pr-0 text-navy overflow-hidden whitespace-normal">
      {{ label }}
      <span *ngIf="optional" class="ml-1">
        ({{ 'admin-edit-user.optional-label' | translate }})</span
      >
    </label>
    <button
      *ngIf="infos"
      class="w-6 h-6 ml-2 focus:outline-none focus:shadow-blue"
      (click)="toggleVisibility()"
      type="button">
      <div
        class="flex-shrink-0 w-6 h-6 fill-current {{ color ?? 'text-blue' }}"
        aria-label="info icon"
        [inlineSVG]="'assets/svg/info.svg'"></div>
    </button>
    <div
      *ngIf="infos && info"
      class="absolute -right-4 w-85 p-4 mt-2 text-sm bg-white rounded-lg top-full shadow-light-backdrop z-dropdown">
      <span class="text-dark-gray whitespace-pre-wrap">{{ infos }}</span>
    </div>
  </div>
  <ng-content></ng-content>
  <app-form-error *ngIf="error" class="mt-1 px-4" [htmlFor]="htmlFor">
    {{ error }}
  </app-form-error>
</div>
