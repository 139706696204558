import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  IBuyedpackage,
  Recommendation,
} from 'src/app/core/models/Buyedpackage';
import { ServicesService } from 'src/app/core/services/services.service';
import { AddRecommendationModalComponent } from '../modals/add-recommendation-modal/add-recommendation-modal.component';
import { hasOwner } from 'src/app/core/utils/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-treatment-recommendation',
  templateUrl: './treatment-recommendation.component.html',
  styleUrls: ['./treatment-recommendation.component.scss'],
})
export class TreatmentRecommendationComponent implements OnInit, OnDestroy {
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() buyedPackage!: IBuyedpackage;
  @Input() canEdit = true;
  @Input() isLoading = false;
  recommendations: Recommendation[] = [];
  isOwner = false;
  $recommendationRefresh!: Subscription;

  constructor(private services: ServicesService) {}

  ngOnInit(): void {
    const role = this.services.sessionService.getCurrentRole();
    this.isOwner = hasOwner(role);
    this.recommendations = this.buyedPackage.recommendations;
    this.$recommendationRefresh = this.services.eventService.subscribe(
      'recommendation:refresh',
      () => {
        this.triggerEvent.emit();
      }
    );
  }

  ngOnDestroy(): void {
    this.$recommendationRefresh && this.$recommendationRefresh.unsubscribe();
  }

  openRecommendationModal(): void {
    this.services.modalService
      .openModal(AddRecommendationModalComponent, {
        height: 'auto',
        data: {
          action: '_CREATE',
          b_id: this.buyedPackage.id,
          hcp: this.buyedPackage.healthcareprovider,
        },
      })
      .subscribe(action => {
        if (action === '_REFRESH') this.triggerEvent.emit();
      });
  }
}
