import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GroupType, Permission } from 'src/app/core/enums/enums';
import { IRole, IUser } from 'src/app/core/models/User';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-global-dropdown',
  templateUrl: './global-dropdown.component.html',
  styleUrls: ['./global-dropdown.component.scss'],
})
export class GlobalDropdownComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('dropdownTrigger', { static: true }) dropdownTrigger!: ElementRef;
  @ViewChild('dropdown', { static: true }) dropdown!: ElementRef;
  isOpen = false;
  @Input() options!: OptionObject[];
  @Input() classNames = '';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() pickValue?: OptionObject;
  @Input() optionWidth?: 'w-60' | 'w-75' | string;
  @Input() caller:
    | '_GLOBAL'
    | '_SPECIALTY'
    | '_COUNTRY'
    | '_CITY'
    | '_BENEFIT' = '_GLOBAL';
  @Input() backdrop = false;
  @Input() formRight = false;
  @Input() formCenter = false;
  @Input() withPickValueText = true;
  @Input() countryIconSize!: string;
  _role_selected!: IRole;
  user!: IUser;
  groupType = GroupType;
  $countrybind!: Subscription;
  widthMatches = [
    { width: 240, match: 'w-60' },
    { width: 300, match: 'w-75' },
  ];
  screenWidth!: number;

  constructor(
    private sessionService: SessionService,
    private services: ServicesService,
    private translateService: TranslateService
  ) {
    //this.onResize();
  }
  ngAfterViewInit(): void {
    if (this.options.length > 0 && !this.pickValue)
      this.pickValue = this.options[0];
    this.triggerEvent.emit(this.pickValue);
  }

  ngOnDestroy(): void {
    if (this.$countrybind) this.$countrybind.unsubscribe();
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    this.$countrybind = this.services.eventService.subscribe(
      'map:bindcountry',
      (data: OptionObject) => {
        this.pickValue = data;
        this.pickValue.label = this.translateService.instant(
          'common.' + data.value
        );
      }
    );
  }

  countryTranslation(options: OptionObject[]): OptionObject[] {
    const countries: OptionObject[] = [];
    options.forEach(country => {
      country.label =
        country?.value !== '_ALL'
          ? this.translateService.instant(
              'common.' + country.src?.toLowerCase()
            )
          : country.label;
      countries.push(country);
    });
    if (this.pickValue)
      this.pickValue.label =
        this.pickValue.value !== '_ALL'
          ? this.translateService.instant(
              'common.' + this.pickValue.src?.toLowerCase()
            )
          : this.pickValue.label;
    return countries;
  }

  isGroupOwer(option: OptionObject): boolean {
    this.user = this.sessionService.getUser();
    if (
      this._role_selected &&
      this._role_selected?.permissions?.length === 0 &&
      this.user.admin_type &&
      this.user.admin_type === 'admin'
    )
      return true;
    this._role_selected = this.user.roles!.find(
      role => role.id == Number(option.value)
    )!;
    return this._role_selected?.permissions?.includes(Permission.Owner);
  }

  selectedOption(option: OptionObject): void {
    if (option.value != '_ADD_GROUP') {
      this.pickValue = option;
      this.triggerEvent.emit(option);
      this.isOpen = false;
    } else {
      this.handleGroupActions('_CREATE_GROUP', option);
    }
  }

  getRoleName(option: OptionObject): string {
    let role!: IRole;
    if (option.value != 'admin')
      role = this.user.roles!.find(role => role.id == Number(option.value))!;
    return role ? role.group.type : '';
  }

  setDialogPosition(): void {
    const target_1: ElementRef = new ElementRef(
      document.getElementById('dropdownTrigger')
    );
    const dropdown: ElementRef = new ElementRef(
      document.getElementById('dropdown')
    );
    if (target_1.nativeElement) {
      const dropdownWidth = this.optionWidth ? this.getCustomWidth() : 300;
      const rect = target_1.nativeElement.getBoundingClientRect();
      const position = {
        top: rect.top + window.pageYOffset,
        left: rect.left + window.pageXOffset,
      };

      const rightGouter = this.screenWidth - rect.right;
      const targetWidth = target_1.nativeElement.offsetWidth;
      if (targetWidth + rightGouter < dropdownWidth) {
        const right = dropdownWidth - (targetWidth + rightGouter);
        dropdown.nativeElement.style = `right:${right}px`;
      } else {
        dropdown.nativeElement.style = `left:0`;
      }
    }
  }

  getCustomWidth(): number {
    return this.widthMatches.find(w => w.match === this.optionWidth)?.width!;
  }

  clickedOutside(): void {
    this.isOpen = false;
  }

  openfilterSelect(): void {
    this.isOpen = !this.isOpen;
    if (this.caller === '_COUNTRY')
      this.options = this.countryTranslation(this.options);
    else if (this.caller === '_CITY')
      if (this.isOpen) {
        this.triggerEvent.emit('open');
        /*setTimeout(() => this.setDialogPosition(), 1000);*/
      }
  }

  handleGroupActions(
    action: '_CREATE_GROUP' | '_EDIT_GROUP',
    pickValue: OptionObject
  ): void {
    this.triggerEvent.emit({ action: action, data: pickValue });
    this.isOpen = false;
  }

  close(): void {
    this.isOpen = false;
  }

  clear(): void {}
}
