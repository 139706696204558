import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GroupType } from 'src/app/core/enums/enums';
import { Currency } from 'src/app/core/models/Common';
import { ILoginResponse, Login } from 'src/app/core/models/Login';
import { IUser } from 'src/app/core/models/User';
import {
  RestAuthentificationService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import {
  isValidEmailAdress,
  isValidPhoneNumber,
} from 'src/app/core/utils/core';
import { getCountries } from 'src/app/core/utils/country';
import { Country, OptionObject, Phone } from 'src/app/core/utils/type';

@Component({
  selector: 'app-pre-login-infos',
  templateUrl: './pre-login-infos.component.html',
  styleUrls: ['./pre-login-infos.component.scss'],
})
export class PreLoginInfosComponent implements OnInit, OnDestroy {
  input_cls = INPUT_CLASSES;
  invalid = false;
  disabled = false;
  isPhoneNumber = false;
  fromError!: string;
  identifier = '';
  _identifier = '';
  loginModel: Login = new Login('');
  phoneModel: Phone = {};
  country = '';
  isLoading = false;
  isComplete = false;
  user!: IUser;
  error?: string;
  currentLocation?: string;
  currency: Currency;
  loginSubscribtion$!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caller: '_SIGN_UP' | '_STEPPER';
      params: {
        key: string | null;
        mode: string | null;
        identifiant: string | null;
      };
    },
    public dialogRef: MatDialogRef<PreLoginInfosComponent>,
    public navigationService: CustomNavigationService,
    public authService: RestAuthentificationService,
    public userService: UserService,
    public services: ServicesService,
    public sessionService: SessionService,
    public translateService: TranslateService,
    private notificationService: NotificationService,
    private route: Router
  ) {
    this.currency = this.sessionService.getCurrencie();
    this.user = this.sessionService.getUser();

    if (this.data.params) {
      this.identifier = this.data.params.identifiant!;
      if (isValidPhoneNumber(this.identifier)) {
        this._identifier = this.identifier;
        this.isPhoneNumber = true;
      }
    }

    this.loginSubscribtion$ = this.services.eventService.subscribe(
      'login:end',
      () => {
        this.services.sessionService.deleteRequestToLogin();
        this.dialogRef.close();
      }
    );
  }

  ngOnDestroy(): void {
    this.loginSubscribtion$ && this.loginSubscribtion$.unsubscribe();
  }

  ngOnInit(): void {
    this.currentLocation = this.route.url;
    this.country = this.sessionService.getCountry().country;
  }

  setIdentifier(event: any): void {
    this.identifier = event.target.value;
    this._identifier = event.target.value;
    if (this._identifier.trim().length > 0) {
      const first = this._identifier[0];
      if (/^[0-9]|\+$/.test(first)) {
        this.isPhoneNumber = true;
      }
    }
  }

  handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
    this.identifier = this.phoneModel.phone!;
  }

  public location(): void {
    this.authService.getlocation().subscribe({
      next: (res: any) => {
        this.country = res.country;
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  public fastLogin(): void {
    if (!this.identifier) {
      this.error = this.translateService.instant('errors.required');
      return;
    }
    if (
      !isValidPhoneNumber(this.identifier) &&
      !isValidEmailAdress(this.identifier)
    ) {
      const first = this.identifier[0];
      if (/^[0-9]|\+$/.test(first)) {
        this.error = this.translateService.instant(
          'errors.invalid-phone-number'
        );
      } else {
        this.error = this.translateService.instant('errors.invalid-email');
      }
      return;
    }
    this.loginModel.identifiant = this.identifier;
    this.loginModel.currencie_id = this.currency.id;
    this.loginModel.language_id =
      this.translateService.currentLang === 'fr' ? 1 : 2;
    this.loginModel.user_id = this.user.id;

    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(c => {
      if (c.code.toLocaleLowerCase() === this.country.toLocaleLowerCase()) {
        this.loginModel.country = c.name;
      }
    });

    this.isLoading = true;

    this.authService.fastLogin(this.loginModel).subscribe({
      next: (res: ILoginResponse) => {
        this.cleanStorage();
        this.sessionService.storeAccessToken(res.access_token);
        this.userService.view(res.user_id).subscribe({
          next: (userRes: IUser) => {
            this.user = userRes;
            this.isComplete = true;
            this.isLoading = true;
            this.sessionService.deleteUser();
            this.sessionService.storeUser(userRes);
            this.storeCurrencie(userRes);
            this.dialogRef.close();
            this.services.sessionService.storeCloseAlertState();
            this.services.eventService.publish('login:end', this.user);
          },
          error: (err: any) => {
            this.notificationService.danger(
              this.translateService.instant('errors.generic')
            );
          },
        });
      },
      error: (err: ILoginResponse) => {
        if (err.status_code === 400) {
          this.isComplete = true;
          this.notificationService.warning(
            this.translateService.instant('sign-in.exist-user')
          );
          this.openLoginModal('_SIGN_IN', this.identifier);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isComplete = true;
        this.isLoading = false;
      },
    });
  }

  storeCurrencie(user: IUser): void {
    this.sessionService.storeUserCurrencie(user.currencie);
    this.sessionService.storeCurrencie(user.currencie);
    const role = this.sessionService.getRole(GroupType.Kimbo);
    this.sessionService.storeCurrentRole(role);
    this.isComplete = true;
  }

  countryListToOptionObject(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  cleanStorage() {
    this.sessionService.deleteAccessToken();
    this.sessionService.deleteCurrentRole();
    this.sessionService.deleteCurrencie();
    this.sessionService.deleteUserCurrencie();
    this.sessionService.deleteHcpCurrencie();
  }

  openLoginModal(caller: string, state?: string): void {
    this.dialogRef.close(state ? { caller: caller, state: state } : caller);
  }
}
