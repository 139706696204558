<div class="rounded-md overflow-hidden relative">
  <button
    mat-dialog-close
    type="button"
    class="flex items-center justify-center text-white bg-white bg-opacity-75 hover:bg-opacity-50 rounded-full w-8 h-8 focus:ring-kimbo-ruby focus:outline-none absolute top-3 right-3">
    <img
      appImg
      src="assets/svg/close.svg"
      alt="close icon"
      class="text-white w-4 h-4" />
    <span class="sr-only">close</span>
  </button>
  <img
    appImg
    [src]="data.image"
    alt="avatar"
    class="h-auto w-full block rounded-xl" />
</div>
