import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { isEmptyString } from 'src/app/core/utils/core';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-country-selection',
  templateUrl: './country-selection.component.html',
  styleUrls: ['./country-selection.component.scss'],
})
export class CountrySelectionComponent implements OnInit {
  input_cls = INPUT_CLASSES;
  countryList!: OptionObject[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { countryOptions: OptionObject[] },
    public dialogRef: MatDialogRef<CountrySelectionComponent>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.countryList = this.data.countryOptions;
  }

  search(event: any): void {
    let seacrhValue = event.target.value.toLocaleLowerCase();
    const normalizedQuery = this.normalizeSearchValue(seacrhValue);
    const filteredOptions = this.data.countryOptions.filter(option => {
      const normalizedLabel = this.normalizeSearchValue(option.value);
      return normalizedLabel.includes(normalizedQuery);
    });
    this.countryList = filteredOptions;
  }

  normalizeSearchValue(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '');
  }

  selectedOption(option: OptionObject) {
    option.value = this.translate.instant(
      'common.' + option.label.toLowerCase()
    );
    this.dialogRef.close(option);
  }
}
