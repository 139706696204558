import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IBuyedpackage,
  Content,
  Wallet,
  ITransaction,
  PatientStore,
  IHealthcarepackage,
  CountryHealthcareProvider,
  HcpOnboardingStore,
  IReview,
} from '../../models/models';
import { IVerification } from '../../models/Verification';
import { StatusLogs } from '../../enums/enums';

@Injectable()
export class UseCaseHcpService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  httpOptionsWithFromData_ = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  public ask_verification(
    buyedpackage_id: number,
    identity: Blob,
    consentments: string[]
  ): Observable<any> {
    const formParams = new FormData();

    formParams.append('image', identity);
    formParams.append('consentments', consentments.join(','));

    return this.http.post<IVerification>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/verification`,
      formParams,
      this.httpOptionsWithFromData
    );
  }

  public ask_verification_with_card(
    buyedpackage_id: number,
    data_id: any,
    consentments: string[]
  ): Observable<any> {
    return this.http.post<IVerification>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/verification`,
      { data_id: data_id, consentments: consentments.join(',') },
      this.httpOptionsWithFromData
    );
  }

  public check_image(buyedpackage_id: number, identity: Blob): Observable<any> {
    const formParams = new FormData();
    formParams.append('image', identity);

    return this.http.post<IVerification>(
      `${this.basePath}/v1/buyedpackage/${buyedpackage_id}/check-image`,
      formParams,
      this.httpOptionsWithFromData
    );
  }

  public check_verification(verification_id: number): Observable<any> {
    return this.http.get<IVerification>(
      `${this.basePath}/v1/verification/check_status/${verification_id}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * list_patients
   * @param groupId the group identifier in which the user belongs to
   */
  public list_patients(
    hcpId: number,
    status: string,
    page?: number,
    order?: string,
    filter?: string,
    search_value?: string
  ): Observable<Content<IBuyedpackage>> {
    const queryParameters = new HttpParams({
      fromObject: {
        status: status,
        page: page ?? 1,
        ...(order != null && { order: order }),
        ...(filter != null && { filter: filter }),
        ...(search_value != null && { search_value: search_value }),
      },
    });

    return this.http
      .get<Content<IBuyedpackage>>(
        `${this.basePath}/v1/healthcareprovider/${hcpId}/list_patients`,
        {
          params: queryParameters,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        map(res => {
          res.data = res.data.map(bp => {
            bp.cancheckout =
              bp.last_log !== undefined &&
              bp.last_log !== null &&
              bp.last_log.status === StatusLogs.TREATMENTCOMPLETED;
            return bp;
          });
          return res;
        })
      );
  }

  public listCountBuyedPackage(hcpId: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/count_buyed_package`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * unuse_buyedpackage
   * @param providerId the heathcare provider identifier
   */
  public unuse_buyedpackage(providerId: number): Observable<IBuyedpackage[]> {
    return this.http.get<IBuyedpackage[]>(
      `${this.basePath}/v1/healthcareprovider/${providerId}/unuse_buyedpackage`,
      this.httpOptions
    );
  }

  /**
   * wallet
   * @param hcpId the heathcare provider identifier
   */
  public wallet(hcpId: number): Observable<Wallet> {
    return this.http.get<Wallet>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/wallet`,
      this.httpOptions
    );
  }

  /**
   * inused amount
   * @param hcpId the heathcare provider identifier
   */
  public inusedAmount(hcpId: number): Observable<number> {
    return this.http.get<number>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/inused_amount`,
      this.httpOptions
    );
  }

  /**
   * list_transactions
   * @param hcpId the heathcare provider identifier
   */
  public list_transactions(
    hcpId: number,
    period: string,
    start_date?: string,
    end_date?: string,
    page?: number
  ): Observable<Content<ITransaction>> {
    const queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date && { start_date: start_date }),
        ...(end_date && { end_date: end_date }),
        ...(page && { page: page }),
      },
    });
    return this.http.get<Content<ITransaction>>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/list_transactions_v2`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * Update patient information
   * @param body
   * @param hcpId the heathcare provider identifier
   * @param patientId the patient identifier
   */
  public updatePatientInfo(
    hcpId: number,
    patientId: number,
    body?: PatientStore
  ): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/update_patient_info/${patientId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * list_healthcarepackages
   * @param providerId the heathcare provider identifier
   */
  public list_healthcarepackages(
    providerId: number,
    term = ''
  ): Observable<any> {
    const queryParameters = new HttpParams({
      fromObject: { search_value: term },
    });
    return this.http.get<any>(
      `${this.basePath}/v1/healthcareprovider/${providerId}/list_healthcarepackages`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * add_healthcarepackage
   * @param body
   * @param hcpId the heathcare provider identifier
   */
  public add_healthcarepackage(hcpId: number, body?: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/add_healthcarepackage`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * remove_healthcarepackage
   * @param healthpId the health package identifier
   * @param hcpId the heathcare provider identifier
   */
  public remove_healthcarepackage(
    hcpId: number,
    healthpId: number
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/remove_healthcarepackage/${healthpId}`,
      this.httpOptions
    );
  }

  public listAllHcpByCountry(): Observable<CountryHealthcareProvider[]> {
    return this.http.get<any>(
      `${this.basePath}/v1/healthcareprovider/list_hcp`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * list_healthcarepackages without login
   */
  public public_list_healthcarepackages(): Observable<IHealthcarepackage[]> {
    return this.http.get<any>(
      `${this.basePath}/v1/healthpackages/list_packages`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /*
   * Add Hcp
   * @param body
   */
  public storeHcpV2(body: any): Observable<any> {
    const formData = new FormData();
    for (const key in body) {
      if (key == 'files') {
        body[key].forEach((file: any) => {
          formData.append(`files[]`, file);
        });
      } else if (key == 'legal_documents') {
        body[key].forEach((file: any) => {
          formData.append(`legal_documents[]`, file);
        });
      } else if (key == 'payment_method') {
        formData.append(`payment_method`, JSON.stringify(body.payment_method));
      } else {
        formData.append(key, body[key]);
      }
    }

    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/store_hcp_v2`,
      formData
    );
  }

  /*
   * Get verification
   * @param body
   */
  public getVerificationCode(contact: string, lang: string): Observable<any> {
    const formData = new FormData();
    formData.append('email', contact);
    formData.append('lang', lang);

    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/send_verification_code`,
      formData,
      this.httpOptionsWithFromData_
    );
  }

  /*
   * verify_code
   * @param body
   */
  public verify_code(body: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/verify_code`,
      body
    );
  }

  /*
   * count_view_hcp
   * @param body
   */
  public count_view_hcp(hcpId: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/count_view_hcp`,
      {}
    );
  }

  /*
   * count_view_hcp
   * @param body
   */
  public update_profil(hcpId: number, fileId: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/update_profile_picture/${fileId}`,
      {}
    );
  }

  /**
   * Suggestions
   * List all suggestion
   * @param page
   */
  public listSuggestions(page: number): Observable<Content<IReview>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
      },
    });

    return this.http.get<Content<IReview>>(
      `${this.basePath}/v1/healthcareprovider/list_suggestions`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
