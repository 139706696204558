<button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button>
<div class="flex flex-col items-center justify-center">
  <img appImg class="w-9/12 sm:w-7/12" src="assets/images/referal.png" />
  <p class="text-navy text-lg mt-6 w-9/12 sm:w-7/12 text-center">
    {{ 'kimbo-home.referal-text-label' | translate }}
  </p>
  <div class="sharing-buttons flex flex-wrap mt-6">
    <a
      class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-3 rounded-lg text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700"
      target="_blank"
      rel="noopener"
      href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffr.freepik.com%2Fsearch%3Fformat%3Dsearch%26last_filter%3Dtype%26last_value%3Dphoto%26query%3Dvague%2520abstrait%2520blue%2520fond%2520transparent%26type%3Dphoto"
      aria-label="Share on Facebook">
      <svg
        aria-hidden="true"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="w-6 h-6">
        <title>Facebook</title>
        <path
          d="M379 22v75h-44c-36 0-42 17-42 41v54h84l-12 85h-72v217h-88V277h-72v-85h72v-62c0-72 45-112 109-112 31 0 58 3 65 4z"></path>
      </svg>
    </a>
    <a
      class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-3 rounded-lg text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700"
      target="_blank"
      rel="noopener"
      href="https://twitter.com/intent/tweet?url=https%3A%2F%2Ffr.freepik.com%2Fsearch%3Fformat%3Dsearch%26last_filter%3Dtype%26last_value%3Dphoto%26query%3Dvague%2520abstrait%2520blue%2520fond%2520transparent%26type%3Dphoto&amp;text=Follow%20me"
      aria-label="Share on Twitter">
      <svg
        aria-hidden="true"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="w-6 h-6">
        <title>Twitter</title>
        <path
          d="m459 152 1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 0 0 156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0 1 36 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 0 1 181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z"></path>
      </svg>
    </a>
    <a
      class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition p-3 rounded-lg text-white border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700"
      target="_blank"
      rel="noopener"
      href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Ffr.freepik.com%2Fsearch%3Fformat%3Dsearch%26last_filter%3Dtype%26last_value%3Dphoto%26query%3Dvague%2520abstrait%2520blue%2520fond%2520transparent%26type%3Dphoto&amp;title=Follow%20me&amp;summary=Follow%20me&amp;source=https%3A%2F%2Ffr.freepik.com%2Fsearch%3Fformat%3Dsearch%26last_filter%3Dtype%26last_value%3Dphoto%26query%3Dvague%2520abstrait%2520blue%2520fond%2520transparent%26type%3Dphoto"
      aria-label="Share on Linkedin">
      <svg
        aria-hidden="true"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="w-6 h-6">
        <title>Linkedin</title>
        <path
          d="M136 183v283H42V183h94zm6-88c1 27-20 49-53 49-32 0-52-22-52-49 0-28 21-49 53-49s52 21 52 49zm333 208v163h-94V314c0-38-13-64-47-64-26 0-42 18-49 35-2 6-3 14-3 23v158h-94V183h94v41c12-20 34-48 85-48 62 0 108 41 108 127z"></path>
      </svg>
    </a>
  </div>
  <div
    class="w-154 border border-light-gray rounded-sm relative h-16 mt-2 flex items-center pl-1">
    <span class="whitespace-nowrap w-full overflow-hidden text-ellipsis"
      >https://fr.freepik.com/vecteurs-libre/clients-gagnent-argent-donnant-likes_9649308.htm#query=referal%20illustration&position=4&from_view=search&track=ais</span
    >
    <button
      mat-raised-button
      color="primary"
      class="h-12 absolute -left-1 w-40">
      <mat-icon> layers</mat-icon>copy link
    </button>
  </div>
  <div class="flex flex-wrap mt-6 justify-start gap-2">
    <app-box>
      <div
        class="py-6 flex flex-col items-center justify-center w-[200px] gap-2">
        <span class="text-2xl font-bold text-navy">400</span>
        <span class="text-lg font-bold text-navy"> Clicks </span>
      </div>
    </app-box>
    <app-box>
      <div
        class="py-6 flex flex-col items-center justify-center w-[200px] gap-2">
        <span class="text-2xl font-bold text-navy">400</span>
        <span class="text-lg font-bold text-navy"> Refered trials </span>
      </div>
    </app-box>
    <app-box>
      <div
        class="py-6 flex flex-col items-center justify-center w-[200px] gap-2">
        <span class="text-2xl font-bold text-navy">400</span>
        <span class="text-lg font-bold text-navy"> Commisions </span>
      </div>
    </app-box>
  </div>
</div>
