<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div
  *ngIf="showBrowerAlert"
  id="alert-border-4"
  class="flex items-center p-4 text-yellow-800 border-t-4 border-yellow-300 bg-yellow-50 relative"
  role="alert">
  <div
    class="w-fit mx-auto ms-3 text-sm font-medium flex justify-center items-center gap-4">
    <div [inlineSVG]="'assets/svg/warningv2.svg'"></div>
    <span>{{ 'common.compatibility-browser-msg' | translate }}</span>
  </div>
  <button
    (click)="dismissBrowserComptilityAlert()"
    type="button"
    class="ms-auto absolute right-6 -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-12 w-12"
    data-dismiss-target="#alert-border-4"
    aria-label="Close">
    <span class="sr-only">Dismiss</span>

    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div *ngIf="isLoading" class="h-full w-full banner-healthcare relative">
  <div
    class="absolute inset-0 glass-morphism flex flex-col items-center justify-center gap-8">
    <img
      appImg
      src="assets/svg/logo.svg"
      class="absolute top-[80px]"
      alt="logo" />
    <span class="user-loader"></span>
    <span class="text-kimbo-turquoise-blue text-2xl font-bold">{{
      isLoadingLabel
    }}</span>
    <span class="text-white text-sm absolute bottom-[5px]"
      >KimboCare v{{ appVersion }}</span
    >
  </div>
</div>
<router-outlet *ngIf="!isLoading"></router-outlet>
