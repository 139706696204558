import { SessionService } from 'src/app/core/services/session.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Currency,
  IBuyedpackage,
  IRole,
  Log,
  Title,
} from 'src/app/core/models/models';
import {
  BuyedPackageService,
  UseCaseBuyedPackageService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { noop, toMoney } from 'src/app/core/utils/misc';
import { OptionObject } from 'src/app/core/utils/type';
import { hasOwner } from 'src/app/core/utils/core';
import { Subscription } from 'rxjs';
import {
  BuyedpackageStatus,
  GroupType,
  HealthPackageType,
} from 'src/app/core/enums/enums';
import { MetricQuestions } from 'src/app/core/models/MetricQuestions';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { UseCaseHealthPackageService } from 'src/app/core/rest-services/usecases/usecase-healthpackage.service';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';
import { BottomSheetService } from 'src/app/core/services/bottom-sheet.service';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-patient-details-modal',
  templateUrl: './patient-details-modal.component.html',
  styleUrls: ['./patient-details-modal.component.scss'],
})
export class PatientDetailsModalComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;
  @Input() isCheckout = true;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  addFollowUpFormOpen = false;
  noteModalisOpen = false;
  addRecommandationFormOpen = false;
  allFollowUpIsVisible = false;
  isOwner = false;

  buyedPackage!: IBuyedpackage;
  _buyedPackage!: IBuyedpackage;
  status = '';

  isDocumentsOpen = true;
  isNotesOpen = false;
  isRecommandationOpen = false;
  isAdditionalInfos = false;
  isConversationOpen = false;
  isFinancialInfosOpen = false;

  breackpointMatche = false;
  language = 'fr';
  $owner!: Subscription;
  seconds = 0;
  minutes = 0;
  hours = 0;
  day = 0;
  healthPackageType = HealthPackageType;
  buyedpackageStatus = BuyedpackageStatus;
  questions!: {
    title: Title;
    type: string;
    value?: string;
    id: number;
    answer_id?: number;
  }[];
  input_cls = INPUT_CLASSES;
  $showConversationLogs!: Subscription;
  role!: IRole;
  groupType = GroupType;
  financialMetrics = {
    amountTopUp: 0,
    traitmentFees: 0,
    kimbocareFees: 0,
    InUseAmount: 0,
    amountLeft: 0,
  };
  hcpCurrency!: Currency;
  userCurrency!: Currency;
  tutorialData!: any;
  currentLanguage = 'en';
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      state: IBuyedpackage;
      caller:
        | '_INTREATMENT'
        | '_INCOMMING'
        | '_FINISHED'
        | '_INTREATMENT_CHECKOUT';
    },
    public dialogRef: MatDialogRef<PatientDetailsModalComponent>,
    public services: ServicesService,
    private buyedPackageService: BuyedPackageService,
    private translateService: TranslateService,
    private useCaseBuyedPackageService: UseCaseBuyedPackageService,
    private notificationService: NotificationService,
    private sessionService: SessionService,
    public packageService: UseCaseHealthPackageService,
    private bottomSheetService: BottomSheetService,
    public breakpointMatcherService: BreakpointMatcherService
  ) {
    this.userCurrency = this.sessionService.getCurrencie();
  }

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang;
    this.role = this.sessionService.getCurrentRole();
    this.isOwner = hasOwner(this.role);
    this.$owner = this.services.eventService.subscribe(
      'owner:has',
      (role: IRole) => {
        this.isOwner = hasOwner(role);
      }
    );
    this.$showConversationLogs = this.services.eventService.subscribe(
      'show-conversation:logs',
      () => {
        this.dialogRef.close();
      }
    );
    this.buyedPackage = this.data.state;

    this.getBuyedPackage(this.data.state.id);
    this.language = this.translateService.currentLang;
    this.initialize();

    this.isFinancialInfosOpen = this.role.group.type === this.groupType.Admin;
    this.isDocumentsOpen =
      this.data.caller !== '_INCOMMING' &&
      this.role.group.type !== this.groupType.Admin;
    this.isNotesOpen =
      this.data.caller === '_INCOMMING' &&
      this.role.group.type !== this.groupType.Admin;

    this.breakpointMatcherService
      .initObserver('md')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
        } else {
          this.breackpointMatche = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.$owner && this.$owner.unsubscribe();
    this.$showConversationLogs?.unsubscribe();
  }

  getBuyedPackage(id: number) {
    this.isLoading = true;
    this.buyedPackageService.view(id).subscribe({
      next: (res: IBuyedpackage) => {
        this._buyedPackage = res;
        this.hcpCurrency = this._buyedPackage.healthcareprovider?.currencie
          ? this._buyedPackage.healthcareprovider?.currencie
          : this.sessionService.getUser().currencie;

        this.financialMetrics.amountTopUp =
          this._buyedPackage.total_credit_amount ?? 0;

        this.financialMetrics.InUseAmount = this._buyedPackage.file_amount ?? 0;

        this.financialMetrics.kimbocareFees = Math.floor(
          this._buyedPackage.total_credit_amount *
            (this._buyedPackage.margin / 100) ?? 0
        );

        this.financialMetrics.traitmentFees =
          this._buyedPackage.total_credit_amount -
          this.financialMetrics.kimbocareFees;

        this.financialMetrics.amountLeft =
          this._buyedPackage.total_credit_amount -
          (this.financialMetrics.InUseAmount +
            this.financialMetrics.kimbocareFees);

        this.questions = [];
        if (res.metric_questions) {
          res.metric_questions!.forEach((q: MetricQuestions) => {
            const answers = q.ansewrs;
            const value =
              answers.length > 0 && answers[0].data
                ? answers[0].data!.label ?? null
                : null;
            const answer_id = answers.length > 0 ? answers[0].id : null;
            this.questions.push({
              title: q.title!,
              type: q.data!.type,
              value: value,
              id: q.id!,
              answer_id: answer_id,
            });
          });
        }
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  collapseSections(section: string) {
    if (section === '_DOCUMENTS') {
      this.isDocumentsOpen = !this.isDocumentsOpen;
      this.isNotesOpen =
        this.isRecommandationOpen =
        this.isAdditionalInfos =
        this.isConversationOpen =
        this.isFinancialInfosOpen =
          false;
    } else if (section === '_NOTES') {
      this.isNotesOpen = !this.isNotesOpen;
      this.isDocumentsOpen =
        this.isRecommandationOpen =
        this.isAdditionalInfos =
        this.isConversationOpen =
        this.isFinancialInfosOpen =
          false;
    } else if (section === '_RECOMMANDATIONS') {
      this.isRecommandationOpen = !this.isRecommandationOpen;
      this.isNotesOpen =
        this.isDocumentsOpen =
        this.isAdditionalInfos =
        this.isConversationOpen =
        this.isFinancialInfosOpen =
          false;
    } else if (section === '_ADDITIONALINFOS') {
      this.isAdditionalInfos = !this.isAdditionalInfos;
      this.isDocumentsOpen =
        this.isNotesOpen =
        this.isRecommandationOpen =
        this.isConversationOpen =
        this.isFinancialInfosOpen =
          false;
    } else if (section === '_CONVERSATION') {
      this.isConversationOpen = !this.isConversationOpen;
      this.isDocumentsOpen =
        this.isNotesOpen =
        this.isRecommandationOpen =
        this.isAdditionalInfos =
        this.isFinancialInfosOpen =
          false;
    } else if (section === '_FINANCIALINFOS') {
      this.isFinancialInfosOpen = !this.isFinancialInfosOpen;
      this.isDocumentsOpen =
        this.isNotesOpen =
        this.isRecommandationOpen =
        this.isAdditionalInfos =
        this.isConversationOpen =
          false;
    }
  }

  checkout(): void {
    if (!this.existBill) {
      this.notificationService.danger(
        this.translateService.instant('hcp-home.final-bill-card-absent')
      );
      return;
    }
    this.isLoading = true;
    this.useCaseBuyedPackageService.checkout(this._buyedPackage.id).subscribe({
      next: (res: any) => {
        this.notificationService.success(
          this.translateService.instant('hcp-home.patient-checkout-Successful')
        );
        this.services.eventService.publish('patient:checkout');
        this.services.eventService.publish('patient:refresh');
        this.closeModal();
      },
      error: error => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  public get existBill(): boolean {
    let res = false;
    this._buyedPackage.files.forEach(file => {
      if (file.type === 'final_invoice') {
        res = true;
      }
    });
    return res;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  close(event: any): void {
    this.triggerEvent.emit(event);
  }

  handleClose = (event: any) => {
    this.isLoading ? noop : this.close(event);
  };

  handleENotevent(event: any): void {
    if (event === 'close') this.noteModalisOpen = false;
  }

  _toLocaleDateTime(date: string): string {
    return toWordLocaleDateTime(new Date(date), this.language);
  }

  handleDocumentActions(param: any): void {
    if (param === '_CLOSE_MOTHER') {
      this.dialogRef.close();
    } else {
      this.getBuyedPackage(this.buyedPackage.id);
    }
  }

  handleRecommendatiotActions(): void {
    this.getBuyedPackage(this.buyedPackage.id);
  }

  toggleAddFollowUpFormOpen(): void {
    this.addFollowUpFormOpen = !this.addFollowUpFormOpen;
  }

  closeAddFollowUpFormOpen(): void {
    this.addFollowUpFormOpen = false;
  }

  toggleRecommendationForm(): void {
    this.addRecommandationFormOpen = !this.addRecommandationFormOpen;
  }
  closeRecommendationFormOpen(): void {
    this.addRecommandationFormOpen = false;
  }
  toggleAllFlowUpVisible(): void {
    this.allFollowUpIsVisible = !this.allFollowUpIsVisible;
  }

  handleFilterDropdownEvent(event: OptionObject): void {
    this.status = event.label;
    this.addLog();
  }

  addLog(): void {
    this.services.eventService.publish('hcp:add:log:start', {
      bp_id: this.buyedPackage.id,
      status: this.status,
    });
    this.useCaseBuyedPackageService
      .add_log(this.buyedPackage.id, { status: this.status })
      .subscribe({
        next: (res: any) => {
          this._buyedPackage.logs.unshift(res);
          this._buyedPackage.last_log = this._buyedPackage.logs[0];
          this.services.eventService.publish('buyedpackage_status_change');
          this.notificationService.success(
            this.translateService.instant('hcp-home.log-was-changed')
          );
          this.services.eventService.publish('hcp:add:log:success', {
            bp_id: res.buyedpackage_id,
            status: res.status,
          });
        },
        error: error => {
          this.notificationService.danger(
            this.translateService.instant('common.line-updated-error')
          );
          this.services.eventService.publish('hcp:add:log:failed', {
            bp_id: this.buyedPackage.id,
            status: this.status,
          });
        },
      });
  }

  calculateTime(currentLog: Log): string {
    const date = currentLog?.created_at as string;
    const dateT = new Date(date);
    const nowDate = new Date();
    const today = new Date();
    nowDate.setTime(today.getTime() - dateT.getTime());
    const hours = nowDate.getUTCHours();
    const minutes = nowDate.getUTCMinutes();
    const seconds = nowDate.getUTCSeconds();
    const Difference_In_Time = today.getTime() - dateT.getTime();
    const day = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    if (day > 0)
      return (
        '(⏱️' +
        day +
        'j ' +
        hours +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds) +
        ')'
      );
    return (
      '(⏱️' +
      hours +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds) +
      ')'
    );
  }

  calculateTime2Dates(currentLog: Log, oldLog: Log): string {
    const startDate = new Date(currentLog?.created_at);
    const endDate = new Date(oldLog?.created_at);
    const diffDate = new Date();
    diffDate.setTime(endDate.getTime() - startDate.getTime());
    const hours = diffDate.getUTCHours();
    const minutes = diffDate.getUTCMinutes();
    const seconds = diffDate.getUTCSeconds();
    const Difference_In_Time = endDate.getTime() - startDate.getTime();
    const day = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    if (day > 0) {
      return (
        '(⏱️' +
        day +
        'j ' +
        hours +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds) +
        ')'
      );
    }
    return (
      '(⏱️' +
      hours +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds) +
      ')'
    );
  }

  incrementTime() {
    this.seconds += 1;

    if (this.seconds >= 60) {
      this.seconds = 0;
      this.minutes += 1;

      if (this.minutes >= 60) {
        this.minutes = 0;
        this.hours += 1;
      }

      if (this.hours >= 60) {
        this.hours = 0;
        this.day += 1;
      }
    }
  }

  initialize() {
    let currentLog: any;
    this.buyedPackage.logs.forEach(log => {
      if (log.status === 'at_receptionist') {
        currentLog = log;
      }
    });
    if (!currentLog) return;

    const date = currentLog?.created_at as string;
    const dateT = new Date(date);
    const nowDate = new Date();
    const today = new Date();
    nowDate.setTime(today.getTime() - dateT.getTime());
    this.hours = nowDate.getUTCHours();
    this.minutes = nowDate.getUTCMinutes();
    this.seconds = nowDate.getUTCSeconds();
    const Difference_In_Time = today.getTime() - dateT.getTime();
    this.day = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    setInterval(() => this.incrementTime(), 1000);
  }

  save() {
    this.isLoading = true;
    this.packageService
      .addAnswer({
        datas: this.questions,
        buyedpackage_id: this.buyedPackage.id,
      })
      .subscribe({
        next: res => {
          this.services.eventService.publish('buyedpackage_status_change');
          this.getBuyedPackage(this.buyedPackage.id);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  _toMoney(number: number, rate: number) {
    return toMoney(number, rate);
  }

  getTutorialData(): any {
    const tutorialData = {
      assetType: '',
      assets: !this.breackpointMatche
        ? [
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-details-1.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-details-2.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-details-3.png',
          ]
        : [
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-details-1.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-details-2.png',
          ],
      asset: '',
      title: this.translateService.instant('kimbo-home.treatment-details'),
    };
    return tutorialData;
  }
}
