import { Injectable } from '@angular/core';

import { CustomNavigationService } from './custom-navigation.service';
import {
  ACCESS_TOKEN_KEY,
  CURRENT_ROLE,
  USER_KEY,
  CURRENCIE,
  CURRENCIE_USER,
  CURRENCIE_HCP,
  LANG,
  PUSHNOTIFICATION,
  INTOUCH_PAYMENT_INFO,
  CURRENCIES,
  DEFAULT_COUNTRY,
  DEFAULT_COUNTRY_CITY,
  DOWNLOAD_SNACK,
  CLOSE_BONUS_ALERT,
  SHOW_EVALUATE_MODAL,
  EVALUATE_MODAL_LAUNCHED,
  APP_RATED,
  TUTORIAL,
  TWO_FA_KEY,
  CONFIG_TWO_FA_KEY,
  REQUEST_LOGIN,
  SHOW_PRE_AUTH_MODAL,
  TIME_SPEND_AS_GUEST,
  MARK_CONSENT,
} from 'src/app/core/utils/constant';
import { AdminRole, DefaultRole, IRole, IUser } from '../models/User';
import { Country, Currency } from '../models/Common';
import {
  AdminRoleType,
  GroupType,
  PostLoginAtions,
  UserType,
} from '../enums/enums';
import {
  RestAuthentificationService,
  CurrencyService,
  UseCaseTotpService,
  UserService,
} from '../rest-services/rest-services';
import { EventService } from './event.service';
import { ServicesService } from './services.service';
import { PushNotificationService } from './push-notification.service';
import { ILoginResponse } from '../models/Login';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginPostActionParams } from '../utils/type';
import { PreLoginInfosComponent } from 'src/app/shared-components/login/pre-login-infos/pre-login-infos.component';
import { LoginModalComponent } from 'src/app/shared-components/login/login-modal/login-modal.component';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    public navigationService: CustomNavigationService,
    private currencyService: CurrencyService,
    private eventService: EventService,
    private services: ServicesService,
    private userService: UserService,
    private pushNotivationService: PushNotificationService,
    public authService: RestAuthentificationService,
    public notificationService: NotificationService,
    public translateService: TranslateService,
    public totpService: UseCaseTotpService
  ) {}

  public storeUser(user: IUser): void {
    this.services.eventService.publish('user:updated', user);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): IUser {
    return JSON.parse(localStorage.getItem(USER_KEY)!);
  }

  public storeRequired2FA(key: string): void {
    localStorage.setItem(TWO_FA_KEY, key);
  }

  public deleteRequired2FA(): void {
    localStorage.removeItem(TWO_FA_KEY);
  }

  public exist2FAToActivate(): boolean {
    return localStorage.getItem(TWO_FA_KEY) != null;
  }

  public storeConfigRequired2FA(key: string): void {
    localStorage.setItem(CONFIG_TWO_FA_KEY, key);
  }

  public deleteConfigRequired2FA(): void {
    localStorage.removeItem(CONFIG_TWO_FA_KEY);
  }

  public existConfig2FAToActivate(): boolean {
    return localStorage.getItem(CONFIG_TWO_FA_KEY) != null;
  }

  public deleteUser(): void {
    localStorage.removeItem(USER_KEY);
  }

  public storeAccessToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  public getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY)!;
  }

  public storeCurrentRole(role: IRole): void {
    localStorage.setItem(CURRENT_ROLE, JSON.stringify(role));
  }

  public getCurrentRole(): IRole {
    try {
      return JSON.parse(localStorage.getItem(CURRENT_ROLE)!);
    } catch (e) {
      this.clearStorage();
      return new DefaultRole(); // Just to prevent multiple modifications
    }
  }

  public deleteCurrentRole(): void {
    return localStorage.removeItem(CURRENT_ROLE)!;
  }

  public storeCurrencie(currencie: Currency): void {
    localStorage.setItem(CURRENCIE, JSON.stringify(currencie));
  }

  public storePaymentInfoToast(): void {
    localStorage.setItem(INTOUCH_PAYMENT_INFO, INTOUCH_PAYMENT_INFO);
  }

  public getPaymentInfoToast(): string {
    return localStorage.getItem(INTOUCH_PAYMENT_INFO)!;
  }

  public removePaymentInfoToast(): void {
    return localStorage.removeItem(INTOUCH_PAYMENT_INFO)!;
  }

  public getCurrencie(): Currency {
    return JSON.parse(localStorage.getItem(CURRENCIE)!);
  }

  public deleteCurrencie(): string {
    return localStorage.removeItem(CURRENCIE)!;
  }

  public storeUserCurrencie(currencie: Currency): void {
    localStorage.setItem(CURRENCIE_USER, JSON.stringify(currencie));
  }

  public getUserCurrencie(): Currency {
    return JSON.parse(localStorage.getItem(CURRENCIE_USER)!);
  }

  public deleteUserCurrencie(): string {
    return localStorage.removeItem(CURRENCIE_USER)!;
  }

  public storeCurrencies(currencie: Currency[]): void {
    localStorage.setItem(CURRENCIES, JSON.stringify(currencie));
  }

  public getCurrencies(): Currency[] {
    return JSON.parse(localStorage.getItem(CURRENCIES)!);
  }

  public deleteCurrencies(): string {
    return localStorage.removeItem(CURRENCIES)!;
  }

  public storeHcpCurrencie(currencie: Currency): void {
    localStorage.setItem(CURRENCIE_HCP, JSON.stringify(currencie));
  }

  public getHcpCurrencie(): Currency {
    return JSON.parse(localStorage.getItem(CURRENCIE_HCP)!);
  }

  public deleteHcpCurrencie(): string {
    return localStorage.removeItem(CURRENCIE_HCP)!;
  }

  public deleteAccessToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  public localLogin(user: IUser, token: string): void {
    this.storeUser(user);
    this.storeAccessToken(token);
  }

  public storeLanguage(lang: string): void {
    localStorage.setItem(LANG, lang);
  }

  public getLanguage(): string {
    return localStorage.getItem(LANG)!;
  }

  public deleteLanguage(): string {
    return localStorage.removeItem(LANG)!;
  }

  public storePushN(): void {
    this.pushNotivationService.requestSubscription();
    localStorage.setItem(PUSHNOTIFICATION, PUSHNOTIFICATION);
  }

  public getPushN(): string {
    return localStorage.getItem(PUSHNOTIFICATION)!;
  }

  public deletePushN(): string {
    this.pushNotivationService.unsubscribePush();
    return localStorage.removeItem(PUSHNOTIFICATION)!;
  }

  public storeCountry(country: Country): void {
    localStorage.setItem(DEFAULT_COUNTRY, JSON.stringify(country));
  }

  public getCountry(): Country {
    return localStorage.getItem(DEFAULT_COUNTRY)
      ? JSON.parse(localStorage.getItem(DEFAULT_COUNTRY)!)
      : {
          ip: '129.0.80.175',
          hostname: 'host-129.0.80.175.mtn.cm',
          city: 'Bafoussam',
          region: 'West',
          country: 'CM',
          loc: '5.4778,10.4176',
          org: 'AS30992 MTN Network Solutions (Cameroon)',
          timezone: 'Africa/Douala',
        };
  }

  public deleteCountry(): string {
    return localStorage.removeItem(DEFAULT_COUNTRY)!;
  }

  public storeCountry_city(country_city: any): void {
    localStorage.setItem(DEFAULT_COUNTRY_CITY, JSON.stringify(country_city));
  }

  public getCountry_city(): any {
    return localStorage.getItem(DEFAULT_COUNTRY_CITY)
      ? JSON.parse(localStorage.getItem(DEFAULT_COUNTRY_CITY)!)
      : {};
  }

  public deleteCountry_city(): string {
    return localStorage.removeItem(DEFAULT_COUNTRY_CITY)!;
  }

  public storeDownloadAppState(): void {
    localStorage.setItem(DOWNLOAD_SNACK, DOWNLOAD_SNACK);
  }

  public storeCountryCity(country_city: any): void {
    localStorage.setItem(DEFAULT_COUNTRY_CITY, JSON.stringify(country_city));
  }

  public getCountryCity(): any {
    return localStorage.getItem(DEFAULT_COUNTRY_CITY)
      ? JSON.parse(localStorage.getItem(DEFAULT_COUNTRY_CITY)!)
      : {};
  }

  public deleteCountryCity(): string {
    return localStorage.removeItem(DEFAULT_COUNTRY_CITY)!;
  }

  public storeMarkConsent(): void {
    localStorage.setItem(MARK_CONSENT, MARK_CONSENT);
  }

  public getMarkConsent(): string {
    return localStorage.getItem(MARK_CONSENT)!;
  }

  public deleteMarkConsent(): string {
    return localStorage.removeItem(MARK_CONSENT)!;
  }

  public getDownloadAppState(): string {
    return localStorage.getItem(DOWNLOAD_SNACK)!;
  }

  public deleteDownloadAppState(): string {
    return localStorage.removeItem(DOWNLOAD_SNACK)!;
  }

  public storeShowEvaluateModal(): void {
    localStorage.setItem(SHOW_EVALUATE_MODAL, SHOW_EVALUATE_MODAL);
  }

  public getShowEvaluateModal(): string {
    return localStorage.getItem(SHOW_EVALUATE_MODAL)!;
  }

  public deleteShowEvaluateModal(): string {
    return localStorage.removeItem(SHOW_EVALUATE_MODAL)!;
  }

  public storeEvaluateModalLaunched(): void {
    localStorage.setItem(EVALUATE_MODAL_LAUNCHED, EVALUATE_MODAL_LAUNCHED);
  }

  public getEvaluateModalLaunched(): string {
    return localStorage.getItem(EVALUATE_MODAL_LAUNCHED)!;
  }

  public deleteEvaluateModalLaunched(): string {
    return localStorage.removeItem(EVALUATE_MODAL_LAUNCHED)!;
  }

  public storeAppRated(): void {
    localStorage.setItem(APP_RATED, APP_RATED);
  }

  public getAppRated(): string {
    return localStorage.getItem(APP_RATED)!;
  }

  public deleteAppRated(): string {
    return localStorage.removeItem(APP_RATED)!;
  }

  public storeCloseAlertState(): void {
    localStorage.setItem(CLOSE_BONUS_ALERT, CLOSE_BONUS_ALERT);
  }

  public getCloseAlertState(): string {
    return localStorage.getItem(CLOSE_BONUS_ALERT)!;
  }

  public deleteCloseAlertState(): string {
    return localStorage.removeItem(CLOSE_BONUS_ALERT)!;
  }

  public storeGlobalTutorialState(): void {
    localStorage.setItem(TUTORIAL.TUTORIAL_GLOBAL, TUTORIAL.TUTORIAL_GLOBAL);
  }

  public getGlobalTutorialState(): string {
    return localStorage.getItem(TUTORIAL.TUTORIAL_GLOBAL)!;
  }

  public deleteGlobalTutorialState(): string {
    return localStorage.removeItem(TUTORIAL.TUTORIAL_GLOBAL)!;
  }

  public storeRequestToLogin(): void {
    localStorage.setItem(REQUEST_LOGIN, REQUEST_LOGIN);
  }

  public getRequestToLogin(): string {
    return localStorage.getItem(REQUEST_LOGIN)!;
  }

  public deleteRequestToLogin(): string {
    return localStorage.removeItem(REQUEST_LOGIN)!;
  }

  public storeShowPreAuthModal(): void {
    localStorage.setItem(SHOW_PRE_AUTH_MODAL, SHOW_PRE_AUTH_MODAL);
  }

  public getShowPreAuthModal(): string {
    return localStorage.getItem(SHOW_PRE_AUTH_MODAL)!;
  }

  public deleteShowPreAuthModal(): string {
    return localStorage.removeItem(SHOW_PRE_AUTH_MODAL)!;
  }

  public storeTimeSpentAsGuest(timeSpendAsGuest: string): void {
    localStorage.setItem(TIME_SPEND_AS_GUEST, timeSpendAsGuest);
  }

  public getTimeSpentAsGuest(): string {
    return localStorage.getItem(TIME_SPEND_AS_GUEST)!;
  }

  public deleteTimeSpentAsGuest(): string {
    return localStorage.removeItem(TIME_SPEND_AS_GUEST)!;
  }

  public logout(redirect = true): void {
    this.authService.logout().subscribe({
      next: () => {
        this.clearStorage(redirect);
      },
      error: () => {
        this.clearStorage(redirect);
      },
    });
  }

  clearStorage(redirect = true): void {
    this.deleteUser();
    this.deleteAccessToken();
    this.deleteCurrentRole();
    this.deleteCurrencie();
    this.deleteUserCurrencie();
    this.deleteHcpCurrencie();
    this.deleteRequestToLogin();
    this.deleteTimeSpentAsGuest();
    this.deleteShowPreAuthModal();
    if (redirect) this.navigationService.goTo('/pre-auth-portal');
  }

  public isLoggedIn(): boolean {
    const user: IUser = this.getUser();
    const token: string = this.getAccessToken();
    return user !== null && token !== null;
  }

  public isGuest(): boolean {
    const user = this.getUser();
    return user && user.user_type === UserType.Guest;
  }

  public loginGuest(): void {
    this.authService.loginGuest().subscribe({
      next: (res: ILoginResponse) => {
        this.storeAccessToken(res.access_token);
        this.userService.view(res.user_id).subscribe({
          next: (userRes: IUser) => {
            this.storeUser(userRes);
            this.storeUserCurrencie(userRes.currencie);
            const role = this.getRole(GroupType.Kimbo);
            this.services.roleService.changeRole(role);
          },
          error: (err: any) => {},
        });
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }

  getCurrentGroupId(): number {
    const role = this.getCurrentRole();
    return role.group.id;
  }

  public getRole(groupType: GroupType): IRole {
    const userRoles: IRole[] = this.getUser().roles!;
    if (groupType === GroupType.Admin) {
      return new AdminRole();
    }
    for (let index = 0; index < userRoles.length; index++) {
      if (userRoles[index].group.type === groupType) {
        return userRoles[index];
      }
    }
    return null as any;
  }

  public hasRole(role: string): boolean {
    if (this.isLoggedIn() && role) {
      const userRoles: IRole[] = this.getUser().roles!;
      if (role === GroupType.Admin) {
        const admin_type = this.getUser().admin_type;
        return (admin_type != null || admin_type != undefined) &&
          admin_type === 'admin'
          ? true
          : false;
      }

      for (let index = 0; index < userRoles.length; index++) {
        if (userRoles[index].group.type === role) {
          return true;
        }
      }
    } else {
      return false;
    }
    return false;
  }

  updateUserStorage(): void {
    const user = this.getUser();
    if (user) {
      this.userService.view(user.id).subscribe({
        next: (userRes: IUser) => {
          this.services.sessionService.deleteUser();
          this.services.sessionService.storeUser(userRes);
          this.currencyService.index().subscribe({
            next: (res: Currency[]) => {
              this.deleteCurrencies();
              this.storeCurrencies(res);
              const currentRole = this.getCurrentRole();
              let currency: Currency;
              if (currentRole.group.type != GroupType.Hcp) {
                currency = res.find(
                  currency => currency.id === userRes.currencie_id
                )!;
                this.deleteUserCurrencie();
                this.storeUserCurrencie(currency);
              } else {
                currency = res.find(
                  currency =>
                    currency.id ===
                    currentRole.group.healthcareprovider?.currencie_id!
                )!;
                this.deleteHcpCurrencie();
                this.storeHcpCurrencie(currency);
              }

              this.deleteCurrencie();
              this.storeCurrencie(currency);
              if (this.getCurrentRole().id !== -1) {
                const role: IRole = userRes.roles?.find(
                  (role: IRole) => role.id === currentRole.id
                )!;
                this.services.sessionService.deleteCurrentRole();
                this.services.sessionService.storeCurrentRole(role);
              }

              this.authService.getlocation().subscribe({
                next: (res: any) => {
                  this.deleteCountry();
                  this.storeCountry(res);
                },
                error: (err: any) => {},
                complete: () => {},
              });

              this.eventService.publish('update:currency', currency);
            },
            error: error => {},
            complete: () => {},
          });
        },
        error: (err: any) => {},
        complete: () => {},
      });
    }
  }

  location(): void {
    this.authService.getlocation().subscribe({
      next: (res: any) => {
        this.deleteCountry();
        this.storeCountry(res);
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }

  openLoginModal(
    action?: PostLoginAtions,
    params?: LoginPostActionParams,
    caller?: string
  ): void {
    this.services.sessionService.storeRequestToLogin();
    if (action) {
      this.services.modalService
        .openModal(PreLoginInfosComponent, {
          width: '400px',
          height: 'auto',
        })
        .subscribe(res => {
          if (res === '_SIGN_IN' || res == '_SIGN_UP') {
            this.services.modalService
              .openModal(LoginModalComponent, {
                width: '950px',
                height: 'auto',
                data: {
                  ...(action != null && { action: action }),
                  ...(params != null && { params: params }),
                  caller: res,
                },
              })
              .subscribe(() =>
                this.services.sessionService.deleteRequestToLogin()
              );
          } else {
            this.services.sessionService.deleteRequestToLogin();
          }
        });
    } else {
      this.services.modalService
        .openModal(LoginModalComponent, {
          width: '950px',
          height: 'auto',
          data: {
            ...(action != null && { action: action }),
            ...(params != null && { params: params }),
            ...(caller != null && { caller: caller }),
          },
        })
        .subscribe(() => this.services.sessionService.deleteRequestToLogin());
    }
  }

  getCurrencyCode(code: string) {
    if (code == 'XAF') {
      return 'CFA';
    }
    return code;
  }

  initialRoute(): string | undefined {
    let initialRoute = '/';
    const adminRole = this.getUser().adminroles!;
    if (!adminRole || adminRole.length === 0) {
      return undefined;
    }

    switch (adminRole[0].role_type) {
      case AdminRoleType.Super:
        initialRoute = '/admin/monitoring';
        break;
      case AdminRoleType.Operation:
        initialRoute = '/admin/healthcare-providers';
        break;
      case AdminRoleType.Finance:
        initialRoute = '/admin/transactions';
        break;
      case AdminRoleType.Support:
        initialRoute = '/admin/transactions';
        break;
      case AdminRoleType.Marketing:
        initialRoute = '/admin/discount';
        break;

      default:
        initialRoute = '/';
        break;
    }
    return initialRoute;
  }
}
