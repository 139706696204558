import {
  Component,
  Renderer2,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Menu, NavService } from 'src/app/core/services/navservice';
import { environment } from 'src/environments/environment';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { BreakpointState } from '@angular/cdk/layout';
import { AdminRoleService } from 'src/app/core/services/admin-role.service';
import { FaqComponent } from '../../../components/faq/faq.component';
import { ServicesService } from 'src/app/core/services/services.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { UseCaseGroupService } from 'src/app/core/rest-services/rest-services';
import { IRole, IUser } from 'src/app/core/models/models';
import { GroupType, Permission } from 'src/app/core/enums/enums';
import { ProfileComponent } from '../../../components/profile/profile.component';
import { TransactionComponent } from 'src/app/shared-components/transaction/transaction.component';
import { CreateEditRolesComponent } from '../../../components/create-edit-roles/create-edit-roles.component';
import {
  DEFAULT_IMAGE_BENEFIT,
  DEFAULT_IMAGE_CORPORATE,
  DEFAULT_IMAGE_HCP,
  DEFAULT_IMAGE_KIMBO,
} from 'src/app/core/utils/constant';

interface NavItem {
  name: string;
  mobileName: string;
  url: string;
  icon: string;
  iconLabel: string;
  shape: string;
  action?: () => void;
  rightsOwner?: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy, OnInit {
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() isLoading = false;
  public windowSubscribe$!: Subscription;
  options = { autoHide: false, scrollbarMinSize: 100 };
  icon!: SafeHtml;
  public menuItems!: Menu[];
  public menuitemsSubscribe$!: Subscription;
  public navServices!: NavService;
  badge = false;
  enviromentname = '';
  appVersion = '';
  breackpointMatche = false;
  translations = {
    navFaqLink: '',
    faqLinkUrl: '',
    navBenefitDashboardLink: '',
    navBenefitEmployeesLink: '',
    navBenefitPackagesLink: '',
    navBenefitHcpLink: '',
    navTransactionLink: '',
    naSettingsLink: '',
  };
  translationKeys: string[] = [
    'kimbo-layout.nav-faq-link',
    'common.faq-link-url',
    'benefit-layout.dashboard-label',
    'benefit-layout.employees-label',
    'benefit-layout.healthcare-module-label',
    'benefit-layout.healthcare-providers-label',
    'benefit-layout.transaction-nav-link',
    'benefit-layout.settings-nav-link',
  ];
  currentRole!: IRole;
  groupType!: GroupType;
  role = GroupType;
  navItems!: NavItem[];
  $languageChange!: Subscription;
  countResponse: any;
  $rolechange!: Subscription;
  $contactCount!: Subscription;
  contacts = 0;
  isSidebarOpen = true;
  user!: IUser;
  $headerEventSub!: Subscription;

  constructor(
    public router: Router,
    public renderer: Renderer2,
    public adminrole: AdminRoleService,
    public breakpointMatcherService: BreakpointMatcherService,
    public services: ServicesService,
    private languageService: LanguageService,
    private usecaseGroupService: UseCaseGroupService
  ) {
    this.user = this.services.sessionService.getUser();
    this.badge = environment.badge;
    this.enviromentname = environment.name;
    this.appVersion = environment.appVersion;
    this.breakpointMatcherService
      .initObserver('2xl')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
          this.isSidebarOpen = true;
          this.triggerEvent.emit(this.isSidebarOpen);
        } else {
          this.breackpointMatche = false;
          this.isSidebarOpen = false;
          this.triggerEvent.emit(this.isSidebarOpen);
        }
      });
    this.navServices = new NavService();
    this.$headerEventSub = this.services.eventService.subscribe(
      'sidemenu:toggle',
      () => this.toggleSidebarOpen()
    );
  }

  ngOnDestroy(): void {
    this.menuitemsSubscribe$.unsubscribe();
    this.$languageChange && this.$languageChange.unsubscribe();
    if (this.$contactCount) this.$contactCount.unsubscribe();
    this.$headerEventSub?.unsubscribe();
  }

  ngOnInit() {
    this.getTranslations();
    this.listenForLanguageChangeEvents();
    this.currentRole = this.services.roleService.getCurrentRole();
    this.groupType = this.services.roleService.getCurrentTypeRole();
    this.menuitemsSubscribe$ = this.navServices.items.subscribe(
      (items: Menu[]) => {
        this.menuItems = items;
      }
    );
    //BENEFICIARIES COUNTER REFRESH
    if (
      this.services.sessionService.getCurrentRole().group.type ===
      this.role.Benefit
    ) {
      this.fetchContactsCount();
    }

    this.$contactCount = this.services.eventService.subscribe(
      'contact-count:refresh',
      () => {
        if (
          this.services.sessionService.getCurrentRole().group.type ===
          this.role.Benefit
        ) {
          this.fetchContactsCount();
        }
      }
    );
    this.navItems = this.getNavItems();
  }

  toggleSidebarOpen(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.triggerEvent.emit(this.isSidebarOpen);
    if (this.isSidebarOpen && !this.breackpointMatche) {
      this.renderer.addClass(document.body, 'no-scroll');
    } else if (!this.isSidebarOpen && !this.breackpointMatche) {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }

  closeSideBar(): void {
    this.isSidebarOpen = false;
    if (!this.breackpointMatche) {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }

  public getNavItems(): NavItem[] {
    const navItems: NavItem[] = [];
    this.currentRole = this.services.sessionService.getCurrentRole();
    const isOwner = this.currentRole.permissions.includes(Permission.Owner);

    const currentRoleOption = {
      label: this.currentRole.group.name,
      value: this.currentRole.id.toString(),
      src:
        this.currentRole.group.image_profil &&
        this.currentRole.group.image_profil.path !==
          'public/general/unknow.png' &&
        this.currentRole.group.image_profil.path !== 'storage/general/group.png'
          ? this.services.utilsService.getServerUrl(
              this.currentRole.group.image_profil.path
            )
          : this.currentRole.group.type === GroupType.Hcp
          ? DEFAULT_IMAGE_HCP
          : this.currentRole.group.type === GroupType.Companie
          ? DEFAULT_IMAGE_CORPORATE
          : this.currentRole.group.type === GroupType.Benefit
          ? DEFAULT_IMAGE_BENEFIT
          : DEFAULT_IMAGE_KIMBO,
    };
    navItems.push({
      name: this.translations.navBenefitDashboardLink,
      mobileName: this.translations.navBenefitDashboardLink,
      url: '/benefit',
      icon: 'line-chart-line',
      iconLabel: 'Tableau de bord icon',
      shape: '_COMMON',
    });
    navItems.push({
      name: this.translations.navBenefitEmployeesLink,
      mobileName: this.translations.navBenefitEmployeesLink,
      url: '/benefit/employees',
      icon: 'group-3-line',
      iconLabel: 'User icon',
      shape: '_COMMON',
    });
    navItems.push({
      name: this.translations.navBenefitPackagesLink,
      mobileName: this.translations.navBenefitPackagesLink,
      url: '/benefit/health-bundles',
      icon: 'apps-line',
      iconLabel: 'Modules icon',
      shape: '_COMMON',
    });
    navItems.push({
      name: this.translations.navBenefitHcpLink,
      mobileName: this.translations.navBenefitHcpLink,
      url: '/benefit/healthcare-providers',
      icon: 'hospital-line',
      iconLabel: 'Hcp',
      shape: '_COMMON',
    });

    return navItems;
  }

  openGroupModal = () => {
    const currentRoleOption = {
      label: this.currentRole.group.name,
      value: this.currentRole.id.toString(),
      src:
        this.currentRole.group.image_profil &&
        this.currentRole.group.image_profil.path !==
          'public/general/unknow.png' &&
        this.currentRole.group.image_profil.path !== 'storage/general/group.png'
          ? this.services.utilsService.getServerUrl(
              this.currentRole.group.image_profil.path
            )
          : this.currentRole.group.type === GroupType.Hcp
          ? DEFAULT_IMAGE_HCP
          : this.currentRole.group.type === GroupType.Companie
          ? DEFAULT_IMAGE_CORPORATE
          : this.currentRole.group.type === GroupType.Benefit
          ? DEFAULT_IMAGE_BENEFIT
          : DEFAULT_IMAGE_KIMBO,
    };
    this.services.modalService.openModal(CreateEditRolesComponent, {
      height: 'auto',
      data: {
        action: '_EDIT_GROUP',
        state: currentRoleOption,
      },
    });
  };

  goTo(entry: string): void {
    this.services.customNavigationService.goTo(entry);
  }

  fetchContactsCount() {
    this.isLoading = true;
    this.usecaseGroupService
      .countContacts(this.services.sessionService.getCurrentRole().group.id)
      .subscribe({
        next: (res: any) => {
          this.contacts = res.contact;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  listenForLanguageChangeEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }

  public listenForRoleChangeEvents(): void {
    this.$rolechange = this.services.eventService.subscribe(
      'change:role',
      () => {
        this.currentRole = this.services.roleService.getCurrentRole();
        this.groupType = this.services.roleService.getCurrentTypeRole();
        this.navItems = this.getNavItems();
      }
    );
  }

  openSideMenu(): void {
    this.services.eventService.publish('show:mobiledrawer');
  }

  openSupportModal(): void {
    this.services.modalService.openModal(FaqComponent, {
      width: '500px',
      height: 'auto',
    });
  }

  openProfilDialog(): void {
    this.services.modalService.openModal(ProfileComponent, {
      width: '1300px',
      height: 'auto',
    });
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.navFaqLink = translations['kimbo-layout.nav-faq-link'];
      this.translations.faqLinkUrl = translations['common.faq-link-url'];
      this.translations.navBenefitDashboardLink =
        translations['benefit-layout.dashboard-label'];
      this.translations.navBenefitEmployeesLink =
        translations['benefit-layout.employees-label'];
      this.translations.navBenefitPackagesLink =
        translations['benefit-layout.healthcare-module-label'];
      this.translations.navBenefitHcpLink =
        translations['benefit-layout.healthcare-providers-label'];
      this.translations.navTransactionLink =
        translations['benefit-layout.transaction-nav-link'];
      this.translations.naSettingsLink =
        translations['benefit-layout.settings-nav-link'];
      this.navItems = this.getNavItems();
    });
  }
}
