import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { Currency } from 'src/app/core/models/Common';
import { File } from 'src/app/core/models/File';
import {
  FileService,
  UsecasePdfService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { shortenFileName, toMoney } from 'src/app/core/utils/misc';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { DocumentModalComponent } from '../../pages/hcp/components/modals/document-modal/document-modal.component';
import { hasOwner } from 'src/app/core/utils/core';
import { BuyedpackageStatus } from 'src/app/core/enums/enums';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';

@Component({
  selector: 'app-treatment-documents',
  templateUrl: './treatment-documents.component.html',
  styleUrls: ['./treatment-documents.component.scss'],
})
export class TreatmentDocumentsComponent implements OnInit {
  @Input() _buyedPackage!: IBuyedpackage;
  @Input() canEdit = true;
  @Input() canUpload = true;
  @Input() caller: '_CHECKOUT' | '_DETAILS' | '_KIMBO' = '_DETAILS';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  isOwner = false;
  fileName?: string;
  file?: File;
  language?: string;
  isLoading = false;
  currency: Currency;
  buyedPackageStatus = BuyedpackageStatus;
  isPdfGenerating = false;
  constructor(
    public services: ServicesService,
    private translateService: TranslateService,
    private fileService: FileService,
    private pdfService: UsecasePdfService,
    private notificationService: NotificationService
  ) {
    this.currency = this.services.sessionService.getCurrencie();
  }

  ngOnInit(): void {
    const role = this.services.sessionService.getCurrentRole();
    this.isOwner = hasOwner(role);
    this.language = this.translateService.currentLang;
  }

  setFieldValue(event: any): void {
    this.fileName = shortenFileName(event.file.name);
    this.file = event.file;
    this.services.modalService
      .openModal(DocumentModalComponent, {
        height: 'auto',
        data: {
          action: '_CREATE',
          buyedPackage: this._buyedPackage,
          fileName: this.fileName,
          file: this.file,
        },
      })
      .subscribe(action => {
        if (action === '_SAVE') {
          this.triggerEvent.emit();
        }
      });
  }

  emitAction(action: string, file: any): void {
    if (action === '_EDIT') {
      this.triggerEvent.emit('_CLOSE_MOTHER');
      this.services.modalService
        .openModal(DocumentModalComponent, {
          height: 'auto',
          data: {
            action: '_EDIT',
            buyedPackage: this._buyedPackage,
            storedFile: file,
          },
        })
        .subscribe(action => {
          if (action === '_SAVE') {
            this.triggerEvent.emit();
          }
        });
    } else if (action === '_DELETE') {
      this.services.modalService
        .openModal(MaterialConfirmDialogComponent, {
          width: '500px',
          height: 'auto',
        })
        .subscribe(action => {
          if (action === '_YES') {
            this.deleteDocument(file);
          }
        });
    }
  }

  deleteDocument(file: any) {
    this.isLoading = true;
    this.fileService.delete(file.id).subscribe({
      next: (res: any) => {
        this.notificationService.success(
          this.translateService.instant('forms.success-delete-document')
        );
        this.triggerEvent.emit();
      },
      error: error => {
        error = this.services.translateService.instant('errors.generic');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  _toLocaleDateTime(date: string): string {
    return toWordLocaleDateTime(new Date(date), this.language!);
  }

  filterFiles(): any[] {
    return this._buyedPackage.files.filter(f => f.type === 'final_invoice');
  }

  _toMoney(hc: number) {
    return toMoney(hc, this.currency.rate);
  }

  getCurrencyCode(code: string) {
    return this.services.sessionService.getCurrencyCode(code);
  }

  downloadInvoice() {
    this.isPdfGenerating = true;
    this.pdfService.createFlyInvoice(this._buyedPackage.id).subscribe({
      next: (res: Blob) => {
        const url = window.URL.createObjectURL(res);
        window.open(url, '_blank');
      },
      error: error => {
        error = this.services.translateService.instant('errors.generic');
        this.isPdfGenerating = false;
      },
      complete: () => {
        this.isPdfGenerating = false;
      },
    });
  }
}
