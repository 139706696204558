import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-app-update-snack-bar',
  templateUrl: './app-update-snack-bar.component.html',
  styleUrls: ['./app-update-snack-bar.component.scss'],
})
export class AppUpdateSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackRef: MatSnackBarRef<AppUpdateSnackBarComponent>,
    private ren: Renderer2
  ) {}

  reload(): void {
    window.location.reload();
  }

  dismiss() {
    this._snackRef.dismiss();
  }
}
