import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AiService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * translateText
   */
  public translateText(
    text: string,
    traget_lang: string,
    source_lang?: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append('text', text);
    formData.append('target_lang', traget_lang);
    if (source_lang) formData.append(source_lang, source_lang);
    return this.http.post<any>(
      `${this.basePath}/v1/translate_text`,
      formData,
      this.httpOptionsWithFromData
    );
  }

  /**
   * speechToTextDeepGram
   */
  public speechToTextDeepGram(file: Blob): Observable<any> {
    const formParams = new FormData();
    formParams.append('file', file);
    return this.http.post<any>(
      `${this.basePath}/v1/speech-to-text`,
      formParams,
      this.httpOptionsWithFromData
    );
  }
}
