import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  getPaginationElements,
  isSeparator,
} from 'src/app/core/utils/pagination';
import { PaginationElement } from 'src/app/core/utils/type';

type PaginationProps = {
  className?: string;
  pagination: PaginationElement;
};

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() params!: PaginationProps;
  @Input() shape = '_BLUE'; // '_BLUE' ¦¦ '_WHITE'
  @Output() triggerEvent: EventEmitter<number | symbol> = new EventEmitter();
  canNext = false;
  canBack = false;
  kind = 'button';
  paginationElements: (number | symbol)[] = [];
  constructor() {
    this.canNext = true;
  }

  ngOnInit(): void {
    this.paginationElements = getPaginationElements(
      this.params.pagination.totalPages,
      this.params.pagination.page
    );
  }

  isSeparator(el: any): boolean {
    return isSeparator(el);
  }

  onPageChange(el: number | symbol): void {
    if (typeof el !== 'symbol') {
      this.triggerEvent.emit(el);
      this.paginationElements = getPaginationElements(
        this.params.pagination.totalPages,
        el
      );
    }
  }

  next(): void {
    const nextPage = this.params.pagination.page + 1;
    if (nextPage < this.paginationElements.length) {
      this.triggerEvent.emit(nextPage);
      this.paginationElements = getPaginationElements(
        this.params.pagination.totalPages,
        nextPage
      );
      this.canBack = true;
    } else {
      this.canNext = false;
      this.canBack = true;
    }
  }

  back(): void {
    const prevPage = this.params.pagination.page - 1;
    if (prevPage >= 1) {
      this.triggerEvent.emit(prevPage);
      this.paginationElements = getPaginationElements(
        this.params.pagination.totalPages,
        prevPage
      );
      this.canBack = true;
    } else {
      this.canBack = false;
      this.canNext = true;
    }
  }
}
