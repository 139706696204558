<main class="w-full min-h-screen transition-all main">
  <app-sidebar (triggerEvent)="setIsSideBarOpen($event)"></app-sidebar>
  <!-- navbar -->
  <app-header
    class="block {{
      isSideBarOpen && breackpointMatche ? '2xl:ml-[320px]' : ''
    }} "></app-header>
  <!-- Content -->
  <div
    class="p-6 {{
      isSideBarOpen && breackpointMatche ? '2xl:ml-[320px]' : ''
    }}">
    <router-outlet></router-outlet>
  </div>
  <!-- End Content -->
</main>
