import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IHcpSearchResponse,
  ILocationScrappingResponse,
} from '../models/scrapping';

@Injectable({ providedIn: 'root' })
export class ScrappingService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * scrapt location datas
   */
  public scraptDatas(
    name: string,
    location: string
  ): Observable<ILocationScrappingResponse> {
    return this.http.post<ILocationScrappingResponse>(
      `${this.basePath}/v1/scrap_datas`,
      {
        name: name,
        location: location,
      },
      this.httpOptions
    );
  }

  /**
   * search HCP on KimboCare and Google
   */
  public searchKimboAndGoogleHCPs(
    longitude: number,
    latitude: number
  ): Observable<IHcpSearchResponse> {
    return this.http.post<IHcpSearchResponse>(
      `${this.basePath}/v1/search_kimbo_and_google_hcps`,
      {
        longitude: longitude,
        latitude: latitude,
      },
      this.httpOptions
    );
  }
}
