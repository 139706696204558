import { IRole } from 'src/app/core/models/models';
import * as emailValidator from 'email-validator';
import { Permission } from '../enums/enums';

export function lz(v: number): string {
  return v.toString().padStart(2, '0');
}

export function not<T extends (...args: any[]) => boolean>(fn: T) {
  return (...args: Parameters<T>): boolean => {
    return !fn(...args);
  };
}

export function isNull(v: unknown): v is null {
  return v === null;
}

export function isTrue(v: unknown): v is true | 'true' {
  return v === true || v === 'true';
}

export function isString(v: unknown): v is string {
  return typeof v === 'string';
}

export function isValidEmail(v: unknown): v is string {
  return isString(v) && emailValidator.validate(v);
}

export function isValidPhoneNumber(v: unknown): v is string {
  return isString(v) && /^\+?[\d()\s-]{7,}$/.test(v);
}

export function isValidEmailAdress(v: unknown): v is string {
  return (
    isString(v) && /^[A-Z]{1}[A-Z0-9._%+-]*@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v)
  );
}

export function isBlankString(v: unknown): v is string {
  return isString(v) && v.length === 0;
}

export function isEmptyString(v: unknown): v is string {
  return isString(v) && v.trim().length === 0;
}

export function isNonEmptyString(v: unknown): v is string {
  return !isEmptyString(v);
}

export function isFullDateString(v: unknown): v is string {
  // Check if value matches DD/MM/YYYY format.
  if (!isString(v) || !/^\d{2}\/\d{2}\/\d{4}$/.test(v)) {
    return false;
  }

  // DD/MM/YYYY -> YYYY-MM-DD
  const d = new Date(v.split('/').reverse().join('-'));

  // Check if entered value is a correct date. This handles case
  // when someone types 30/02/YEAR - it is invalid date, but javascript
  // Date object creates a valid 01/03/YEAR.
  return v === `${lz(d.getDate())}/${lz(d.getMonth() + 1)}/${d.getFullYear()}`;
}

export function hasDigit(v: unknown): v is string {
  return isString(v) && /\d/.test(v);
}

export function doesMapToNumberInRange(
  min: number = -Infinity,
  max: number = Infinity
) {
  return (v: unknown) => {
    let value = v;

    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    return typeof value === 'number' && value >= min && value <= max;
  };
}

export function isIntegerString(v: unknown) {
  return isString(v) && /^(0|-?[1-9]\d*)$/.test(v);
}

export function isFloatString(v: unknown) {
  return isString(v) && /^-?(0|[1-9]\d*)(\.\d+)?$/.test(v);
}

export function isOptionalFloatString(v: unknown) {
  return v === '' || isFloatString(v);
}

export function isObject(v: unknown): v is object {
  return (
    typeof v === 'object' &&
    v !== null &&
    !(v instanceof Set) &&
    !(v instanceof Map)
  );
}

export function isNonEmptyObject(v: unknown): v is object {
  return isObject(v) && Object.keys(v).length > 0;
}

export function isOneOf<T>(collection: T[]) {
  return (v: unknown): v is T => {
    return collection.includes(v as any);
  };
}

export function isCardExpirationDate(v: unknown): v is string {
  // Check if value matches MM/YY format.
  // We allow only years from 2020 to 2099.
  return isString(v) && /^(0[1-9]|1[012])\/[2-9]\d$/.test(v);
}

export function isValidCVC(v: unknown): v is string {
  return isString(v) && /^\d{3,4}$/.test(v);
}

// All Visa card numbers start with a 4. New cards have 16 digits. Old cards have 13.
export function isValidVisaCardNumber(v: unknown): v is string {
  return isString(v) && /^4[0-9]{12}(?:[0-9]{3})?$/.test(v);
}

// MasterCard numbers either start with the numbers 51 through 55
// or with the numbers 2221 through 2720. All have 16 digits.
export function isValidMastercardCardNumber(v: unknown): v is string {
  return (
    isString(v) &&
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
      v
    )
  );
}

// American Express card numbers start with 34 or 37 and have 15 digits.
export function isValidAmericanExpressCardNumber(v: unknown): v is string {
  return isString(v) && /^3[47][0-9]{13}$/.test(v);
}

export function isFile(v: unknown): v is File {
  return v instanceof File;
}

export function isImageFile(v: unknown): v is File {
  return isFile(v) && /^image\/(jpg|jpeg|png|gif)$/.test(v.type);
}

export function hasSizeInMegebytes(mb: number) {
  return (v: unknown): v is File => {
    return isFile(v) && v.size <= mb * 1024 * 1024;
  };
}

export function hasLength(min: number, max: number, trim: boolean = true) {
  return (v: unknown): v is string => {
    if (!isString(v)) {
      return false;
    }

    const value = trim ? v.trim() : v;
    return value.length >= min && value.length <= max;
  };
}

export function hasLowerCase(v: unknown): v is string {
  return isNonEmptyString(v) && v.toUpperCase() !== v;
}

export function hasUpperCase(v: unknown): v is string {
  return isNonEmptyString(v) && v.toLowerCase() !== v;
}

export function hasNonAlphanumericChar(v: unknown): v is string {
  return isString(v) && /[@!#$%^&*()_\-+=§£`~,.<>/?'";:[\]{}]/i.test(v);
}

export function hasCityOrCountryChars(v: unknown): v is string {
  if (!isString(v)) {
    return false;
  }

  if (/[^\s-]{2,}[\s-][^\s-]{2,}/.test(v)) {
    return !hasNonAlphanumericChar(v.replace(/[\s-]/g, ''));
  }

  return !hasNonAlphanumericChar(v);
}

export function hasOwner(role: IRole): boolean {
  let permission = false;
  role.permissions.forEach(p => {
    if (p === Permission.Owner) {
      permission = true;
    }
  });
  return permission;
}
