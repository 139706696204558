import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { useDiscount } from '../../models/Discount';

@Injectable()
export class UseCaseDiscountService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   *
   * @param number the number of code to generate
   * Bulk generation of discount code
   */
  public codeBulkGeneration(
    discountId: number,
    number: number
  ): Observable<string[]> {
    let queryParameters = new HttpParams({
      fromObject: {
        number: number,
      },
    });

    return this.http.get<string[]>(
      `${this.basePath}/v1/discount/${discountId}/bulk_generate`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * useDiscount
   * Use discount on kimbo
   */
  public UseDiscount(body: useDiscount): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/discount/use`,
      body,
      this.httpOptions
    );
  }
}
