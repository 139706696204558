<div *ngIf="isLoading" class="w-full flex items-center justify-center h-32">
  <app-spinner class="inline-block ml-4 h-8 w-8"></app-spinner>
</div>
<div
  *ngIf="recommendations && recommendations.length === 0 && !isLoading"
  class="w-full h-full mt-4 no-campaign-bg relative">
  <div class="w-full flex flex-col justify-center items-center">
    <!--img appImg class="grayscale" src="assets/images/feedback.png" />
    <p-- class="my-6 text-gray font-bold text-lg">
      {{ 'hcp-common.no-recommendations' | translate }}
    </p-->
    <app-no-content
      imgPath="assets/gif/recommendation-animate.svg"
      [message]="'hcp-common.no-recommendations' | translate"
      textWidth="text-base"
      iconWidth="w-52"
      customHeight="h-fit"
      class="block w-full sm:w-9/12 my-4 mx-auto" />
  </div>
</div>
<div *ngIf="!isLoading">
  <div class="flex flex-wrap items-stretch justify-between mt-4 h-auto">
    <app-recommendated-module
      *ngFor="let recommendation of recommendations"
      [recommendation]="recommendation"
      class="w-full lg:w-1/2-with-gap mt-8 px-3"></app-recommendated-module>
    <!-- Add a ghost element so `justify-content: space-between` works correctly
   when we have 1 elements in the last row, when the rest of rows have 2 of them.-->
    <div
      *ngIf="recommendations.length % 2 === 1"
      class="hidden lg:block lg:w-1/2-with-gap"></div>
  </div>

  <div *ngIf="canEdit" class="w-full flex justify-center mt-4">
    <button
      class="h-12"
      mat-raised-button
      color="primary"
      (click)="openRecommendationModal()"
      type="button">
      <mat-icon>add</mat-icon>
      {{ 'hcp-common.add-recommendations' | translate }}
    </button>
  </div>
</div>
