<h2 class="text-navy" mat-dialog-title>
  <span>Confirmation</span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="close icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="relative">
  <div class="flex flex-col items-center justify-center w-full px-1 py-2">
    <p
      class="text-center"
      [innerHTML]="'common.confirm-role' | translate : { tel: contact }"></p>
    <div class="flex justify-between w-full pt-4">
      <button mat-stroked-button mat-dialog-close color="primary">
        {{ 'common.no-button' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="close()">
        {{ 'common.yes-button' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
