import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { concatMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IMessage,
  IConversationable,
  CreateConversationStore,
  IMessageStore,
} from '../models/Conversation';
import { Content } from '../models/Common';
import { BuyedPackageService } from './rest-services';
import { IBuyedpackage } from '../models/Buyedpackage';
import { ConversationnableType } from '../enums/enums';

@Injectable({ providedIn: 'root' })
export class ChatService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private buyedPackageService: BuyedPackageService
  ) {}

  /**
   * createConversation
   * @param conversation
   */
  public createConversation(
    conversation: CreateConversationStore,
    buyedpackage_id: number
  ): Observable<IBuyedpackage> {
    return this.http
      .post<IBuyedpackage>(
        `${this.basePath}/v1/chat/create`,
        conversation,
        this.httpOptions
      )
      .pipe(concatMap(() => this.buyedPackageService.view(buyedpackage_id)));
  }

  /**
   * sendMessage
   * @param message
   * @param conversationId
   */
  public sendMessage(
    message: IMessageStore,
    conversationId: number
  ): Observable<Content<IMessage>> {
    return this.http
      .post<Content<IMessage>>(
        `${this.basePath}/v1/chat/send/${conversationId}`,
        message,
        this.httpOptions
      )
      .pipe(
        concatMap(() =>
          this.getMessages(
            conversationId,
            1,
            message.conversationnable_type,
            message.conversationnable_id
          )
        )
      );
  }

  /**
   * getMessages
   * @param conversationId
   * @param page
   */
  public getMessages(
    conversationId: number,
    page: number,
    conversationnable_type: ConversationnableType,
    conversationnable_id: number
  ): Observable<Content<IMessage>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page,
        conversationnable_type: conversationnable_type,
        conversationnable_id: conversationnable_id,
      },
    });

    return this.http.get<Content<IMessage>>(
      `${this.basePath}/v1/chat/message/${conversationId}`,
      {
        params: queryParameters,
        ...this.httpOptions,
      }
    );
  }

  /**
   * getConversation
   * @param conversationId
   */
  public getConversation(
    conversationId: number
  ): Observable<IConversationable> {
    return this.http.get<IConversationable>(
      `${this.basePath}/v1/chat/${conversationId}`
    );
  }
}
