<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  (click)="startRecording()"
  matRippleColor="rgba(30,104,194,.1)"
  [matTooltip]="
    (recordingStart
      ? 'common.stop-recording-label'
      : 'common.start-recording-label'
    ) | translate
  "
  matTooltipClass="custom-material-tooltip"
  class="rounded-full shadow w-fit cursor-pointer relative">
  <div
    *ngIf="recordingStart"
    class="animate-ping rounded-full inset-0 absolute bg-kimbo-ruby bg-opacity-10"></div>
  <button
    type="button"
    class="flex items-center justify-center h-12 w-12 rounded-full {{
      recordingStart ? 'bg-kimbo-ruby' : 'bg-kimbo-gradient'
    }} text-white {{ !isReady && 'opacity-55 pointer-events-none' }}">
    <i *ngIf="recordingStart && !isLoading" class="ri-stop-large-fill"></i>
    <i *ngIf="!recordingStart && !isLoading" class="ri-mic-fill"></i>
    <app-spinner
      *ngIf="isLoading"
      class="inline-block w-5 h-5 filter-white"></app-spinner>
  </button>
</div>
