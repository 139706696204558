import { hasOwner } from 'src/app/core/utils/core';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { Content } from 'src/app/core/models/Common';
import { Note } from 'src/app/core/models/Note';
import { NoteService } from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { NoteModalComponent } from '../modals/note-modal/note-modal.component';
import { IRole } from 'src/app/core/models/User';
import { Subscription } from 'rxjs';
import { PaginationElement } from 'src/app/core/utils/type';

@Component({
  selector: 'app-treatment-notes',
  templateUrl: './treatment-notes.component.html',
  styleUrls: ['./treatment-notes.component.scss'],
})
export class TreatmentNotesComponent implements OnInit, OnDestroy {
  dropdownVisibility: boolean = false;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() buyedPackage!: IBuyedpackage;
  @Input() canEdit: boolean = true;
  notes: Note[] = [];
  page = 1;
  pagination: PaginationElement = { page: 1, totalPages: 1 };
  @Input() isLoading: boolean = false;
  isOwner: boolean = false;
  $owner!: Subscription;

  constructor(
    public services: ServicesService,
    private noteService: NoteService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    let role = this.sessionService.getCurrentRole();
    this.isOwner = hasOwner(role);
    this.$owner = this.services.eventService.subscribe(
      'owner:has',
      (role: IRole) => {
        this.isOwner = hasOwner(role);
      }
    );
    this.getNotes();
  }

  ngOnDestroy(): void {
    this.$owner && this.$owner.unsubscribe();
  }

  toggleDropdownVisibility(): void {
    this.dropdownVisibility = !this.dropdownVisibility;
  }

  setFieldValue(event: any): void {}

  openNoteModal(): void {
    this.services.modalService
      .openModal(NoteModalComponent, {
        height: '500px',
        data: {
          action: '_CREATE',
          b_id: this.buyedPackage.id,
        },
      })
      .subscribe(action => {
        if (action === '_ACT') {
          this.page = 1;
          this.getNotes();
        }
      });
  }

  getNotes(_page?: number) {
    this.isLoading = true;
    this.noteService.index(this.page, this.buyedPackage.id).subscribe({
      next: (res: Content<Note>) => {
        this.notes = res.data;
        this.pagination = {
          page: _page ? _page : 1,
          totalPages: res.last_page,
          total: res.total,
          perPage: res.per_page,
          form: res.from,
          to: res.to,
        };
      },
      error: error => {
        error = this.services.translateService.instant('errors.generic');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  deleteNote(note: Note) {
    this.isLoading = true;
    this.noteService.delete(this.buyedPackage.id, note.id).subscribe({
      next: (res: Note) => {
        this.notificationService.success(
          this.translateService.instant('forms.success-delete-note')
        );
        this.getNotes();
      },
      error: error => {
        error = this.services.translateService.instant('errors.generic');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleNoteEdition(note: Note): void {
    this.services.modalService
      .openModal(NoteModalComponent, {
        height: '500px',
        data: {
          action: '_EDIT',
          b_id: this.buyedPackage.id,
          state: note,
        },
      })
      .subscribe(action => {
        if (action === '_ACT') {
          this.page = 1;
          this.getNotes();
        }
      });
  }

  handleDeleteAction(note: Note): void {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.deleteNote(note);
        }
      });
  }

  setPage(el: number | symbol): void {
    if (typeof el !== 'symbol') {
      this.page = el;
      this.getNotes(this.page);
    }
  }

  emitAction(action: string, note: Note): void {
    this.triggerEvent.emit({ action: action, state: note });
  }
}
