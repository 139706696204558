import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRole, RoleStore } from '../../models/models';

@Injectable()
export class RestRoleService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  // COMMON ROLE MANAGMENT

  /**
   * Delete
   * @param roleId
   */
  public delete(roleId: number): Observable<IRole> {
    return this.http.delete<IRole>(
      `${this.basePath}/v1/resources/role/${roleId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: any): Observable<IRole> {
    return this.http.post<IRole>(
      `${this.basePath}/v1/resources/role`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param roleId
   */
  public update(roleId: number, body?: RoleStore): Observable<IRole> {
    return this.http.put<IRole>(
      `${this.basePath}/v1/resources/role/${roleId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Roles
   * @param groupId
   */
  public index(groupId?: number): Observable<IRole[]> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(groupId != null && { group_id: groupId }),
      },
    });

    return this.http.get<IRole[]>(`${this.basePath}/v1/resources/role`, {
      params: queryParameters,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  /**
   * View
   * @param roleId
   */
  public view(roleId: number): Observable<IRole> {
    return this.http.get<IRole>(
      `${this.basePath}/v1/resources/role/${roleId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public inviteMember(body: any): Observable<IRole> {
    return this.http.post<IRole>(
      `${this.basePath}/v1/usecase-role/invite`,
      JSON.stringify(body),
      this.httpOptions
    );
  }
}
