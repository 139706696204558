import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content, ISetting, SettingStore } from '../../models/models';

@Injectable()
export class SettingsService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param settingId
   */
  public delete(settingId: number): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/v1/resources/setting/${settingId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: SettingStore): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/resources/setting`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param settingId
   */
  public update(settingId: number, body?: SettingStore): Observable<any> {
    return this.http.put<any>(
      `${this.basePath}/v1/resources/setting/${settingId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Groups
   * @param page
   */
  public index(page: number, search?: string): Observable<Content<ISetting>> {
    let queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(search != null && { search: search }),
      },
    });

    return this.http.get<Content<ISetting>>(
      `${this.basePath}/v1/resources/setting`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param settingId
   */
  public view(settingId: number): Observable<ISetting> {
    return this.http.get<ISetting>(
      `${this.basePath}/v1/resources/setting/${settingId}`,
      this.httpOptions
    );
  }
}
