<button
  matTooltipPosition="after"
  type="button"
  (click)="closeMapPannel()"
  class="absolute z-header flex items-center justify-center w-10 h-10 text-white bg-white rounded-full hover:bg-opacity-75 ring-4 focus:ring-blue-300 focus:outline-none top-5 left-5">
  <img
    appImg
    src="assets/svg/close.svg"
    alt="close icon"
    class="w-6 h-6 text-white" />
  <span class="sr-only">Open actions menu</span>
</button>
<div id="map"></div>
