import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from './services.service';
import { Subscription } from 'rxjs';
import { StatusLogs } from '../enums/enums';
import { IBuyedpackage } from '../models/Buyedpackage';
import { OptionObject } from '../utils/type';

@Injectable({
  providedIn: 'root',
})
export class HcpService implements OnDestroy {
  translations: any = {
    statusAtReceptionist: '',
    statusTreatmentCompleted: '',
    statusReadyForPayment: '',
    statusNotStarted: '',
    statusAtDrugstore: '',
    statusAtImagery: '',
    statusAtLaboratory: '',
    statusPrimaryCare: '',
    statusSpecialistCare: '',
    statusInpatientServices: '',
    statusCurrentServiceOver: '',
  };
  translationKeys: string[] = [
    'common.at_receptionist',
    'common.treatment_completed',
    'common.not_started',
    'common.at_drugstore',
    'common.at_imagery',
    'common.at_laboratory',
    'common.primary_care',
    'common.specialist_care',
    'common.inpatient_services',
    'common.current_service_over',
  ];
  $languageChange!: Subscription;
  statusLogs = StatusLogs;

  constructor(
    private translateService: TranslateService,
    public services: ServicesService
  ) {
    this.getTranslations();
    this.listenForLanguageEvents();
  }
  ngOnDestroy(): void {
    this.$languageChange?.unsubscribe();
  }

  setLastLog(buyedPackage: IBuyedpackage): OptionObject | undefined {
    const lastLog = buyedPackage.last_log ? buyedPackage.last_log.status : '';
    if (
      buyedPackage.last_log != null &&
      (lastLog === this.statusLogs.CURRENTSERVICEOVER ||
        lastLog === this.statusLogs.INPATIENTSERVICES ||
        lastLog === this.statusLogs.SPECIALISTCARE ||
        lastLog === this.statusLogs.PRIMARYCARE ||
        lastLog === this.statusLogs.ATDRUGSTORE ||
        lastLog === this.statusLogs.ATIMAGERY ||
        lastLog === this.statusLogs.ATLABORATORY ||
        lastLog === this.statusLogs.NOTSTARTED ||
        lastLog === this.statusLogs.TREATMENTCOMPLETED ||
        lastLog === this.statusLogs.ATRECEPTIONIST)
    ) {
      return {
        label: lastLog,
        value:
          lastLog === this.statusLogs.CURRENTSERVICEOVER
            ? this.translations.statusCurrentServiceOver
            : lastLog === this.statusLogs.INPATIENTSERVICES
            ? this.translations.statusInpatientServices
            : lastLog === this.statusLogs.SPECIALISTCARE
            ? this.translations.statusSpecialistCare
            : lastLog === this.statusLogs.PRIMARYCARE
            ? this.translations.statusPrimaryCare
            : lastLog === this.statusLogs.ATLABORATORY
            ? this.translations.statusAtLaboratory
            : lastLog === this.statusLogs.ATIMAGERY
            ? this.translations.statusAtImagery
            : lastLog === this.statusLogs.ATDRUGSTORE
            ? this.translations.statusAtDrugstore
            : lastLog === this.statusLogs.NOTSTARTED
            ? this.translations.statusNotStarted
            : lastLog === this.statusLogs.TREATMENTCOMPLETED
            ? this.translations.statusTreatmentCompleted
            : this.translations.statusAtReceptionist,
      };
    }
    return undefined;
  }

  public listenForLanguageEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }

  getFilter(): OptionObject[] {
    return [
      {
        label: this.statusLogs.NOTSTARTED,
        value: this.translations.statusNotStarted,
      },
      {
        label: this.statusLogs.ATRECEPTIONIST,
        value: this.translations.statusAtReceptionist,
      },
      {
        label: this.statusLogs.ATLABORATORY,
        value: this.translations.statusAtLaboratory,
      },
      {
        label: this.statusLogs.PRIMARYCARE,
        value: this.translations.statusPrimaryCare,
      },
      {
        label: this.statusLogs.SPECIALISTCARE,
        value: this.translations.statusSpecialistCare,
      },
      {
        label: this.statusLogs.ATIMAGERY,
        value: this.translations.statusAtImagery,
      },
      {
        label: this.statusLogs.ATDRUGSTORE,
        value: this.translations.statusAtDrugstore,
      },
      {
        label: this.statusLogs.CURRENTSERVICEOVER,
        value: this.translations.statusCurrentServiceOver,
      },
      {
        label: this.statusLogs.INPATIENTSERVICES,
        value: this.translations.statusInpatientServices,
      },
      {
        label: this.statusLogs.TREATMENTCOMPLETED,
        value: this.translations.statusTreatmentCompleted,
      },
    ];
  }

  getTranslations(): void {
    this.translateService.get(this.translationKeys).subscribe(translations => {
      this.translations.statusAtReceptionist =
        translations['common.at_receptionist'];
      this.translations.statusTreatmentCompleted =
        translations['common.treatment_completed'];
      this.translations.statusNotStarted = translations['common.not_started'];
      this.translations.statusAtDrugstore = translations['common.at_drugstore'];
      this.translations.statusAtImagery = translations['common.at_imagery'];
      this.translations.statusAtLaboratory =
        translations['common.at_laboratory'];
      this.translations.statusPrimaryCare = translations['common.primary_care'];
      this.translations.statusSpecialistCare =
        translations['common.specialist_care'];
      this.translations.statusInpatientServices =
        translations['common.inpatient_services'];
      this.translations.statusCurrentServiceOver =
        translations['common.current_service_over'];
    });
  }
}
