import { Component, Input, OnInit } from '@angular/core';

type BoxParams = {
  withIcon: boolean;
  iconPath: string;
  withTitle: boolean;
  title: string;
  titleClasses: string;
  withWave?: boolean;
  dataInRight?: string;
};

type BoxParamsShapeTwo = {
  icon: string;
  title: string;
  value: string | number;
  titleClasses?: string;
};

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
  @Input() boxClasses: string = '';
  @Input() params: BoxParams = {
    withIcon: false,
    iconPath: '',
    withTitle: false,
    title: '',
    titleClasses: '',
    withWave: false,
  };
  @Input() paramsShapeTwo: BoxParamsShapeTwo = {
    icon: '',
    title: '',
    value: '',
    titleClasses: 'bg-kimbo-blue text-kimbo-blue',
  };
  @Input() variant: string = 'none';
  @Input() boxImageClasses: string = 'w-5';
  @Input() info!: string;
  @Input() shape = '_ONE';

  constructor() {}

  ngOnInit(): void {}
}
