<h2 class="text-navy" mat-dialog-title>
  <span>{{ 'forms.add-recommendation-heading' | translate }}</span>
</h2>
<button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <form class="flex flex-wrap items-start mt-8">
    <app-search-dropdown
      class="w-full md:w-1/2-with-gap"
      (triggerEvent)="handleSearchHpEvent($event)"
      [searchPlaceholder]="
        'admin-fields.health-package-placeholder' | translate
      "
      [searchLabel]="'admin-fields.health-package-label' | translate"
      [options]="healthPackageOptions"
      [isFinding]="isFindingHp"
      [key]="'_HP'"
      caller="_OTHER"></app-search-dropdown>
    <app-search-dropdown
      class="mt-6 w-full md:w-1/2-with-gap md:ml-4 md:mt-0"
      (triggerEvent)="handleSearchHcpEvent($event)"
      [searchPlaceholder]="
        'admin-fields.transaction-healthcare-provider-label' | translate
      "
      [searchLabel]="'admin-fields.healthcare-provider-label' | translate"
      [options]="hcpOptions"
      [isFinding]="isFindingHcp"
      [pickValue]="hcpDefaultOption"
      [key]="'_HCP'"
      [caller]="'_ADMIN_HCP'"></app-search-dropdown>
    <div *ngIf="healthPackage" class="w-full mt-6">
      <label for="amount-range" class="block mb-2 text-navy">{{
        'forms.amount' | translate
      }}</label>
      <div class="flex justify-between w-full py-2 items-end">
        <div class="flex justify-start gap-2 items-center">
          <input
            class="w-[150px]
        {{ input_cls.common }}"
            [value]="pickAmount"
            [min]="minRecommendedAmount"
            [max]="getMaxRecommendAmountForCurrency()"
            type="number"
            (input)="handleCustomAmount($event)" />
          <span>{{ currency.symbol }} </span>
        </div>
        <span
          >{{ getMaxRecommendAmountForCurrency() }} {{ currency.symbol }}</span
        >
      </div>
      <input
        id="amount-range"
        type="range"
        [min]="minRecommendedAmount"
        [max]="getMaxRecommendAmountForCurrency()"
        [value]="pickAmount"
        step="1"
        class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        name="pickAmount"
        (input)="handleRangeEvent($event)" />
    </div>
    <div *ngIf="error" class="text-sm text-red block w-full mt-1">
      {{ error }}
    </div>
    <app-form-field
      [label]="'hcp-common.note-label' | translate"
      class="w-full mt-6">
      <textarea
        class="w-full rounded-lg px-4 bg-light-gray placeholder-dark-gray focus:outline-none focus:shadow-inner-blue resize-none py-4"
        [(ngModel)]="note"
        id="textNote"
        [placeholder]="'forms.recommandation-text-placeholder' | translate"
        name="note"></textarea>
    </app-form-field>
    <div
      class="w-full flex-col sm:items-end flex justify-center border-t-1 flex-shrink-0 border-gray mt-6 h-24">
      <div
        class="flex items-center justify-between w-full mt-6 py-4 md:py-0 flex-col xs:flex-row xs:justify-between gap-2">
        <button
          class="w-full sm:w-fit h-12"
          mat-stroked-button
          color="primary"
          [disabled]="isLoading"
          type="button"
          (click)="close('_CLOSE')">
          {{ 'forms.cancel-button' | translate }}
        </button>
        <button
          class="w-full sm:w-fit h-12"
          mat-raised-button
          color="primary"
          [disabled]="!cansubmit()"
          (click)="handleSubmit()"
          type="button">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block h-6 w-6 filter-white"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
