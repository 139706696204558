import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IAutoCompleteSearchResponse,
  IHcpFilterResponse,
  ILocationSearchModel,
} from '../models/search';

@Injectable({ providedIn: 'root' })
export class SearchService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Filter HCP
   */
  public filter_hcp(
    data: ILocationSearchModel
  ): Observable<IHcpFilterResponse> {
    return this.http.post<IHcpFilterResponse>(
      `${this.basePath}/v1/search`,
      {
        data: data,
      },
      this.httpOptions
    );
  }

  /**
   * Autocomplete search
   */
  public autocomplete_search(
    search_value: string
  ): Observable<IAutoCompleteSearchResponse> {
    return this.http.post<IAutoCompleteSearchResponse>(
      `${this.basePath}/v1/search/autocomplete`,
      {
        search: search_value,
      },
      this.httpOptions
    );
  }

  /**
   * mark iteress on location search
   */
  public markInteressOnLocation(interess: string): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/search/mark_interest`,
      {
        text: interess,
      },
      this.httpOptions
    );
  }
}
