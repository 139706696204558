import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecommendationStatus } from 'src/app/core/enums/enums';
import { Recommendation } from 'src/app/core/models/Buyedpackage';
import { Currency } from 'src/app/core/models/Common';
import { IHealthcareProvider } from 'src/app/core/models/HealthcareProvider';
import { IHealthcarepackage } from 'src/app/core/models/Healthcarepackage';
import {
  HealthPackageService,
  HealthProviderService,
  RecommendationService,
} from 'src/app/core/rest-services/rest-services';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { hasOwner } from 'src/app/core/utils/core';
import { toMoney } from 'src/app/core/utils/misc';
import { MaterialConfirmDialogComponent } from '../material-confirm-dialog/material-confirm-dialog.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recommendated-module',
  templateUrl: './recommendated-module.component.html',
  styleUrls: ['./recommendated-module.component.scss'],
})
export class RecommendatedModuleComponent implements OnInit {
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() recommendation!: Recommendation;
  @Input() variant = 'backdrop';
  @Input() caller: '_KIMBO' | '_HCP' = '_HCP';
  currency!: Currency;
  infoIsOpen = false;
  healthcarepackage?: IHealthcarepackage;
  healthcareProvider?: IHealthcareProvider;
  isLoading?: boolean = false;
  recommendationStatus = RecommendationStatus;
  dropdownVisibility = false;
  isOwner = false;
  currentLanguage = 'en';
  $languageChange!: Subscription;

  constructor(
    private sessionService: SessionService,
    private healthPackageService: HealthPackageService,
    private healthProviderervice: HealthProviderService,
    private services: ServicesService,
    private recommendationService: RecommendationService,
    private translateService: TranslateService
  ) {
    this.currency = this.sessionService.getCurrencie();
    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.listenForLanguageChangeEvents();
    const role = this.sessionService.getCurrentRole();
    this.isOwner = hasOwner(role);
    this.getHcModule();
  }

  getHcModule() {
    this.isLoading = true;
    this.healthPackageService
      .view(this.recommendation.healthcarepackage_id)
      .subscribe({
        next: (res: IHealthcarepackage) => {
          this.healthcarepackage = res;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  toggleDropdownVisibility(): void {
    this.dropdownVisibility = !this.dropdownVisibility;
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency ? this.currency.rate : 0);
  }

  handleDelete(): void {
    this.isLoading = true;
    this.recommendationService
      .deleteRecommendation(this.recommendation.id)
      .subscribe({
        next: (res: any) => {
          this.services.translateService.instant(
            'common.recommendation-deleted'
          );
          this.services.eventService.publish('recommendation:refresh');
        },
        error: err => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  delete(): void {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.handleDelete();
        }
      });
  }

  accept(): void {
    this.triggerEvent.emit(this.healthcarepackage);
  }

  reject(): void {
    this.triggerEvent.emit('_REJECT');
  }

  getCurrencyCode(code: string) {
    return this.services.sessionService.getCurrencyCode(code);
  }

  listenForLanguageChangeEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.currentLanguage = this.translateService.currentLang;
      }
    );
  }
}
