import { makeCurrencytoShow } from 'src/app/core/utils/functions';
import { hasOwner } from 'src/app/core/utils/core';
import { BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GroupType } from 'src/app/core/enums/enums';
import { Currency } from 'src/app/core/models/Common';
import { Wallet } from 'src/app/core/models/Group';
import { IRole, IUser } from 'src/app/core/models/User';
import {
  UseCaseGroupService,
  UseCaseHcpService,
} from 'src/app/core/rest-services/rest-services';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { RoleService } from 'src/app/core/services/role.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { toHealthCredit, toMoney } from 'src/app/core/utils/misc';
import { TopUpComponent } from 'src/app/shared-components/top-up/top-up.component';
import { TransactionComponent } from '../transaction/transaction.component';

@Component({
  selector: 'app-health-credits-panel',
  templateUrl: './health-credits-panel.component.html',
  styleUrls: ['./health-credits-panel.component.scss'],
})
export class HealthCreditsPanelComponent implements OnInit, OnDestroy {
  @Input() className = '';
  @Input() distant = false;
  @Input() variant: '_MENU' | '_HEADER' | '_MENU_SHAPE_2' = '_HEADER';
  @Input() caller: '_OTHER' | '_CORPORATE' = '_OTHER';
  currentRoles!: GroupType;
  isTransitioning = false;
  _wallet!: Wallet;
  currency!: Currency;
  hcpId!: number;
  $rolechange!: Subscription;
  $usedCredits!: Subscription;
  $userUpdate!: Subscription;
  $updateCurrency!: Subscription;
  isMatchedBreakPoint?: boolean;
  groupType = GroupType;
  isOwner = false;
  $owner!: Subscription;

  constructor(
    private roleService: RoleService,
    private translateService: TranslateService,
    private usecaseGroupService: UseCaseGroupService,
    private sessionService: SessionService,
    private useCaseHcpService: UseCaseHcpService,
    private services: ServicesService,
    public breakpointMatcherService: BreakpointMatcherService,
    public navigationService: CustomNavigationService
  ) {}

  ngOnInit(): void {
    this.currency = this.sessionService.getCurrencie();
    this.$updateCurrency = this.services.eventService.subscribe(
      'update:currency',
      (currency: Currency) => {
        this.currency = currency;
      }
    );

    const role = this.sessionService.getCurrentRole();
    this.isOwner = hasOwner(role);

    this.$owner = this.services.eventService.subscribe(
      'owner:has',
      (role: IRole) => {
        this.isOwner = hasOwner(role);
      }
    );
    this.currentRoles = this.roleService.getCurrentTypeRole();
    this.hcpId = this.roleService.getCurrentHcpId()!;
    if (this.hcpId) {
      if (this.isOwner) this.fetchHcpWallet();
    } else {
      if (role.group.type == GroupType.Kimbo) {
        this.isOwner = true;
      }
      this.fetchKimboWallet();
    }
    this.$rolechange = this.services.eventService.subscribe(
      'change:role',
      () => {
        this.hcpId = this.roleService.getCurrentHcpId()!;
        this.currency = this.sessionService.getCurrencie();
        if (this.hcpId) {
          if (this.isOwner) this.fetchHcpWallet();
        } else {
          this.fetchKimboWallet();
        }
        this.currentRoles = this.roleService.getCurrentTypeRole();
      }
    );
    this.$usedCredits = this.services.eventService.subscribe(
      'healthcredit:used',
      () => {
        if (this.hcpId) {
          if (this.isOwner) this.fetchHcpWallet();
        } else {
          setTimeout(() => {
            this.fetchKimboWallet();
          }, 3000);
        }
      }
    );
    this.$userUpdate = this.services.eventService.subscribe(
      'user:update',
      () => {
        this.currency = this.sessionService.getCurrencie();
      }
    );
    this.breakpointMatcherService
      .initObserver('md')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatchedBreakPoint = true;
        } else {
          this.isMatchedBreakPoint = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.$rolechange) this.$rolechange.unsubscribe();
    if (this.$usedCredits) this.$usedCredits.unsubscribe();
    if (this.$userUpdate) this.$userUpdate.unsubscribe();
    if (this.$updateCurrency) this.$updateCurrency.unsubscribe();
    if (this.$owner) this.$owner.unsubscribe();
  }

  fetchHcpWallet(): void {
    this.isTransitioning = true;
    this.useCaseHcpService.wallet(this.hcpId).subscribe({
      next: (res: Wallet) => {
        this._wallet = res;
      },
      error: () => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  fetchKimboWallet(): void {
    this.isTransitioning = true;
    const groupId = this.sessionService.getCurrentGroupId();
    const role = this.sessionService.getCurrentRole();
    if (role.group.type !== GroupType.Admin) {
      this.usecaseGroupService.wallet(groupId).subscribe({
        next: (res: Wallet) => {
          this._wallet = res;
        },
        complete: () => {
          this.isTransitioning = false;
        },
      });
    }
  }

  openWalletModal(): void {
    this.services.modalService.openModal(TransactionComponent, {
      width: '1000px',
      height: 'auto',
      data: {
        caller:
          this.currentRoles === this.groupType.Kimbo ||
          this.currentRoles === this.groupType.Benefit
            ? '_KIMBO'
            : this.currentRoles === this.groupType.Hcp
            ? '_HCP'
            : '_CORPORATE',
      },
    });
  }

  openTopUpModal(): void {
    this.services.modalService.openModal(TopUpComponent, {
      width: '900px',
      height: 'auto',
      data: {
        currencies: this.currency,
      },
    });
  }

  _toHealthCredit(amount: number, rate: number): number {
    return toHealthCredit(amount, rate);
  }

  _toMoney(amount: number, rate: number): number {
    return toMoney(amount, rate);
  }

  convertXafXof(currency: string) {
    return makeCurrencytoShow(currency);
  }
}
