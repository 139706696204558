import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-custom',
  templateUrl: './button-custom.component.html',
  styleUrls: ['./button-custom.component.scss'],
})
export class ButtonCustomComponent implements OnInit {
  @Input() text: string = '';
  @Input() shape: string = '1';
  @Input() disable: boolean = false;
  @Input() addClassNames: string = 'rounded-full';
  @Input() modalId: string = '';
  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {}
}
