import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRoleAdmin, StoreAminRole } from '../../models/AdminRole';
import { Content } from '../../models/models';

@Injectable()
export class RestAdminRoleService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param roleId
   */
  public delete(roleId: number): Observable<IRoleAdmin> {
    return this.http.delete<IRoleAdmin>(
      `${this.basePath}/v1/resources/adminrole/${roleId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: StoreAminRole): Observable<IRoleAdmin> {
    return this.http.post<IRoleAdmin>(
      `${this.basePath}/v1/resources/adminrole`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param roleId
   */
  public update(roleId: number, body?: StoreAminRole): Observable<IRoleAdmin> {
    return this.http.put<IRoleAdmin>(
      `${this.basePath}/v1/resources/adminrole/${roleId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Roles
   * @param groupId
   */
  public index(
    page?: number,
    search?: string
  ): Observable<Content<IRoleAdmin>> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(page != null && { page: page }),
        ...(search != null && { search: search }),
      },
    });
    return this.http.get<Content<IRoleAdmin>>(
      `${this.basePath}/v1/resources/adminrole`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param roleId
   */
  public view(roleId: number): Observable<IRoleAdmin> {
    return this.http.get<IRoleAdmin>(
      `${this.basePath}/v1/resources/adminrole/${roleId}`,
      this.httpOptions
    );
  }
}
