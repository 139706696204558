<div *ngIf="caller !== '_KIMBO'">
  <div
    *ngIf="_buyedPackage && _buyedPackage.files.length === 0 && !isLoading"
    class="relative w-full h-full mt-4 no-campaign-bg">
    <div class="flex flex-col items-center justify-center w-full">
      <!--img
        appImg
        class="grayscale"
        src="assets/images/new-document.png"
        alt="new-document" />
      <p-- class="my-6 text-lg font-bold text-gray">
        {{ 'hcp-common.no-documents' | translate }}
      </p-->
      <app-no-content
        imgPath="assets/gif/documents-animate.svg"
        [message]="'hcp-common.no-documents' | translate"
        textWidth="text-base"
        iconWidth="w-52"
        customHeight="h-fit"
        class="block w-full sm:w-9/12 my-4 mx-auto" />
    </div>
  </div>
  <div
    *ngFor="
      let f of caller === '_CHECKOUT' ? filterFiles() : _buyedPackage.files
    ">
    <div
      *ngIf="!_buyedPackage.is_on_the_fly || f.type !== 'final_invoice'"
      class="p-4 mb-6 rounded-2xl bg-light-gray md:p-8 md:rounded-4xl">
      <div
        class="flex flex-col items-start justify-between lg:flex-row lg:items-center">
        <div class="flex items-center justify-between w-full">
          <div class="flex items-center justify-start">
            <img
              appImg
              src="assets/images/document.png"
              class="w-8 h-8"
              alt="document" />
            <div
              class="flex flex-col items-end justify-center ml-4 font-bold text-white">
              <p class="flex items-center h-full text-lg font-bold text-navy">
                {{ f.data ? f.data.name : '__' }}
              </p>
              <p class="text-[10px] text-navy">
                {{ _toLocaleDateTime(f.created_at) }}
              </p>
            </div>
          </div>
          <p *ngIf="caller !== '_CHECKOUT'" class="hidden sm:block">
            {{ 'hcp-common.' + f.type | translate }}
          </p>
          <p
            *ngIf="f.type === 'intermediate_bill' || f.type === 'final_invoice'"
            class="hidden font-bold sm:block">
            {{
              f.data.amount
                ? _toMoney(f.data.amount)
                : f.data.amount_client / 100
            }}
            {{ currency.symbol }}
          </p>
          <div class="flex w-fit">
            <app-square-button
              *ngIf="canEdit && isOwner"
              type="button"
              size="normal"
              theme="blue"
              class="flex items-center ml-2"
              (click)="emitAction('_DELETE', f)">
              <div
                class="w-6 h-6 stroke-current text-red"
                aria-label="pencil icon"
                [inlineSVG]="'assets/svg/trash.svg'"></div>
            </app-square-button>
            <a
              [href]="services.utilsService.getServerUrl(f.path)"
              target="_blank"
              download>
              <app-square-button
                type="button"
                size="normal"
                theme="blue"
                class="flex items-center ml-2 rotate-180">
                <div
                  class="w-6 h-6 stroke-current text-blue"
                  aria-label="Upload icon"
                  [inlineSVG]="'assets/svg/upload.svg'"></div>
              </app-square-button>
            </a>
          </div>
        </div>
        <div
          class="flex items-center justify-between w-full pl-1 mt-2 sm:hidden">
          <p *ngIf="caller !== '_CHECKOUT'">
            {{ 'hcp-common.' + f.type | translate }}
          </p>
          <p
            *ngIf="f.type === 'intermediate_bill' || f.type === 'final_invoice'"
            class="px-3 py-1 font-bold rounded-full bg-blue w-fit bg-opacity-5 text-blue">
            {{ f.data.amount_client / 100 }} {{ currency.symbol }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="canUpload && canEdit" class="flex justify-center w-full mt-8">
    <app-file-input
      caller="_HCP"
      accept="image/jpg, image/jpeg, image/png, application/pdf"
      name="image"
      (changeEvent)="setFieldValue($event)"
      theme="blue-outline"
      >{{ 'common.document-upload-button' | translate }}</app-file-input
    >
  </div>
  <div
    *ngIf="
      _buyedPackage.is_on_the_fly &&
      _buyedPackage.status === buyedPackageStatus.Finished
    "
    class="flex justify-end w-full mt-8">
    <button
      mat-stroked-button
      color="primary"
      [disabled]="isPdfGenerating"
      class="h-12"
      (click)="downloadInvoice()">
      <span *ngIf="!isPdfGenerating">{{
        'hcp-common.generate-final-invoice' | translate
      }}</span>
      <app-spinner
        *ngIf="isPdfGenerating"
        class="inline-block h-6 w-6 filter-blue"></app-spinner>
    </button>
  </div>
</div>
<div *ngIf="caller === '_KIMBO'">
  <div
    *ngIf="!_buyedPackage.is_on_the_fly"
    class="p-4 rounded-2xl bg-light-gray md:p-8 md:rounded-4xl">
    <div
      class="flex flex-col items-start justify-between lg:flex-row lg:items-center">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center justify-start">
          <img
            appImg
            src="assets/images/document.png"
            class="w-8 h-8"
            alt="document" />
          <div
            class="flex flex-col items-end justify-center ml-4 font-bold text-white">
            <p class="flex items-center h-full text-lg font-bold text-navy">
              {{
                _buyedPackage.final_invoice.data
                  ? _buyedPackage.final_invoice.data.name
                  : '__'
              }}
            </p>
            <p class="text-[10px] text-navy">
              {{ _toLocaleDateTime(_buyedPackage.final_invoice.created_at) }}
            </p>
          </div>
        </div>
        <p class="hidden font-bold sm:block">
          {{ _toMoney(_buyedPackage.final_invoice.data.amount) }}
          {{ currency.symbol }}
        </p>
        <div class="flex w-fit">
          <a
            [href]="
              services.utilsService.getServerUrl(
                _buyedPackage.final_invoice.path
              )
            "
            target="_blank"
            download>
            <app-square-button
              type="button"
              size="normal"
              theme="blue"
              class="flex items-center ml-2 rotate-180">
              <div
                class="w-6 h-6 stroke-current text-blue"
                aria-label="Upload icon"
                [inlineSVG]="'assets/svg/upload.svg'"></div>
            </app-square-button>
          </a>
        </div>
      </div>
      <div class="flex items-center justify-between w-full pl-1 mt-2 sm:hidden">
        <p
          class="px-3 py-1 font-bold rounded-full bg-blue w-fit bg-opacity-5 text-blue">
          {{ _toMoney(_buyedPackage.final_invoice.data.amount) }}
          {{ currency.symbol }}
        </p>
      </div>
    </div>
  </div>

  <div
    class="w-full flex justify-center items-center"
    *ngIf="_buyedPackage.is_on_the_fly">
    <button
      mat-stroked-button
      color="primary"
      [disabled]="isPdfGenerating"
      class="h-12"
      (click)="downloadInvoice()">
      <span *ngIf="!isPdfGenerating">{{
        'hcp-common.generate-final-invoice' | translate
      }}</span>
      <app-spinner
        *ngIf="isPdfGenerating"
        class="inline-block h-6 w-6 filter-blue"></app-spinner>
    </button>
  </div>
</div>
