import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IContact,
  ContactStore,
  Content,
  IBuyedpackage,
} from '../../models/models';

@Injectable()
export class ContactService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Récupère les contacts avec options de recherche et de filtrage par groupe.
   *
   * @param search Chaîne de recherche optionnelle
   * @param groupId ID du groupe optionnel
   * @returns Observable de la réponse JSON avec pagination
   */
  public index(
    page?: number,
    search?: string,
    groupId?: number
  ): Observable<Content<IContact>> {
    const queryParameters = new HttpParams({
      fromObject: {
        page: page ? page : 1,
        ...(search != null && { search: search }),
        ...(groupId != null && { group_id: groupId.toString() }),
      },
    });

    return this.http.get<Content<IContact>>(
      `${this.basePath}/v1/resources/contact`,
      { ...this.httpOptions, params: queryParameters }
    );
  }

  /**
   * Récupère les buyed packages d'un contact.
   * @param contactId ID du contact
   * @returns Observable de la réponse JSON avec les buyed packages
   */
  public getBuyedPackagesByContact(
    contactId: number
  ): Observable<IBuyedpackage[]> {
    return this.http.get<IBuyedpackage[]>(
      `${this.basePath}/v1/resources/contact/${contactId}/buyedpackages`,
      this.httpOptions
    );
  }

  /**
   * View
   * @param contactId the contact identifier
   * @param groupId the group identifier to which the contact belongs
   */
  public view(contactId: number, groupId: number): Observable<IContact> {
    return this.http.get<IContact>(
      `${this.basePath}/v1/resources/group/${groupId}/contact/${contactId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   * @param groupId the group identifier to which the contact belongs
   */
  public store(body?: ContactStore): Observable<IContact> {
    return this.http.post<IContact>(
      `${this.basePath}/v1/resources/contact`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param contactId the contact identifier
   * @param groupId the group identifier to which the contact belongs
   */
  public update(contactId: number, body?: any): Observable<IContact> {
    return this.http.put<IContact>(
      `${this.basePath}/v1/resources/contact/${contactId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param contactId the contact identifier
   * @param groupId the group identifier to which the contact belongs
   */
  public delete(contactId: number): Observable<IContact> {
    return this.http.delete<IContact>(
      `${this.basePath}/v1/resources/contact/${contactId}`,
      this.httpOptions
    );
  }
}
