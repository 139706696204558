import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAdminPermission } from '../../models/AdminRole';
import { IUser } from '../../models/models';

@Injectable()
export class UseCaseAdminRoleService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  /**
   * listAllAdminUser
   * List all admin user
   */
  public listAllAdminUser(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.basePath}/v1/user/list-admin`);
  }

  /**
   * listAllUser
   * List all user

   */
  public listAllAUser(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.basePath}/v1/user/list-users`);
  }

  /**
   * listAllPermission
   * List all admin permissions
   * @param groupId
   */
  public listAllPermission(search: string): Observable<IAdminPermission[]> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(search != null && search.trim().length > 0 && { search: search }),
      },
    });
    return this.http.get<IAdminPermission[]>(
      `${this.basePath}/v1/adminrole/list-permissions`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  /**
   * attachRoleToUser
   * @param userId the user id
   * @param roleId the admin role id
   */
  public attachRoleToUser(userId: number, roleId: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/adminrole/attach/${userId}/${roleId}`,
      {},
      this.httpOptions
    );
  }

  /**
   * attachRoleToUser
   * @param userId the user id
   * @param roleId the admin role id
   */
  public detachRoleToUser(userId: number, roleId: number): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/v1/adminrole/detach/${userId}/${roleId}`,
      this.httpOptions
    );
  }

  /**
   * addAdminUser
   * @param identifier the user identifier
   */
  public addAdminUser(body: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/user/store-admin`,
      body,
      this.httpOptions
    );
  }
}
