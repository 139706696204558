import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent {
  @Input() isSelected = false;
  isHighlighted = false;
  @Input() classNames = '';
  @Input() classNameForVariant = '';
  @Input() readonly: boolean = false;
  @Input({ required: true }) InCOption!: OptionObject;
  option!: OptionObject;
  @Output() selectEvent = new EventEmitter<OptionObject>();

  constructor() {}

  handleSelect(): void {
    this.option = this.InCOption;
    this.selectEvent.emit(this.option);
  }
}
