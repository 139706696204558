import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-referal-modal',
  templateUrl: './referal-modal.component.html',
  styleUrls: ['./referal-modal.component.scss'],
})
export class ReferalModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {},
    public dialogRef: MatDialogRef<ReferalModalComponent>
  ) {}

  ngOnInit(): void {}
}
