import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HcpDashboardService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  public homeDashboard(
    hcpId: number,
    period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'month',
    start_date?: string,
    end_date?: string
  ): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/healthcareprovider/${hcpId}/dashboard`,
      {
        period: period,
        ...(start_date && { start_date: start_date }),
        ...(end_date && { end_date: end_date }),
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
