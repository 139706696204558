import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Content,
  GroupeResponse,
  GroupStore,
  IGroup,
} from '../../models/models';

@Injectable()
export class GroupService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param groupId
   */
  public delete(groupId: number): Observable<IGroup> {
    return this.http.delete<IGroup>(
      `${this.basePath}/v1/resources/group/${groupId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: GroupStore): Observable<GroupeResponse> {
    return this.http.post<GroupeResponse>(
      `${this.basePath}/v1/resources/group`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param groupId
   */
  public update(groupId: number, body?: any): Observable<IGroup> {
    return this.http.put<IGroup>(
      `${this.basePath}/v1/resources/group/${groupId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Groups
   * @param page
   */
  public index(
    page: number,
    search?: string,
    user_id?: number
  ): Observable<Content<IGroup>> {
    let queryParameters!: HttpParams;
    if (user_id === undefined || user_id === null) {
      queryParameters = new HttpParams({
        fromObject: {
          page: page,
          ...(search != null && { search: search }),
        },
      });
    } else {
      queryParameters = new HttpParams({
        fromObject: {
          page: page,
          ...(search != null && { search: search, user_id: user_id }),
        },
      });
    }

    return this.http.get<Content<IGroup>>(
      `${this.basePath}/v1/resources/group`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param groupId
   */
  public view(groupId: number): Observable<IGroup> {
    return this.http.get<IGroup>(
      `${this.basePath}/v1/resources/group/${groupId}`,
      this.httpOptions
    );
  }
}
