import { IUser } from './models';

export interface ILogin {
  identifiant?: string;
  code?: string;
  user_id?: number;
  date: string;
  country?: string;
  currencie_id?: number;
  language_id?: number;
  totp_code?: string;
  mode?: string; // param for user invitation in group
  key?: string; // param for user invitation in group
}

export interface ILoginResponse {
  status_code: number;
  access_token: string;
  user_id: number;
  user: IUser;
  token_type: string;
  message?: string;
  language_id?: number;
}

export class Login implements ILogin {
  constructor(
    public date: string,
    public identifiant?: string,
    public code?: string,
    public user_id?: number,
    public country?: string,
    public currencie_id?: number,
    public language_id?: number,
    public mode?: string, // param for user invitation in group
    public key?: string, // param for user invitation in group
    public totp_code?: string
  ) {}
}

export class IGoogleLogin {
  googleobject: IGoogleCredential;
  token: string;
  user_id?: number;
  country?: null;

  constructor(
    googleobject: IGoogleCredential,
    token: string,
    user_id?: number,
    country?: null
  ) {
    this.googleobject = googleobject;
    this.user_id = user_id;
    this.token = token;
    this.country = country;
  }
}

export interface IGoogleCredential {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  email: string;
  email_verified: boolean;
  nbf: number;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  iat: number;
  exp: number;
  jti: string;
}
