import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICountryModel } from '../../models/Country';

@Injectable()
export class UseCaseCountryService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public listCountries(): Observable<ICountryModel[]> {
    return this.http.get<ICountryModel[]>(
      `${this.basePath}/v1/adminrole/list-countries`
    );
  }
}
