import { RestAuthentificationService } from '../../../../core/rest-services/usecases/rest-authentication.service';
import { Currency, Data } from '../../../../core/models/Common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GroupType, Permission } from 'src/app/core/enums/enums';
import { GroupStore, IGroup, Wallet } from 'src/app/core/models/Group';
import { ImageProfil } from 'src/app/core/models/ImageProfil';
import { GroupeResponse, IRole, IUser } from 'src/app/core/models/User';
import {
  FileService,
  GroupService,
  RestRoleService,
  UseCaseGroupService,
  UseCaseRoleService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import {
  DEFAULT_IMAGE,
  DEFAULT_IMAGE_BENEFIT,
  DEFAULT_IMAGE_CORPORATE,
  DEFAULT_IMAGE_HCP,
  DEFAULT_IMAGE_KIMBO,
  INPUT_CLASSES,
  MAX_FILE_SIZE,
} from 'src/app/core/utils/constant';
import { isValidEmail } from 'src/app/core/utils/core';
import { getCountries } from 'src/app/core/utils/country';
import { Country, OptionObject, Phone } from 'src/app/core/utils/type';
import {
  noop,
  shortenFileName,
  toHealthCredit,
  toMoney,
} from 'src/app/core/utils/misc';
import { FileResponse } from 'src/app/core/models/File';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';
import { PatientDetailsModalComponent } from 'src/app/pages/hcp/components/modals/patient-details-modal/patient-details-modal.component';

type Member = {
  roleId?: number;
  groupId?: number;
  user_id?: number;
  name?: string;
  tel?: string;
  email?: string;
  image?: ImageProfil;
  permissions?: string[];
  active?: boolean;
};

@Component({
  selector: 'app-create-edit-roles',
  templateUrl: './create-edit-roles.component.html',
  styleUrls: ['./create-edit-roles.component.scss'],
})
export class CreateEditRolesComponent implements OnInit {
  input_cls = INPUT_CLASSES;
  disabled = false;
  form!: FormGroup;
  formCreateRole!: FormGroup;
  showInfo = false;
  isIndividual = true;
  isCompany = false;
  isBenefit = false;
  isHcp = false;
  isLoading = false;
  groupStore!: GroupStore;
  user!: IUser;
  currentRole!: IRole;
  role!: IRole;
  formatedMemberList: Member[] = [];
  invitationsMember: any[] = [];
  _role_selected!: IRole;
  isOwer = false;
  p_ower = Permission.Owner;
  p_member = Permission.Member;
  _groupRole: IRole[] = [];
  isCreatingMember = false;
  isEditingMember = false;
  phoneModel?: Phone;
  memberToUpdate!: Member;
  allRolesIsVisible = false;
  isGettingMember = true;
  isCreateEditModalOpen = false;
  addMemberWithPhone = true;
  memberEmail = '';
  imageName?: string;
  displayableFileName?: string;
  file?: File;
  country = '';
  wallet!: Wallet;
  isFetchingWallet = false;
  currency!: Currency;
  groupeType = GroupType;
  optionsCountryDropdown: OptionObject[] = [];
  selectedCountry!: OptionObject;
  groupUserCurrency!: Currency;
  isFetchingGroupCurrency = false;
  DEFAULT_IMAGE = DEFAULT_IMAGE;
  buyedpackages: IBuyedpackage[] = [];
  language = '';
  domainName!: string;
  amountPeEmployee!: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: '_CREATE_GROUP' | '_EDIT_GROUP';
      state: any;
      caller: '_ADMIN' | '_OTHER';
      target: '_TRANSACTION' | '_OTHER';
      role?: IRole;
    },
    public dialogRef: MatDialogRef<CreateEditRolesComponent>,
    private fb: FormBuilder,
    public authService: RestAuthentificationService,
    private groupService: GroupService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private userService: UserService,
    private services: ServicesService,
    private roleService: RestRoleService,
    private useCaseRoleService: UseCaseRoleService,
    private fileService: FileService,
    private useCaseGroupService: UseCaseGroupService
  ) {
    this.currency = this.services.sessionService.getCurrencie();
    this.country = this.sessionService.getCountry().country;
    this.language = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    this.currentRole = this.sessionService.getCurrentRole();
    this.form = this.fb.group({
      name: [
        this.data.caller === '_ADMIN' ? this.data.state.label : '',
        Validators.required,
      ],
    });

    this.formCreateRole = this.fb.group({
      permission: ['member', Validators.required],
    });
    if (this.data.action === '_EDIT_GROUP')
      this.getRole(
        this.data.caller === '_ADMIN'
          ? this.data.role?.id!
          : Number(this.data.state.value)
      );

    if (this.data.caller === '_ADMIN' && this.data.action === '_EDIT_GROUP') {
      this.getPatients();
    }
  }

  getPatients() {
    const group_id = this.data.role!.group_id;
    this.isLoading = true;
    this.useCaseGroupService.list_buyedpackage(group_id).subscribe({
      next: (res: IBuyedpackage[]) => {
        this.buyedpackages = res;
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  toLocaleDateTime(date: string): string {
    return toWordLocaleDateTime(new Date(date), this.language);
  }

  getRole(id: number) {
    this.isLoading = true;
    this.roleService.view(id).subscribe({
      next: (res: IRole) => {
        this.role = res;
        this.form.patchValue({
          name: this.data.state.label,
        });
        if (this.role.group.type === this.groupeType.Benefit) {
          this.amountPeEmployee = this.role?.group?.benefit_amount_to_credit
            ? this._toMoney(this.role?.group?.benefit_amount_to_credit)
            : 0;
          this.domainName = this.role?.group?.benefit_domain
            ? this.role?.group?.benefit_domain
            : '';
        }
        this._role_selected =
          this.data.caller === '_ADMIN'
            ? res
            : this.user.roles!.find(
                role => role.id == Number(this.data.state.value)
              )!;
        this.isFetchingGroupCurrency = true;
        this.userService.view(this.role.user_id).subscribe({
          next: (userRes: IUser) => {
            this.groupUserCurrency =
              this.role.group.type === this.groupeType.Hcp
                ? this.data.state.currency!
                : userRes.currencie;
          },
          error: (err: any) => {
            this.isFetchingGroupCurrency = false;
          },
          complete: () => {
            this.isFetchingGroupCurrency = false;
          },
        });

        if (this._role_selected?.group.type === GroupType.Kimbo)
          this.isIndividual = true;
        else this.isIndividual = false;

        if (this._role_selected?.group.type === GroupType.Companie)
          this.isCompany = true;
        else this.isCompany = false;

        if (this._role_selected?.group.type === GroupType.Benefit)
          this.isBenefit = true;
        else this.isBenefit = false;

        this.isOwer = this._role_selected.permissions.includes(
          Permission.Owner
        );
        if (
          (this.isOwer && this.data.caller !== '_ADMIN') ||
          this.data.target === '_TRANSACTION'
        )
          this.getRoles(this._role_selected.group_id);

        if (this.data.target === '_TRANSACTION') this.fetchWallet();
      },
      error: err => {
        this.notificationService.danger(
          this.translateService.instant(err.translate)
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  fetchWallet(): void {
    this.isFetchingWallet = true;
    this.useCaseGroupService.wallet(this.role.group_id).subscribe({
      next: (res: Wallet) => {
        this.wallet = res;
      },
      complete: () => {
        this.isFetchingWallet = false;
      },
    });
  }

  save(): void {
    this.groupStore = this.initExpectedGroup(this.form.value);
    this.isLoading = true;
    if (this.data.action === '_CREATE_GROUP') {
      this.groupService.store(this.groupStore).subscribe({
        next: (res: GroupeResponse) => {
          if (this.file != undefined && this.file != null) {
            this.setFile(res, res.group.image_profil!.id);
          } else {
            if (this.data.caller !== '_ADMIN')
              this.getUser(this.user.id, undefined, undefined, res);
            else this.services.eventService.publish('admin:group:create', res);
            this.notificationService.success(
              this.translateService.instant('forms.success-created-group', {
                name: this.groupStore.name,
              })
            );
            this.isLoading = false;
            this.closeModal();
          }
        },
        error: error => {
          this.notificationService.danger(
            this.translateService.instant('errors.generic')
          );
          this.isLoading = false;
        },
      });
    } else {
      const _role_selected =
        this.data.caller === '_ADMIN'
          ? this.role!
          : this.user.roles!.find(
              role => role.id == Number(this.data.state.value)
            )!;
      this.groupService
        .update(_role_selected!.group_id, this.groupStore)
        .subscribe({
          next: (res: IGroup) => {
            if (this.file != undefined && this.file != null) {
              this.setFile(res, res.image_profil!.id);
            } else {
              if (this.data.caller !== '_ADMIN')
                this.getUser(
                  this.user.id,
                  undefined,
                  undefined,
                  undefined,
                  res
                );
              else
                this.services.eventService.publish('admin:group:update', res);
              this.notificationService.success(
                this.translateService.instant('forms.success-update-group', {
                  name: this.groupStore.name,
                })
              );
              this.isLoading = false;
              this.closeModal();
            }
          },
          error: () => {
            this.notificationService.danger(
              this.translateService.instant('errors.generic')
            );
            this.isLoading = false;
          },
        });
    }
  }

  handleBuyedPackageEvent(item: IBuyedpackage): void {
    this.services.modalService.openModal(PatientDetailsModalComponent, {
      width: '950px',
      height: 'auto',
      data: {
        state: item,
        caller: '_FINISHED',
      },
    });
  }

  public location(): void {
    this.authService.getlocation().subscribe({
      next: (res: any) => {
        this.country = res.country;
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  setFile(groupeResponse: any, imageId: number): void {
    this.fileService.update(imageId, this.file).subscribe({
      next: (fileRes: FileResponse) => {
        if (this.data.caller !== '_ADMIN') {
          if (this.data.action === '_CREATE_GROUP')
            this.getUser(this.user.id, undefined, undefined, groupeResponse);
          else
            this.getUser(
              this.user.id,
              undefined,
              undefined,
              undefined,
              groupeResponse
            );
        } else {
          if (this.data.action === '_CREATE_GROUP')
            this.services.eventService.publish(
              'admin:group:create',
              groupeResponse
            );
          else
            this.services.eventService.publish(
              'admin:group:update',
              groupeResponse
            );
        }
        if (this.data.action === '_CREATE_GROUP') {
          this.notificationService.success(
            this.translateService.instant('forms.success-created-group', {
              name: this.groupStore.name,
            })
          );
        } else {
          this.notificationService.success(
            this.translateService.instant('forms.success-update-group', {
              name: this.groupStore.name,
            })
          );
        }

        this.isLoading = false;
        this.closeModal();
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.closeModal();
      },
    });
  }

  _isValidEmail(email: string): boolean {
    return isValidEmail(email);
  }

  initExpectedGroup(formValue: any): GroupStore {
    const data: any =
      this.data.caller === '_OTHER'
        ? { user_id: this.sessionService.getUser().id }
        : {};
    let groupStore: GroupStore = new GroupStore(
      '',
      '',
      data,
      this.data.caller !== '_ADMIN'
    );
    if (this.isIndividual) {
      groupStore = new GroupStore(
        formValue.name,
        GroupType.Kimbo,
        data,
        this.data.caller !== '_ADMIN'
      );
    } else if (this.isCompany) {
      groupStore = new GroupStore(
        formValue.name,
        GroupType.Companie,
        data,
        this.data.caller !== '_ADMIN'
      );
    } else if (this.isBenefit) {
      groupStore = new GroupStore(
        formValue.name,
        GroupType.Benefit,
        data,
        this.data.caller !== '_ADMIN',
        this._toHealthCredit(this.amountPeEmployee),
        this.domainName
      );
    } else if (this.isHcp || this._role_selected.group.type === GroupType.Hcp) {
      groupStore = new GroupStore(
        formValue.name,
        GroupType.Hcp,
        data,
        this.data.caller !== '_ADMIN'
      );
    }
    return groupStore;
  }

  getUser(
    userId: number,
    member?: Member,
    isLast?: boolean,
    groupeResponse?: GroupeResponse,
    group?: IGroup
  ): void {
    this.isLoading = true;
    this.userService.view(userId).subscribe({
      next: (userRes: IUser) => {
        if (member) {
          member.name =
            userRes.data && (userRes.data.firstName || userRes.data.lastName)
              ? userRes.data.firstName + ' ' + userRes.data.lastName
              : '__';
          member.tel = userRes.tel ? userRes.tel : '__';
          member.email = userRes.email ? userRes.email : '__';
          member.image = userRes.image_profil;
          member.active = userRes.public_properties.active;
          this.formatedMemberList.push(member);
          if (isLast) this.isGettingMember = false;
        } else {
          this.sessionService.deleteUser();
          this.sessionService.storeUser(userRes);
          if (groupeResponse && groupeResponse.role) {
            this.services.eventService.publish(
              'group:create',
              groupeResponse.role
            );
          }
          if (group) {
            this.services.eventService.publish('group:update', group);
          }
        }
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  getRoles(group_id: number): void {
    this.isLoading = true;
    this.isGettingMember = true;
    this.roleService.index(group_id).subscribe({
      next: (userRes: any) => {
        this._groupRole = userRes.data;
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  countryListToOptionObject(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
  }

  setMemberEmail(event: any): void {
    this.memberEmail = event?.target.value;
  }

  toggleAggingMethod(): void {
    this.addMemberWithPhone = !this.addMemberWithPhone;
  }

  toggleSelection(selection: string): void {
    if (selection === '_INDV') {
      this.isCompany = false;
      this.isHcp = false;
      this.isBenefit = false;
      if (!this.isIndividual) this.isIndividual = true;
    } else if (selection === '_COMP') {
      this.isIndividual = false;
      this.isHcp = false;
      this.isBenefit = false;
      if (!this.isCompany) this.isCompany = true;
    } else if (selection === '_BENEF') {
      this.isIndividual = false;
      this.isHcp = false;
      this.isCompany = false;
      if (!this.isBenefit) this.isBenefit = true;
    } else if (selection === '_HCP') {
      this.isCompany = false;
      this.isIndividual = false;
      this.isBenefit = false;
      if (!this.isHcp) this.isHcp = true;
    }
  }

  toggleVisibility(): void {
    this.showInfo = !this.showInfo;
  }

  toggleAllRoleVisible(): void {
    this.allRolesIsVisible = !this.allRolesIsVisible;
  }

  closeEditRoleModal(): void {
    this.isCreatingMember = false;
    this.isEditingMember = false;
    this.isCreateEditModalOpen = false;
  }

  toggleCreateOverlayVisibility(data?: Member): void {
    if (data) {
      this.isEditingMember = true;
      this.formCreateRole.patchValue({
        permission:
          data && data.permissions?.includes(Permission.Owner)
            ? 'owner'
            : 'member',
      });
      this.memberToUpdate = data;
      this.isCreateEditModalOpen = true;
    } else {
      this.isCreateEditModalOpen = !this.isCreateEditModalOpen;
      this.isEditingMember = false;
      this.isCreatingMember = true;
    }
  }

  getDefaultGroupImage(): string {
    return this.isIndividual
      ? DEFAULT_IMAGE_KIMBO
      : this.isCompany
      ? DEFAULT_IMAGE_CORPORATE
      : this.isBenefit
      ? DEFAULT_IMAGE_BENEFIT
      : DEFAULT_IMAGE_HCP;
  }

  getGroupImageProfil(): string {
    return !this._role_selected
      ? 'assets/images/groupk.png'
      : this._role_selected.group.image_profil!.path !==
          'public/general/unknow.png' &&
        this._role_selected.group.image_profil!.path !==
          'storage/general/group.png'
      ? this.services.utilsService.getServerUrl(
          this._role_selected.group.image_profil!.path
        )
      : this.getDefaultGroupImage();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  deleteGroup() {
    this.isLoading = true;
    this.groupService.delete(this._role_selected.group_id).subscribe({
      next: res => {
        if (this._role_selected.group.type === GroupType.Companie)
          this.getUserRole();
        this.notificationService.success(
          this.translateService.instant('common.line-deleted')
        );
        this.closeModal();
      },
      error: error => {
        this.notificationService.danger(
          this.translateService.instant(error.translate)
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  setFieldValue(event: any): void {
    this.file = event.file;
    if (
      this.file != undefined &&
      this.file != null &&
      this.file!.size > MAX_FILE_SIZE
    ) {
      this.notificationService.danger(
        this.translateService.instant('errors.max-file-size-with-params', {
          filename: shortenFileName(this.file!.name),
        })
      );
      this.file = undefined;
    } else {
      this.imageName = event.url;
      this.displayableFileName = this.shortenFileName(this.file!.name);
    }
  }

  shortenFileName(longName: string): string {
    return shortenFileName(longName);
  }

  handleUploadComponentChanges(event: any): void {
    if (event === 'edit') {
      noop();
    } else if (event === 'delete') {
      this.imageName = undefined;
      this.file = undefined;
      this.displayableFileName = undefined;
    }
  }

  getUserRole(): void {
    const user = this.sessionService.getUser();
    this.userService.view(user.id).subscribe({
      next: (userRes: IUser) => {
        this.sessionService.deleteUser();
        this.sessionService.storeUser(userRes);
        if ((userRes.roles?.length ?? 0) < 1) {
          this.sessionService.logout();
        }
        this.services.eventService.publish(
          'group:update',
          userRes.roles?.[0].group
        );
      },
    });
  }

  _toMoney_(amount: number, rate: number): number {
    return toMoney(amount, rate);
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency ? this.currency.rate : 0);
  }

  _toHealthCredit(amount: number) {
    return toHealthCredit(amount, Number(this.currency.rate));
  }
}
