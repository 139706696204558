<button
  [disabled]="!disable"
  *ngIf="shape === '1'"
  class="w-36 py-3 rounded-full border-[1px] {{
    !disable ? 'disable' : 'enable'
  }} text-center shadow-btn-shadow font-semibold">
  {{ text }}
</button>
<button
  *ngIf="shape === '2'"
  class="w-36 py-3 rounded-full text-corporategreen border-[1px] border-corporategreen text-center shadow-btn-shadow font-semibold">
  {{ text }}
</button>
<button
  *ngIf="shape === '3'"
  class="w-full h-[47px] bg-corporategreen text-white font-bold {{
    addClassNames
  }}">
  {{ text }}
</button>
<button
  *ngIf="shape === '3-m'"
  class="w-full h-[47px] bg-corporategreen text-white font-bold {{
    addClassNames
  }} hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 "
  data-modal-toggle="modalId">
  {{ text }}
</button>
<button
  *ngIf="shape === '4'"
  [disabled]="disable"
  class="w-full h-12 bg-gradient-to-r from-[#5CC8AD] to-corporateblue text-white rounded-[12px] font-semibold relative">
  <span
    *ngIf="loading"
    class="absolute flex justify-center items-center inset-0 text-white">
    <app-spinner classNames="w-6 h-6 filter-white"></app-spinner>
  </span>
  <span *ngIf="!loading">{{ text }}</span>
</button>
