export function noop(): void {
  // This function should act like a placeholder,
  // so we don't need an implementation.
}

export function identity<T>(v: T): T {
  return v;
}

export function sleep(timeoutMs: number) {
  return new Promise(resolve => setTimeout(resolve, timeoutMs));
}

export function partition<T>(
  collection: T[],
  predicate: (e: T) => boolean
): [T[], T[]] {
  const trueElements: T[] = [];
  const falseElements: T[] = [];

  for (const element of collection) {
    const result = predicate(element);
    const chunk = result ? trueElements : falseElements;

    chunk.push(element);
  }

  return [trueElements, falseElements];
}

export function hasOwn<P extends string>(
  obj: unknown,
  prop: P
): obj is { [k in P]: any } {
  return (
    typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, prop)
  );
}

export function mapObject<K extends string, V, T>(
  obj: Record<K, V>,
  predicate: (value: V, key: K, index: number) => T
): T[] {
  const values: T[] = [];
  let index = 0;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      values.push(predicate(obj[key], key, index));
      index += 1;
    }
  }

  return values;
}

export function findById<T extends { id: string }>(
  collection: T[],
  id: string
): T | null {
  return collection.find(element => element.id === id) ?? null;
}

export function toggleInArray<T extends { id: string }>(
  collection: T[],
  item: T
): T[] {
  let wasInArrayAlready = false;

  const newCollection: T[] = collection.filter(element => {
    if (element.id === item.id) {
      wasInArrayAlready = true;
      return false;
    }

    return true;
  });

  if (!wasInArrayAlready) {
    newCollection.push(item);
  }

  return newCollection;
}

export function replaceInArray<T extends { id: string }>(
  collection: T[],
  replacement: T
): T[] {
  return collection.map(element => {
    if (element.id === replacement.id) {
      return replacement;
    }

    return element;
  });
}

export function arrayToObject<Element>(
  collection: Element[],
  keyOrPredicate: keyof Element | ((el: Element) => string)
): Record<string, Element> {
  return collection.reduce((obj, element) => {
    const key =
      typeof keyOrPredicate === 'function'
        ? keyOrPredicate(element)
        : String(element[keyOrPredicate]);

    return {
      ...obj,
      [key]: element,
    };
  }, {} as Record<string, Element>);
}

export function toggleInObject<T extends { id: string }>(
  obj: Record<string, T>,
  item: T
): Record<string, T> {
  const newObj: Record<string, T> = { ...obj };

  if (item.id in newObj) {
    delete newObj[item.id];
  } else {
    newObj[item.id] = item;
  }

  return newObj;
}

export function extract<T extends string>(
  field: T,
  collection: { [k in T]: string }[]
): string[] {
  return collection.map(el => el[field]);
}

export function stringOrDefault<T>(val: unknown, fallback: T): string | T {
  return typeof val === 'string' ? val : fallback;
}

export function removeSpaces(v: unknown): string {
  return typeof v === 'string' ? v.replace(/\s/g, '') : '';
}

export function shortenFileName(filename: string): string {
  if (filename) {
    const chunks = filename.split('.');
    const extension = chunks.pop();
    const filenameWithoutExtension = chunks.join('.');

    if (filenameWithoutExtension.length <= 12) {
      return filename;
    }

    return `${chunks.join('.').slice(0, 12)}...${extension}`;
  } else return '';
}

export function shortenText(str: string) {
  if (str.length <= 20) {
    return str;
  } else {
    return str.substring(0, 30) + '...';
  }
}

export function formatCardNumber(x: string) {
  const y = x.replace(/[^\d]/g, '');
  const head = y.slice(0, 2);
  const tail = y.slice(2);

  return [head, ...(tail.match(/.{1,4}/g) ?? [])].join(' ');
}

export function toHealthCredit(amount: number, rate: number): number {
  return Math.floor(rate * amount);
}

export function toHealthCreditCeil(amount: number, rate: number): number {
  return Math.ceil(rate * amount);
}

export function toMoney(healthcredit: number, rate: number): number {
  return Math.round(healthcredit / rate);
}

//return a promise that resolves with a File instance
export function urltoFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (token) {
      return token.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function parseTokNotion(token: number): string {
  if (token === 0) return '0';
  const count = token + '';
  let counter = token + '';
  if (token >= 1000 && token < 10000) {
    counter = count.substring(0, 1) + '.' + count.substring(1, 2) + 'k';
    if (count.substring(1, 2) === '0') counter = count.substring(0, 1) + 'k';
  } else if (token >= 10000 && token < 100000) {
    counter = count.substring(0, 2) + '.' + count.substring(2, 3) + 'k';
    if (count.substring(2, 3) === '0') counter = count.substring(0, 2) + 'k';
  } else if (token >= 100000 && token < 1000000) {
    counter = count.substring(0, 3) + '.' + count.substring(3, 4) + 'k';
    if (count.substring(3, 4) === '0') counter = count.substring(0, 3) + 'k';
  } else if (token >= 1000000) {
    counter = count.substring(0, 1) + '.' + count.substring(1, 2) + 'M';
    if (count.substring(1, 2) === '0') counter = count.substring(0, 1) + 'M';
  }
  return counter;
}

export function findArrayPosition(targetArray: any[], givenArray: any[]) {
  const targetString = targetArray.join(',');

  for (let i = 0; i < givenArray.length; i++) {
    const currentString = givenArray[i].join(',');

    if (targetString === currentString) {
      return i;
    }
  }

  return -1; // Indicates the target array was not found in the given array
}

export function isNumber(input: any) {
  return !isNaN(parseFloat(input)) && isFinite(input);
}

export function extractQueryParameters(url: string, keysArray: string[]) {
  const url_ = new URL(url);

  const urlParams = new URLSearchParams(url_.search);
  const result: any = {};
  let found = false;

  keysArray.forEach(key => {
    const value = urlParams.get(key);
    if (value !== null) {
      found = true;
      result[key] = value;
    }
  });

  return found ? result : null;
}

export function paginate(array: any[], page_size: number, page_number: number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function calculatePages(array: any[], x: number) {
  // Calculate the number of pages
  const numPages = Math.ceil(array.length / x);
  return numPages;
}

export function containsAlphabeticCharacters(str: string): boolean {
  const regex = /[a-zA-Z]/;
  return regex.test(str);
}

type GroupedData = {
  [key: string]: any[];
};

export function groupByDateLikeConversationFlow(
  data: any[],
  locale: 'en' | 'fr' = 'fr'
): GroupedData {
  const groupedData: GroupedData = {};
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatDate = (date: Date, locale: 'en' | 'fr'): string => {
    return date.toLocaleDateString(locale, { day: 'numeric', month: 'long' });
  };

  data.forEach(item => {
    const createDate = new Date(item.created_at);
    const dateLabel = createDate.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
    });
    const isToday = createDate.toDateString() === today.toDateString();
    const isYesterday = createDate.toDateString() === yesterday.toDateString();

    let label: string;
    if (isToday) {
      label =
        (locale === 'fr' ? "Aujourd'hui" : 'Today') +
        ', ' +
        formatDate(createDate, locale);
    } else if (isYesterday) {
      label =
        (locale === 'fr' ? 'Hier' : 'Yesterday') +
        ', ' +
        formatDate(createDate, locale);
    } else {
      label = formatDate(createDate, locale);
    }

    if (!groupedData[label]) {
      groupedData[label] = [];
    }

    groupedData[label].push(item);
  });

  return groupedData;
}

export function getGroupedKeys(groupedData: GroupedData): string[] {
  return Object.keys(groupedData).sort((a, b) => {
    const todayLabels = ["Aujourd'hui", 'Today'];
    const yesterdayLabels = ['Hier', 'Yesterday'];

    if (todayLabels.includes(a)) return 1;
    if (todayLabels.includes(b)) return -1;
    if (yesterdayLabels.includes(a)) return 1;
    if (yesterdayLabels.includes(b)) return -1;

    const dateA = new Date(a);
    const dateB = new Date(b);

    if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
      return dateA.getTime() - dateB.getTime();
    }

    return 0;
  });
}
