import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {} from '../../models/models';

@Injectable({ providedIn: 'root' })
export class ErrorsService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * add_healthcarepackage
   * @param body
   */
  public post_error(body?: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/client_error`,
      JSON.stringify(body),
      this.httpOptions
    );
  }
}
