<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<h2 class="text-navy" mat-dialog-title>
  <span>{{ 'hcp-common.check-out' | translate }}</span>
</h2>
<button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div *ngIf="isLoading" class="px-0 pt-6 h-fit md:px-8 md:pt-8">
    <div class="flex items-center justify-center w-full h-20">
      <app-spinner class="inline-block w-8 h-8 ml-4 filter-blue"></app-spinner>
    </div>
  </div>
  <div *ngIf="!isLoading" class="px-0 pt-6 h-fit md:px-8 md:pt-8">
    <app-patient-modal-header
      *ngIf="buyedPackage"
      [buyedPackage]="_buyedPackage!"
      [_buyedPackage]="buyedPackage!"></app-patient-modal-header>

    <div class="mt-7">
      <div
        *ngIf="!isCheckoutSuccess && buyedPackage && !existBill"
        class="when-the-is-final-bill">
        <div class="flex items-center justify-start gap-3">
          <button
            *ngIf="canBack"
            (click)="back()"
            class="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 rounded-full cursor-pointer border-1 border-blue hover:opacity-75 focus:outline-none focus:shadow-blue"
            type="button">
            <div
              class="w-4 h-4 fill-current text-blue"
              [inlineSVG]="'assets/svg/arrow-left.svg'"></div>
          </button>
          <div>
            <div
              class="flex items-center justify-start gap-2 text-lg font-medium text-blue">
              <span>{{ currentStepText }}</span>
              <div
                class="fill-current text-blue"
                [inlineSVG]="'assets/svg/' + currentStepIcon"></div>
            </div>
            <div
              class="px-2 py-1 text-sm rounded-full w-fit text-blue bg-blue bg-opacity-10">
              <span>
                {{
                  'common.step-of-label'
                    | translate
                      : { currentstep: currentStep, lastStep: lastStep }
                }}
              </span>
            </div>
          </div>
        </div>
        <mat-stepper linear #stepper (selectionChange)="setCurrentStep($event)">
          <mat-step [editable]="isEditable">
            <ng-template matStepLabel>{{
              'hcp-home.checkout-final-bill-step' | translate
            }}</ng-template>
            <div class="flex flex-col items-center gap-5 mt-3">
              <div
                class="flex items-center justify-center w-full gap-2 p-4 text-sm rounded-lg text-kimbo-blue bg-kimbo-blue bg-opacity-5"
                role="alert">
                <div
                  [inlineSVG]="'assets/svg/info_sec.svg'"
                  class="flex-shrink-0 w-6 h-6 mr-3 fill-current text-blue"></div>
                <span class="font-medium text-center">{{
                  'hcp-home.checkout-bill-amount-step-info' | translate
                }}</span>
              </div>

              <div
                class="flex flex-wrap items-center justify-center w-full gap-6 p-6 mx-auto sm:w-1/2 {{
                  isExtractingDatas && 'pointer-events-none opacity-50'
                }}">
                <label
                  class="w-[210px] transition-all relative rounded-xl flex flex-col items-center justify-center gap-3 py-3 px-6 cursor-pointer hover:opacity-80 hover:bg-kimbo-blue hover:bg-opacity-5 hover:border-kimbo-blue hover:border shadow-box-outer-sh {{
                    (isInCheckout || isStoringFile) &&
                      'pointer-events-none opacity-75'
                  }}"
                  for="checkout_upload">
                  <div
                    class="absolute top-0 flex items-center justify-center h-6 px-2 text-xs font-semibold text-white rounded-b-md bg-kimbo-blue w-fit shadow-dark-gray">
                    <span>{{ 'hcp-common.recommended' | translate }}</span>
                  </div>
                  <img
                    src="assets/images/upload-file-alt.png"
                    alt="webcam"
                    class="w-16 mt-6" />
                  <hr
                    class="w-1/2 h-2 border rounded-full border-kimbo-blue bg-kimbo-blue" />
                  <span class="text-base font-semibold text-kimbo-blue">{{
                    'hcp-home.checkout-patient-mode-upload-label' | translate
                  }}</span>
                  <input
                    accept=".jpg, .jpeg, .png, application/pdf"
                    class="hidden"
                    id="checkout_upload"
                    (change)="handleFileUploadChanges($event)"
                    type="file" />
                </label>
                <div
                  (click)="chooseCreateBill($event)"
                  class="w-[200px] transition-all relative rounded-xl flex flex-col items-center justify-center gap-3 py-3 px-6 cursor-pointer hover:opacity-80 hover:bg-kimbo-blue hover:bg-opacity-5 hover:border-kimbo-blue hover:border shadow-box-outer-sh {{
                    (isInCheckout || isStoringFile) &&
                      'pointer-events-none opacity-75'
                  }}">
                  <mat-progress-bar
                    *ngIf="isInCheckout || isStoringFile"
                    mode="indeterminate"
                    class="absolute top-0"></mat-progress-bar>
                  <img
                    src="assets/images/document-alt.png"
                    alt="sms"
                    class="w-16 mt-6" />
                  <hr
                    class="w-1/2 h-2 border rounded-full border-kimbo-blue bg-kimbo-blue" />
                  <span class="text-base font-semibold text-kimbo-blue">{{
                    'hcp-home.checkout-patient-mode-create-label' | translate
                  }}</span>
                </div>
              </div>
              <div
                *ngIf="isExtractingDatas"
                class="w-11/12 sm:w-1/3 flex items-center justify-center mx-auto">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            </div>
          </mat-step>
          <mat-step
            *ngIf="patientCheckoutMode === 'UPLOAD_INVOICE'"
            [editable]="isEditable">
            <ng-template matStepLabel>{{
              'hcp-home.checkout-bill-amount-step' | translate
            }}</ng-template>
            <div
              *ngIf="withOldWay"
              class="mt-6 flex flex-col sm:flex-row gap-5 md:gap-10 items-center justify-center  {{
                (isInCheckout || isStoringFile) &&
                  'pointer-events-none opacity-75'
              }}">
              <ng-container
                [ngTemplateOutlet]="confirmationStep"></ng-container>
              <div
                class="flex flex-col items-center justify-center md:justify-between gap-5 w-full md:w-1/3-with-gap h-[208px]">
                <app-form-field
                  [label]="
                    'common.final-amount'
                      | translate : { currency: currency.symbol }
                  "
                  class="w-full"
                  [error]="
                    billAmount > validAmount
                      ? ('errors.invalid-bill-amount'
                        | translate
                          : {
                              balance: validAmount,
                              currency: currency.symbol
                            })
                      : billAmount < fileAmount
                      ? ('errors.small-bill-amount'
                        | translate
                          : {
                              balance: fileAmount,
                              currency: currency.symbol
                            })
                      : ''
                  ">
                  <input
                    class="
               {{ input_cls.common }}
             "
                    type="number"
                    [placeholder]="validAmount"
                    (input)="setBillAmount($event)"
                    [value]="billAmount"
                    id="name" />
                </app-form-field>
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="openConfirmDialog()"
                  [disabled]="
                    isInCheckout ||
                    isStoringFile ||
                    !billAmount ||
                    billAmount > validAmount ||
                    billAmount < fileAmount
                  "
                  class="w-full h-12 uppercase">
                  <span *ngIf="!isInCheckout && !isStoringFile">{{
                    'common.confirm-button' | translate
                  }}</span>
                  <app-spinner
                    *ngIf="isInCheckout || isStoringFile"
                    class="inline-block w-6 h-6 filter-blue"></app-spinner>
                  <mat-icon *ngIf="!isInCheckout && !isStoringFile" class="ml-1"
                    >arrow_forward</mat-icon
                  >
                </button>
              </div>
            </div>

            <div
              *ngIf="!withOldWay && medicalSpecialitiesLines"
              class="mt-6 flex flex-col items-center justify-center">
              <div
                class="p-4 mt-6 text-sm text-kimbo-blue rounded-sm bg-kimbo-blue bg-opacity-5 flex justify-center gap-2 items-center"
                role="alert">
                <i class="ri-information-2-line text-xl"></i>
                <span>{{
                  'hcp-common.alert-info-price-invoice'
                    | translate
                      : {
                          amount: _toMoney(1),
                          currency: currency.symbol
                        }
                }}</span>
              </div>

              <div
                *ngIf="medicalSpecialitiesLines.length > 0"
                class="flex justify-end flex-shrink-0 w-full gap-2 py-4 font-semibold">
                <button
                  *ngIf="!isAddLineMode"
                  mat-stroked-button
                  color="primary"
                  [disabled]="isAddLineMode || isEditingLineMode.length > 0"
                  class="w-full h-12 md:w-1/3"
                  (click)="setAddLineMode()">
                  <mat-icon class="mr-1">add</mat-icon>
                  {{ 'forms.add-line' | translate }}
                </button>
                <button
                  *ngIf="isAddLineMode"
                  mat-stroked-button
                  color="primary"
                  class="w-full h-12 md:w-1/3"
                  (click)="cancelAddingLineMode()">
                  <mat-icon class="mr-1">cancel</mat-icon>
                  {{ 'common.cancel-button-alt' | translate }}
                </button>
              </div>
              <div
                *ngIf="medicalSpecialitiesLines.length > 0"
                class="flex flex-wrap justify-center w-full gap-2">
                <div
                  class="w-full flex"
                  *ngIf="!withOldWay && medicalSpecialitiesLines">
                  <div class="inline-block min-w-full py-2 align-middle">
                    <div
                      class="overflow-y-visible border border-gray-200 dark:border-gray-700 md:rounded-lg">
                      <table
                        class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 {{
                          isCreatingInvoice
                            ? 'opacity-50 pointer-events-none'
                            : ''
                        }}">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            <th
                              scope="col"
                              class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              {{ 'forms.wording' | translate }}
                            </th>
                            <th
                              scope="col"
                              class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              {{ 'forms.specialities' | translate }}
                            </th>
                            <th
                              scope="col"
                              class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              {{ 'forms.price' | translate }}
                            </th>
                            <th
                              scope="col"
                              class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              {{ 'forms.doctor-name' | translate }}
                            </th>
                            <th
                              scope="col"
                              class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              <span class="data-table-actions">Action(s)</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          <tr
                            *ngFor="
                              let item of medicalSpecialitiesLines;
                              let i = index
                            ">
                            <td
                              (click)="toggleLineEdition(item)"
                              class="px-4 py-4 text-sm text-left font-medium text-gray-500 cursor-pointer">
                              <input
                                *ngIf="item.isEditionMode"
                                class="{{ input_cls.common }}"
                                type="text"
                                [(ngModel)]="item.name"
                                [placeholder]="'forms.service' | translate"
                                [id]="'name_' + i" />
                              <a
                                *ngIf="!item.isEditionMode"
                                class="font-bold pointer-events-none text-blue">
                                {{ item.name }}
                              </a>
                            </td>
                            <td class="px-4 py-4 text-sm text-left">
                              <app-global-dropdown
                                *ngIf="speciaityDropdown"
                                (triggerEvent)="
                                  handleSpecialityDropdownEvent($event, item)
                                "
                                [options]="speciaityDropdown"
                                [optionWidth]="'w-75'"
                                caller="_SPECIALTY"
                                [formRight]="true">
                              </app-global-dropdown>
                            </td>
                            <td
                              class="px-4 py-4 text-left text-sm cursor-pointer"
                              (click)="toggleLineEdition(item)">
                              <div
                                class="{{
                                  item.isEditionMode
                                    ? 'text-navy'
                                    : 'font-bold text-kimbo-blue'
                                }} flex items-center gap-2">
                                <app-form-field
                                  *ngIf="item.isEditionMode"
                                  [error]="
                                    validPriceAmount &&
                                    item.amount >= _toMoney(1)
                                      ? ''
                                      : ('hcp-common.min-amount'
                                        | translate
                                          : {
                                              amount: _toMoney(1),
                                              currency: currency.symbol
                                            })
                                  ">
                                  <input
                                    class="{{ input_cls.common }}"
                                    type="text"
                                    (input)="verifyAmount($event)"
                                    [(ngModel)]="item.amount"
                                    [placeholder]="'forms.price' | translate"
                                    [id]="'price_' + i" />
                                </app-form-field>
                                <span *ngIf="!item.isEditionMode">{{
                                  item.amount
                                }}</span>
                                <span
                                  class="font-medium text-sm text-kimbo-blue">
                                  {{ currency.symbol }}
                                </span>
                              </div>
                            </td>
                            <td
                              class="px-4 py-4 text-left text-sm cursor-pointer"
                              (click)="toggleLineEdition(item)">
                              <div
                                class="{{
                                  item.isEditionMode
                                    ? 'text-navy'
                                    : 'font-bold text-kimbo-blue'
                                }} flex items-center gap-2">
                                <input
                                  *ngIf="item.isEditionMode"
                                  class="{{ input_cls.common }}"
                                  type="text"
                                  [(ngModel)]="item.doctor_name"
                                  [placeholder]="
                                    'forms.doctor-name' | translate
                                  "
                                  [id]="'doctor_' + i" />
                                <span *ngIf="!item.isEditionMode">{{
                                  item.doctor_name
                                }}</span>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-left text-sm">
                              <div class="flex items-center w-fit">
                                <button
                                  *ngIf="!item.isEditionMode"
                                  [disabled]="isAddLineMode"
                                  mat-icon-button
                                  color="primary"
                                  (click)="toggleLineEdition(item)"
                                  [matTooltip]="
                                    'common.edit-button-label-alt' | translate
                                  ">
                                  <i class="ri-edit-2-line text-xl"></i>
                                </button>
                                <button
                                  *ngIf="
                                    !item.isEditionMode &&
                                    medicalSpecialitiesLines.length > 1
                                  "
                                  [disabled]="
                                    isAddLineMode ||
                                    isEditingLineMode.length > 0
                                  "
                                  mat-icon-button
                                  id="deleteBtn"
                                  color="warn"
                                  (click)="deleteItem(i)"
                                  [matTooltip]="
                                    'admin-layout.delete-action-label'
                                      | translate
                                  ">
                                  <i class="ri-delete-bin-2-line text-xl"></i>
                                </button>
                                <button
                                  *ngIf="item.isEditionMode"
                                  mat-stroked-button
                                  color="primary"
                                  class=""
                                  [disabled]="
                                    !item.canSave || !validPriceAmount
                                  "
                                  (click)="toggleLineEdition(item, true)">
                                  {{ 'common.save-label' | translate }}
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="medicalSpecialitiesLines.length === 0">
                            <td
                              colspan="5"
                              class="p-2 break-words flex-shrink-0 w-full text-center text-gray">
                              {{ 'forms.no-data' | translate }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <span
                  *ngIf="!validLinesAmount"
                  class="mt-4 font-semibold text-center text-red">
                  {{
                    'errors.invalid-finalbill-amount'
                      | translate
                        : {
                            max: _toMoney(buyedPackage.health_credit_amount),
                            min: _toMoney(buyedPackage.file_amount ?? 0),
                            currency: currency.symbol
                          }
                  }}
                </span>
                <div class="flex justify-end flex-shrink-0 w-full py-4">
                  <button
                    *ngIf="medicalSpecialitiesLines.length > 0"
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="saveInvoiceV2()"
                    [disabled]="
                      isCreatingInvoice ||
                      !validLinesAmount ||
                      isAddLineMode ||
                      isEditingLineMode.length > 0 ||
                      isInCheckout ||
                      isStoringFile
                    "
                    class="w-full h-12 uppercase md:w-1/3">
                    <span
                      *ngIf="
                        !isCreatingInvoice && !isInCheckout && !isStoringFile
                      "
                      >CHECK OUT</span
                    >
                    <app-spinner
                      *ngIf="isCreatingInvoice || isInCheckout || isStoringFile"
                      class="inline-block w-6 h-6 filter-blue"></app-spinner>
                    <mat-icon
                      *ngIf="
                        !isCreatingInvoice && !isInCheckout && !isStoringFile
                      "
                      class="ml-1"
                      >arrow_forward</mat-icon
                    >
                  </button>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step
            *ngIf="patientCheckoutMode === PATIENTCHECKOUTMODE.CREATE_INVOICE"
            [editable]="isEditable">
            <ng-template matStepLabel>{{
              'hcp-home.checkout-create-bill-step' | translate
            }}</ng-template>
            <div class="flex flex-col items-center gap-5 mt-3">
              <div
                class="flex items-center justify-start w-full gap-2 p-4 text-sm rounded-lg text-kimbo-blue bg-kimbo-blue bg-opacity-5"
                role="alert">
                <div
                  [inlineSVG]="'assets/svg/info_sec.svg'"
                  class="flex-shrink-0 w-6 h-6 mr-3 fill-current text-blue"></div>
                <span class="font-medium text-center">{{
                  (!isAddLineMode
                    ? 'hcp-home.checkout-create-bill-step-info'
                    : isAddLineMode && selectedHealthcareModules.length === 0
                    ? 'hcp-home.choose-health-bundle-info'
                    : 'hcp-home.enter-bill-amount-info'
                  ) | translate
                }}</span>
              </div>
            </div>

            <div class="w-full mt-6">
              <div
                *ngIf="isAddLineMode"
                class="flex flex-wrap items-center justify-center gap-6 {{
                  selectedHealthcareModules.length === 0 && 'pb-6'
                }}">
                <div
                  *ngFor="let healthcareModule of healthPackages; let i = index"
                  (click)="toggleHealthModule($event, healthcareModule)"
                  class=" flex flex-col rounded-4xl h-full bg-white cursor-pointer handle-click {{
                    !isSelected(healthcareModule)
                      ? 'shadow-backdrop'
                      : 'shadow-inner-green-sm'
                  }} hover:shadow-inner-green-sm transition-all px-5 py-3 relative items-center justify-center mt-3">
                  <div class="absolute w-fit h-fit -top-5 handle-click">
                    <img
                      *ngIf="!isSelected(healthcareModule)"
                      appImg
                      class="w-10 h-10 mb-3 fill-current handle-click"
                      [src]="healthcareModule.image_profil | imageToUrl"
                      alt="module image" />
                    <img
                      *ngIf="isSelected(healthcareModule)"
                      appImg
                      class="w-8 h-8 mb-3 fill-current handle-click"
                      src="assets/svg/success.svg"
                      alt="module image" />
                  </div>

                  <div
                    class="flex items-center justify-center gap-2 handle-click">
                    <input
                      type="radio"
                      [id]="'module_' + i"
                      name="health_module"
                      [checked]="isSelected(healthcareModule)"
                      class="w-4 h-4 text-[#2EA50E] bg-gray-100 border-gray-300 rounded-full focus:ring-[#2fad0c] handle-click" />
                    <div
                      class="text-base font-semibold text-center text-navy handle-click">
                      {{
                        healthcareModule.title.languages
                          | textLanguages : currentLanguage
                      }}
                    </div>
                    <app-info-component
                      *ngIf="healthcareModule.description"
                      [info]="
                        healthcareModule.description.languages | textLanguages
                      "></app-info-component>
                  </div>
                </div>
              </div>
              <div
                *ngIf="isAddLineMode && selectedHealthcareModules.length > 0"
                class="flex flex-col items-center justify-center gap-5">
                <app-form-field
                  [label]="
                    ('forms.price' | translate) + '(' + currency.symbol + ')'
                  "
                  class="w-full mt-6 md:w-1/3"
                  [error]="
                    billAmount > validAmountCreateInv
                      ? ('errors.invalid-bill-amount'
                        | translate
                          : {
                              balance: validAmountCreateInv,
                              currency: currency.symbol
                            })
                      : billAmount < fileAmount
                      ? ('errors.small-bill-amount'
                        | translate
                          : {
                              balance: fileAmount,
                              currency: currency.symbol
                            })
                      : ''
                  ">
                  <input
                    class="{{ input_cls.common }}"
                    type="number"
                    min="0"
                    [(ngModel)]="price"
                    [placeholder]="'forms.price' | translate"
                    (input)="setBillAmount($event)"
                    id="price" />
                </app-form-field>
                <div class="flex flex-col w-full gap-5 mt-1 md:w-1/3">
                  <button
                    mat-raised-button
                    color="primary"
                    [disabled]="
                      price <= 0 ||
                      !billAmount ||
                      billAmount > validAmount ||
                      billAmount > validAmountCreateInv ||
                      billAmount < fileAmount
                    "
                    class="w-full h-12"
                    (click)="addInvoiceLine()">
                    <mat-icon class="mr-1">add</mat-icon>
                    {{ 'forms.add' | translate }}
                  </button>
                  <button
                    mat-button
                    color="primary"
                    class="w-full h-12"
                    (click)="resetHealthCareModuleSelection()">
                    <mat-icon class="mr-1">close</mat-icon>
                    {{ 'common.cancel-button-alt' | translate }}
                  </button>
                </div>
              </div>

              <div
                *ngIf="!isAddLineMode && lines.length > 0"
                class="flex justify-end flex-shrink-0 w-full gap-2 py-4 font-semibold">
                <button
                  mat-stroked-button
                  color="primary"
                  class="w-full h-12 md:w-1/3"
                  (click)="setAddLineMode()">
                  <mat-icon class="mr-1">add</mat-icon>
                  {{ 'forms.add-line' | translate }}
                </button>
              </div>
              <div
                *ngIf="!isAddLineMode && lines.length > 0"
                class="flex flex-wrap justify-center w-full gap-2">
                <div
                  class="flex flex-col items-start w-full {{
                    isCreatingInvoice ? 'opacity-50 pointer-events-none' : ''
                  }}">
                  <div
                    class="flex items-start w-full text-xs border-solid rounded-t-lg border-b-1 border-gray">
                    <div
                      class="__cell text-dark-gray p-2 flex items-start w-[40%]">
                      {{ 'forms.wording' | translate }}
                    </div>
                    <div
                      class="__cell text-dark-gray p-2 flex items-start w-[30%]">
                      {{ 'forms.price' | translate }}
                    </div>
                    <div
                      class="__cell text-dark-gray p-2 flex items-end justify-center w-[30%]">
                      <span>Action</span>
                    </div>
                  </div>
                  <div
                    *ngFor="let item of lines"
                    class="flex items-center w-full border-solid __row border-b-1 border-gray">
                    <div
                      class="__cell p-2 break-words flex gap-2 justify-start items-center flex-shrink-0 w-[40%] blank-image">
                      <a class="font-bold pointer-events-none text-blue">
                        {{ item.libelle }}
                      </a>
                    </div>
                    <div
                      class="__cell p-2 break-words flex gap-2 justify-start items-center flex-shrink-0 w-[30%] blank-image">
                      <a class="font-bold pointer-events-none text-blue">
                        {{ item.price + ' ' + currency.symbol }}
                      </a>
                    </div>

                    <div
                      class="__cell p-2 break-words flex-shrink-0 flex items-center justify-end w-[30%]">
                      <div class="flex items-center w-fit">
                        <button
                          mat-icon-button
                          color="warn"
                          [matTooltip]="'common.delete-label' | translate"
                          (click)="removeLine(item)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  *ngIf="currentAmount < fileAmount"
                  class="mt-4 font-semibold text-center text-red">
                  {{
                    'errors.small-bill-amount'
                      | translate
                        : { balance: fileAmount, currency: currency.symbol }
                  }}
                </span>
                <div class="flex justify-end flex-shrink-0 w-full py-4">
                  <button
                    *ngIf="lines.length > 0"
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="createInvoice()"
                    [disabled]="isCreatingInvoice"
                    class="w-full h-12 uppercase md:w-1/3">
                    <span *ngIf="!isCreatingInvoice">{{
                      'hcp-home.create-invoice-alt' | translate
                    }}</span>
                    <app-spinner
                      *ngIf="isCreatingInvoice"
                      class="inline-block w-6 h-6 filter-blue"></app-spinner>
                    <mat-icon *ngIf="!isCreatingInvoice" class="ml-1"
                      >arrow_forward</mat-icon
                    >
                  </button>
                </div>
              </div>
              <div
                *ngIf="!isAddLineMode && lines.length === 0"
                (click)="setAddLineMode()"
                class="flex flex-col items-center content-center justify-center w-full gap-4 px-4 pb-6 text-center rounded-lg cursor-pointer bg-kimbo-blue bg-opacity-5">
                <img
                  appImg
                  src="assets/gif/printing-invoices-animate.svg"
                  class="w-40"
                  alt="printing-invoices-animate" />
                <p
                  class="w-full text-base font-normal text-center md:w-9/12 text-dark-gray">
                  {{ 'hcp-home.create-line-description' | translate }}
                </p>
                <button
                  mat-raised-button
                  color="primary"
                  class="w-full h-12 mt-4 md:w-1/3">
                  <mat-icon class="mr-1">add</mat-icon>
                  {{ 'forms.add-line' | translate }}
                </button>
              </div>
            </div>
          </mat-step>
          <mat-step
            *ngIf="patientCheckoutMode === PATIENTCHECKOUTMODE.CREATE_INVOICE"
            [editable]="isEditable">
            <ng-template matStepLabel>{{
              'hcp-home.patient-confirmation-label' | translate
            }}</ng-template>
            <div
              class="flex flex-col items-center justify-center w-full gap-5 mt-3">
              <ng-container
                [ngTemplateOutlet]="confirmationStep"></ng-container>
              <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="openConfirmDialog()"
                [disabled]="isInCheckout"
                class="w-full h-12 uppercase md:w-1/2">
                <span *ngIf="!isInCheckout">{{
                  'common.confirm-button' | translate
                }}</span>
                <app-spinner
                  *ngIf="isInCheckout"
                  class="inline-block w-6 h-6 filter-blue"></app-spinner>
                <mat-icon *ngIf="!isInCheckout" class="ml-1"
                  >arrow_forward</mat-icon
                >
              </button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
      <div *ngIf="!isCheckoutSuccess && buyedPackage && existBill">
        <div
          class="flex items-center justify-start gap-2 text-lg font-medium text-blue">
          <span>{{ 'hcp-home.patient-confirmation-label' | translate }}</span>
          <div
            class="fill-current text-blue"
            [inlineSVG]="'assets/svg/confirmation.svg'"></div>
        </div>
        <div
          class="flex flex-col items-center justify-center w-full gap-5 mt-3">
          <ng-container [ngTemplateOutlet]="confirmationStep"></ng-container>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="openConfirmDialog()"
            [disabled]="isInCheckout"
            class="w-full h-12 uppercase md:w-1/2">
            <span *ngIf="!isInCheckout">{{
              'common.confirm-button' | translate
            }}</span>
            <app-spinner
              *ngIf="isInCheckout"
              class="inline-block w-6 h-6 filter-blue"></app-spinner>
            <mat-icon *ngIf="!isInCheckout" class="ml-1"
              >arrow_forward</mat-icon
            >
          </button>
        </div>
      </div>
      <div
        *ngIf="!isLoading && isCheckoutSuccess"
        class="flex flex-col items-center justify-center gap-5">
        <app-image-render
          class="w-44"
          [classNames]="'w-44'"
          img="/assets/svg/winners.svg"></app-image-render>
        <p class="font-semibold text-navy">
          {{ 'hcp-home.patient-checkout-Successful' | translate }}
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>

<ng-template #confirmationStep>
  <div
    class="relative w-full {{
      existBill ? 'md:w-1/2' : 'md:w-1/3'
    }}  rounded-md overflow-hidden ">
    <div class="flex items-center justify-end gap-2 py-1">
      <h3
        class="absolute flex items-center text-sm font-medium bg-white rounded-full bg-opacity-90 text-kimbo-blue left-2 top-2 w-fit">
        <mat-icon> attach_file </mat-icon>
        <span>{{ 'hcp-home.final-bill-card-title' | translate }}</span>
      </h3>
      <div class="flex items-center justify-end gap-2 mb-1">
        <a
          *ngIf="invoiceFiles && invoiceFiles.length > 0"
          [href]="uploadPath"
          target="_blank"
          download="filename.pdf">
          <button
            mat-icon-button
            color="primary"
            aria-label="Action download file"
            [matTooltip]="'common.download-label' | translate">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </a>
        <button
          mat-icon-button
          (click)="deleteFinaleBill()"
          color="warn"
          aria-label="Action delete file"
          [matTooltip]="'common.delete-label' | translate">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="relative w-full overflow-hidden rounded-md">
      <img
        *ngIf="imagePreview"
        [src]="uploadPath"
        alt="image preview"
        class="w-full h-[160px] object-cover" />

      <img
        *ngIf="pdfPreview"
        [src]="'assets/images/document-PDF.png'"
        alt="document-PDF"
        class="w-full h-[160px] object-contain" />

      <div
        class="glass-morphism-black p-3 flex items-center justify-between absolute bottom-0 left-0 right-0 h-[70px] gap-2">
        <div class="flex items-center justify-start gap-2">
          <div
            class="text-white fill-current"
            [inlineSVG]="
              imagePreview ? 'assets/svg/image.svg' : 'assets/svg/pdf.svg'
            "></div>
          <div class="flex flex-col justify-end">
            <p class="text-base font-semibold text-white md:text-lg">
              {{ fileName }}
            </p>
            <p
              *ngIf="invoiceFiles && invoiceFiles.length > 0"
              class="text-[10px] font-medium text-white">
              {{ _toLocaleDateTime(invoiceFiles[0].created_at) }}
            </p>
          </div>
        </div>
        <div
          *ngIf="invoiceFiles && invoiceFiles.length > 0"
          class="flex flex-col items-end justify-center gap-1 text-white">
          <div
            class="text-white fill-current"
            [inlineSVG]="'assets/svg/money.svg'"></div>
          <p class="text-base font-semibold text-white md:text-lg">
            {{ _toMoney(invoiceFiles[0].data.amount) }} {{ currency.symbol }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
