<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="px-2 sm:px-6 h-[80px] bg-white flex items-center justify-between shadow-md shadow-backdrop-light sticky top-0 left-0 z-mobile-bar">
  <div
    (click)="toggleSideMenuOpen()"
    matRipple
    matRippleColor="rgba(30,104,194,.1)"
    class="rounded-md p-2 bg-kimbo-blue w-12 h-12 bg-opacity-5 cursor-pointer">
    <i class="ri-menu-2-line text-kimbo-blue text-3xl"></i>
  </div>

  <div class="w-fit justify-center sm:ml-auto flex items-center h-full">
    <app-menu-role-select caller="_BENEFIT"></app-menu-role-select>
  </div>
</div>
