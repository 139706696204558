import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content, IUser, UserStore } from '../../models/models';
import { Note, NoteStore } from '../../models/Note';

@Injectable()
export class NoteService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param buyedpackageId
   * @param noteId
   */
  public delete(buyedpackageId: number, noteId: number): Observable<Note> {
    return this.http.delete<Note>(
      `${this.basePath}/v1/resources/buyedpackage/${buyedpackageId}/note/${noteId}`,
      this.httpOptions
    );
  }

  /**
   * store
   * @param buyedpackageId
   * @param body
   */
  public store(buyedpackageId: number, body?: NoteStore): Observable<Note> {
    return this.http.post<Note>(
      `${this.basePath}/v1/resources/buyedpackage/${buyedpackageId}/note`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param buyedpackageId
   * @param noteId
   * @param body
   */
  public update(
    buyedpackageId: number,
    noteId: number,
    body?: NoteStore
  ): Observable<Note> {
    return this.http.put<Note>(
      `${this.basePath}/v1/resources/buyedpackage/${buyedpackageId}/note/${noteId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Notes
   * @Param page
   * @param buyedpackageId
   */
  public index(
    page: number,
    buyedpackageId: number
  ): Observable<Content<Note>> {
    let queryParameters = new HttpParams({ fromObject: { page: page } });

    return this.http.get<Content<Note>>(
      `${this.basePath}/v1/resources/buyedpackage/${buyedpackageId}/note`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * View
   * @param buyedpackageId
   */
  public view(buyedpackageId: number): Observable<Note> {
    return this.http.get<Note>(
      `${this.basePath}/v1/resources/buyedpackage/${buyedpackageId}`,
      this.httpOptions
    );
  }
}
