import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss'],
})
export class PreviewImageComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { image: string; name: string },
    public dialogRef: MatDialogRef<PreviewImageComponent>
  ) {}

  ngOnInit(): void {}
}
