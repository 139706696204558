import { TranslateService } from '@ngx-translate/core';
import { BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AdminLogTag, AdminLogsTarget, PERIOD } from 'src/app/core/enums/enums';
import { PermissionEnums } from 'src/app/core/enums/permission-enums';
import { AdminLog } from 'src/app/core/models/AdminMonitoring';
import { Content } from 'src/app/core/models/Common';
import { AdminMonitoringService } from 'src/app/core/rest-services/rest-services';
import { AdminRoleService } from 'src/app/core/services/admin-role.service';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { NavService } from 'src/app/core/services/navservice';
import { ServicesService } from 'src/app/core/services/services.service';
import { SwitcherService } from 'src/app/core/services/switcher.service';
import { groupByDateLikeConversationFlow } from 'src/app/core/utils/misc';
import { OptionObject, PaginationElement } from 'src/app/core/utils/type';
import { EditUserComponent } from 'src/app/pages/admin/components/edit-user/edit-user.component';

@Component({
  selector: 'app-usage-logs',
  templateUrl: './usage-logs.component.html',
  styleUrls: ['./usage-logs.component.scss'],
})
export class UsageLogsComponent implements OnInit, AfterViewInit, OnDestroy {
  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });
  moment = moment;
  currentLang!: string;
  isLoading = false;
  page = 1;
  pagination!: PaginationElement;
  currentTarget!: AdminLogsTarget;
  adminLogs!: AdminLog[];
  activeUrl = '';
  logsTargets: { path: string; value: AdminLogsTarget }[] = [
    { path: '/admin', value: AdminLogsTarget.User },
    { path: '/admin/role', value: AdminLogsTarget.Adminrole },
    { path: '/admin/settings', value: AdminLogsTarget.Setting },
    { path: '/admin/country', value: AdminLogsTarget.Countrie },
    { path: '/admin/campaigns', value: AdminLogsTarget.Campaign },
    {
      path: '/admin/healthcare-providers',
      value: AdminLogsTarget.Healthcareprovider,
    },
    {
      path: '/admin/healthcare-package',
      value: AdminLogsTarget.Healthcarepackage,
    },
    { path: '/admin/buyedpackages', value: AdminLogsTarget.Buyedpackage },
    { path: '/admin/currencies', value: AdminLogsTarget.Currencie },
    { path: '/admin/transactions', value: AdminLogsTarget.Payment },
    { path: '/admin/invoice', value: AdminLogsTarget.Invoice },
    { path: '/admin/discount', value: AdminLogsTarget.Discount },
  ];
  canShowLogs = false;
  adminLogTag = AdminLogTag;
  adminLogTarget = AdminLogsTarget;
  optionPeriodDropdown!: OptionObject[];
  filterPickedValue?: OptionObject;
  translations: any = {
    all: '',
    thisDay: '',
    thisWeek: '',
    thisMonth: '',
    thisYear: '',
    custom: '',
  };
  translationKeys: string[] = [
    'admin-fields.all-campaign',
    'admin-fields.day',
    'admin-fields.week',
    'admin-fields.month',
    'admin-fields.year',
    'admin-fields.custom-period',
  ];
  _PERIOD = PERIOD;
  period: 'year' | 'month' | 'day' | 'sevent_day' | 'custom' = 'year';

  groupedDatas!: {
    [key: string]: AdminLog[];
  };
  hasSuperAdminRole = false;
  $showLogs!: Subscription;

  constructor(
    public SwitcherService: SwitcherService,
    public navService: NavService,
    private elementRef: ElementRef,
    public breakpointMatcherService: BreakpointMatcherService,
    private services: ServicesService,
    private _formBuilder: FormBuilder,
    private adminMonitoringService: AdminMonitoringService,
    private router: Router,
    private adminRoleService: AdminRoleService
  ) {
    this.hasSuperAdminRole = this.adminRoleService.hasPermission(
      PermissionEnums.SuperAdmin
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = this.router.url;
        this.canShowLogs = !!this.logsTargets.find(
          (log: { path: string; value: AdminLogsTarget }) =>
            log.path === this.activeUrl
        );
        this.currentTarget =
          this.logsTargets.find(
            (log: { path: string; value: AdminLogsTarget }) =>
              log.path === this.activeUrl
          )?.value ?? AdminLogsTarget.User;
      }
    });
  }

  closeSideLogsPanel(): void {
    this.services.eventService.publish('admin-logs:close');
  }

  ngOnDestroy(): void {
    this.$showLogs?.unsubscribe();
  }

  ngOnInit(): void {
    this.$showLogs = this.services.eventService.subscribe(
      'show-admin:logs',
      () => {
        this.page = 1;
        this.getAdminLogs();
      }
    );
    this.currentLang = this.services.translateService.currentLang;
    moment.locale(this.currentLang);
    this.getTranslations();
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  getAdminLogs(): void {
    this.isLoading = true;
    this.adminMonitoringService
      .getAdminLogs(this.page, this.currentTarget, this.period)
      .subscribe({
        next: (res: Content<AdminLog>) => {
          this.adminLogs = res.data;
          this.pagination = {
            page: this.page,
            totalPages: res.last_page,
            total: res.total,
            perPage: res.per_page,
            form: res.from,
            to: res.to,
          };
          this.groupedDatas = this._groupByDateLikeConversationFlow();
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  clearToggle() {
    const html = this.elementRef.nativeElement.ownerDocument.documentElement;
    html?.setAttribute('toggled', 'close');
    document.querySelector('#responsive-overlay')?.classList.remove('active');
  }

  onInLogsScroll(): void {
    this.page++;
    if (this.page <= this.pagination.totalPages) {
      this.getAdminLogs();
    }
  }

  openUserModal(id: number) {
    this.services.modalService.openModal(EditUserComponent, {
      width: '950px',
      height: 'auto',
      data: {
        caller: '_UPDATE',
        state: id,
      },
    });
  }

  getGroupedKeys(): string[] {
    return Object.keys(this.groupedDatas);
  }

  _groupByDateLikeConversationFlow(): {
    [key: string]: AdminLog[];
  } {
    return groupByDateLikeConversationFlow(
      this.adminLogs,
      this.services.translateService.currentLang.toLocaleLowerCase() as
        | 'fr'
        | 'en'
    );
  }

  getFilter(): void {
    this.optionPeriodDropdown = [
      {
        label: this.translations.thisDay,
        value: PERIOD.DAY,
      },
      {
        label: this.translations.thisWeek,
        value: PERIOD.WEEK,
      },
      {
        label: this.translations.thisMonth,
        value: PERIOD.MONTH,
      },
      {
        label: this.translations.thisYear,
        value: PERIOD.YEAR,
      },
    ];
  }

  handlePeriodDropdownEvent(period: any): void {
    if (period !== 'open') {
      this.period = period.value as PERIOD;
      this.getFilter();
      this.page = 1;
      if (this.canShowLogs && this.hasSuperAdminRole) this.getAdminLogs();
    }
  }

  public listenForLanguageChangeEvents(): void {
    this.services.eventService.subscribe('change:language', () => {
      this.getTranslations();
      this.currentLang = this.services.translateService.currentLang;
      moment.locale(this.currentLang);
    });
  }

  getTranslations(): void {
    this.services.translateService
      .get(this.translationKeys)
      .subscribe(translations => {
        this.translations.all = translations['admin-fields.all-campaign'];
        this.translations.thisDay = translations['admin-fields.day'];
        this.translations.thisWeek = translations['admin-fields.week'];
        this.translations.thisMonth = translations['admin-fields.month'];
        this.translations.thisYear = translations['admin-fields.year'];
        this.translations.custom = translations['admin-fields.custom-period'];
        this.getFilter();
      });
  }
}
