<div
  *ngIf="caller === '_GOBAL'"
  class="w-full font-bold transition-all {{
    withArrow ? 'flex justify-between cursor-pointer py-4' : ''
  }} {{ classNames }} {{
    isOpen ? bgColor + ' rounded-md text-white px-4' : ''
  }}">
  <div class="flex justify-start items-center gap-2">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <ng-content select="[slot=global]"></ng-content>
  </div>

  <img
    appImg
    *ngIf="withArrow"
    aria-label="chevron icon"
    class="flex items-center w-6 filter-blue {{
      isOpen ? 'rotate-120 filter-white' : '-rotate-180'
    }}"
    src="assets/svg/chevron-up.svg"
    alt="arrow" />
</div>

<div
  *ngIf="caller === '_GOBAL_TRANS_BG'"
  class="w-full font-bold transition-all {{ classNames }} {{
    bgColor
  }} rounded-md p-3 flex justify-between cursor-pointer py-4">
  <div class="flex justify-start items-center gap-2">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <ng-content select="[slot=globaltransbg]"></ng-content>
  </div>

  <img
    appImg
    aria-label="chevron icon"
    class="flex items-center w-6 filter-blue {{
      isOpen ? 'rotate-120' : '-rotate-180'
    }}"
    src="assets/svg/chevron-up.svg"
    alt="arrow" />
</div>

<div
  *ngIf="caller === '_WITHOUTDROPDOWN'"
  class="w-full font-bold transition-all {{ classNames }} {{
    bgColor
  }} rounded-md p-3">
  <div class="flex justify-start items-center gap-2">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <ng-content select="[slot=withoutdropdown]"></ng-content>
  </div>
</div>

<div
  *ngIf="caller === '_BENEFIT'"
  class="w-full font-bold transition-all {{
    withArrow ? 'flex justify-between items-center cursor-pointer py-4' : ''
  }} {{
    classNames
  }} border-1 border-kimbo-blue-light text-kimbo-turquoise px-4 {{
    isOpen ? 'rounded-tl-md rounded-tr-md' : 'rounded-md'
  }} ">
  <div class="flex justify-start items-center gap-2">
    <ng-content select="[slot=benefit]"></ng-content>
  </div>
  <i
    class="ri-arrow-down-s-line {{
      !isOpen ? 'rotate-120' : '-rotate-180'
    }} text-kimbo-turquoise text-2xl"></i>
</div>
